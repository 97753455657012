export const ES = {
  lang: 'es',
  code: 'es-ES',
  bt_ok: 'Ok',
  bt_cancelar: 'Cancelar',
  bt_fechar: 'Cerrar',
  bt_back: 'Regresar',
  bt_apagar: 'Eliminar',
  bt_editar: 'Editar',
  bt_reordenar: 'Reordenar',
  bt_salvar: 'Guardar',
  bt_novo: 'Nuevo',
  bt_atualizar: 'Actualizar',
  bt_remover: 'Remover',
  bt_add: 'Adicionar',
  bt_filtrar: 'Filtrar',
  bt_selecionar: 'Selecionar',
  bt_clonar: 'Clonar',
  bt_enviar: 'Enviar',
  anterior: 'Anterior',
  proximo: 'Próximo',
  items_pagina: 'artículos por página',
  pagina: 'página',
  de: 'of',
  buscar: 'Buscar',
  msg_sim: 'Si',
  msg_nao: 'No',
  salvar: 'Guardar',
  homem: 'Hombre',
  mulher: 'Mujer',
  masculino: 'Masculino',
  feminino: 'Feminino',
  ambos: 'Ambos',
  veja_mais: 'Veja mais',
  recolher: 'Recolher',
  carregando3pt: 'Loading...',
  pronto: 'Pronto!',
  link_termos: 'https://supertreinosapp.com.br/termos-de-uso-es/',
  link_privacidade: 'https://supertreinosapp.com.br/politica-privacidade-es/',
  link_cancelamento: 'https://supertreinosapp.com.br/politica-de-cancelamento/',
  mais_opcoes: 'Más opciónes',
  opcoes: 'Opciónes',

  detalhes: 'Detalles',
  dificuldade: 'Dificultad',
  iniciante: 'Principiante',
  intermediario: 'Intermediario',
  avancado: 'Avanzado',
  expert: 'Expert',
  objetivo: 'Objetivo',
  semanas: 'Semanas',
  dias_por_semana: 'Dias por semana',
  tempo_treino: 'tiempo de entrenamiento',
  minutos: 'minutos',
  pontos_concluir: 'Puntos al finalizar',
  completo: 'completo',

  operacao_sucesso: 'A operação foi realizada com sucesso!',
  algo_errado: 'Algo no funcionó bien. Inténtalo de nuevo.',
  indeterminado: 'Indeterminado',
  conteudo_premium: 'Contenido premium',
  trava_generica: '¡Ups! Este es un contenido disponible para los suscriptores <span class="name">Premium</span>. ¡Suscríbase ahora!',
  trava_indisponivel: '¡Ups! ¡Este contenido ya no está disponible! Si está interesado en este contenido, comuníquese con nuestro soporte para obtener más información.',

  fechar_app: 'Cerrar',
  fechar_st: 'Cerrar SuperTreinos',
  fechar_sc: 'Cerrar ST Coach',
  fechar_sct: 'Cerrar ST Coach Pro',
  certeza: '¿Estás seguro?',
  conta_blok: 'Tu cuenta esta bloqueada. Comuníquese con el administrador.',

  campo_obrigatorio: 'Este campo es obligatorio',
  campo_invalido: 'El valor de este campo no es válido.',

  programas: 'Programas',
  programa: 'Programa',
  treinos: 'Entrenamientos',
  treino: 'Entrenamiento',
  exercicios: 'Ejercicios',
  exercicio: 'Ejercicio',
  biblioteca: 'Biblioteca',
  prescricoes: 'Recetas',
  nutricao: 'Nutrición',
  avaliacao: 'Evaluation',

  recurso_exclusivo: 'Esta función es exclusiva para suscriptores Standard, Pro o Black. ¿Le gustaría actualizar su plan?',
  recurso_exclusivo_black: '<center>Esta función es exclusiva para suscriptores Pro o Black.</center><br><center>¿Le gustaría actualizar su plan?</center>',
  recurso_exclusivo_black_only: 'Esta función es exclusiva para suscriptores Black.',
  recurso_exclusivo_title: 'Recurso Exclusivo',
  gratis: 'Gratis',

  clique_arraste_reordenar: 'Haga clic y arrastre para ordenar',
  ordernar_cima: 'Ordenar',
  ordernar_baixo: 'Ordenar',
  sexo: 'Sexo',
  nome: 'Nome',
  titulo: 'Título',
  subtitulo: 'Subtítulo',
  antes_envio_poster: 'Antes de enviar un póster, agregue un TÍTULO',
  ou: 'o',
  escolha_poster: 'Elige un póster',
  escolha_data: 'Elige una data',
  endereco_video: 'Dirección del vídeo',
  use_video_vimeo_youtube: 'Usar vídeos de Youtube o Vimeo',
  descricao: 'Descripción',
  minutos_por_dia: 'Minutos por día',
  disponivel_planos: 'Disponible para planes',
  grupos_musculares: 'Grupos de músculos',

  dz_remover_imagem: 'ELIMINAR IMAGEN',
  dz_cancelar_envio: 'CANCELAR ENVÍO',
  dz_confirma_cancelar_envio: '¿CONFIRMAR CANCELAR ENVÍO?',
  dz_arquivo_invalido: 'ARCHIVO NO VÁLIDO',

  nao_filtrar: 'No filtres',
  filtros: 'Filtres',
  limpar_filtros: 'Borrar filtros',
  publicado: 'Publicado',
  ocultado: 'Oculto',
  nao_publicado: 'No Publicado',
  reservado: 'Reservado',

  ativar: 'Activar',
  ativo: 'Ativo',
  status: 'Status',
  bloquear: 'Bloquear',
  expiracao: 'Expiración',
  bloqueado: 'Bloqueado',
  cadastro: 'Cadastro',
  historico: 'Historico',
  notas: 'Notas',
  arquivos: 'Archivos',

  aluno: 'alumno',
  paciente: 'alumno',
  dia: 'día',
  dias: 'días',
  peso: 'peso',

  limite_maximo:  'Lo sentimos, has alcanzado el número máximo de registros permitidos en esta cuenta. ' +
  'Comuníquese con el administrador de su sistema para obtener más información ' +
  'o para solicitar una actualización de su nivel de acceso.',
  limite_registros: '¡Ups! Ha alcanzado el límite del registros en su cuenta actual. Actualiza o elimina algunos registros antiguos.',
  link_copiado: 'El enlace fue copiado al Portapapeles',
  link_copiado_erro: 'Su sistema no permite la copia automática.',

  por_alimentos: 'Por alimentos',
  por_textos_livres: 'Por textos libres',
  dieta: 'Dieta',
  exportar_pdf: 'Exportar PDF',
  assinar_black: 'Suscribir Black',

  // MENUS
  MenuUI: {
    menu_idioma: 'Idioma',
    menu_minhaconta: 'Mi cuenta',
    menu_suporte: 'Soporte',
    menu_sair: 'Cerrar',
    menu_dashboard: 'Panel de control',
    menu_programas: 'Programas',
    menu_alunos: 'Alumnos',
    menu_nutricao: 'Nutrición',
    menu_biblioteca: 'Biblioteca',
    menu_arquivos: 'Archivos',
    menu_loja: 'Tienda',
    menu_tutoriais: 'Tutoriales',
    ptbr: 'Portuguese',
    enus: 'English',
    eses: 'Spanish',
    assinatura_pendente: 'Subscrippción Pendiente',
    assinatura_pendente_regularize: 'Regulariza tu suscripción en un plazo de 3 días para evitar el bloqueo de tu cuenta',
    assinatura_pendente_gerenciar: 'Administrar suscripción',
    assinatura_pendente_regularizada: '¡Firma regularizada exitosamente!',
  },

  // LOGIN
  LoginPage: {
    hello: 'Hola',
    acesse: 'Acceso',
    email: 'E-mail',
    senha: 'Contraseña',
    entrar: 'Acceso',
    proxima: 'Siguiente',
    anterior: 'Anterior',
    sem_conta: '¿No tienes una cuenta?',
    sem_conta_buscar_shape: '¡Pongámonos en forma!',
    digite_email: 'Ingrese su correo electrónico para iniciar sesión en el sistema',
    digite_senha: 'Ingrese su contraseña para iniciar sesión en el sistema',
    cadastrese: '¡Regístrese aquí!',
    criarconta: 'Crear cuenta',
    gratis: '¡Es gratis!',
    erro_login: 'Inicio de sesión / Contraseña incorrecta.',
    senha_invalida: 'Contraseña incorrecta.',
    user_notfound: 'Cuenta incorrecta',
    login: 'Login',
    esqueceu: '¿olvido la contraseña?',
    erro_login_user: 'Usuario no encontrado. Compruebe que el correo electrónico de inicio de sesión es correcto.',
    erro_login_user_st: 'Esta cuenta solo es válida para usar en la aplicación SuperTreinos.',
    erro_login_user_sc: 'Esta cuenta solo es válida para usar en la aplicación STCoach.',
    erro_login_wait: 'Usuario en espera de activación.',
    erro_login_block: '¡Ups! Su acceso está bloqueado. Póngase en contacto con su entrenador personal.',
    erro_login_pwd: 'Contraseña incorrecta.',
    erro_login_empty: 'Nombre de usuario y/o contraseña no proporcionados.',
    erro_login_generic: 'Algo no funciona correctamente. Nueva llegada.',
  },

  // CADASTRO
  RegistrationPage: {
    cadastro: 'Cadastro',
    ficha: '¡Regístrese y comience a entrenar ahora mismo!',
    ficha_nutri: 'Cadastro para Nutricionistas. <br> Os campos com (*) são obrigatórios.',
    not_customer: 'Não é permitido cadastro de alunos.',
    nome: 'Nombre',
    email: 'E-mail',
    email_confirme: 'Confirmar E-mail',
    ddi: 'DDI',
    celular: 'Número de Teléfono',
    nascimento: 'Fecha de Nacimiento',
    sexo: 'Sexo',
    homem: 'Hombre',
    mulher: 'Mujer',
    senha: 'Contraseña (mínimo 6 dígitos)',
    confirme: 'Confirmar Contraseña',
    ao_cadastrar: 'Al registrarme, declaro haber leído y aceptado el',
    termos: 'condiciones de uso',
    privacidade: 'política de privacidad',
    vogal_e: 'y',
    cancelamento: 'política de cancelación',
    cadastrar: 'REGISTRARSE',
    sucesso: 'Bienvenido [name]. ¡Tu registro fue exitoso!',
    profissao: 'Profesión',
    edfisica: 'Profesional de Educación Física',
    fisio: 'Fisioterapeuta',
    nutri: 'Nutricionista',
    estud: 'Alumno',
    cpf: 'Campo sólo para residentes en Brasil.',
    cref: 'Campo sólo para residentes en Brasil.',
    crefito: 'Campo sólo para residentes en Brasil.',
    crn: 'Campo sólo para residentes en Brasil.',    
    nutriname: 'Campo sólo para residentes en Brasil.',
    cpf_not_valid: 'Campo sólo para residentes en Brasil.',    
    cref_not_empty: 'Campo sólo para residentes en Brasil.',
    crefito_not_empty: 'Campo sólo para residentes en Brasil.',
    crn_not_empty: 'Campo sólo para residentes en Brasil.',
    nutriname_not_empty: 'Campo sólo para residentes en Brasil.',
    sou_estrangeiro: 'Soy extranjero y declaro que estoy capacitado ejercer la profesión de acuerdo con las determinaciones de mi país.',
    senha_diferente: '¡Las contraseñas no coinciden!',
    email_diferente: '¡Los correos electrónicos no coinciden!',
    escolha_prof: '¡Selecciona al menos una profesión!',
    email_cadastrado: 'Correo eletrónico existente! Por favor registre otro correo electrónico.',
    campo_obrigatorio: 'Este campo es obligatorio',
    campo_invalido: 'El valor de este campo no es válido.',
    ddd: 'número de teléfono',
  },

  // DASHBOARD
  DashboardPage: {
    assinatura: 'Plan',
    assinar: 'Suscribirse',
    vencimento: 'Expiración',
    renovar: 'Renovar',
    lista_espera: 'Lista de Espera',
    nenhum_aluno_espera: 'No hay alumnos esperando',
    cliente: 'Alumno',
    clientes: 'Alumnos',
    em_espera: 'Esperando',
    ver_lista: 'Ver lista',
    veja_como_funciona: 'ver cómo funciona',
    link_captacao: 'Capturar enlace',
    link_copiado: 'El enlace fue copiado al Portapapeles',
    link_copiado_erro: 'Su sistema no permite la copia automática.',
    form_cadastro: 'Formulario de registro: crea tu cuenta',
    ver_todos: 'ver todo',
    alunos_cadastrados: 'Alumnos',
    pacientes_cadastrados: 'Status',
    ativos: 'Activos',
    expirados: 'Caducados',
    bloqueados: 'Bloqueados',
    clientes_premium: 'Alumnos Premium',
    tempo_consultoria: 'Tiempo de asesoramiento físico',
    pacientes: 'Pacientes',
    primeiro_aluno: 'Registra tu primer alumno',
    primeiro_paciente: 'Registra tu primer alumno',
    bloqueado: 'Bloqueado',
    perfil: 'Perfil',
    no_phone: 'Sin teléfono',
    tel_nao_cadastrado: '¡El número de teléfono del alumno no estaba registrado!',
    clientes_nao_retornados: 'Alumnos no devueltos. Inténtalo de nuevo',
    cadastro_primeiro: 'Registra tu primer programa',
    pg_objetivos: 'Objetivos',
    pg_dificuldade: 'Dificuldad',
    pg_programas_cadastrados: 'Programas Registrados',
    total_de: 'Total de',
    treinos_concluidos_fluxo: 'Entrenamientos completados dentro del flujo de la agenda',
    nenhum_treino_concluido: 'No se completó ningún entrenamiento',
    ultimos_treinos: 'Últimos entrenamientos',
    ver: 'Ver',
    ver_7: 'Ver 7 dias',
    ver_15: 'Ver 15 dias',
    ver_30: 'Ver 30 dias',
    dias_sem_treinos_concluidos: 'Los días sin entrenamientos completados no se muestran en el gráfico',
    ver_como_funciona: 'ver cómo funciona',
    treinos_concluidos: 'Entrenamientos completados',
  },

  // PROGRAMAS
  ProgramasPage: {
    programas: 'Programas',
    treinos: 'Entrenamientos',
    exercicios: 'Ejercicios',
    editar_programa: 'Editar el programa',
    editar_treino: 'Editar entrenamiento',
    editar_exercicio: 'Editar el ejercicio',
    click_enviar_banner: 'Haga clic aquí para enviar el banner del programa',
    arraste_banner: 'Arrastra el banner del programa aquí',
    click_enviar_capavideo: 'Haga clic aquí para enviar la portada del vídeo',
    arraste_capavideo: 'Arrastra la portada del vídeo aquí',
    click_enviar_featured: 'Haga clic aquí para enviar el banner destacado (1280px de largura por 720px de altura)',
    arraste_featured: 'Arrastra el banner destacado aquí (1280px de largura por 720px de altura)',
    atualizado_sucesso: '¡Programa actualizado exitosamente!',
    cadastrado_sucesso: '¡El programa se registró exitosamente!',
    limite_maximo:  'Lo sentimos, has alcanzado el número máximo de registros permitidos en esta cuenta. ' +
                    'Comuníquese con el administrador de su sistema para obtener más información ' +
                    'o para solicitar una actualización de su nivel de acceso.',
    limite_programas: '¡Ups! Ha alcanzado el límite del programa en su cuenta actual. Actualiza o elimina algunos programas antiguos.',
    bt_upgrade: 'UPGRADE',
    cadastro_programas: 'Registro del Programa',
    clone_confirmation: '<p>Algunos programas pueden tardar hasta 15 minutos en clonarse.</p> <p>¿Realmente quieres clonar este programa?</p>',
    clone_confirmation_title: 'Programa de clonación',
    clone_confirmation_success: 'El programa está siendo clonado. Este proceso puede tardar un poco, verifique el programa clonado más tarde.',
    apagar_programa: '¿Realmente deseas eliminar este programa? ¡Todos los entrenamientos y ejercicios de este programa también se eliminarán!',
    apagar_programa_title: 'Eliminar Programa',
    apagar_programa_placeholder: 'escribe BORRAR, en mayúsculas',
    apagar_programa_confirmacao: 'ELIMINAR',
    apagar_programa_confirmacao_sucesso: '¡El Programa y todos sus Entrenamientos y Ejercicios fueron eliminados exitosamente!',
    apagar_clone: 'Cuando dejes de clonar, todos los Entrenamientos y Ejercicios de este Programa serán eliminados.',
    apagar_clone_title: 'Detener clonación',
    apagar_clone_sucesso: '¡La clonación fue interrumpida exitosamente!',
    reordenar_programa_sucesso: '¡Programa reordenado exitosamente!',
    reordenar_programas_sucesso: '¡Programas reordenados exitosamente!',
    help_video: '<p>Si es necesario, publica un vídeo explicativo del programa para tu alumno.</p>' +
                '<p><a href="https://www.stcoach.app/tutoriais-videos/" target="_blank">Más información</a></p>',
    help_clone: '<p>Debido al volumen de contenido, puede ocurrir un error. Cancelar.</p>' +
                '<p><a href="https://www.stcoach.app/tutoriais-videos/" target="_blank">Más información</a></p>',
    banner_upload_sucesso: '¡El banner del programa se envió exitosamente!',
    thumb_upload_sucesso: '¡La miniatura del programa se cargó exitosamente!',
    menos_minuto: 'menos de 1 minuto',
    minutos: 'minutos',
    lista_programas_aluno: 'La lista de programas para alumnos ha sido actualizada.',
    lista_arquivos_aluno: 'La lista de archivos de alumnos ha sido actualizada.',
    programa_listado: 'programa listado',
    programas_listados: 'programas listados',
    disponiveis: 'disponible',
    como_criar_treinos: 'Cómo crear entrenamientos',
    processando_clonagem: 'procesando clonación',
    aguarde_15min: 'Por favor espere hasta 15 minutos.',
    atualize_tela: 'Después de este tiempo, actualiza la pantalla para que se muestre el programa.',
    selecione_alunos: 'Seleccionar alumnos para este programa',
    nenhum_aluno: 'Ningún alumno registrado',
    novo_aluno: 'nuevo alumno',
    enviar_todos: 'Enviar a todos',
    selecionado: 'Seleccionado',
    ocultado: 'Oculto',
    limpar_busca: 'Borrar búsqueda para volver a habilitar la clasificación',
    clique_arraste_reordenar: 'Haga clic y arrastre para ordenar',
    ordernar_cima: 'Ordenar',
    ordernar_baixo: 'Ordenar',
    titulo: 'Título',
    antes_envio_poster: 'Antes de enviar un póster, agregue un TÍTULO al Programa.',
    poster_programa: 'Cartel del programa',
    ou: 'o',
    escolha_poster: 'Elige un póster',
    detalhes: 'Detalles',
    endereco_video: 'Dirección del vídeo',
    use_video_vimeo_youtube: 'Usar vídeos de Youtube o Vimeo',
    descricao: 'Descripción',
    objetivo: 'Objetivo',
    dias_por_semana: 'Días por semana',
    minutos_por_dia: 'Minutos por día',
    selecione_capa: 'Selecciona un póster para el Programa',
    disponivel_planos: 'Disponible para planes',
  },

  // TREINOS
  TreinosPage: {
    click_enviar_banner: 'Haga clic y envíe el banner de entrenamiento',
    arraste_banner: 'Arrastra el banner de entrenamiento aquí',
    atualizado_sucesso: '¡Entrenamiento actualizado exitosamente!',
    cadastrado_sucesso: '¡La Capacitación fue registrada exitosamente!',
    limite_maximo:  'Lo sentimos, has alcanzado el número máximo de registros permitidos en esta cuenta. ' +
                    'Para más información contacta a tu administrador de sistemas ' +
                    'o para solicitar una actualización a su nivel de acceso.',
    limite_programas: '¡Ups! Ha alcanzado el límite del programa en su cuenta actual. Actualice o elimine algunos programas antiguos.',
    apagar_treino: '¿Realmente deseas eliminar esta Capacitación? ¡Todos los ejercicios de esta capacitación también se eliminarán!',
    apagar_treino_title: 'Eliminar entrenamiento',
    apagar_treinos: '¿Realmente deseas eliminar los entrenamientos seleccionados? ¡Los ejercicios de los entrenamientos seleccionados también se eliminarán!',
    apagar_treinos_title: 'Eliminar entrenamientos',
    apagar_treino_placeholder: 'escribe BORRAR en mayúsculas',
    apagar_treino_confirmacao: 'BORRAR',
    apagar_treino_confirmacao_sucesso: '¡Los entrenamientos y todos tus ejercicios se han eliminado correctamente!',
    title_innerhtml: '<img src="assets/imgs/icon-timer.svg" alt="">Elija la fecha de lanzamiento de esta capacitación.',
    description: 'Los entrenamientos programados se publicarán y mostrarán automáticamente dentro del programa del alumno.',
    label_date: 'Vencimiento actual:',
    reordenar_treino_sucesso: '¡Programa reordenado exitosamente!',
    reordenar_treinos_sucesso: '¡Programas reordenados exitosamente!',
    recarregando_treinos: 'Recargando entrenamiento...',
    clone_treino_msg: 'Los entrenamientos se clonarán aleatoriamente debajo de los originales.<br>Tenga cuidado al reordenarlos.',
    clone_treino_msg2: 'Se alcanzó el límite de entrenamiento. Elimina un entrenamiento si quieres hacer un clon.',
    clone_treino_title: 'Entrenamiento de clones',
    clone_treino_sucesso: '¡Entrenamiento clonado con éxito!',
    publicar_treino: 'Sesiones de formación seleccionadas estarán disponibles para los alumnos. Quieres proceder?',
    publicar_treino_title: 'Publicar capacitación',
    publicar_treino_sucesso: '¡Entrenamiento publicado con éxito!',
    ocultar_treino: 'Los entrenamientos seleccionados estarán ocultos para los alumnos. Quieres proceder?',
    ocultar_treino_title: 'Ocultar entrenamiento',
    ocultar_treino_sucesso: '¡Entrenamiento oculto exitoso!',
    enviar_treino: '¿Quieres enviar una copia de este entrenamiento al programa?',
    enviar_treino_title: 'Enviar entrenamiento',
    enviar_treino_sucesso: '¡Entrenamiento enviado exitosamente!',
    exportar_pdf_title: 'Exportar PDF',
    help_video: '<p>Si es necesario, publica un vídeo explicativo de la formación para tu alumno.</p>' +
                '<p><a href="https://www.stcoach.app/tutoriais-videos/" target="_blank">Sepa mas</a></p>',
    tag_adicionada: 'Etiqueta agregada al entrenamiento.',
    tags_adicionadas: 'Etiquetas agregadas al entrenamiento.',
    tags_atualizadas: 'Etiquetas actualizadas.',
    tags_exibidas: 'Las etiquetas de estos entrenamientos se mostrarán a los alumnos.',
    tags_ocultadas: 'Las etiquetas de estos entrenamientos estarán ocultas para los alumnos.',
    gerar_pdf_completo: 'Generar PDF del programa completo',
    programa_sem_treino: 'Este programa no contiene ninguna formación. ¿Hagamos?',
    tp_apagar_treino: 'Eliminar entrenamiento',
    tp_clonar_treino: 'Entrenamiento de clones',
    tp_ocultar_selecionados: 'Ocultar seleccionado',
    tp_exibir_selecionados: 'Ver seleccionado',
    tp_exportar_clone: 'Exportar clon',
    tp_agendar_liberacao: 'Programar lanzamiento',
    tp_exportar_pdf: 'Exportar entrenamiento seleccionado a PDF',
    tp_tag_id: 'Etiqueta de identificación',
    enviar_programa: '¿Enviar a qué programa?',
    tag_title: 'Etiquetas de identificación',
    tag_subtitle: 'Seleccione una etiqueta para identificar el tipo de formación del alumno (opcional):',
    tag_list: 'Mostrar etiquetas para el alumno en la aplicación',
    sem_tag: 'Sin etiqueta (predeterminado)',
    sem_descricao: 'No hay una descripción',
    selecionados: 'seleccionado:',
    ocultado: 'Oculto',
    programado: 'Programado',
    para: 'para',
    liberado: 'Liberado',
    em: 'en',
    listado: 'Listado',
  },

  // EXERCICIOS
  ExerciciosPage: {
    click_enviar_banner: 'Haga clic aquí para enviar la miniatura del ejercicio',
    arraste_banner: 'Arrastre la miniatura del ejercicio aquí',
    banner_upload_sucesso: '¡La miniatura del ejercicio se envió correctamente!',
    atualizado_sucesso: '¡Ejercicio actualizado exitosamente!',
    cadastrado_sucesso: '¡El Ejercicio fue registrado exitosamente!',
    apagar_exercicio: '¿Realmente deseas eliminar este Ejercicio?',
    apagar_exercicio_title: 'Eliminar ejercicio',
    apagar_exercicio_placeholder: 'escribe BORRAR en mayúsculas',
    apagar_exercicio_confirmacao: 'BORRAR',
    apagar_exercicio_confirmacao_sucesso: '¡El ejercicio se eliminó exitosamente!',
    reordenar_exercicio_sucesso: '¡Ejercicio reordenado exitosamente!',
    reordenar_exercicios_sucesso: '¡Ejercicios reordenados exitosamente!',
    campo_intervalo: 'El campamento de descanso solo acepta números',
    campo_nome_obrigatorio: 'El campo de nombre es obligatorio.',
    serie_cadastro_sucesso: '¡La Serie ha sido registrada exitosamente!',
    serie_atualizado_sucesso: '¡Serie actualizada con éxito!',
    apagar_serie: '¿Realmente quieres eliminar esta serie? No se eliminarán los ejercicios, sólo la agrupación.',
    apagar_serie_title: 'Eliminar serie',
    apagar_serie_placeholder: 'escribe BORRAR en mayúsculas',
    apagar_serie_confirmacao: 'BORRAR',
    apagar_serie_confirmacao_sucesso: '¡La serie ha sido eliminada exitosamente!',
    atualizar_exercicio_a: '¿Realmente deseas actualizar el campo',
    atualizar_exercicio_b: 'de todos los ejercicios?', 
    atualizar_exercicio_title: 'Ejercicios de actualización',
    atualizar_exercicio_sucesso: '¡Los ejercicios se han actualizado con éxito!',
    ajuda_intervalo:  '<p>En el campo de intervalo, ingrese solo números por segundo.</p>' +
                      '<p class="red">No uses comillas.</p>',
    ajuda_video:  '<p>Si es necesario, publica un vídeo explicativo de la formación para tu alumno.</p>' +
                  '<p><a href="https://www.stcoach.app/tutoriais-videos/" target="_blank">Sepa mas</a></p>',
    apagar_exercicios: '¿Realmente desea eliminar los ejercicios seleccionados?',
    apagar_exercicios_title: 'Eliminar ejercicios',
    apagar_exercicios_sucesso: '¡La operación se realizó con éxito!',
    clonar_exercicio: '¿Realmente desea clonar los ejercicios seleccionados?',
    o_limite_de: 'el límite de ',
    limite_exercicios: ' Los ejercicios se conseguirán con esta clonación. Elimina un ejercicio o selecciona menos ejercicios si quieres hacer un clon.',
    clonar_exercicio_title: 'Ejercicio de clonación',
    clonar_exercicio_sucesso: '¡La clonación se completó con éxito!',

    series_repetition: 'Series x repeticiones',
    interval: 'Descansar',
    bt_agrupar: 'Un grupo',
    nenhum_exercicio: 'Este entrenamiento no contiene ningún ejercicio. Para agregarlos, haga clic en "Bibliotecas".',
    treinos_selecionados: 'Entrenamientos seleccionados:',
    salvar_serie: 'Guardar serie',
    selecione_cor: 'Seleccione un color para la Serie:',
    deletar_exercicio: 'Eliminar ejercicio',
    sugestao_carga: 'Cargar:',
    intervalo: 'Descansar:',
    antes_enviar_poster: 'Antes de enviar la miniatura, escribe un TÍTULO para el Ejercicio.',
    imagem_miniatura: 'Imagen en miniatura del ejercicio',
    nenhum_video: 'No se ha subido ningún vídeo',
    aplicar_todos: 'Aplicar a todos',
    sugestao_carga_opcional: 'Sugerencia de carga (opcional)',

    adicionar_exercicio_biblioteca: '¿Estás seguro de que quieres añadir los ejercicios seleccionados a tu entrenamiento?',
    cadastro_exercicios: 'Registro de ejercicio',
    campos_atualizados: '¡Campos actualizados exitosamente!',
    minha_biblioteca: 'Mi biblioteca',
    exercicios_listados: 'ejercicios disponibles',
    exercicio_listado: 'ejercicio disponible',
    tipo: 'Tipo',
    metodos: 'Métodos',
    musculos: 'Músculos',
    equipamentos: 'Equipo',
    exercicios_selecionados: 'Ejercicios seleccionados',
    add_ao_treino: 'agregar al entrenamiento',
    limpar_selecao: 'borrar selección',
    adicionar_video: 'Puedes agregar y organizar tus videos en Mi biblioteca.',
    saiba_mais_biblioteca: 'Obtenga más información en Menú > Mi biblioteca.',
    novo_treino: 'NUEVO ENTRENAMIENTO',
    editar_treino: 'EDITAR ENTRENAMIENTO',
  },

  // ALUNOS
  AlunosPage: {
    apagar_aluno_a: 'Realmente deseas eliminar el Alumno <b class="name">',
    apagar_aluno_b: '</b>? ¡Todo su progreso será borrado!',
    apagar_aluno_title: 'Eliminar Alumno',
    apagar_aluno_msg: 'escribe BORRAR en mayúsculas',
    apagar_aluno_placeholder: 'escribe BORRAR en mayúsculas',
    apagar_aluno_confirmacao: 'BORRAR',
    apagar_aluno_confirmacao_sucesso_a: '¡El alumno  (',
    apagar_aluno_confirmacao_sucesso_b: ') se ha eliminado correctamente!',
    expiracao: '<img src="assets/imgs/timer.svg" alt="">Establecer el tiempo de acceso para ',
    expiracao_lable: 'Vencimiento actual:',
    expiracao_msg_a: 'La validez del plan del alumno (',
    expiracao_msg_b: ') ha sido actualizado exitosamente!',
    cliente_ativo_bloqueado: ') ha sido bloqueado exitosamente!',
    cliente_ativo_desbloqueado: ') ha sido activado exitosamente!',
    cliente_apagar: '¿Estás seguro de que deseas eliminar cuentas caducadas?',
    cliente_apagar_title: 'Eliminar alumnos caducados',
    cliente_apagar_sucesso: '¡Las cuentas se han eliminado correctamente!',
    cliente_desbloquear: '¿Estás seguro de que deseas desbloquear cuentas no vencidas?',
    cliente_desbloquear_title: 'Desbloqueie alumnos ativos',
    cliente_desbloquear_sucesso: '¡Las cuentas se han desbloqueado exitosamente!',
    limite_maximo:  'Lo sentimos, has alcanzado el número máximo de registros permitidos en esta cuenta. ' +
                    'Para más información contacta a tu administrador de sistemas ' +
                    'o para solicitar una actualización a su nivel de acceso.',
    limite_clientes: '¡Ups! Ha alcanzado el límite de cuenta en su suscripción actual. Actualice o elimine algunas cuentas antiguas.',
    cadastro_title: 'Registro',
    nenhuma_conta: 'No se seleccionaron cuentas',
    contas_bloqueadas_sucesso: '¡Las cuentas han sido bloqueadas exitosamente!',
    todas_as_contas: '<p class="danger">¡ATENCIÓN!<br> ¡Seleccionaste TODAS las cuentas!</p>',
    cinco_contas: '<p class="danger">¡ATENCIÓN!!<br> ¡Has seleccionado más de 5 cuentas!</p>',
    contas_apagar: '<p>¿Realmente deseas eliminar las cuentas seleccionadas? Se eliminarán todos los datos y la actividad de estas cuentas.</p>',
    contas_apagar_title: 'Eliminar cuentas',
    contas_apagar_placeholder: 'escribe BORRAR en mayúsculas',
    contas_apagar_confirmacao: 'BORRAR',
    contas_apagar_sucesso: '¡Las cuentas se han eliminado correctamente!',
    contas_apagar_falha: 'La acción fue cancelada. Comprueba que has introducido el texto de confirmación correctamente.',
    avalicao_title: 'Evaluación física',
    precadastro_ativadas_sucesso: '¡Las cuentas se han activado exitosamente!',
    precadastro_nao_selecionadas: 'No se seleccionó ninguna preinscripción',
    precadastro_apagar: '¿Realmente quieres eliminar las preinscripciones?',
    precadastro_apagar_title: 'Eliminar preinscripciones',
    precadastro_apagar_sucesso: '¡Las preinscripciones se han eliminado correctamente!',
    precadastro_aceite: 'Estás seguro de que quieres añadir esta preinscripción a tu lista ',
    precadastro_aceite_title: 'Aceptar nuevo ',
    precadastro_aceite_sucesso: '¡La preinscripción fue aceptada exitosamente!',
    precadastro_remover: '¿Estás seguro de que quieres eliminar esta preinscripción de tu lista de espera?',
    precadastro_remover_title: 'Rechazar ',
    precadastro_remover_sucesso: '¡La preinscripción fue rechazada exitosamente!',
    conta_recuperacao_stcoach: '<p>La recuperación de cuenta es una función exclusiva de los planes Pro y Black.</p> <p>¿Quieres “actualizar” tu plan?</p>',
    conta_recuperacao_mydietpro: '<p>La recuperación de cuenta es una función exclusiva de los planes Lite y Gold.</p> <p>¿Quieres “actualizar” tu plan?</p>',
    conta_recuperacao_title: 'Recuperación de cuenta',
    conta_recuperacao_excede: '<p><center>La recuperación de esta cuenta excede su plan actual.</p> <p>¿Quieres suscribirte a un plan superior?</center></p>',
    conta_recuperacao_excede_2: '<p><center>La recuperación de estas cuentas excede su plan actual.</p> <p>¿Quieres suscribirte a un plan superior?</center></p>',
    conta_recuperacao_single: '¿Estás seguro de que deseas restaurar esta cuenta?',
    conta_recuperacao_multi: '¿Está seguro de que desea restaurar estas cuentas?',
    conta_recuperacao_none: 'No se seleccionaron cuentas',
    conta_recuperacao_title_2: 'Restaurar cuenta',
    conta_recuperacao_sucesso: '¡Acción ejecutada exitosamente!',
    conta_exclusao: '¿Estás seguro de que deseas eliminar completamente esta cuenta? Después de esta acción, ¡todos los datos se eliminarán permanentemente!',
    conta_exclusao_nenhuma: 'No se seleccionaron cuentas',
    conta_exclusao_confirmacao: '¿Estás seguro de que deseas eliminar estas cuentas por completo? Después de esta acción, ¡todos los datos se eliminarán permanentemente!',
    conta_exclusao_title: 'Borrar permanentemente',
    conta_exclusao_sucesso: '¡Acción ejecutada exitosamente!',
    editar_link_captacao: 'Editar enlace de captura',
    aceitos: 'Aceptado',
    lista_espera: 'Lista de Espera',
    excluidos: 'Eliminados',
    opcoes: 'Opciones',
    reativar_contas: 'Reactivar todas las cuentas desactivadas',
    selecionados: 'seleccionado:',
    saiba_tudo_sobre: 'Descubra todo sobre',
    aceitar_selecionados: 'Aceptar seleccionado',
    recusar_selecionados: 'Rechazar seleccionado',
    recuperar_selecionados: 'Recuperar seleccionado',
    eliminar_selecionados: 'Eliminar seleccionado',
    data_inscricao: 'Fecha de Registro',
    data_exclusao: 'Fecha de Eliminación',
    lista_espera_vazia: 'No hay cuentas en lista de espera.',
    recurso_rest_conta_h: 'La función de restauración de cuenta es exclusiva de los planes Pro y Black.',
    recurso_rest_conta_p: 'Las cuentas eliminadas se pueden recuperar en un plazo de 7 días. Después de este período, se eliminarán por completo del sistema y no se podrán recuperar.',
    recurso_rest_conta_h_diet: 'La función de restauración de cuenta es exclusiva del plan Lite y Gold.',
    recurso_rest_conta_p_diet: 'Las cuentas eliminadas se pueden recuperar en un plazo de 7 días. Después de este período, se eliminarán por completo del sistema y no se podrán recuperar.',
    contas_selecionadas: 'Cuentas seleccionadas:',
    dias_restantes: 'Días restantes',
    lista_exclusao_vazia: 'No hay cuentas en la lista de exclusión.',
  },

  DialogValuationComponent: {
    bioimpedancia: 'Bioimpedancia',
    dados_balanca: 'Datos de escala',
    db_gordura: 'Gordo',
    musculo_esqueletico: 'Músculo Esquelético',
    idade_corporal: 'Edad del Cuerpo',
    metabolismo_basal: 'Metabolismo Basal',
    gordura_visceral: 'Grasa Visceral',
    agua_corporal: 'Agua Corporal',
    massa_muscular: 'Masa Muscular',
    massa_gorda: 'Masa Grasa',
    massa_ossea: 'Masa Ósea',
    db_anos: 'Años',
    nivel: 'Nivel',    
    pollock_homem: 'Pollock 7 pliegues (hombres)',
    pollock_mulher: 'Pollock 7 pliegues (mujeres)',
    referencias: 'Referencias',
    tabela_acms: 'Tabla % de grasa ideal, ACMS - Lea & Febiger, 1986',
    tabela_acms_idades: 'Edad</div><div>18 al 29</div><div>30 al 39</div><div>40 al 49</div><div>50 al 59</div><div> > 60 ',
    tabela_acms_homens: 'Hombres</div><div>14%</div><div>16%</div><div>17%</div><div>18%</div><div>21%',
    tabela_acms_mulheres: 'Mujeres</div><div>19%</div><div>21%</div><div>22%</div><div>23%</div><div>26%',
    tabela_imc: 'Tabla de IMC de la Organización Mundial de la Salud',
    tabela_imc_baixo: 'Bajo</div><div class="value"> < 18,5',
    tabela_imc_baixo_tag: 'Obesidad leve</div><div class="value"> 30,0 al 34,9',
    tabela_imc_ideal: 'Ideal</div><div class="value"> 18,5 al 24,9',
    tabela_imc_ideal_tag: 'Obesidad moderada</div><div class="value"> 35,0 al 39,9',
    tabela_imc_sobre: 'Exceso de peso</div><div class="value"> 25,0 al 29,9',
    tabela_imc_sobre_tag: 'Obesidad morbida</div><div class="value"> > 40',
    deletar_avaliacao: '¿Realmente desea eliminar esta evaluación física?',
    deletar_avaliacao_title: 'Eliminar evaluación física',
    deletar_avaliacao_sucesso: ': ¡Los datos se han eliminado correctamente!',
    salvar_avaliacao_sucesso: ': ¡Los datos se han actualizado correctamente!',
    obesidade_morbida: 'Obesidad morbida',
    obesidade_mnoderada: 'Obesidad moderada',
    obesidade_leve: 'Obesidad leve',
    sobrepeso: 'Exceso de peso',
    idealpeso: 'Ideal',
    pesobaixo: 'Bajo',
    gorduraatual: 'grasa actual',
    pesogordo: 'peso gordo',
    pesomagro: 'peso magro',
    pesoideal: 'peso ideal',
    excesso: 'exceso',
    avaliacao_fisica: 'Evaluación Física',
    avaliacao_dia: 'Evaluación Física del día',
    selecione_nova: 'Seleccione una evaluación física o agregue una nueva',
    nova_avaliacao: 'Nueva evaluación física',
    data_avaliacao: 'Fecha',
    peso: 'Peso',
    altura: 'Altura',
    idade: 'Edad',
    anos: 'años',
    gorduraideal: 'Grasa ideal',
    gordura: 'grasa',
    imc: 'IMC',
    nenhuma_avaliacao: 'No se registró evaluación física. ¿Empezamos uno nuevo?',
    medidas: 'Mediciones',
    selecione_protocolo: 'Seleccionar protocolo',
    dobras_cutaneas: 'Pliegues',
    subescapular: 'Subescapular',
    tricipital: 'Tríceps',
    peitoral: 'Pectoral',
    axilarmedia: 'Axilar Medio',
    suprailiaca: 'Suprailiaco',
    abdominal: 'Abdomen',
    coxa: 'Muslo',
    resultados: 'Resultados',
    massagorda: 'Grasa corporal',
    massamagra: 'Masa magra',
    perimetros_centrais: 'Perímetros Centrales',
    ombros: 'Espalda',
    torax: 'Pecho',
    cintura: 'Cintura',
    quadril: 'Cadera',
    perimetros_perifericos: 'Perímetros Periféricos',
    direito: 'Derecha',
    esquerdo: 'Izquierda',
    antebraco: 'Antebrazo',
    braco_relaxado: 'Brazo relajado',
    braco_contraido: 'Brazo contraído',
    coxa_proximal: 'Muslo proximal',
    coxa_media: 'Muslo medio',
    panturrilha: 'Pantorrilla',
    voltar_avaliacoes: 'Volver al listado de evaluaciones físicas',
    salvar_avaliacao: 'Guardar evaluación física',
  },

  FormComponent: {
    click_aqui: 'Haga clic aquí para enviar la foto',
    arraste_aqui: 'Arrastra la foto aquí',
    foto_removida_sucesso: '¡La foto se ha eliminado con éxito!',
    senha_diferente: '¡Las contraseñas no coinciden!',
    tel_branco: '¡El teléfono/whatsapp no ​​puede estar en blanco!',
    conta_atualizada_sucesso: '¡Los detalles de la cuenta se han actualizado correctamente!',
    criado_sucesso: ' creado exitosamente!',
    email_cadastrado: 'Correo eletrónico existente! Por favor registre otro correo electrónico.',
    apagar_conta: '¿Realmente quieres eliminar esta cuenta? ¡Todos los datos de esta cuenta serán eliminados!',
    apagar_conta_title: 'Borrar cuenta',
    apagar_conta_sucesso: '¡La cuenta ha sido eliminada exitosamente!',
    foto_enviada_sucesso: '¡La foto fue enviada exitosamente!',
    formulario: 'Forma',
    limpar_formulario: 'Forma clara',
    nome: 'Nombre',
    email: 'Email',
    objetivo: 'Meta',
    senha: 'Contraseña (mínimo 6 dígitos)',
    confirme_senha: 'Confirme la contraseña',
    foto: 'Fotografía',
    remover_foto: 'Quitar foto',
    info_adicional: 'Informaciones adicionales',
    info_adicional_exp: 'Podemos brindarte una mejor experiencia si nos cuentas un poco más sobre ti.',
    celular: 'Teléfono móvil',
    ddi: 'DDI',
    telefone: 'Teléfono/Whatsapp',
    ddd_numero: 'número',
    data_nascimento: 'Fecha de nacimiento',
    selecione_opcao: 'Seleccione una opción',
    altura: 'Altura (m)',
    peso: 'Peso (kg)',
  },
  
  CustomerAnamnesisComponent: {
    queixa_dor_cabeca: 'Dolor de cabeza',
    queixa_dor_pescoco: 'Dolor en el cuello',
    queixa_dor_peito: 'Dolor de pecho',
    queixa_dor_ombros: 'Dolor de hombro',
    queixa_dor_lombar: 'Dolor lumbar',
    queixa_falta_ar_esforco: 'Dificultad para respirar con el esfuerzo.',
    queixa_falta_ar_repouso: 'Dificultad para respirar en reposo',
    queixa_palpitacoes: 'Palpitaciones del corazón',
    queixa_dor_joelhos: 'Dolor de rodilla',
    queixa_tontura: 'Mareo',
    queixa_sem_dores: 'No tengo dolor',
    doenca_diabetes: 'Diabetes',
    doenca_colesterol: 'Colesterol',
    doenca_pressao_alta: 'Hipertensión',
    doenca_nao_tem: 'No tengo nada',
    fav_serie_trad: 'Serie de culturismo tradicional.',
    fav_serie_circ: 'Serie en circuito',
    fav_peso_corpo: 'Entrenamientos de peso corporal',
    fav_peso_cardio: 'Pesos alternativos y cardio',
    senha_invalida: 'Esta contraseña no es válida. Inténtalo de nuevo.',
    anamnese_add_sucesso: '¡Anamnesis registrada con éxito!',
    anamnese_apagar: '¿Quieres eliminar esta anamnesis?',
    anamnese_apagar_title: 'Eliminar anamnesis',
    anamnese_apagar_sucesso: '¡Historial eliminado exitosamente!',
    questionario: 'Anamnesis: responder al cuestionario de salud.',
    digite_senha: 'Introduce tu contraseña para validar el acceso a la anamnesis.',
    precisa_senha: 'Necesitas ingresar tu contraseña',
    validar: 'Validar',
    anamnese: 'Anamnesis',
    avaliacao_saude: 'Anamnesis - Cuestionario de salud',
    responda: 'Por favor responda el cuestionario lo más completo posible.',
    envie_link: 'Enviar el enlace al alumno para acceder a la anamnesis.',
    anamnese_vazia: 'El alumno aún no ha completado ninguna anamnesis.',
    voltar_lista: 'Volver a la lista',
    queixas_atuais: 'Quejas actuales',
    outros: 'Otros',
    doencas_pre: 'Enfermedades preexistentes',
    antecedentes_pessoais: 'Experiencia personal',
    cirurgias: '¿Cirugías?',
    traumas: '¿Trauma?',
    antecedentes_familiares: 'Trasfondo familiar',
    colesterol: '¿Colesterol?',
    diabetes: '¿Diabetes?',
    habitos: 'Hábitos de vida',
    etilismo: '¿Alcoholismo?',
    tabagismo: '¿De fumar?',
    anticoncepcionais: '¿Anticonceptivos?',
    historico_treino: 'Historial de entrenamiento',
    quanto_tempo_treino: '¿Cuánto tiempo llevas entrenando',
    quantas_vezes_treina: '¿Cuántas veces a la semana puedes entrenar?',
    periodo_manha: 'Por la mañana',
    periodo_tarde: 'De tarde',
    periodo_noite: 'La noche',
    periodo_possivel: '¿Es posible que entrenes dos veces en un solo día? Si es así, ¿qué días de la semana serían posibles?',
    treino_gosta: '¿Qué tipo de entrenamientos te gustan más?',
    sobre_treino_anterior: '¿Algo que quieras contarme sobre tu formación anterior? Describe los detalles:',
    data_inicio: 'Selecciona la fecha en la que quieres empezar a entrenar',
    complemento_anamnese: 'Describe algo importante que pueda complementar esta anamnesis.',
    apagar_anamnese: 'Eliminar anamnesis',
    obrigado: 'Gracias por responder el cuestionario de salud.',
  },

  CustomerMenusComponent: {
    lista_prescricoes_atualizada: 'La lista de recetas ha sido actualizada.',
    ajuda_enviar_programas: '<p>Primero elija el alumno que desea editar. De esta manera estarán disponibles la Lista de programas y la pestaña Formulario.</p>'
                          + '<p>Luego seleccione los programas que desea enviar al alumno haciendo clic en la tecla “oculto/seleccionado”.</p>'
                          + '<p>El programa será enviado inmediatamente.</p>'
                          + '<p>Envía tantos programas como necesites.</p>'
                          + '<p>Si desea ver la lista de espera nuevamente, haga clic en el botón VOLVER en la pestaña Formulario.</p>',
    prescricoes: 'Recetas',
    prescricoes_listadas: ' recetas listadas',
    prescricao_listada: ' receta listada',
    disponiveis: 'disponible',
    selecionado: 'Seleccionado',
    oculto: 'Oculto',
  },
  
  CustomerProgramsComponent: {
    lista_programas_atualizada: 'La lista de programas de alumno se ha actualizado.',
    ajuda_enviar_programas: '<p>Primero elija el alumno que desea editar. De esta manera estarán disponibles la Lista de programas y la pestaña Formulario.</p>'
                          + '<p>Luego seleccione los programas que desea enviar al alumno haciendo clic en la tecla “oculto/seleccionado”.</p>'
                          + '<p>El programa será enviado inmediatamente.</p>'
                          + '<p>Envía tantos programas como necesites.</p>'
                          + '<p>Si desea ver la lista de espera nuevamente, haga clic en el botón VOLVER en la pestaña Formulario.</p>',
    programas: 'Programas',
    programas_listados: ' programas listados',
    programa_listado: ' programa listado',
    disponiveis: 'disponible',
    selecionado: 'Seleccionado',
    oculto: 'Oculto',
    agenda: 'Agenda',
  },
  
  CustomerHistoryComponent: {
    historico: 'Historia',
    historico_vazio: 'El historial de actividad está vacío',
    concluido: 'Terminado',
    programa: 'Programa',
  },
  
  CustomerNotesComponent: {
    notas: 'Notas',
    notas_subtitulo: 'Estas son las últimas notas del alumno dentro de la aplicación.',
    nenhuma_anotacao: 'No se tomaron notas.',
  },
  
  CustomerFilesComponent: {
    arquivos: 'Archivos',
    onde_visualizar_arquivo: '¿Dónde ve el alumno este archivo?',
    todos_arquivos: 'Todos los archivos.',
    remover_arquivo: '¿Quieres eliminar este archivo de alumno?',
    remover_arquivo_titulo: 'Eliminar archivo',
    lista_arquivo_sucesso: 'La lista de Archivos de Alumno ha sido actualizada.',
    ajuda_arquivos: '<p>Estos son todos los archivos cargados en su cuenta.</p>'
                  + '<p>Seleccione los que desea enviar a este alumno.</p>'
                  + '<p>Para cargar más documentos, renombrarlos o eliminarlos, accede a la opción "Archivos" del menú.</p>',
    selecionado: 'Seleccionado',
    oculto: 'Oculto',              
  },

  NutricaoComponent: {
    alimento_alterado_sucesso: '¡La comida ha sido cambiada exitosamente!',
    alimento_cadastrado_sucesso: '¡La comida se registró correctamente!',
    alimento_clonado_sucesso: '¡La comida ha sido clonada con éxito!',
    alimento_apagar: '¿Realmente deseas eliminar este alimento?',
    alimento_apagar_title: 'Eliminar Alimento',
    alimento_apagar_sucesso: '¡La comida se ha apagado correctamente!',
    ajuda: '<p>Primero elija el alumno que desea editar. De esta manera estarán disponibles la Lista de programas y la pestaña Formulario.</p>'
         + '<p>Luego seleccione los programas que desea enviar al alumno haciendo clic en la tecla “oculto/seleccionado”.</p>'
         + '<p>El programa será enviado inmediatamente.</p>'
         + '<p>Envía tantos programas como necesites.</p>'
         + '<p>Si desea ver la lista de espera nuevamente, haga clic en el botón VOLVER en la pestaña Formulario.</p>',
    formulario: 'FORMA',
    formulario_limpar: 'FORMA CLARA',
    novo_alimento: 'Nueva comida',
    cadastre_alimentos: 'Registra alimentos con este formulario.',
    editar_alimento: 'Editar comida',
    nome: 'Nombre',
    unidades: 'Unidades',
    gramas: 'Gramos',
    mililitros: 'Mililitros',
    porcao: 'Porción',
    quantidade: 'Cantidad',
    energia: 'Energía',
    carboidratos: 'Carbohidratos',
    acucares: 'Azúcares',
    proteinas: 'Proteínas',
    gorduras: 'Grasas',
    gord_saturadas: 'Grasas Saturadas',
    gord_monoinsaturadas: 'Grasas Monoinsaturadas',
    gord_poliinstaturadas: 'Grasas Poliinsaturadas',
    colesterol: 'Colesterol',
    fibras: 'Fibras',
    sodio: 'Sodio',
    potassio: 'Potasio',
    alimentos: 'ALIMENTOS',
    alimentos_listados: ' alimentos enumerados',
    alimento_listado: ' alimento enumerado',
    disponiveis: 'disponible',
    adicione_alimento: 'agregar un alimento',
  },
  
  MenusComponent: {
    legend_title: 'Cálculo',
    extra_suplementos: 'Texto exemplo:\n\n- Whey protein isolado\n- Creatina monohidratada\n- Multivitaminico & Mineral\n- Ômega 3\n- UC II',
    extra_manipulados: 'Texto exemplo:\n\nFórmula 1:\n- Metilfolato (1mg)\n- Pridoxal 5 Fosfato (25mg)\n\nFórmula 2:- Curumina (300mg)\n- Piperina (3mg)',
    extra_compras: 'Texto exemplo:\n\n- Arroz integral\n- Batata\n- Pescados\n- Filete de pollo\n- Salmón\n- Ananás',
    menu_atualizado_sucesso: '¡La receta se actualizó exitosamente!',
    menu_cadastrado_sucesso: '¡La receta fue registrada exitosamente!',
    menu_clonado_sucesso: '¡La receta fue clonada con éxito!',
    marca_dagua_mydietpro: 'MYDIETPRO EN MODO LIBRE',
    marca_dagua_funcaex: 'FUNCIÓN EXCLUSIVA PARA SUSCRIPTORES DEL PLAN PRO',
    marca_dagua_stcoach: 'STCOACH EN MODO LIBRE',
    proteinas: 'Proteínas',
    lipidios: 'Lipídios',
    carbo: 'Carbohidratos',
    texto_livre: '[Texto libre de hasta 10.000 caracteres]',
    nova_refeicao: 'Nueva comida',
    refeicoes_geradas: '¡Comidas creadas exitosamente!',
    nova_refeicao_sucesso: '¡Nueva comida creada con éxito!',
    refeicao_atualizada_sucesso: '¡Comida actualizada exitosamente!',
    clonar_refeicao: '¿Quieres clonar esta comida?',
    clonar_refeicao_title: 'Comida clonada',
    clonar_refeicao_sucesso: '¡Comida clonada con éxito!',
    remover_refeicao: '¿Quieres eliminar esta comida?',
    remover_refeicao_title: 'Eliminar comida',
    remover_refeicao_sucesso: '¡La comida se eliminó exitosamente del sistema!',
    reordenar_refeicao: '¡Comidas reordenadas exitosamente!',
    lista_atualizada: 'La lista de recetas del alumnos ha sido actualizada.',
    lista_atualizada_pacientes: 'Se ha actualizado la lista de recetas de alumnos.',
    lista_arquivos_atualizada: 'La lista de archivos ha sido actualizada.',
    modal_limite: 'Lo sentimos, has alcanzado el número máximo de registros permitidos en esta cuenta. '
                + 'Póngase en contacto con el administrador de su sistema para obtener más información. '
                + 'o para solicitar una actualización a su nivel de acceso.',
    modal_limite_menus: '¡Ups! ha alcanzado el límite de recetas en su cuenta actual. Actualice o elimine algunas recetas antiguas.',
    menus_title: 'Recetas',
    nenhuma_prescricao: 'No se seleccionaron recetas',
    um_clone_vez: 'Clone solo una receta a la vez',
    um_pdf_vez: 'Genere un PDF solo para una receta a la vez',
    gerando_pdf: 'Generando el PDF, por favor espere...',
    apagar_prescricoes: '¿Quieres eliminar las recetas seleccionadas?',
    apagar_prescricoes_title: 'Eliminar Recetas',
    escreva_deletar: 'escribe BORRAR en mayúsculas',
    escreva_deletar_input: 'BORRAR',
    apagar_prescricoes_sucesso: '¡Los archivos se han eliminado correctamente!',
    prescricoes: 'Recetas',
    subtitulo_nutri: 'Crea planes nutricionales completos y envíalos a tus pacientes.',
    subtitulo_prof: 'Crea planes nutricionales completos y envíalos a tus alumnos.',
    funcao_desktop: 'Función disponible para escritorio.',
    funcao_desktop_sub: 'Actualmente, esta funcionalidad solo está disponible para el acceso de computadoras. Estamos trabajando activamente para adaptar las pantallas a la versión móvil.',
    nova_prescricao: '+ Nuevo',
    gerar_pdf: 'Generar PDF',
    clonar: 'Clon',
    alimento: 'Alimento',
    alimentos: 'Alimentos',
    textos_livres: 'Textos libres',
    ultima_atualizacao: 'La última actualización se realizó el',
    tipo: 'Tipo',
    kcal: 'Kcal',
    prot: 'Prot.',
    carb: 'Carb.',
    gord: 'Gras.',
    selecione_pacientes: 'Seleccione alumnos para esta receta',
    selecione_alunos: 'Seleccione alumnos para esta prescripción',
    enviar_todos: 'Enviar a todos',   
    selecionado: 'Seleccionado',
    ocultado: 'Oculto',
    sem_menus: 'No existen planes nutricionales registrados.',
    voltar_tela: 'Volver a la pantalla anterior',
    atualizar_prescricao: 'Actualizar receta',
    nome_obrigatorio: 'Déle un nombre a esta receta (obligatorio)',
    continuar: 'Continuar',
    como_deseja: '¿Cómo quieres prescribir?',
    menu_1_desc: 'La prescripción se realiza eligiendo alimentos de nuestra base de datos. Este formulario considera cálculos de macro y micronutrientes.',
    menu_2_desc: 'La prescripción de la dieta se realiza mediante texto libre, sin considerar cálculos. Sin embargo, puedes ingresar los valores manualmente.',
    menu_1: 'Prescribir comidas con alimentos de la base de datos. Edita lo que quieras.',
    menu_2: 'Prescribir comidas con textos libres. Edita lo que quieras.',
    gerar_ref_inicial: 'Generar lista de comidas inicial',
    alimento_adicionar: 'Añadir un alimento de la base de datos',
    alimento_texto_livre: 'Añadir texto libre',
    alimento_duplicar: 'Duplica esta comida',
    alimento_apagar: 'Eliminar esta comida',
    hora: 'Hora',
    nome_menu: 'Nombre de la receta (Estándar)',
    nome_menu_en: 'Nombre de la receta (Inglés)',
    nome_menu_es: 'Nombre de la receta (Español)',
    qtd: 'ctd',
    unidade: 'unidad',
    lipid: 'Grasas',
    calorias: 'calorias',
    porcao: ' Porción',
    porcoes: ' Porciones',
    total_macro: 'Macronutrientes totales',
    sem_alimentos: 'comida sin alimentos',
    sem_descricao: 'Comida sin descripción',
    nutrientes: 'Nutrientes',
    acucares: 'Azúcares',
    fibras: 'Fibras',
    sodio: 'Sodio',
    potassio: 'Potasio',
    colesterol: 'Colesterol',
    gd_sat: 'Gr. Saturada',
    gd_monosat: 'Gr. Monoinsaturada',
    gd_polisat: 'Gr. Poliinsaturada',
    gd_total: 'Gr. Total',
    atualizar_nutrientes: 'Actualizar nutrientes',
    suplementos: 'Suplementos',
    manipulados: 'Manipulados',
    lista_compras: 'Lista de compras',
    quantidade: 'Cantidad',
    relatorio_nutrientes: 'Informe de nutrientes',
    refeicao: 'Comida',
    total: 'Total',
    atualizar_crn_title: '¡Se requiere acción!',
    atualizar_crn: 'Para utilizar esta funcionalidad es necesario rellenar los datos del profesional de la nutrición.',
    atualizar_crn_bt_ok: 'Ir al registro',
  },

  ArquivosComponent: {
    carregue_arquivo: 'CARGAR EL ARCHIVO',
    arquivo_maximo: 'El archivo debe tener como máximo ',
    remover_arquivo: '¿Quieres eliminar este archivo?',
    remover_arquivo_titulo: 'Eliminar archivo',
    remover_arquivo_sucesso: '¡Archivo eliminado exitosamente del sistema!',
    renomear_arquivo: 'Cambiar nombre de archivo',
    renomear_arquivo_sucesso: '¡Archivo actualizado exitosamente!',
    lista_alunos_atualizada: 'La lista de archivos ha sido actualizada.',
    ajuda_todos_arquivos: '<p>Estos son todos los archivos cargados en su cuenta.</p>'
                        + '<p>Seleccione los que desea enviar a este alumno.</p>'
                        + '<p>Para cargar más documentos, renombrarlos o eliminarlos, accede a la opción "Archivos" del menú.</p>',
    arquivo_grande: '¡El archivo es más grande que el espacio de almacenamiento disponible!',
    sem_espaco: '¡No tienes más espacio de almacenamiento disponible!',
    arquivo_envio_sucesso: '¡Su archivo ha sido enviado exitosamente!',
    nenhum_selecionado: 'No se seleccionaron archivos',
    apagar_arquivos: '¿Realmente deseas eliminar los archivos seleccionados?',
    apagar_arquivos_titulo: 'Eliminar Archivos',
    apagar_arquivos_placeholder: 'escribe BORRAR en mayúsculas', 
    apagar_arquivos_confirmacao: 'BORRAR',
    apagar_arquivos_sucesso: '¡Los archivos se han eliminado correctamente!',
    ajuda_legenda: '¿Dónde el alumno verá este archivo?',
    recurso_exclusivo: 'Función solo disponible para suscriptores del plan Standard o superior.',
    armazenamento_maximo: 'Almacenamiento máximo',
    total_usado: 'Total Usado',
    precisa_mais: '¿Necesita más espacio de almacenamiento?',
    faca_upgrade: '¡Actualiza tu plan!',
    lista: 'Lista',
    lista_vazia: 'Su lista de archivos está vacía.',
    enviado: 'Enviado',
    atualizado: 'Actualizado',
    alunos_selecionados: 'Alumnos Seleccionados',
    selecione_alunos: 'Seleccionar alumnos para este documento',
    enviar_todos: 'Enviar a todos',
    selecionado: 'Seleccionado',
    ocultado: 'Oculto',
    nenhum_arquivo: 'No hay archivos registrados.',
  },
  
  BibliotecaComponent: {
    exercicio_atualizado_sucesso: '¡Ejercicio actualizado exitosamente!',
    exercicio_cadastrado_sucesso: '¡El Ejercicio fue registrado exitosamente!',
    apagar_exercicio: '¿Realmente deseas eliminar este Ejercicio? a',
    apagar_exercicio_title: 'Eliminar Ejercicio b',
    apagar_exercicio_sucesso: '¡El ejercicio se eliminó exitosamente!',
    nenhum_selecionado: 'No se seleccionaron ejercicios.',
    apagar_exercicio_certeza: '¿Realmente deseas eliminar los ejercicios seleccionados?',
    apagar_exercicio_certeza_title: 'Eliminar',
    apagar_exercicio_certeza_placeholder: 'escribe BORRAR en mayúsculas',
    apagar_exercicio_certeza_confirmacao: 'BORRAR',
    apagar_exercicio_certeza_sucesso: '¡El ejercicio se eliminó exitosamente!',
    apagar_exercicios_certeza_sucesso: '¡Los ejercicios se eliminaron con éxito!',
    banner_enviado_sucesso: 'Banner enviado exitosamente. ¡No olvides guardar el ejercicio!',
    biblioteca_ajuda: '<h4>Añade hasta 300 vídeos.</h4>'
                    + '<p>En esta sección podrás organizar hasta 300 vídeos de ejercicios para utilizar en montajes de entrenamiento.</p>'
  
                    + '<h4>Función del plan PRO</h4>'
                    + '<p>Los videos de "Mi biblioteca" solo pueden ser utilizados por suscriptores del plan Pro.</p>'
  
                    + '<h4>Vídeos</h4>'
                    + '<p>El montaje de "Mi Biblioteca" debe realizarse mediante enlaces de YouTube o códigos de Vimeo. <a href="https://www.stcoach.app/tutoriais-videos" target="_blank">Haga clic aquí</a> para saber más.</p>'
  
                    + '<h4>Imágenes</h4>'
                    + '<p>Las miniaturas de ejercicios son opcionales. Si vas a agregarlo sigue las recomendaciones:</p>'
                    + '<ul><li>Formato JPG</li>'
                    + '<li>Dimensión 300x300px</li></ul>',
    exclusivo_pro_plus: 'Esta función es exclusiva para suscriptores Pro o Black. ¿Le gustaría actualizar su plan?',
    exclusivo_pro_plus_title: 'Función Exclusiva',
    minha_biblioteca: 'Mi Biblioteca',
    biblioteca_vazia: 'No hay ejercicios registrados en la biblioteca.',
    biblioteca_procurando: 'Buscando ejercicios en la biblioteca...',
    novo_exercicio: 'Nuevo ejercicio',
    editar_exercicio: 'Editar ejercicio',
    antes_enviar_banner: 'Antes de cargar una miniatura, agregue un TÍTULO al Ejercicio.',
    enviar_mini: 'Haga clic aquí para enviar miniatura',
  },

  ProfessoresComponent: {
    clique_envie_foto: 'Haz clic aquí para enviar tu foto',
    arraste_foto: 'Arrastra tu foto aquí',
    clique_envie_logo: 'Haga clic aquí para enviar su logotipo',
    arraste_logo: 'Arrastra tu logo aquí',
    dados_atualizados_sucesso: '¡Tus datos han sido actualizados exitosamente!',
    conta_ativada_sucesso: '¡Su cuenta ha sido activada exitosamente!',
    cancelamento_plano: 'Cancelación del plan de suscripción',
    cancelamento_plano_teacher: '' +
                              + '<center><p><b>Al cancelar el plan, tu cuenta quedará gratuita.</b></p></center>'
                              + '<center><p><b>Perderás el acceso a los entrenamientos que creaste y tus alumnos perderán el acceso a la aplicación.</b></p></center>',
    cancelamento_plano_nutri: '' +
                            + '<center><p><b>Cuando canceles tu suscripción, tu cuenta quedará gratuita inmediatamente.</b></p></center>'
                            + '<center><p><b>Perderás el acceso a dietas que excedan el plan gratuito.</b></p></center>',
    cancel_plano_bt_ok: 'Mantener el plan',
    cancel_plano_bt_cancel: 'Confirmar cancelación',
    ativar_conta: '<p>Por razones de seguridad, necesitamos confirmar la activación de su cuenta antes de continuar. Esta activación es automática y no se requiere ninguna acción adicional.</p> <p>¿Quieres activar tu cuenta?</p>',
    ativar_conta_title: 'Activar cuenta',
    plano_atual: '' +
              + '<center><p><b>Este es tu plan actual.</b></p></center>'
              + '<center><p><b>Al cancelar el plan, tu cuenta quedará gratuita.</b></p></center>'
              + '<center><p><b>Perderás el acceso a los entrenamientos que creaste y tus alumnos perderán el acceso a la aplicación.</b></p></center>',
    plano_atual_bt_ok: 'Mantener el plan',
    plano_atual_bt_cancel: 'Confirmar cancelación',
    plano_superior: 'Tu plan actual es superior al que elegiste. ¿Quieres migrar a este plan? Tendrá menos recursos disponibles y todos los alumnos y programas excedentes quedarán inhabilitados.',
    plano_superior_title: 'Suscríbete al nuevo plan',
    plano_nao_selecionado: '¡El plan de suscripción no ha sido seleccionado!',
    cc_branco: '¡El número de tarjeta de crédito está en blanco!',
    cvc_branco: '¡El código de seguridad de la tarjeta de crédito está en blanco!',
    expira_mes_branco: '¡El campo del mes de vencimiento de la tarjeta de crédito está en blanco!',
    expira_ano_branco: '¡El campo del año de vencimiento de la tarjeta de crédito está en blanco!',
    erro_reload: 'Ha ocurrido un error y recargaremos la página.',
    verifica_pagamento: 'La verificación de pago está completa y su plan está actualizado',
    aluno_desativado: '¡El Alumno ha sido desactivado exitosamente!',
    aluno_ativado: '¡El Alumno ha sido activado exitosamente!',
    aluno_bloqueado: '¡El Alumno ha sido bloqueado exitosamente!',
    ajuda_assinatura: ''
                    + '<h3>Suscripción</h3>'
                    + '<p>- Sistema de suscripción recurrente mensual (renovación automática).</p>'
                    + '<p>- Pago únicamente con tarjeta de crédito.</p>'
                    + '<p>- Para reemplazar el plan, simplemente haga clic en el deseado y suscríbase.</p>'
                    + '<br/>'
                    + '<h3>Dudas</h3>'
                    + '<p>1. ¿Cómo cambio de plan?</p>'
                    + '<p>Simplemente haga clic en el plan deseado y suscríbase nuevamente. La suscripción anterior se cancelará automáticamente y la nueva entrará en vigor. Simplemente intente actualizar dentro de unos días después de la renovación automática, ya que el sistema no compensa automáticamente los montos.</p>'
                    + '<p>2. ¿Si cancelo, perderé mi trabajo y a mis alumnos?</p>'
                    + '<p>No. Si cancelas tu suscripción o tu tarjeta de crédito no autoriza la renovación, el acceso a alumnos y programas quedará bloqueado en el sistema. En la aplicación, sus alumnos perderán el acceso a su inicio de sesión.</p>'
                    + '<p>Todas las inscripciones de alumnos, listas de espera, así como programas creados permanecerán en el sistema hasta que se reactive la suscripción.</p>',
    avatar_enviado_sucesso: '¡Tu avatar se ha subido correctamente!',
    logo_enviado_sucesso: '¡Su logo ha sido enviado exitosamente!',
    foto_removida_sucesso: '¡Tu foto ha sido eliminada exitosamente!',
    perfil: 'Perfil',
    subtitulo: 'Elija el logotipo que se mostrará en la aplicación.',
    remover_foto: 'Quitar foto',
    minha_logo: 'Mi logotipo',
    minha_logo_sub: 'Elija el logotipo que se mostrará en el sistema y en la aplicación del alumno',
    exclusividade: 'Exclusividad',
    logo_pdf: 'Logotipo a PDF',
    logo_pdf_sub: 'Elija el logotipo que se mostrará en el encabezado del PDF (fondo blanco).',
    exclusivo_pro_black: 'Exclusivo para suscriptores <span>PRO y Black</span>',
    exclusivo_gold: 'Exclusivo <span>GOLD</span>',
    dados: 'Datos',
    info_basica: 'Tu información básica.',
    ddd_numero: 'número de teléfono celular',
    selecione_estado: 'Seleccione un estado',
    cidade: 'Ciudad',
    selecione_opcao: 'Seleccione una opción',
    cref: 'Aplicable únicamente a profesionales de Brasil. Puede rellenar cualquier número..',
    crn: 'Aplicable únicamente a profesionales de Brasil. Puede rellenar cualquier número.',
    cpf: 'Campo sólo para residentes en Brasil.',
    crefito: 'Campo sólo para residentes en Brasil.',
    nutriname: 'Campo sólo para residentes en Brasil.',
    cpf_not_valid: 'Campo sólo para residentes en Brasil.',    
    cpf_empty: 'Campo sólo para residentes en Brasil.',    
    cref_not_empty: 'Campo sólo para residentes en Brasil.',
    crefito_not_empty: 'Campo sólo para residentes en Brasil.',
    crn_not_empty: 'Campo sólo para residentes en Brasil.',
    nutriname_not_empty: 'Campo sólo para residentes en Brasil.',
    sou_estrangeiro: 'Soy extranjero y declaro que estoy capacitado ejercer la profesión de acuerdo con las determinaciones de mi país.',
    nutriname_igual_nome: 'Campo sólo para residentes en Brasil.',
    atividade: 'Actividad profesional',
    edfisica: 'Entrenador físico',
    fisio: 'Fisioterapeuta',
    nutri: 'Nutricionista',
    estudante: 'Alumno',
    senha: 'Contraseña (mínimo 6 dígitos)',
    senha_sub: 'Simplemente cambie los campos si desea cambiar su contraseña.',
    confirme_senha: 'Confirmar Contraseña',
    apagar_conta: 'Eliminar Cuenta',
    planos_assinatura: 'Planes de Suscripción',
    planos_sub: 'Elige el plan que mejor se adapta a tu perfil',
    mes: 'mes',
    p_gratis: 'Gratis',
    p_gratis_alunos: '1 Alumno',
    p_gratis_programas: '2 Programas',
    p_gratis_capas: 'Carteles Básicos',
    p_basic: 'Basic',
    p_standard: 'Standard',
    p_pro: 'Pro',
    p_black: 'Black',
    p_lite: 'Lite',
    p_gold: 'Gold',
    p_alunos: 'Alumnos',
    p_programas: 'Programas',
    p_capas: 'Carteles Básicos',
    p_capas_e: 'Carteles Especiales',
    p_arquivos: 'para archivos',
    p_avaliacao: 'Evaluación Física',
    p_sualogo: 'Tu logo en la aplicación',
    p_nutri: 'Nutrición',
    p_mylib: 'Mi biblioteca',
    p_bkp: 'Copia de Seguridad',
    p_pdf: 'Convertir entrenamientos a PDF',
    p_pacientes: 'Alumnos',
    p_prescricoes: 'Recetas',
    p_link: 'Enlace de reclutamiento de alumnos',
    p_pacientes_ili: 'Pacientes ilimitados',
    p_prescricoes_ili: 'Recetas ilimitadas',
    p_gratuito: 'Plan gratuito',
    capas: '*Los planes que tienen acceso a carteles especiales también tienen acceso a carteles básicos.',
    meu_plano_atual: 'Mi plan actual',
    gerenciar: 'Administrar suscripción',
    plano_prepago: 'Plan prepago',
    expiracao: 'Hora de vencimiento del plan actual',
    renovacao: 'Este tipo de plan requiere renovación cada mes. No se renueva automáticamente.',
    total_alunos: 'Total de alumnos matriculados',
    ativos: 'Activos',
    inativos: 'Inactivo',
    total_programas: 'Programas totales',
    arquivos: 'Archivos',
    total_arquivos: 'Archivos totales',
    total_pacientes: 'Total de alumnos registrados',
    total_prescricoes: 'Prescripciones totales',
    assinatura_recorrente: 'Suscripción recurrente',
    renovacao_automatica: 'Con renovación automática cada 30 días',
    cc_nome: 'Tu nombre en tu Tarjeta de Crédito',
    cc_numero: 'Número de Tarjeta de Crédito',
    cc_cvv: 'CVV',
    cc_data_exp: 'Fecha de expiración',
    cc_mes: 'Mes',
    cc_ano: 'Año',
    cc_doisultimos: '(últimos dos dígitos)',
    limpar_campos: 'Borrar campos',
    assinar: 'Suscribir',
    pagamento_pix: 'Planes Prepagos',
    sem_renovacao: 'Sólo para método de pago brasileño. Sin renovación automática.',
    pelo_computador: 'Recomendamos que este método de pago se realice a través de computadora.',
    pagar: 'Pagar',
    gerando_link: 'Generando el enlace de pago...',
    aguarde: 'Por favor espera unos momentos',
    link_sucesso: 'Enlace generado exitosamente',
    pag_instrucoes: 'Haga clic en el botón a continuación y siga las instrucciones de pago para foto, comprobante bancario o tarjeta de crédito. Este método de pago requiere un CPF brasileño.',
    pag_demora: 'El pago realizado con foto o tarjeta de crédito tarda 2 minutos en liberar las funciones.',
    pag_boleto_demora: 'El pago realizado por "BOLETO" puede tardar hasta 72 horas en ser liquidado y procesado.',
    pag_aguarde: 'Espere estos períodos antes de abrir una queja con nosotros.',
    link_abrir: 'Abrir enlace de pago',
    cancelar_voltar: 'Cancelar y regresar',
    historico: 'Historia',
    desc_hist_stcoach: 'Aquí podrás ver tus últimos pagos en la plataforma.',
    desc_hist_mydiet: 'Aquí podrás ver tus últimos pagos en la plataforma.',
    verificando: 'Revisando tus compras...',
    expirado: 'Venció',
    processando: 'Procesando',
    pago: 'Pagado',
    minha_conta: 'Mi cuenta',
    criado_em: 'Creado el',
    ultima_atual: 'Última actualización',
    avisos_legais: 'Avisos Legales',
    termos_uso: 'Condiciones de Uso',
    politiva_priv: 'Política de Privacidad',
    politica_cancel: 'Política de Cancelación',
  },

  DialogI18nComponent: {
    campo_vazio: '¡El campo de traducción está vacío!',
    traduza_texto: 'Traducir el texto',
    em_ingles: 'Inglés',
    em_espanhol: 'Español',
    traduzir: 'Traducir',
    add_traducoes: 'Agregar traducciones',
  },

  CustomerWaitlineComponent: {
    link_transferencia: 'El enlace ha sido copiado.',
    copia_negada: 'Su sistema no permite la copia automática. Copie y pegue manualmente.',
    alterar_sauda: '¿Estás seguro de que deseas cambiar la frase de saludo de tu lista de espera?',
    alterar_sauda_title: 'Cambiar frase de saludo',
    alterar_sauda_sucesso: '¡La frase de saludo se ha actualizado correctamente!',
    saudacao_padrao: ''
                    + '<p>Frase de saludo después de que el alumno abra el enlace de preinscripción.</p>'
                    + '<p>Escribe una frase amistosa.</p>',
    saudacao_ajuda: ''
                    + '<p>Para incluir alumnos potenciales en la lista, comparta el enlace de arriba en su sitio web y redes sociales.</p>'
                    + '<p>Los alumnos preinscritos no contarán como alumnos permanentes en su plan, a menos que sean aceptados.</p>'
                    + '<p>Intente aceptar alumnos potenciales para una prueba gratuita y aumente sus posibilidades de conversión.</p>',
    lista_espera: 'Lista de Espera',
    link_captacao: 'Enlace de preinscripción',
    saudacao: 'Frase de bienvenida',
    concordo: 'Al registrarme declaro que he leído y acepto',
    privacidade: 'la política de privacidad',
  },  
};