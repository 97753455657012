import { Component, OnChanges, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
// import { AuthService } from '../../providers/auth/auth.service';
// import { CustomerProvider } from '../../providers/customer/customer';
import { I18nProvider } from '../../../providers/i18n/i18n';


@Component({
  selector: 'dashboard-total-alunos',
  templateUrl: './total-alunos.html',
})
export class DashboardTotalAlunosComponent implements OnChanges, OnInit {
  
  STRINGS: any;
  PAGE: any;

  @Input() user: any;
  loading = true;
  title: string = 'Alunos Cadastrados';

  data: any[];

  // options
  label: string = '';
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  view: any[] = [640, 360];

  colorScheme = {
    domain: ['#00B826', '#f53d3d', '#999999']
  };

  constructor(
    public toastr: ToastrService,
    private router: Router,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('DashboardPage');
    this.title = this.PAGE.alunos_cadastrados;
  }

  ngOnInit() {
    this.loading = true;
    if (this.user.account_type === 'nutri') {
      this.colorScheme = {
        domain: ['#59bbc8', '#9e6ec7', '#8E8DBA']
      };
      this.title = this.PAGE.pacientes_cadastrados;
    }
    if (this.user.subs.students !== undefined ) {
      this.processData();
    }
  }

  ngOnChanges() {
    this.loading = true;
    if (this.user.subs.students !== undefined ) {
      this.processData();
    }
  }

  processData() {
    this.data = [
      {
        "name": this.PAGE.ativos,
        "value": (this.user.subs.students.total - this.user.subs.students.inactives - this.user.subs.students.expireds),
      },
      {
        "name": this.PAGE.expirados,
        "value": this.user.subs.students.expireds,
      },
      {
        "name": this.PAGE.bloqueados,
        "value": this.user.subs.students.inactives,
      }
    ];
    this.title = this.title + ' - ' + this.user.subs.students.total + '/' + this.user.subs.students.max;
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  }



  onSelect(_data: any): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(_data)));
  }

  onActivate(_data: any): void {
    // console.log('Activate', JSON.parse(JSON.stringify(_data)));
  }

  onDeactivate(_data: any): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(_data)));
  }

  goToStudent(student: any) {
    // console.log(student);
    this.router.navigate(['professor/alunos/' + student.id], { skipLocationChange: true });
  }


}
