import { Injectable } from '@angular/core';
import { ApiProvider } from '../api/api';

@Injectable()
export class MealProvider {

  constructor(
    public api: ApiProvider
  ) {
    //
  }

  index() {
    const url = 'v2/meals';
    return this.api.get(url);
  }

  get(id: any) {
    return this.api.get(`v2/adm/meals/${id}`);
  }

  patch(meal: any) {
    meal._method = 'PATCH';
    return this.api.post(`v2/adm/meals/${meal.id}`, meal);
  }

  post(meal: any) {
    return this.api.post(`v2/adm/meals/`, meal);
  }

  delete(id: any) {
    return this.api.delete(`v2/adm/meals/${id}`);
  }

  clone(meal: any) {
    return this.api.post(`v2/adm/meals/clone`, meal);
  }

  reorder(meals: any) {
    return this.api.post(`v2/adm/meals/reorder/`, meals);
  }



}
