import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-light',
  templateUrl: 'dialog-light.html',
})
export class DialogLightComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogLightComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    //
  }

  onNoClick(): void {
    this.dialogRef.close('cancelou');
  }

}

export interface DialogData {
  title: string;
  message: string;
  confirmation: string;
  bt_ok?: string;
}
