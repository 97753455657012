import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { I18nProvider } from '../../providers/i18n/i18n';

@Component({
  selector: 'app-dialog',
  templateUrl: 'dialog.html',
})
export class DialogComponent {

  STRINGS: any;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    public i18n: I18nProvider,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this.STRINGS = this.i18n.translate();
  }

  onNoClick(): void {
    this.dialogRef.close('cancelou');
  }

}

export interface DialogData {
  title: string;
  message: string;
  confirmation: string;
  inner_msg?: string;
  bt_ok?: string;
  bt_ok_class?: string;
  bt_cancel?: string;
  bt_cancel_hide?: boolean;
  show_alert?: boolean;
  url?: string;
}
