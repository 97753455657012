import { Injectable } from '@angular/core';
import { ApiProvider } from '../api/api';

@Injectable()
export class BannerProvider {

  constructor(
    public api: ApiProvider
  ) {
    //
  }

  get() {
    const url = 'v2/payment/banners';
    return this.api.get(url);
  }

  patchBannerItem(bannerItem: any) {
    bannerItem._method = 'PATCH';
    return this.api.post(`v2/payment/banners/${bannerItem.id}`, bannerItem);
  }

  postBannerItem(bannerItem: any) {
    return this.api.post(`v2/payment/banners/`, bannerItem);
  }

  deleteBannerItem(id: number) {
    return this.api.delete(`v2/payment/banners/${id}`);
  }

}
