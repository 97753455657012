import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from '../../ui/dialog/dialog';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment';
import { banners } from '../../ui/dialog-select-training-banner/banners';

@Component({
  selector: 'dialog-select-training-banner',
  templateUrl: 'dialog-select-training-banner.html',
})

export class DialogSelectTrainingBannerComponent {
  constructor(
    private router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogSelectTrainingBannerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    //
  }

  protected API = (environment.API_ENV_URLS).replace('/api', '/');

  banners: any = banners.free;
  banners_std: any = banners.std;
  banner_selected = '';
  user: any = {};

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
  }


  select(banner: any, _event: any) {
    let elements = document.getElementsByClassName('cover_img');
    for (let i = 0; i < elements.length; i++) { elements[i].setAttribute('style', 'background-color: transparent;'); }​
    _event.target.style.boxShadow = '5px 5px 3px rgba(247, 149, 30, 0.4)';
    this.dialogRef.close(banner);
  }

  selectStandard(banner: any, _event: any) {
    if (this.data.account_status > 1) {
      let elements = document.getElementsByClassName('cover_img');
      for (let i = 0; i < elements.length; i++) { elements[i].setAttribute('style', 'background-color: transparent;'); }​
      _event.target.style.boxShadow = '5px 5px 3px rgba(247, 149, 30, 0.4)';
      this.dialogRef.close(banner);
    } else {
      // call modal to subscribe
      this.callToSubs(banner);
    }
  }

  callToSubs(banner: any) {
    const msg = 'Esse recurso é exclusivo de assinantes Standard ou Pro. Gostaria de dar um upgrade no seu plano?';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: 'Recurso Exclusivo', message: msg, confirmation: 'Sim'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Sim') {
        this.dialogRef.close(banner);
        this.router.navigate(['professor/perfil'], { skipLocationChange: true });
      }
    });
  }

}
