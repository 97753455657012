import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '../../../providers/auth/auth.service';
import { PurchaseService } from '../../../providers/purchase/purchase.service';

import { CustomerProvider } from '../../../providers/customer/customer';
import { HelpersProvider } from '../../../providers/helpers/helpers';

import { DialogLinkComponent } from '../../../ui/dialog-link/dialog-link';

import { environment } from '../../../environments/environment';


@Component({
  selector: 'component-customer-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class CustomerAccountComponent implements OnInit {

  user: any;
  subscription: any;
  premium_expiring = true;
  premium_expired = true;
  loading = false;
  loading_link = false;
  plan: number;
  link: string;
  product: any;
  environment: string = environment.dev;
  ismobile: boolean;

  accountName: string;

  cardForm = new FormGroup({
    supertreinos_plan_id: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    number: new FormControl('', [Validators.required]),
    cvv: new FormControl('', [Validators.required]),
    month_late: new FormControl('', [Validators.required]),
    year_late: new FormControl('', [Validators.required])
  });

  constructor(
    public auth: AuthService,
    public purchase: PurchaseService,
    public toastr: ToastrService,
    private customerProv: CustomerProvider,
    public dialog: MatDialog,
    private device: DeviceDetectorService,
    private helpers: HelpersProvider,
  ) {
    this.ismobile = this.device.isMobile();
    this.user = this.auth.retrieveLocalUser();
  }

  ngOnInit() {
    // console.log('ngOnInit');
    this.getAccount();
  }

  getAccount() {
    this.loading = true;
    this.auth.getCustomerLogin(this.user.email, this.user.password).then(
      (res: any) => {
        this.user = res.user;
        this.user.premium_expires_date = this.helpers.normalizeDate(this.user.premium_expires_date);
        this.loading = false;
        this.setPremiumExpiration();
        this.getSubscription();
      },
      (err: any) => {
        this.loading = false;
        this.toastr.error('Não foi possível retornar os dados da sua conta!', '', this.helpers.toastrCfgs);
        console.log(err);
      }
    );
  }

  getSubscription() {
    this.loading = true;
    this.customerProv.getSubscription().then(
      (res: any) => {
        this.loading = false;
        this.subscription = res.subscription;
        this.setPremiumExpiration();
      },
      (err: any) => {
        this.loading = false;
        this.toastr.error('Não foi possível retornar os dados da sua assinatura!', '', this.helpers.toastrCfgs);
        console.log(err);
      }
    );
  }

  getSubsDetails() {
    // add_subscription_date: "2022-03-04 17:31:15"
    // cancel_subscription_date: "2022-03-22 19:31:39"
    // premium_expires_date: "2025-01-01 00:00:00"
    // prepaid_pagarme: []
    // subscription_apple: false
    // subscription_google: false
    // subscription_pagarme: false
    // console.log(this.subscription);
    if (this.subscription?.subscription_apple) {
      this.accountName = 'Premium Apple';
      return true;
    }
    if (this.subscription?.subscription_google) {
      this.accountName = 'Premium Google';
      return true;
    }
    if (this.subscription?.subscription_pagarme) {
      this.accountName = 'Premium Pagarme';
      return true;
    }
    if (this.subscription?.prepaid_pagarme.length > 0) {
      if (this.premium_expired === false) {
        this.accountName = 'Premium Pré-pago';
        return true;
      }
    }
    if (this.premium_expired === false && this.user.status === 1) {
      this.accountName = 'Premium Pré-pago';
      return true;
    }
    this.accountName = 'Conta Gratuita';
  }

  setPremiumExpiration() {
    const expire = new Date(this.user.premium_expires_date);
    const now = new Date();
    if ( (expire.getTime() - (10 * 24 * 60 * 60 * 1000 )) > now.getTime() ) {
      this.premium_expiring = false;
    } else {
      this.premium_expiring = true;
    }
    if (new Date(this.user.premium_expires_date) > new Date()) {
      this.premium_expired = false;
    }
    this.getSubsDetails();
  }

  gerarLinkPagamento(id: any) {
    // if (this.user.status === '1' || this.user.status === 1) {
    //   this.toastr.error('Cancele sua assinatura ou aguarde a expiração atual antes de assinar um plano pré-pago!');
    //   return false;
    // }
    this.plan = id;
    this.loading_link = true;
    this.purchase.getPaymentLink(id).then(
      (res: any) => {
        console.log(res);
        this.loading_link = false;
        this.link = res.purchase.url;
        this.product = res.purchase;
        // window.open(res.purchase.url, '_blank');
      },
      (err: any) => {
        this.loading_link = false;
        console.log(err);
      }
    );
  }

  abrirLinkpagamento() {
    window.open(this.link, '_blank');
    this.link = null;
  }

  pagVoltar() {
    this.plan = null;
    setTimeout(() => {
      this.getAccount();
    }, 60000);
  }

  getUserFormValidationMessage(el: any) {
    if (el.errors.required) {
      return 'Este campo é obrigatório';
    }

    if (el.errors.incorrect || el.errors.pattern) {
      return 'O valor deste campo é inválido';
    }
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  limparCampos() {
    const plano = this.cardForm.value.supertreinos_plan_id;
    this.cardForm.reset();
    this.cardForm.patchValue({supertreinos_plan_id: plano});
  }

  cardMoc() {
    const data: any = {
      number: '4018720572598048',
      name: 'Aardvark Silva',
      cvv: '123',
      month_late: '11',
      year_late: '22'
    }
    this.cardForm.patchValue(data);
  }


  pay() {
    this.validateAllFormFields(this.cardForm);

    if (this.cardForm.value.name === '' || this.cardForm.value.name === null) {
      this.toastr.error('O nome do cartão de crédito está em branco!', '', this.helpers.toastrCfgs);
      return false;
    }
    if (this.cardForm.value.number === '' || this.cardForm.value.number === null) {
      this.toastr.error('O número do cartão de crédito está em branco!', '', this.helpers.toastrCfgs);
      return false;
    }
    if (this.cardForm.value.cvv === '' || this.cardForm.value.cvv === null) {
      this.toastr.error('O código de segurança do cartão de crédito está em branco!', '', this.helpers.toastrCfgs);
      return false;
    }
    if (this.cardForm.value.month_late === '' || this.cardForm.value.month_late === null) {
      this.toastr.error('O campo do mês de expiração do cartão de crédito está em branco!', '', this.helpers.toastrCfgs);
      return false;
    }
    if (this.cardForm.value.year_late === '' || this.cardForm.value.year_late === null) {
      this.toastr.error('O campo do ano de expiração do cartão de crédito está em branco!', '', this.helpers.toastrCfgs);
      return false;
    }

    const dataString = JSON.stringify(this.cardForm.value);
    const data = JSON.parse(dataString);

    this.loading = true;
    this.customerProv.subscribe(data).then(
      (res: any) => {
        // console.log(res);
        this.user = res.user;
        this.loading = false;
        this.getSubscription();
        this.toastr.success('Maravilha! Acesso premium liberado, agora vamos treinar de verdade!', '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        this.loading = false;
        err = JSON.parse(err.toString().replace('Error: ', ''));
        // console.log(err);
        if (err.errAdm) {
          // console.log(err.errAdm);
          const dialogRef = this.dialog.open(DialogLinkComponent, {
            width: '70vw',
            maxWidth: '360px',
            data: { inner_msg: err.errAdm, title: err.cap, bt_ok: err.btok, url: err.bturl, show_alert: true } });
          dialogRef.afterClosed().subscribe(_result => {
            if (_result === 'ok') {
              // if (err.bturl) {
              //   window.open(err.bturl, '_blank');
              // }
            }
          });
        } else {
          console.log(err);
          this.toastr.error('O sistema retornou um erro. Tente novamente mais tarde ou entre em contato com o suporte.', '', this.helpers.toastrCfgs);
        }
      }
    );

  }

  manageSubs() {
    window.open(this.subscription.subscription_pagarme.manage_url, '_blank');
  }


}
