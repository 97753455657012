import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterprogram'
})

export class ProgramFilterPipe implements PipeTransform {
  transform(
    value: any,
    name: any = '',
    premium: any = '',
    published: any = '',
    workoutMethodIds: any = [],
    workoutMuscleIds: any = [],
    workoutEquipIds: any = [],
    maxItens: number = 5,
    page: number = 0,
    totalItens: any,
  ): any {
    // tratando os filtros
    if (name.length < 3) name = '';
    if (premium === '0') premium = 0;
    if (premium === '1') premium = 1;
    if (published === '0') published = 0;
    if (published === '1') published = 1;
    // console.log(workout_method_ids);

    // testando os filtros
    // se o argumento estiver diferente do filtro ou o filtro estiver vazio, descarta
    if (value === undefined || value === null) return null;
    const retorno = value.filter(
      (v: any) => {
        // nome
        if (name !== '') {
          if (this.removeAccents(v.name.toLowerCase()).indexOf(this.removeAccents(name.toLowerCase())) === -1) {
            return false;
          }
        }

        // premium
        if (premium !== '') {
          if (v.premium !== premium) {
            return false;
          }
        }

        // publicado
        if (published !== '') {
          if (v.published !== published) {
            return false;
          }
        }

        // metodos
        if (workoutMethodIds.length !== 0 && v.workout_method_ids !== null) {
          return this.testFilter(workoutMethodIds, v.workout_method_ids);
        }

        // musculos
        if (workoutMuscleIds.length !== 0 && v.workout_muscle_ids !== null) {
          return this.testFilter(workoutMuscleIds, v.workout_muscle_ids);
        }

        // equipamentos
        if (workoutEquipIds.length !== 0 && v.workout_equip_ids !== null) {
          return this.testFilter(workoutEquipIds, v.workout_equip_ids);
        }

        return true;
      }
    );

    totalItens.count = retorno.length;
    const offset = (page === 0) ? 0 : (page*maxItens);
    return (maxItens === 0) ? retorno : retorno.slice(offset, (offset+maxItens+1));
    // return retorno;
    // return retorno.slice(0, maxItens);
  }

  testFilter(filter: any, prop: any) {
    if (filter.length === 1) {
      for (let a = 0; a < filter.length; a++) {
        for (let b = 0; b < prop.length; b++) {
          if (filter[a] === prop[b]) {
            return true;
          }
        }
      }
      return false;

    } else {
      // if (JSON.stringify(filter) === JSON.stringify(prop)) {
        let contFound = 0;
        for (let a = 0; a < filter.length; a++) {
          for (let b = 0; b < prop.length; b++) {
            if (filter[a] === prop[b]) {
              contFound++;
            }
          }
        }
        if (contFound === filter.length) {
          return true;
        } else {
          return false;
        }
      // }
      // return false;
    }
  }


  removeAccents(source: any) {
    if (source === undefined || source === null) return null;
    var accent = [
      /[\300-\306]/g, /[\340-\346]/g, // A, a
      /[\310-\313]/g, /[\350-\353]/g, // E, e
      /[\314-\317]/g, /[\354-\357]/g, // I, i
      /[\322-\330]/g, /[\362-\370]/g, // O, o
      /[\331-\334]/g, /[\371-\374]/g, // U, u
      /[\321]/g, /[\361]/g, // N, n
      /[\307]/g, /[\347]/g, // C, c
      /[\040-\057]/g,
      /[\072-\077]/g,
      /[\133-\137]/g,
      /[\173-\176]/g,
      /[\100]/g,
      /[\140]/g
    ],
    noaccent = ['A','a','E','e','I','i','O','o','U','u','N','n','C','c', "", "", "", "", "", ""];

    for (var i = 0; i < accent.length; i++){
        source = source.replace(accent[i], noaccent[i]);
    }

    return source;
  };
}
