import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog } from '@angular/material/dialog';

import { DialogLightComponent } from '../../ui/dialog-light/dialog-light';

import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '../../providers/auth/auth.service';
import { HelpersProvider } from '../../providers/helpers/helpers';
import { DialogLoginComponent } from '../../ui/dialog-login/dialog-login';
import { GoalProvider } from '../../providers/goal/goal';
import { CustomerProvider } from '../../providers/customer/customer';
import { I18nProvider } from '../../providers/i18n/i18n';

import packageJson from '../../../package.json';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  // styleUrls: ['./login.component.scss']
})


export class LoginComponent implements OnInit {
  @Output() isAdmin = new EventEmitter<boolean>();
  STRINGS: any;
  PAGE: any;

  inputTypePassword: string = 'password';

  loading: boolean = false;
  form: any;
  formSpinner: boolean = false;
  btIsDisabled: boolean = false;
  isMobile: boolean = false;
  public version: string = packageJson.version;

  formEmail: FormGroup;
  showInvalidEmail: boolean = false;
  emailNotFound: any = '';

  formPassword: FormGroup;
  showInvalidPassword: boolean = false;
  passwordError: any = '';

  domain: string = 'supercoach'
  user: any = {};
  goals: any;

  showLogin: boolean = true;

  studentForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    goal_id: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    password_confirmation: new FormControl('', [Validators.required]),
  });

  loginEmail: string = '';
  loginPass: string = '';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private auth: AuthService,
    private device: DeviceDetectorService,
    public toastr: ToastrService,
    public dialog: MatDialog,
    public helpers: HelpersProvider,
    private goal: GoalProvider,
    private customerProv: CustomerProvider,
    private i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('LoginPage');
    this.isMobile = this.device.isMobile();
  }

  ngOnInit() {
    // this.formSpinner = true;
    this.goal.indexOpen().then(
      (res: any) => {
        this.goals = res.goals;
      },
      (err: any) => {
        console.log(err);
      }
    );

    this.domain = this.helpers.checkDomain();
    // this.domain = 'supertreinos';
    // this.domain = 'supercoach';
    // console.log(this.domain);

    this.formEmail = this.fb.group({
      email: [{value: '', disabled: false}, [Validators.required, Validators.email]]
    });

    this.formPassword = this.fb.group({
      password: [{value: '', disabled: false}, Validators.required]
    });

    if (this.auth.isLoggednIn()) {
      if (this.auth.getAccountType() === 'customer') {
        this.router.navigate(['perfil'], { skipLocationChange: true });
      } else {
        if (this.auth.isTeacher()) {
          this.router.navigate(['professor/dashboard'], { skipLocationChange: true });
        }
        if (this.auth.isMaster()) {
          this.router.navigate(['dashboard'], { skipLocationChange: true });
        }
        if (this.auth.isNutri()) {
          this.router.navigate(['nutri/dashboard'], { skipLocationChange: true });
        }
      }
    }
  }

  ngAfterViewInit() {
    this.setFocus("emailLogin");
  }

  changeLang(lang: string = 'br') {
    this.helpers.storeLocally('language', lang, 0);
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('LoginPage');
  }

  selectionChange(evt: any) {
    // console.log(evt);
    // console.log(evt.selectedIndex);
    if (evt.selectedIndex === 0) {
      this.setFocus("emailLogin");
    } else {
      this.setFocus("pwdLogin");
    }
  }

  private setFocus(targetInput: any) {
    let targetElem = document.getElementById(targetInput);
    setTimeout(function waitTargetElem() {
      if (document.body.contains(targetElem)) {
        targetElem.focus();
      } else {
        setTimeout(waitTargetElem, 100);
      }
    }, 500);
  }

  // onChange(event: any) {
  //   let index = String(event.selectedIndex);
  //   this.targetInput = 'input' + index;
  //   this.setFocus();
  // }

  //  matStepperNext
  emailValidator(stepper: MatStepper) {
    this.resetFormResponses();
    const selectedLanguage = this.helpers.getLocallyStored('language');
    localStorage.clear();
    this.helpers.storeLocally('language', selectedLanguage, 0);
    if (this.formEmail.valid) {
      this.setSpinner();
      // { "user": { "name": "Vinicius Piffardini", "email": "piffardini@hotmail.com" } }
      this.auth.getUserNameAndEmail(this.formEmail.value.email).then(
        res => {
          if (res.error) {
            console.log(res);
            this.resetFormResponses();
            this.emailNotFound = this.PAGE.user_notfound;
            return false;
            // this.emailNotFound = 'Usuário não encontrado';
          }

          // se existir duas contas (Professor e Cliente SuperTreinos) com o mesmo email
          if (res.length > 1) {
            const dialogRef = this.dialog.open(DialogLoginComponent, { disableClose: true, width: '360px', panelClass: 'panel-login' });
            dialogRef.afterClosed().subscribe(result => {
              let logged_user = res[0];
              if (result === 'customer') logged_user = res[1];
              this.auth.storeLocallyUser(logged_user);

              if (logged_user.name) {
                this.user = logged_user;
                this.resetFormResponses();
                stepper.next();
              } else {
                this.resetFormResponses();
                this.showInvalidEmail = true;
                this.user = '';
              }
            });

          } else {
            this.auth.storeLocallyUser(res[0]);

            if (res[0].name) {
              this.user = res[0];
              this.resetFormResponses();
              stepper.next();
            } else {
              this.resetFormResponses();
              this.showInvalidEmail = true;
              this.user = '';
            }
          }

        },
        (err: any) => {
          this.resetFormResponses();
          console.log(err);
          this.emailNotFound = 'Usuário não encontrado';
        }
      );

    } else {
      this.resetFormResponses();
      this.showInvalidEmail = true;
      this.user = '';
    }
  }

  backAccount(stepper: MatStepper) {
    this.user = '';
    stepper.previous();
  }

  login() {
    if (this.formPassword.valid) {
      // console.log(this.user.account_type);
      if (this.user.account_type) {
        this.callUserLogin();
      } else {
        this.callCustomerLogin();
      }

    } else {
      this.resetFormResponses();
      this.showInvalidPassword = true;
    }
  }

  callUserLogin() {
    // console.log('call teacher');
    this.setSpinner();
    this.auth.getLoginToken(this.user.email, this.formPassword.value.password).then(
      res => {
        if (res.token) {
          this.user = res.user;
          this.auth.storeToken(res.token);
          this.auth.storeLocallyUser(this.user);
          this.auth.storeAdminHash();
          this.resetFormResponses();
          this.isAdmin.emit(true);
          if (this.auth.isTeacher()) {
            // console.log('redirect teacher');
            this.disableWhatsSupport();
            this.router.navigate(['professor/dashboard'], { skipLocationChange: true });
          } else {
            // console.log('redirect master');
            this.disableWhatsSupport();
            this.router.navigate(['dashboard'], { skipLocationChange: true });
          }
          this.showInvalidPassword = false;
        }

        if (res.error) {
          // console.log(res);
          // this.auth.logout();
          this.resetFormResponses();
          this.resetFormResponses();
          this.passwordError = this.PAGE.senha_invalida;
        }
      },
      err => {
        // deprecated
        this.auth.logout();
        this.resetFormResponses();
        this.resetFormResponses();
        this.passwordError = err;
      }
    );
  }

  callCustomerLogin() {
    // console.log('call client');
    this.loginEmail = this.user.email;
    this.loginPass = this.formPassword.value.password;
    this.doCustomerLogin();
  }
  doCustomerLogin() {
    // console.log('do client');
    this.setSpinner();
    this.auth.getCustomerLogin(this.loginEmail, this.loginPass).then(
      res => {
        if (res.token) {
          // console.log(res);
          this.user = res.user;
          this.auth.storeToken(res.token);
          this.auth.storeLocallyUser(this.user);
          this.resetFormResponses();
          this.isAdmin.emit(false);
          // console.log('redirect client');
          this.disableWhatsSupport();
          this.router.navigate(['perfil'], { skipLocationChange: true });
          this.showInvalidPassword = false;
        }
      },
      err => {
        console.log(err);
        this.resetFormResponses();
        this.passwordError = err;
      }
    );
  }

  recoverPwd() {
    this.setSpinner();
    this.toastr.info('Requisitando recuperação de senha para o email: ' + this.user.email, '', this.helpers.toastrCfgs);
    let teacher: boolean = (this.user.account_type) ? true : false;
    this.auth.recoverPassword(this.user.email, teacher).then(
      _res => {
        // console.log(res);
        this.toastr.success('A sua senha foi enviada para o email: ' + this.user.email, '', this.helpers.toastrCfgs);
        const dialogRef = this.dialog.open(DialogLightComponent, {
          disableClose: true,
          width: '360px',
          panelClass: 'recover-login',
          data: {
            message: '<i class="material-icons">done</i> <div>A senha foi enviada para: <br> <b>' + this.user.email + '</b></div>',
            bt_ok: 'Favor verificar a caixa de "Spam"'
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log(result);
        });

        this.resetFormResponses();
      },
      err => {
        this.toastr.error(err, '', this.helpers.toastrCfgs);
        this.resetFormResponses();
      }
    );
  }

  callNewAccount(type: string = 'teacher') {
    switch (type) {
      case 'teacher':
        this.router.navigate(['professor/nova-conta'], { skipLocationChange: true });
        break;
    
      case 'nutri':
        // this.toastr.error('As novas contas estão desabilitadas temporariamente.', '', this.helpers.toastrCfgs);
        this.router.navigate(['nutri/nova-conta'], { skipLocationChange: true });
        break;
    
      default:
        this.router.navigate(['cliente/nova-conta'], { skipLocationChange: true });
        break;
    }        
  }

  save() {
    this.validateAllFormFields(this.studentForm);

    if (this.studentForm.value.password !== this.studentForm.value.password_confirmation) {
      this.toastr.error('As senhas não conferem!', '', this.helpers.toastrCfgs);
      return false;
    }

    const dataString = JSON.stringify(this.studentForm.value);
    const data = JSON.parse(dataString);
    // if (data.details.birthdate) {
    //   data.details.birthdate = data.details.birthdate.substring(-10, 10);
    // }

    // supertreinos customer
    data.user_id = 1;
    // empty field to satisfy API
    data.details = {};

    this.loading = true;
    this.customerProv.createCustomer(data).then(
      (_user: any) => {
        // this.loading = false;
        this.loginEmail = data.email;
        this.loginPass = data.password;
        this.doCustomerLogin();
      },
      (err: any) => {
        this.loading = false;
        this.toastr.error(err || 'Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
      }
    );

  }

  //
  resetFormResponses() {
    this.formSpinner = false;
    this.showInvalidEmail = false;
    this.btIsDisabled = false;
    this.emailNotFound = '';

    this.showInvalidPassword = false;
    this.passwordError = '';
  }

  setSpinner() {
    this.formSpinner = true;
    this.btIsDisabled = true;
  }

  recoverPassword() {
    this.toastr.info('Requisitando recuperação de senha para o email: ' + this.user.email, '', this.helpers.toastrCfgs);
    this.auth.recoverPassword(this.formEmail.value.email).then(
      res => {
        if (res.status) {
          this.toastr.success('A sua senha foi enviada para o email: ' + this.user.email, '', this.helpers.toastrCfgs);
        }
      },
      err => {
        this.toastr.error(err, '', this.helpers.toastrCfgs);
      }
    );
  }

  getUserFormValidationMessage(el: any) {
    if (el.errors.required) {
      return 'Este campo é obrigatório';
    }

    if (el.errors.incorrect || el.errors.pattern) {
      return 'O valor deste campo é inválido';
    }
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  tooglePassword() {
    // console.log('minimenu');
    if (this.inputTypePassword === 'password') {
      this.inputTypePassword = 'text';
    } else {
      this.inputTypePassword = 'password';
    }
  }

  disableWhatsSupport() {
    let whats = document.getElementById('__EAAPS_PORTAL');
    // console.log(whats);
    whats.remove();
  }


}
