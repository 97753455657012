import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../../providers/auth/auth.service';


@Component({
  selector: 'app-basic-nav',
  templateUrl: './basic-nav.component.html',
  // styleUrls: ['./material-nav.component.scss']
})
export class BasicNavComponent implements OnInit {

  public user: any;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    public auth: AuthService
  ) {
    //
  }

  ngOnInit() {
    this.user = this.auth.retrieveLocalUser();
  }

}
