import { Component, OnInit } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { DialogLightComponent } from '../../../ui/dialog-light/dialog-light';

@Component({
  selector: 'app-alunos-anamnese',
  templateUrl: './anamnese.component.html',
  styleUrls: ['./anamnese.component.scss']
})
export class AlunosAnamneseComponent implements OnInit {
  protected headers: any;

  loading: boolean = false;

  constructor(
    public dialog: MatDialog,
    public toastr: ToastrService,
  ) {
    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};
    // this.teacherCode = this.route.snapshot.params.code;
  }

  ngOnInit() {
    //
  }


  helpWaitline() {
    let msg = '';
    msg += '<p>Para obter alunos na lista, divulgue o link acima em seu site e nas suas redes sociais.</p>';
    msg += '<p>Os alunos pré-cadastrados não somarão como alunos efetivos no seu plano, exceto se forem aceitos.</p>';
    msg += '<p>Procure aceitar possíveis alunos para um período grátis e aumentar suas chances de conversão.</p>';

    const dialogRef = this.dialog.open(DialogLightComponent, { width: '360px', data: { message: msg } });
    dialogRef.afterClosed().subscribe(_result => {});
  }


}
