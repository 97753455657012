import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { DialogComponent } from '../../../ui/dialog/dialog';

import { ApiProvider } from '../../../providers/api/api';
import { HelpersProvider } from '../../../providers/helpers/helpers';
import { AuthService } from '../../../providers/auth/auth.service';
import { CustomerProvider } from '../../../providers/customer/customer';
import { UserProvider } from '../../../providers/user/user';
import { I18nProvider } from '../../../providers/i18n/i18n';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'component-customer-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class CustomerFeedbackComponent implements OnInit {
  protected headers: any;
  @Input() studentData: any = null;
  @Input() user: any = null;
  @Input() studentSelected: any = null;

  loading: boolean = false;
  feedbacks = [];

  PAGE: any;
  STRINGS: any;
  ENV: any;

  constructor(
    public toastr: ToastrService,
    public auth: AuthService,
    public api: ApiProvider,
    public helpers: HelpersProvider,
    public dialog: MatDialog,
    public studentProv: CustomerProvider,
    public userProv: UserProvider,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('DashboardFeedbacksComponent');

    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};
    this.ENV = environment;
  }
  ngOnInit() {
    this.getFeedbacks(this.studentData.id);
  }

  ngOnChanges() {
    this.getFeedbacks(this.studentData.id);
    // this.getHistory(this.studentData.id);
    // this.loading = true;
    // setTimeout(() => {
    //   this.loading = false;
    // }, 400);
  }

  getFeedbacks(id: any = 0) {
    this.loading = true;
    this.studentProv.getFeedbacks(id).then(
      (res: any) => {
        this.loading = false;
        this.feedbacks = res.feedbacks;
      },
      (err: any) => {
        this.loading = false;
        console.log(err);
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      }
    );
  }

  toggleClass(item: any) {
    item.active = !item.active;
  }

  openFeedback(f: any = null) {
    const title = this.PAGE.feedback_title;
    const inner_msg = '' +
      '<p>'+this.PAGE.aluno+': '+f.customer_name+'</p>' +
      '<p>'+this.PAGE.titulo_programa+': '+f.program_name+'</p>' +
      '<p>'+this.PAGE.titulo_treino+': '+f.training_name+'</p>' +
      '<pre>'+this.PAGE.feedback+': '+f.feedback+'</pre>' +
      '';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '90%',
      panelClass: 'mat-dialog-feedback-details',
      data: {title: title, inner_msg: inner_msg, confirmation: 'deletar', bt_cancel_hide: true}
    });
  }

}
