import { Component, OnInit, OnChanges, Input } from '@angular/core';
// import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DialogComponent } from '../../../ui/dialog/dialog';
import { DialogLightComponent } from '../../../ui/dialog-light/dialog-light';
// import { ActivatedRoute } from '@angular/router';

import { ApiProvider } from '../../../providers/api/api';
import { HelpersProvider } from '../../../providers/helpers/helpers';
import { AuthService } from '../../../providers/auth/auth.service';
import { CustomerProvider } from '../../../providers/customer/customer';
import { UserProvider } from '../../../providers/user/user';
import { FileProvider } from '../../../providers/file/file';
import { I18nProvider } from '../../../providers/i18n/i18n';


@Component({
  selector: 'component-customer-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class CustomerFilesComponent implements OnInit, OnChanges {
  protected headers: any;
  @Input() studentData: any = null;

  loading: boolean = false;
  files = [];
  arquivos = [];

  PAGE: any;
  STRINGS: any;

  constructor(
    public toastr: ToastrService,
    public auth: AuthService,
    public api: ApiProvider,
    public helpers: HelpersProvider,
    public dialog: MatDialog,
    // private route: ActivatedRoute,
    public studentProv: CustomerProvider,
    public userProv: UserProvider,
    public fileProv: FileProvider,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('CustomerFilesComponent');

    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};

  }
  ngOnInit() {
    // console.log('notes ok');
    // console.log(this.studentData);
    // this.getFiles(this.studentData.id);
    // this.getFilesList();
  }

  ngOnChanges() {
    this.getFilesList();
  }

  getFilesList() {
    this.loading = true;
    this.fileProv.index().then(
      (res: any) => {
        // this.loading = false;
        this.files = res.files;
        this.getFiles(this.studentData.id);
      },
      (err: any) => {
        console.log(err);
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        this.files = [];
        this.loading = false;
      }
    );
  }

  getFiles(id: number = 0) {
    // this.loading = true;
    this.studentData.files = [];
    // busca os arquivos do aluno
    this.studentProv.getUserFiles(id).then(
      (res: any) => {
        // this.loading = false;
        this.studentData.files = res.files;
        this.reorderFiles();
      },
      (err: any) => {
        console.log(err);
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        this.studentData.files = [];
        this.loading = false;
      }
    );
  }  

  reorderFiles() {
    // ordena os arquivos de acordo com a selecao do aluno
    if (this.studentData.files.length === 0) {
      this.loading = false;
      return false;
    }

    const stdfiles = [];
    const loosefiles = [];
    this.studentData.files.forEach(sFile => {
      this.files.sort((x,y) => {
        if (x.id === sFile.file_id) {
          return -1;
        } else if (y.id === sFile.file_id) {
          return 1;
        } else {
          return 0;
        }
      })
    });
    this.loading = false;
  }

  studentHasDoc(student: any, file_id: number) {
    if (student.files.length === 0) return false;
    // verifica se o arquivo tem algum aluno selecionado para ele
    let checked: boolean = false;
    student.files.forEach(function (file: any) {
      (file.file_id === file_id) ? checked = true : null;
    }); 
    return checked;
  }

  removeStudentFile(fid: number) {
    const msg = this.PAGE.remover_arquivo;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.remover_arquivo_titulo, message: msg, confirmation: 'deletar'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'deletar') {
        this.loading = true;
        const data = {
          cid: this.studentData.id,
          fid: fid
        };
        this.studentProv.admCustomerToggleFile(data).then(
          (_res: any) => {
            this.toastr.success(this.PAGE.lista_arquivo_sucesso, '', this.helpers.toastrCfgs);
            this.getFiles(this.studentData.id);
          },
          (err: any) => {
            console.log(err)
            this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
            this.loading = false;
          }
        );
      }
    });
  }

  toggleActiveProgram(cid: number, fid: number) {
    const data = {
      cid: cid,
      fid: fid
    };
    this.studentProv.admCustomerToggleFile(data).then(
      (_res: any) => {
        // this.loading = false;
        // this.students = res.customers;
        this.toastr.success(this.PAGE.lista_arquivo_sucesso, '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        console.log(err);
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        this.loading = false;
      }
    );
  }

  toggleDocStudent(file: any, student: any) {
    this.toggleActiveProgram(student.id, file.id);
  }

  checkSlideValue(slide: any) {
    return slide._checked;
  }

  helpFile() {
    let msg = '';
    msg += '<img src="assets/imgs/ajuda-arquivos.png">';

    const dialogRef = this.dialog.open(DialogLightComponent, { width: '70vw', data: { message: msg } });
    dialogRef.afterClosed().subscribe(_result => {});
  }

  helpAllFiles() {
    let msg = this.PAGE.ajuda_arquivos;

    const dialogRef = this.dialog.open(DialogLightComponent, { width: '360px', data: { message: msg } });
    dialogRef.afterClosed().subscribe(_result => {});
  }

}
