import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-cancel-plan',
  templateUrl: 'dialog-cancel-plan.html',
  styleUrls: ['dialog-cancel-plan.scss']
})
export class DialogCancelPlanComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogCancelPlanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    //
  }

  onNoClick(): void {
    this.dialogRef.close('cancelou');
  }

}

export interface DialogData {
  title: string;
  message: string;
  confirmation: string;
  inner_msg?: string;
  bt_ok?: string;
  bt_ok_class?: string;
  bt_cancel?: string;
  bt_cancel_class?: string;
  show_alert?: boolean;
  url?: string;
}
