import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterteacher'
})

export class TeacherFilterPipe implements PipeTransform {
  transform(
    value: any,
    name: any = '',
    // premium: any = '',
    // published: any = '',
    // gender: any = '',
    maxItens: number = 12,
    totalItens: any,
  ): any {
    // tratando os filtros
    if (name.length < 3) name = '';
    // if (premium === '0') premium = 0;
    // if (premium === '1') premium = 1;
    // if (published === '0') published = 0;
    // if (published === '1') published = 1;
    // if (gender === 'ambos') gender = '';

    // testando os filtros
    // se o argumento estiver diferente do filtro ou o filtro estiver vazio, descarta
    if (value === undefined || value === null) return null;
    const retorno = value.filter(
      (v: any) => {
        // testa primeiro o nome e se nao achar, testa o email
        // nome
        if (name !== '') {
          if (this.removeAccents(v.name.toLowerCase()).indexOf(this.removeAccents(name.toLowerCase())) === -1) {
            // email
            if (this.removeAccents(v.email.toLowerCase()).indexOf(this.removeAccents(name.toLowerCase())) === -1) {
              return false;
            }
          }
        }

        // premium
        // if (premium !== '') {
        //   if (v.premium !== premium) {
        //     return false;
        //   }
        // }
        //
        // // publicado
        // if (published !== '') {
        //   if (v.published !== published) {
        //     return false;
        //   }
        // }
        //
        // // genero
        // if (gender !== '') {
        //   if (v.gender !== gender) {
        //     return false;
        //   }
        // }

        return true;
      }
    );

    totalItens.count = retorno.length;

    // return retorno;
    return retorno.slice(0, maxItens);
  }

  testFilter(filter: any, prop: any) {
    if (filter.length === 1) {
      for (let a = 0; a < filter.length; a++) {
        for (let b = 0; b < prop.length; b++) {
          if (filter[a] === prop[b]) {
            return true;
          }
        }
      }
      return false;

    } else {
      let contFound = 0;
      for (let a = 0; a < filter.length; a++) {
        for (let b = 0; b < prop.length; b++) {
          if (filter[a] === prop[b]) {
            contFound++;
          }
        }
      }
      if (contFound === filter.length) {
        return true;
      } else {
        return false;
      }
    }
  }

  removeAccents(source: any) {
    if (source === undefined || source === null) return null;
    var accent = [
      /[\300-\306]/g, /[\340-\346]/g, // A, a
      /[\310-\313]/g, /[\350-\353]/g, // E, e
      /[\314-\317]/g, /[\354-\357]/g, // I, i
      /[\322-\330]/g, /[\362-\370]/g, // O, o
      /[\331-\334]/g, /[\371-\374]/g, // U, u
      /[\321]/g, /[\361]/g, // N, n
      /[\307]/g, /[\347]/g, // C, c
      /[\040-\057]/g,
      /[\072-\077]/g,
      /[\133-\137]/g,
      /[\173-\176]/g,
      /[\100]/g,
      /[\140]/g
    ],
    noaccent = ['A','a','E','e','I','i','O','o','U','u','N','n','C','c', "", "", "", "", "", ""];

    for (var i = 0; i < accent.length; i++){
        source = source.replace(accent[i], noaccent[i]);
    }

    return source;
  };

}
