import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { DeviceDetectorService } from 'ngx-device-detector';

import { ProgramProvider } from '../../../providers/program/program';
import { CustomerProvider } from '../../../providers/customer/customer'
import { I18nProvider } from '../../../providers/i18n/i18n';

@Component({
  selector: 'dashboard-trainings-done',
  templateUrl: './trainings-done.html',
})
export class DashboardTrainingsDoneComponent implements OnInit {

  STRINGS: any;
  PAGE: any;

    @Input() user: any;
    loading = true;

    multi: any[];
    data: any[];
    filterDays: number = 7;
    result: any;

    trainingDoneByDay: any[];


    // options
    legend: boolean = false;
    showLabels: boolean = false;
    animations: boolean = true;
    gradient: boolean = true;
    xAxis: boolean = true;
    yAxis: boolean = true;
    showYAxisLabel: boolean = true;
    showXAxisLabel: boolean = true;
    xAxisLabel: string = 'Dias';
    yAxisLabel: string = 'Treinos Concluídos';
    timeline: boolean = true;
    showGridLines: boolean = true;
    view: any[];

    colorScheme = {
      domain: ['#5AA454']
    };

    ismobile: boolean = false;

    constructor(
      public toastr: ToastrService,
      public programProv: ProgramProvider,
      public customerProv: CustomerProvider,
      private router: Router,
      private device: DeviceDetectorService,
      public i18n: I18nProvider,
    ) {
      //
      // Object.assign(this, { data: this.data });
      this.STRINGS = this.i18n.translate();
      this.PAGE = this.i18n.translate('DashboardPage');
    }

    ngOnInit() {
      this.ismobile = this.device.isMobile();
      this.view = (this.ismobile) ? [300, 280] : [565, 340];
      // this.data = [
      //   {
      //     "avatar": "http://supertreinos.loc/storage/files/user/13/avatar/13_avatar_1591642769.png",
      //     "name": "Luana",
      //     "lastTraining": "13/08/2020 - Programa de Definição  - Treino C",
      //     "id": 1,
      //   },
      //   {
      //     "avatar": "http://supertreinos.loc/storage/files/user/13/avatar/13_avatar_1591642769.png",
      //     "name": "Luana",
      //     "lastTraining": "13/08/2020 - Programa de Definição  - Treino C",
      //     "id": 1,
      //   },
      //   {
      //     "avatar": "http://supertreinos.loc/storage/files/user/13/avatar/13_avatar_1591642769.png",
      //     "name": "Luana",
      //     "lastTraining": "13/08/2020 - Programa de Definição  - Treino C",
      //     "id": 1,
      //   },
      //   {
      //     "avatar": "http://supertreinos.loc/storage/files/user/13/avatar/13_avatar_1591642769.png",
      //     "name": "Luana",
      //     "lastTraining": "13/08/2020 - Programa de Definição  - Treino C",
      //     "id": 1,
      //   },
      // ];
      //
      // this.multi = [
      //   {
      //     "name": "Treinos Concluídos",
      //     "series": [
      //       {
      //         "name": "Segunda",
      //         "value": 3
      //       },
      //       {
      //         "name": "Terça",
      //         "value": 7
      //       },
      //       {
      //         "name": "Quarta",
      //         "value": 4
      //       },
      //       {
      //         "name": "Quinta",
      //         "value": 11
      //       },
      //       {
      //         "name": "Sexta",
      //         "value": 14
      //       },
      //       {
      //         "name": "Sábado",
      //         "value": 4
      //       },
      //       {
      //         "name": "Domingo",
      //         "value": 1
      //       },
      //     ]
      //   },
      // ];


      this.customerProv.getLastDone().then(
        (res: any) => {
          this.result = res;
          this.processaData(this.result);
        },
        (err: any) => {
          console.log(err);
        }
      );

    }

    processaData(res: any) {
      let counter: number = 0;
      let trainingDoneByDay: Array<any> = [];

      res.trainingDoneByDay.map((row: any) => {
        counter++;
        if (counter > this.filterDays) return true;
        const d = new Date(row.name);
        const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
        const da = parseInt(new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d));
        trainingDoneByDay.unshift({ "name": `${da+1}/${mo}`, "value": row.value });
      });

      this.trainingDoneByDay = trainingDoneByDay;
      this.multi = [{
        "name": this.PAGE.treinos_concluidos,
        "series": trainingDoneByDay
      }];

      // console.log(res.trainingDoneByDay);
      // console.log(this.multi);

      this.data = res.lastTrainings;
      this.loading = false;
    }

    changeDays(days: number = 7) {
      this.filterDays = days;
      this.processaData(this.result);
    }



    onSelect(_data: any): void {
      // console.log('Item clicked', JSON.parse(JSON.stringify(_data)));
    }

    onActivate(_data: any): void {
      // console.log('Activate', JSON.parse(JSON.stringify(_data)));
    }

    onDeactivate(_data: any): void {
      // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
    }

    goToStudent(student: any) {
      // console.log(student);
      if (this.user.account_type === 'teacher') {
        this.router.navigate(['professor/alunos/' + student.id], { skipLocationChange: true });
      } else {
        this.router.navigate(['clientes/' + student.email], { skipLocationChange: true });
      }
    }

    goToStudents() {
      if (this.user.account_type === 'teacher') {
        this.router.navigate(['professor/alunos/0'], { skipLocationChange: true });
      } else {
        this.router.navigate(['clientes/0'], { skipLocationChange: true });
      }
    }

    goToStudentHistory(student: any) {
      // console.log(student);
      this.router.navigate(['professor/alunos/' + student.id], { skipLocationChange: true });
    }

    goToTutorial() {
      window.open('https://www.stcoach.app/tutoriais-aluno', '_system', 'location=yes');
    }

}
