import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../../ui/dialog/dialog';
import { DialogInputCustomComponent } from '../../../ui/dialog-input-custom/dialog-input-custom';
import { DialogLogoProComponent } from '../../../ui/dialog-logo-pro/dialog-logo-pro';
import { DialogLightComponent } from '../../../ui/dialog-light/dialog-light';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { AuthService } from '../../../providers/auth/auth.service';
import { HelpersProvider } from '../../../providers/helpers/helpers';
import { UserProvider } from '../../../providers/user/user';
import { ApiProvider } from '../../../providers/api/api';

import { codes } from '../../../providers/helpers/countriesJson_ptBR';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-professores-adm-lista',
  templateUrl: './professores.component.html',
  // styleUrls: ['./professores.component.scss']
})
export class ListaAdmProfessoresComponent implements OnInit {
  @ViewChild(DropzoneComponent, {static: false}) componentRef?: DropzoneComponent;
  protected headers: any;
  section: string = 'account'
  codes = codes;
  ismobile: boolean = false;
  environment: string = environment.dev;

  teachers: any = [];
  totalTeachers: number = 0;
  selectedTeacher: number = 0;
  user: any = {};
  selectedUser: any = {};
  userBirthdate: any;
  userPremiumExpire: any;
  userForm = new FormGroup({
    id: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
    password: new FormControl('', [Validators.required]),
    password_confirmation: new FormControl('', [Validators.required]),
    gender: new FormControl(''),
    birthdate: new FormControl(''),
    cref: new FormControl(''),
    uf: new FormControl(''),
    city: new FormControl(''),
    ddi: new FormControl(''),
    whatsapp: new FormControl(''),
    premium_id: new FormControl(''),
    premium_expires_date: new FormControl(''),
    observations: new FormControl(''),
  });
  userSuperTreinosPlanId = '0';
  activity = { 
    "is_educf" : false,
    "is_fisio": false,
    "is_nutri": false,
    "is_stdnt": false
  };

  cardForm = new FormGroup({
    supertreinos_plan_id: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    number: new FormControl('', [Validators.required]),
    cvv: new FormControl('', [Validators.required]),
    month_late: new FormControl('', [Validators.required]),
    year_late: new FormControl('', [Validators.required])
  });

  purchase: any = {
    supertreinos_plan_id: 0,
  };
  students: any = {
    total: 0,
    max: 0,
    inactives: 0,
  };
  programs: any = {
    total: 0,
    max: 0,
  };
  files: any = {
    total: 0,
    max: 0,
  };

  formSpinner: boolean = false;
  btIsDisabled: boolean = false;
  loading: boolean = false;
  showFormCC: boolean = false;

  ufs: any;

  totalItens = { count: 0 };
  maxItens: number = 12;
  search: any = {
    name: <string> '',
    email: <string> '',
  };

  public avatar: DropzoneConfigInterface = {
    paramName: 'avatar',
    thumbnailMethod: 'contain',
    thumbnailHeight: null,
    thumbnailWidth: null,
    addRemoveLinks: true,
    dictRemoveFile: 'REMOVER IMAGEM',
    dictCancelUpload: 'CANCELAR ENVIO',
    dictCancelUploadConfirmation: 'CONFIRMA CANCELAR ENVIO?',
    dictInvalidFileType: 'ARQUIVO INVÁLIDO',
    maxFiles: 1,
  };

  public logo: DropzoneConfigInterface = {
    paramName: 'logo',
    thumbnailMethod: 'contain',
    thumbnailHeight: null,
    thumbnailWidth: null,
    addRemoveLinks: true,
    dictRemoveFile: 'REMOVER IMAGEM',
    dictCancelUpload: 'CANCELAR ENVIO',
    dictCancelUploadConfirmation: 'CONFIRMA CANCELAR ENVIO?',
    dictInvalidFileType: 'ARQUIVO INVÁLIDO',
    maxFiles: 1,
  };

  constructor(
    public auth: AuthService,
    public toastr: ToastrService,
    public userProv: UserProvider,
    public helpers: HelpersProvider,
    public dialog: MatDialog,
    public api: ApiProvider,
    public route: Router,
    private device: DeviceDetectorService,
  ) {
    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};
  }

  ngOnInit() {
    this.ismobile = this.device.isMobile();
    this.codes = this.helpers.sortByProperty(codes, 'nome');
    this.ufs = this.helpers.returnUFs();
    this.user = this.auth.retrieveLocalUser();
    this.avatar.dictDefaultMessage = ((this.ismobile)
        ? 'Clique aqui para enviar sua foto (500px de largura por 500px de altura)'
        : 'Arraste sua foto até aqui (500px de largura por 500px de altura)'
    );
    this.logo.dictDefaultMessage = ((this.ismobile)
        ? 'Clique aqui para enviar sua logo (500px de largura por 185px de altura)'
        : 'Arraste sua logo até aqui (500px de largura por 185px de altura)'
    );
    if (this.user.account_type === 'teacher') {
      this.selectedUser = this.user;
      if (this.user.subs) {
        this.userSuperTreinosPlanId = this.user.subs.supertreinos_plan_id;
      }
      this.activity = { 
        "is_educf" : this.user.is_educf,
        "is_fisio": this.user.is_fisio,
        "is_nutri": this.user.is_nutri,
        "is_stdnt": this.user.is_stdnt
      };  
      this.loadForm(this.selectedUser);
      this.getSubscriptions();
      this.updateDropzoneParams(this.avatar);
      this.updateDropzoneParams(this.logo);
    } else {
      this.getTeacherList();
    }
  }

  formatPlan(teacher: any) {
    const plan = teacher.purchase.supertreinos_plan_title.split(' ');
    const pagarme = (teacher.purchase.pagarme === null) ? '' : '(recorrente)';
    const expires = (teacher.premium_expires_date === null) ? null : teacher.premium_expires_date.split(' ');
    const expiration = (expires === null || expires[0] === undefined) ? '' : '(' + expires[0] + ')';

    if (plan[3] === undefined) {
      return '<span class="free">Gratuito</span>';
    } else {
      return '<span class="' + plan[3] + '">' + plan[3] + ' ' + pagarme + ' ' + expiration + '</span>';
    }
  }

  checkPlan(teacher: any) {
    teacher.bt_plan = '<span class="checking">verificando...</span>';

    this.userProv.subscription(teacher.id).then(
      (res: any) => {
        const plan = res.purchase.supertreinos_plan_title.split(' ');
        const pagarme = (res.purchase.pagarme === null) ? '' : '(recorrente)';
        const expires = (res.teacher.premium_expires_date === null) ? null : res.teacher.premium_expires_date.split(' ');
        const expiration = (expires === null || expires[0] === undefined) ? '' : '(' + expires[0] + ')';

        teacher.students = res.students;
        teacher.programs = res.programs;
        teacher.files = res.files;

        if (plan[3] === undefined) {
          teacher.bt_plan = '<span class="free">Gratuito</span>';
        } else {
          teacher.bt_plan = '<span class="' + plan[3] + '">' + plan[3] + ' ' + pagarme + ' ' + expiration + '</span>';
        }

        return teacher.premium_id;
      },
      (err: any) => {
        this.loading = false;
        teacher.bt_plan = '<span class="erro">erro</span>';
        this.toastr.error(err || 'Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
      }
    );

  }

  getPlan(teacher: any) {
    let plan = 'Gratuito';

    if (teacher) {
      if (teacher.active_sub) {
        if (teacher.pagarme_payment_link_status === 'paid') {
          if (teacher.active_sub.supertreinos_plan_title) {
            return teacher.active_sub.supertreinos_plan_title;
          }
        }
        if (teacher.active_sub.pagarme_subscriber_id !== null && teacher.active_sub.cancel_subscription_date === null) {
          if (teacher.active_sub.supertreinos_plan_title) {
            return teacher.active_sub.supertreinos_plan_title;
          }
        }
      }
      if (teacher.premium_expires_date) {
        if (teacher.premium_id) {
          switch (teacher.premium_id) {
            case 0:
              plan = 'Grátis';
              break;
            case 1:
              plan = 'Basic';
              break;
            case 2:
              plan = 'Standard';
              break;
            case 3:
              plan = 'Pro';
              break;
            case 4:
              plan = 'Black';
              break;
            default:
              plan = 'N/D';
              break;
          }
        }
        if (this.expired(teacher)) {
          plan += ' (expirado)';
        } else {
          plan += ' (' + teacher.premium_expires_date.substring(-10, 10) + ')';
        }

      }
    }
    return plan;
  }

  expired(teacher: any, mode: string = 'date') {
    if (!teacher.premium_expires_date) return null;
    const expires = new Date(teacher.premium_expires_date.replace(' ', 'T')).getTime();
    const now = Date.now();

    let distance: any = expires - now;

    if (distance < 0) {
      // return false;
      return true;
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    // const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    // const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    distance = '';

    if (mode === 'date') {
      if (days > 0 && days < 2) distance += days + ' dia'; // + minutes + "m " + seconds + "s ";
      if (days > 1) distance += days + ' dias';

      if (days > 0 && hours > 0) distance += ' e ';

      if (hours > 0 && hours < 2) distance += hours + ' h'; // hora
      if (hours > 1) distance += hours + ' h'; // horas
    }

    if (mode === '%') {
      distance = (((days * 24) + hours) / (30 * 24)) * 100;
      if (days > 30) {
        distance = 100;
      }
    }

    // return distance;
    return false;
  }

  getTeacherList() {
    this.loading = true;
    this.userProv.getTeacherList().then(
      (res: any) => {
        this.teachers = res;
        this.totalTeachers = 0;
        this.teachers.map((_prof: any) => {
          this.totalTeachers++;
        });
        this.loading = false;
      },
      (err: any) => {
        this.loading = false;
        console.log(err);
      }
    );
  }

  doInfinite(){
    setTimeout(() => {
      this.maxItens = this.maxItens + 12;
    }, 250);
  }
  resetScroll() {
    this.maxItens = 12;
  }

  save() {
    // verifique se a senha esta sendo enviada vazia
    // this.validateAllFormFields(this.userForm);

    if (this.userForm.value.password !== this.userForm.value.password_confirmation) {
      this.toastr.error('As senhas não conferem!', '', this.helpers.toastrCfgs);
      return false;
    }

    // formata a data de nascimento
    const dataString = JSON.stringify(this.userForm.value);
    const data = JSON.parse(dataString);
    if (data.birthdate) {
      data.birthdate = data.birthdate.substring(-10, 10);
    }
    // forma a data de expiracao
    if (data.premium_expires_date) {
      data.premium_expires_date = data.premium_expires_date.substring(-10, 10);
    }
    if (data.password === '') {
      delete data.password;
      delete data.password_confirmation;
    }

    data.user_id = this.selectedUser.id;

    data.is_educf = (this.activity.is_educf) ? 1 : 0;
    data.is_fisio = (this.activity.is_fisio) ? 1 : 0;
    data.is_nutri = (this.activity.is_nutri) ? 1 : 0;
    data.is_stdnt = (this.activity.is_stdnt) ? 1 : 0;

    this.loading = true;
    this.userProv.updateUser(data).then(
      (_user: any) => {
        // console.log(user);
        this.loading = false;
        this.toastr.success('Seus dados foram atualizados com sucesso!', '', this.helpers.toastrCfgs);
        this.getTeacherList();
      },
      (err: any) => {
        this.loading = false;
        this.toastr.error(err || 'Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
        this.getTeacherList();
      }
    );

  }


  callPlan(planId: number = 0) {

    if (planId === 0) {
      if (parseInt(this.purchase.supertreinos_plan_id) !== 0) {
        const msg = 'Deseja cancelar a sua assinatura? todos os alunos e programas em excesso serão desabilitados.';
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '360px',
          data: {title: 'Assinar novo plano', message: msg, confirmation: 'Sim'}
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result === 'Sim') {
            this.cancelSubs();
          }
        });

      }

    } else if (parseInt(this.purchase.supertreinos_plan_id) < planId) {

      this.showFormCC = true;
      this.cardForm.patchValue({supertreinos_plan_id: planId});

    } else if (planId !== 0 && parseInt(this.purchase.supertreinos_plan_id) === planId) {

      const msg = 'Este é o seu plano atual. Deseja cancelar?';
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '360px',
        data: {
          title: 'Cancelar o plano atual?',
          message: msg,
          confirmation: 'Sim',
          bt_ok: 'Sim',
          bt_cancel: 'Não',
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === 'Sim') {
          this.cancelSubs();
        }
      });

    } else {

      const msg = 'Seu plano atual é superior ao escolhido. Deseja migrar para esse plano? Você terá menos recursos disponíveis e todos os alunos e programas em excesso serão desabilitados.';
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '360px',
        data: {title: 'Assinar novo plano', message: msg, confirmation: 'Sim'}
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === 'Sim') {
          this.showFormCC = true;
          this.cardForm.patchValue({supertreinos_plan_id: planId});
        }
      });

    }
  }

  cancelSubs() {
    this.loading = true;
    this.userProv.cancelSubscription().then(
      (user: any) => {
        this.files = user.files;
        this.programs = user.programs;
        this.purchase = user.purchase;
        this.students = user.students;

        this.userSuperTreinosPlanId = this.purchase.supertreinos_plan_id;
        this.setUserPlanId(this.purchase);
        this.setSubscription();
        (document.getElementById('super-coach-logo') as HTMLFormElement).src = 'assets/imgs/logo-topo-stcoach.png';
        this.loading = false;
        this.toastr.success('Seus dados foram atualizados com sucesso!', '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        this.loading = false;
        this.toastr.error(err || 'Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
      }
    );
  }

  pay() {
    this.validateAllFormFields(this.cardForm);

    if (this.cardForm.value.supertreinos_plan_id === '' || this.cardForm.value.supertreinos_plan_id === null) {
      this.toastr.error('O plano da assinatura não foi selecionado!', '', this.helpers.toastrCfgs);
      return false;
    }
    // if (this.cardForm.value.name === '' || this.cardForm.value.name === null) {
    //   this.toastr.error('O nome do cartão de crédito está em branco!');
    //   return false;
    // }
    if (this.cardForm.value.number === '' || this.cardForm.value.number === null) {
      this.toastr.error('O número do cartão de crédito está em branco!', '', this.helpers.toastrCfgs);
      return false;
    }
    if (this.cardForm.value.cvv === '' || this.cardForm.value.cvv === null) {
      this.toastr.error('O código de segurança do cartão de crédito está em branco!', '', this.helpers.toastrCfgs);
      return false;
    }
    if (this.cardForm.value.month_late === '' || this.cardForm.value.month_late === null) {
      this.toastr.error('O campo do mês de expiração do cartão de crédito está em branco!', '', this.helpers.toastrCfgs);
      return false;
    }
    if (this.cardForm.value.year_late === '' || this.cardForm.value.year_late === null) {
      this.toastr.error('O campo do ano de expiração do cartão de crédito está em branco!', '', this.helpers.toastrCfgs);
      return false;
    }

    const dataString = JSON.stringify(this.cardForm.value);
    const data = JSON.parse(dataString);

    this.loading = true;
    this.userProv.subscribe(data).then(
      (user: any) => {
        this.purchase = user.purchase;
        this.students = user.students;
        this.programs = user.programs;
        this.files = user.files;
        this.loading = false;
        this.showFormCC = false;
        this.userSuperTreinosPlanId = this.purchase.supertreinos_plan_id;
        this.setUserPlanId(this.purchase);
        this.setSubscription();
        console.log(this.userSuperTreinosPlanId);
        if (this.userSuperTreinosPlanId === '3' || this.userSuperTreinosPlanId === '4') {
          (document.getElementById('super-coach-logo') as HTMLFormElement).src = (this.selectedUser.logo);
        } else {
          (document.getElementById('super-coach-logo') as HTMLFormElement).src = 'assets/imgs/logo-topo-stcoach.png';
        }
        this.toastr.success('Seus dados foram atualizados com sucesso!', '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        this.loading = false;
        this.toastr.error(err || 'Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
      }
    );

  }

  getSubscriptions() {
    this.loading = true;
    this.userProv.subscriptions().then(
      (p: any) => {
        this.purchase = p.purchase;
        this.students = p.students;
        this.programs = p.programs;
        this.files = p.files;
        this.loading = false;
        this.userSuperTreinosPlanId = this.purchase.supertreinos_plan_id;
        this.setUserPlanId(this.purchase);
        if (this.userSuperTreinosPlanId === '3' || this.userSuperTreinosPlanId === '4') {
          (document.getElementById('super-coach-logo') as HTMLFormElement).src = (this.selectedUser.logo);

        }
      },
      (err: any) => {
        this.loading = false;
        this.toastr.error(err || 'Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
      }
    );
  }

  loadForm(user: any) {
    // console.log(user);
    this.userForm.patchValue(user);
    this.userForm.value.goal_id = user.goal_id;

    this.userBirthdate = new Date(user.birthdate);
    let offsetMs = this.userBirthdate.getTimezoneOffset() * 3 * 60 * 1000;
    this.userBirthdate = new Date(this.userBirthdate.getTime() + offsetMs);

    if (user.premium_expires_date !== null) {
      this.userPremiumExpire = new Date(user.premium_expires_date);
      offsetMs = this.userPremiumExpire.getTimezoneOffset() * 3 * 60 * 1000;
      this.userPremiumExpire = new Date(this.userPremiumExpire.getTime() + offsetMs);
    }

    this.activity = { 
      "is_educf" : user.is_educf,
      "is_fisio": user.is_fisio,
      "is_nutri": user.is_nutri,
      "is_stdnt": user.is_stdnt
    };    

  }

  getUserFormValidationMessage(el: any) {
    if (el.errors.required) {
      return 'Este campo é obrigatório';
    }

    if (el.errors.incorrect || el.errors.pattern) {
      return 'O valor deste campo é inválido';
    }
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  limparCampos() {
    const plano = this.cardForm.value.supertreinos_plan_id;
    this.cardForm.reset();
    this.cardForm.patchValue({supertreinos_plan_id: plano});
  }

  cardMoc() {
    const data: any = {
      name: 'Aardvark Silva',
      number: '4018720572598048',
      cvv: '123',
      month_late: '11',
      year_late: '22'
    }
    this.cardForm.patchValue(data);
  }

  toggleActiveTeacher(teacher: any) {
    let msg: string;

    if (teacher.status === 0) {
      msg = 'A conta foi desativada com sucesso!';
    } else if (teacher.status === 1){
      msg = 'A conta foi ativada com sucesso!';
    } else if (teacher.status === 9){
      msg = 'A conta foi bloqueada com sucesso!';
    }

    this.userProv.updateUser(teacher).then(
      (_user: any) => {
        this.toastr.success(msg, '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        this.loading = false;
        this.toastr.error(err || 'Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
      }
    );
  }

  select(teacher: any) {
    this.selectedTeacher = teacher.id;
    this.selectedUser = Object.assign({}, teacher);
    if (this.selectedUser.active_sub === null) {
      this.selectedUser.active_sub = { 'pagarme_subscriber_id': '' };
    }
    this.loadForm(this.selectedUser);
    // this.getSubscriptions();
    this.students = teacher.students;
    this.programs = teacher.programs;
    this.files = teacher.files;
    this.updateDropzoneParams(this.avatar);
    this.updateDropzoneParams(this.logo);
  }

  delete(teacher: any) {
    const msg = 'Todos os Programas, Alunos e Histórico de Compras desta conta serão apagados. Deseja apagar este conta?';
    const dialogRef = this.dialog.open(DialogInputCustomComponent, {
      width: '360px',
      data: {title: 'Apagar Conta de Professor', placeholder: 'escreva DELETAR, em caixa alta', message: msg, confirmation: 'Sim'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'DELETAR') {
        this.loading = true;
        this.userProv.deleteUser(teacher).then(
          (_res: any) => {
            this.toastr.success('Conta removida com sucesso do sistema!', '', this.helpers.toastrCfgs);
            if (this.user.account_type === 'master' || this.user.account_type === 'admin') {
              this.getTeacherList();
            } else {
              this.logout();
            }

          },
          (err: any) => {
            this.loading = false;
            this.toastr.error(err || 'Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
          }
        );
      }
    });
  }

  callProModal() {
    const dialogRef = this.dialog.open(DialogLogoProComponent, {
      data: { date_selected: null },
    });

    dialogRef.afterClosed().subscribe(result => { console.log(result); });
  }

  setUserPlanId(purchase: any = null) {
    let user = this.auth.retrieveLocalUser();
    this.userSuperTreinosPlanId = this.purchase.supertreinos_plan_id;
    user.subs = purchase;
    this.auth.storeLocallyUser(user);
  }

  setSubscription() {
    const teacher = this.user;
    const subs = {
      'files': this.files,
      'programs': this.programs,
      'purchase': this.purchase,
      'students': this.students,
      'teacher': teacher
    }
    this.helpers.storeLocally('teacherAccount', subs);
  }

  logout() {
    this.helpers.clearDB();
    localStorage.clear();
    this.user = null;
    this.route.navigate(['login'], { skipLocationChange: true });
  }

  backToList() {
    this.selectedTeacher = 0;
  }

  helpSubs() {
    let msg = '';
    msg += '<h3>Assinatura</h3>';
    msg += '<p>- Sistema de assinatura recorremente mensal (renovação automática).</p>';
    msg += '<p>- Pagamento apenas por cartão de crédito.</p>';
    msg += '<p>- Para substituir o plano, basca clicar no desejado e assinar.</p>';
    msg += '<br/>';
    msg += '<h3>Dúvidas</h3>';
    msg += '<p>1. Como faço pra trocar de plano?</p>';
    msg += '<p>Basta clicar no plano desejado e assinar novamente. A assinatura antiga será automaticamente cancelada e a nova entrará em vigor. Apenas procure fazer o upgrade faltando poucos dias para a renovação automática, pois os valores não são compensados automaticamente pelo sistema.</p>';
    msg += '<p>2. Se cancelar, perderei meu trabalho e meus alunos?</p>';
    msg += '<p>Não. Caso cancele a assinatura ou o cartão de crédito não autorize a renovação, no sistema ocorrerá o bloqueio de acessos aos alunos e programas. No app, seus alunos perderão o acesso ao login.</p>';
    msg += '<p>Todos os cadastros dos alunos, lista de espera, bem como os programas criados permanecerão no sistema até que a assinatura seja reativada.</p>';

    const dialogRef = this.dialog.open(DialogLightComponent, { width: '70vw', data: { message: msg } });
    dialogRef.afterClosed().subscribe(_result => {});
  }


  // dropzone funcs
  onAddedFile(_args: any) {
    //
  }

  onSendingFile(_$event: any) {
    //
  }

  onRemovedFile(_$event: any) {
    //
  }

  onUploadError(_$event: any) {
    //
  }

  onAvatarUploadSuccess($event: any) {
    console.log($event);
    this.loading = false;
    this.selectedUser.id = $event[1].user.id;
    this.selectedUser.avatar = $event[1].user.avatar;
    this.toastr.success('Seu avatar foi enviado com sucesso!', '', this.helpers.toastrCfgs);

    this.resetDropzoneUploads();
    this.avatar.url = this.api.API_URL + '/v2/user/update/';
    this.avatar.params = {
      '_method': 'post',
      'id': this.selectedUser.id,
      'avatar': this.selectedUser.avatar,
    };

    this.auth.storeLocallyUser($event[1].user);
    (document.getElementById('profile-avatar') as HTMLFormElement).src = this.selectedUser.avatar;
  }

  onLogoUploadSuccess($event: any) {
    // console.log($event);
    this.loading = false;
    this.selectedUser = this.auth.retrieveLocalUser();
    this.selectedUser.id = $event[1].user.id;
    this.selectedUser.logo = $event[1].user.logo;
    this.toastr.success('Sua logo foi enviada com sucesso!', '', this.helpers.toastrCfgs);

    this.resetDropzoneUploads();
    this.logo.url = this.api.API_URL + '/v2/user/update/';
    this.logo.params = {
      '_method': 'post',
      'id': this.selectedUser.id,
      'logo': this.selectedUser.logo,
    };

    this.auth.storeLocallyUser(this.selectedUser);
    (document.getElementById('super-coach-logo') as HTMLFormElement).src = ($event[1].user.logo);
  }

  updateDropzoneParams(dz: any): void {
    dz.headers = this.headers;
    dz.params = {
      'id': this.selectedUser.id,
      'avatar': (this.selectedUser.avatar === '' ? 'fake_cover_path' : this.selectedUser.avatar),
      'logo': (this.selectedUser.logo === '' ? 'default' : this.selectedUser.logo),
    };
    dz.url = this.api.API_URL + '/v2/user/update/';
  }

  resetDropzoneUploads(): void {
    this.componentRef.directiveRef.reset(true);
  }

  removeAvatar() {
    console.log('remover avatar');

    const data = {
      'id': this.selectedUser.id,
      'avatar': null,
    }

    this.loading = true;
    this.userProv.updateUser(data).then(
      (_user: any) => {
        // console.log(user);
        this.selectedUser.avatar = _user.avatar;
        this.loading = false;
        this.toastr.success('Sua foto foi removida com sucesso!', '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        this.loading = false;
        this.toastr.error(err || 'Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
      }
    );
  }


}
