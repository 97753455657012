import { Component, OnInit, ViewChild } from '@angular/core';
// import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../../ui/dialog/dialog';
import { DialogInputComponent } from '../../../ui/dialog-input/dialog-input';
import { DialogInputCustomComponent } from '../../../ui/dialog-input-custom/dialog-input-custom';
import { DialogLogoProComponent } from '../../../ui/dialog-logo-pro/dialog-logo-pro';
import { DialogLightComponent } from '../../../ui/dialog-light/dialog-light';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { AuthService } from '../../../providers/auth/auth.service';
import { HelpersProvider } from '../../../providers/helpers/helpers';
import { CustomerProvider } from '../../../providers/customer/customer';
import { UserProvider } from '../../../providers/user/user';
import { FileProvider } from '../../../providers/file/file';
import { ApiProvider } from '../../../providers/api/api';
import { I18nProvider } from '../../../providers/i18n/i18n';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-professores-arquivo',
  templateUrl: './arquivos.component.html',
  // styleUrls: ['./arquivos.component.scss']
})
export class ArquivosComponent implements OnInit {
  PAGE: any;
  STRINGS: any;

  @ViewChild(DropzoneComponent, {static: false}) componentRef?: DropzoneComponent;
  protected headers: any;
  section: string = 'account'
  ismobile: boolean = false;
  environment: string = environment.dev;
  user: any;
  userSuperTreinosPlanId: any;
  purchase: any = {
    supertreinos_plan_id: 0,
  };

  files: any = [];
  selectedFile: any;
  totalMBs: number = 0;
  maxMBs: number = 0;
  barPct: number = 0;
  barClass: string = 'success';

  formSpinner: boolean = false;
  loading: boolean = false;

  students: any;

  dataSource: MatTableDataSource<any>;
  displayedColumns = ['select', 'name_clear', 'total_students', 'menu'];
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  allComplete: boolean = false;
  checkAllIndeterminate: boolean = false;
  checkedFiles = [];
  selectedFiles = [];
  total_selected = 0;
  filesOriList: any = [];
  totalFiles: number = 0;

  public userpdf: DropzoneConfigInterface = {
    paramName: 'userpdf',
    thumbnailMethod: 'contain',
    thumbnailHeight: null,
    thumbnailWidth: null,
    addRemoveLinks: true,
    dictRemoveFile: 'REENVIAR ARQUIVO',
    dictCancelUpload: 'CANCELAR ENVIO',
    dictCancelUploadConfirmation: 'CONFIRMA CANCELAR ENVIO?',
    dictInvalidFileType: 'ARQUIVO INVÁLIDO',
    dictFileTooBig: 'Envie um arquivo menor',
    maxFiles: 1,
    acceptedFiles: 'application/pdf',
  };

  searchStudent: any = {
    name: <string> '',
  };
  maxItens: number = 5;
  totalItens = { count: 0 };


  constructor(
    public auth: AuthService,
    public toastr: ToastrService,
    public student: CustomerProvider,
    public userProv: UserProvider,
    public fileProv: FileProvider,
    public helpers: HelpersProvider,
    public dialog: MatDialog,
    public api: ApiProvider,
    public route: Router,
    private device: DeviceDetectorService,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('ArquivosComponent');

    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.ismobile = this.device.isMobile();
    this.user = this.auth.retrieveLocalUser();
    this.getSubscriptions();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  handlePageEvent(evt: any = null) {
    this.helpers.storeLocally('lastPageSizeFiles', evt.pageSize);
  }


  setFilesLimit() {
    switch (this.userSuperTreinosPlanId) {
      case '0':
        this.userpdf.maxFilesize = 0;
        this.maxMBs = 0;
      case '1':
        this.userpdf.maxFilesize = 2;
        this.maxMBs = 2;
      break;
      case '2':
        this.userpdf.maxFilesize = 10;
        this.maxMBs = 10;
      break;
      case '3':
        this.userpdf.maxFilesize = 20;
        this.maxMBs = 100;
      break;
      case '4':
        this.userpdf.maxFilesize = 20;
        this.maxMBs = 200;
      break;
      default:
        this.userpdf.maxFilesize = 20;
      break;
    }
    this.userpdf.dictDefaultMessage =
      '<div class="icon-upload"><img src="assets/imgs/icon-upload.svg"></div>' +
      this.PAGE.carregue_arquivo +
      '<small>' + this.PAGE.arquivo_maximo + this.userpdf.maxFilesize + 'MBs</small>';

    this.updateDropzoneParams(this.userpdf);
    this.getFilesList();
  }

  getSubscriptions() {
    this.loading = true;
    this.userProv.subscriptions().then(
      (p: any) => {
        this.purchase = p.purchase;
        this.files = p.files;
        this.loading = false;
        this.userSuperTreinosPlanId = this.purchase.supertreinos_plan_id;
        this.setUserPlanId(this.purchase);
        if (this.userSuperTreinosPlanId === '3' || this.userSuperTreinosPlanId === '4'
        || this.userSuperTreinosPlanId === 3 || this.userSuperTreinosPlanId === 4) {
          const teacher_logo = (p.teacher.logo === null || p.teacher.logo.indexOf('assets/imgs/logo-topo-stcoach.png') !== -1) ? 'assets/imgs/logo-topo-stcoach.png' : p.teacher.logo;
          (document.getElementById('nav-teacher-super-coach-logo') as HTMLFormElement).src = (p.teacher.logo);
        }
        this.setFilesLimit();
      },
      (err: any) => {
        this.loading = false;
        console.log(err);
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      }
    );
  }


  getFilesList() {
    this.loading = true;
    this.fileProv.index().then(
      (res: any) => {
        this.loading = false;
        this.files = res.files;
        this.totalMBs = 0;
        this.files.map( (file: any) => {
          this.totalMBs = this.totalMBs + file.size;
        });
        this.barPct = this.returnBarPct();
        this.barClass = this.returnBarClass();

        const lastPageSize = this.helpers.getLocallyStored('lastPageSizeFiles');
        this.paginator._changePageSize(lastPageSize || 25);
        this.dataSource = new MatTableDataSource();

        this.processFileList(res);

        this.getCustomers();
      },
      (err: any) => {
        this.loading = false;
        console.log(err);
      }
    );
  }

  processFileList(res: any = null) {
    this.files = res.files;
    this.filesOriList = res.files;
    // this.helpers.storeLocally('files', res.files);
    this.totalFiles = 0;
    for (let i=0; i<this.files.length;i++) {
      this.totalFiles++;
      this.files[i].total_students = this.files[i].customer_users_files?.length;
      this.files[i].name_clear = this.helpers.removeDiacritics(this.files[i].name);
    }
    // console.log(this.students);

    this.dataSource = new MatTableDataSource(this.files);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getCustomers() {
    this.loading = true;
    this.student.getCustomers().then(
      (res: any) => {
        this.loading = false;
        this.students = this.helpers.sortByProperty(res.customers, 'name');
      },
      (err: any) => {
        this.loading = false;
        console.log(err);
      }
    );
  }

  delete(file: any) {
    const msg = this.PAGE.remover_arquivo;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.remover_arquivo_titulo, message: msg, confirmation: 'Sim'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Sim') {
        this.loading = true;
        this.fileProv.delete(file.id).then(
          (_res: any) => {
            this.toastr.success(this.PAGE.remover_arquivo_sucesso, '', this.helpers.toastrCfgs);
            this.getFilesList();

          },
          (err: any) => {
            this.loading = false;
            console.log(err);
            this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
          }
        );
      }
    });
  }

  rename(file: any) {
    const msg = null;
    const dialogRef = this.dialog.open(DialogInputComponent, {
      width: '360px',
      panelClass: 'custom-dialog-input',
      data: {
          title: this.PAGE.renomear_arquivo,
          message: msg,
          confirmation: 'Sim',
          input: file.name,
          bt_ok: this.STRINGS.bt_ok,
          bt_cancel: this.STRINGS.bt_cancelar,
        }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result === undefined) return false;
      if (result !== '' && result.toLowerCase() !== 'cancelou') {
        file.name = result;
        this.loading = true;
        this.fileProv.patch(file).then(
          (_res: any) => {
            this.toastr.success(this.PAGE.renomear_arquivo_sucesso, '', this.helpers.toastrCfgs);
            this.getFilesList();

          },
          (err: any) => {
            this.loading = false;
            console.log(err);
            this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
          }
        );
      }
    });
  }

  callProModal() {
    const dialogRef = this.dialog.open(DialogLogoProComponent, {
      data: { date_selected: null },
    });

    dialogRef.afterClosed().subscribe(result => { console.log(result); });
  }

  setUserPlanId(purchase: any = null) {
    let user = this.auth.retrieveLocalUser();
    this.userSuperTreinosPlanId = this.purchase.supertreinos_plan_id;
    user.subs.purchase = purchase;
    this.auth.storeLocallyUser(user);
  }

  studentHasDoc(student: any, file_id: number) {
    let checked: boolean = false;
    // verifica se o arquivo tem algum aluno selecionado para ele
    student.files.forEach(function (file: any) {
      (file.file_id === file_id) ? checked = true : null;
    });
    return checked;
  }

  docHasAllStudents(file: any) {
    // console.log(file?.customer_users_files?.length);
    // console.log(this.students?.length);
    if (file?.customer_users_files?.length === this.students?.length) {
      return true;
    }
    return false;
  }

  toggleAllStudent(file: any, v: boolean = false) {
    setTimeout(() => {
      this.loading = true;
      // v, o valor do slider é invertido porque usamos o evento click nele pra executar a funcao,
      // logo pega o estado inicial dele, nao o final como acontece com o evento change
      this.fileProv.admCustomerToggleAllFileStudents(file.id, !v).then(
        (_res: any) => {
          // this.loading = false;
          // this.students = res.customers;
          this.toastr.success(this.PAGE.lista_alunos_atualizada, '', this.helpers.toastrCfgs);
          this.getFilesList();
        },
        (err: any) => {
          console.log(err)
          this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
          this.loading = false;
        }
      );
    }, 500);
  }

  toggleDocStudent(file: any, student: any) {
    this.toggleActiveProgram(student.id, file.id);
  }

  checkSlideValue(slide: any) {
    return slide._checked;
  }

  toggleActiveProgram(cid: number, fid: number) {
    const data = {
      cid: cid,
      fid: fid
    };
    this.student.admCustomerToggleFile(data).then(
      (_res: any) => {
        // this.loading = false;
        // this.students = res.customers;
        this.toastr.success(this.PAGE.lista_alunos_atualizada, '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        console.log(err)
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        this.loading = false;
      }
    );
  }


  returnBarPct() {
    let barPct = (((this.totalMBs / (1024*1024))/this.maxMBs) * 100);
    return barPct;
  }

  returnBarClass() {
    let percent = this.returnBarPct();
    let retClass = 'success';
    if (percent > 35) { retClass = 'start'; }
    if (percent > 65) { retClass = 'alert'; }
    if (percent > 99) { retClass = 'danger'; }
    return retClass;
  }

  goToPlans() {
    this.route.navigate(['professor/perfil'], { skipLocationChange: true });
  }


  helpFile() {
    let msg = '';
    msg += '<img src="assets/imgs/ajuda-arquivos.png">';

    const dialogRef = this.dialog.open(DialogLightComponent, { width: '70vw', data: { message: msg } });
    dialogRef.afterClosed().subscribe(_result => {});
  }

  helpAllFiles() {
    let msg = this.PAGE.ajuda_todos_arquivos

    const dialogRef = this.dialog.open(DialogLightComponent, { width: '360px', data: { message: msg } });
    dialogRef.afterClosed().subscribe(_result => {});
  }

  getFilePath(file: any) {
    const filepath = (environment.API_ENV_URLS).replace('/api', '/storage') + file.path;
    return filepath;
  }

  fileDownload(file: any) {
    console.log(file);
    const server = (environment.API_ENV_URLS).replace('/api', '/storage');
    // window.open(server + file.path);

    var file_path = server + file.path;
    var a = document.createElement('A') as any;
    a.href = file_path;
    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  // dropzone funcs
  onAddedFile(file: any) {
    // console.log(file);
    // console.log(file.size);
    // console.log(this.totalMBs);
    // console.log(this.maxMBs * 1024 * 1024);
    if (file.size > (this.maxMBs * 1024 * 1024)) {
      this.toastr.error(this.PAGE.arquivo_grande, '', this.helpers.toastrCfgs);
      this.userpdf.autoQueue = false;
      this.resetDropzoneUploads();
    } else if ((file.size + this.totalMBs) > (this.maxMBs * 1024 * 1024)) {
      this.toastr.error(this.PAGE.sem_espaco, '', this.helpers.toastrCfgs);
      this.userpdf.autoQueue = false;
      this.resetDropzoneUploads();
    }
  }

  onSendingFile(_$event: any) {
    // console.log(_$event);
  }

  onRemovedFile(_$event: any) {
    //
  }

  onUploadError(_$event: any) {
    // console.log(_$event);
  }

  onFileUploadSuccess($event: any) {
    // console.log($event);

    this.loading = false;
    this.toastr.success(this.PAGE.arquivo_envio_sucesso, '', this.helpers.toastrCfgs);
    this.files = $event[1].files;
    this.totalMBs = 0;
    this.files.map( (file: any) => {
      this.totalMBs = this.totalMBs + file.size;
    });
    this.barPct = this.returnBarPct();
    this.barClass = this.returnBarClass();

    this.resetDropzoneUploads();
    this.userpdf.url = this.api.API_URL + '/v2/files/';
    this.userpdf.params = {
      '_method': 'post',
      'id': this.user.id,
      'file': null,
    };

    this.processFileList($event[1]);
  }

  updateDropzoneParams(dz: any): void {
    dz.headers = this.headers;
    dz.params = {
      'id': this.user.id,
      'file': (this.selectedFile === '' ? 'default' : this.selectedFile),
    };
    dz.url = this.api.API_URL + '/v2/files/';
  }

  resetDropzoneUploads(): void {
    this.userpdf.autoQueue = true;
    this.componentRef.directiveRef.reset(true);
  }


  // datatable functions
  deleteSelected() {
    if (this.selectedFiles.length < 1) {
      this.toastr.error(this.PAGE.nenhum_selecionado, '', this.helpers.toastrCfgs);
      return false;
    }

    console.log(this.selectedFiles);

    const msg = this.PAGE.apagar_arquivos;
    const dialogRef = this.dialog.open(DialogInputCustomComponent, {
      width: '360px',
      data: {
        title: this.PAGE.apagar_arquivos_titulo, 
        placeholder: this.PAGE.apagar_arquivos_placeholder, 
        inner_msg: msg, 
        confirmation: 'sim',
        bt_ok: this.STRINGS.bt_ok,
        bt_cancel: this.STRINGS.bt_cancelar,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === this.PAGE.apagar_arquivos_confirmacao) {
        this.loading = true;
        let idx = 0;
        for (let i = 0; i < (this.selectedFiles.length); i++) {
          this.fileProv.delete(this.selectedFiles[i].id).then(
            (_res: any) => {
              idx++;
              if (idx === this.selectedFiles.length) {
                this.getFilesList();
                this.selectedFiles = [];
                this.checkedFiles = [];
                this.allComplete = false;
                this.checkAllIndeterminate = false;
                this.toastr.success(this.PAGE.apagar_arquivos_sucesso, '', this.helpers.toastrCfgs);
              }
              // this.toastr.success('Arquivo removido com sucesso do sistema!');
            },
            (err: any) => {
              this.loading = false;
              console.log(err);
              this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
            }
          );
        }


        // this.studentProvider.admCustomerMassDelete(this.selectedFiles).then(
        //   (_res: any) => {
        //     this.loading = false;
        //     this.getSubscriptions();
        //     if (this.ismobile) this.backToList();
        //   },
        //   (err: any) => {
        //     console.log(err);
        //     this.toastr.warning(err);
        //     this.loading = false;
        //     if (this.ismobile) this.backToList();
        //   });
      }
    });
  }
  setAll(checked: boolean) {
    if (this.checkedFiles == null) return;
    if (!checked) this.checkAllIndeterminate = false;
    let count: number = 0;
    for (let i = 0; i < this.students.length; i++) {
      this.checkedFiles[i] = checked;
      if (checked) this.selectedFiles.push(this.students[i]);
      count++;
    }
    this.total_selected = (checked) ? count : 0;
  }
  changeCheck(checked: boolean, student: any = null) {
    this.total_selected += (checked) ? + 1 : - 1;
    if (checked) {
      this.selectedFiles.push(student);
    } else {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        if (this.selectedFiles[i].id === student.id) {
          this.selectedFiles.splice(i, 1);
        }
      }
    }
  }
  updateAllComplete() {
    let leastOne: boolean = false
    for (let i = 0; i < this.students.length; i++) {
      if (this.checkedFiles[i]) {
        this.allComplete = ((i + 1) === this.students.length && !leastOne) ? false : true;
        leastOne = true;
      } else {
        this.allComplete = false;
        if (leastOne) break;
      }
    }
    if (!this.allComplete && leastOne) this.checkAllIndeterminate = true;
    if (this.allComplete) this.checkAllIndeterminate = false;
    if (!leastOne) this.checkAllIndeterminate = false;
  }

}
