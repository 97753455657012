import { Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../../providers/auth/auth.service';
import { HelpersProvider } from '../../providers/helpers/helpers';

@Component({
  selector: 'app-nav-partner',
  templateUrl: './nav-partner.component.html',
  // styleUrls: ['./material-sidenav.component.scss']
})
export class NavPartnerComponent implements OnInit {
  @ViewChild('snav', {static: false}) sidenav: MatSidenav;
  public user: any;
  minimize = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    public auth: AuthService,
    public helpers: HelpersProvider,
  ) {
    //
  }

  ngOnInit() {
    this.user = this.auth.retrieveLocalUser();
  }

  toogleMini() {
    // console.log('minimenu');
    this.minimize = !this.minimize;
  }

  closeSnav() {
    this.sidenav.close();
    this.minimize = false;
  }

}
