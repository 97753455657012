import { Component, OnInit, ViewChild } from '@angular/core';

import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ToastrService } from 'ngx-toastr';
import { DeviceDetectorService } from 'ngx-device-detector';

import { HelpersProvider } from '../../../providers/helpers/helpers';
import { ApiProvider } from '../../../providers/api/api';
import { PurchaseService } from '../../../providers/purchase/purchase.service';
import { AuthService } from '../../../providers/auth/auth.service';


@Component({
  selector: 'st-customer-profile',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {
  @ViewChild(DropzoneComponent, {static: false}) componentRef?: DropzoneComponent;
  payment_links: any;
  customer: any;
  headers: any;
  studentAvatar: any;
  tabCustomer: number;
  ismobile: boolean;
  loading: boolean = false;

  public avatar: DropzoneConfigInterface = {
    paramName: 'file',
    thumbnailMethod: 'contain',
    thumbnailHeight: null,
    thumbnailWidth: null,
    addRemoveLinks: true,
    dictRemoveFile: 'REMOVER IMAGEM',
    dictCancelUpload: 'CANCELAR ENVIO',
    dictCancelUploadConfirmation: 'CONFIRMA CANCELAR ENVIO?',
    dictInvalidFileType: 'ARQUIVO INVÁLIDO',
    maxFiles: 1,
    dictDefaultMessage: 'Arraste a foto até aqui (No máximo 500px de largura por 500px de altura)',
  };

  constructor(
    public api: ApiProvider,
    public purchase: PurchaseService,
    private auth: AuthService,
    private toastr: ToastrService,
    private device: DeviceDetectorService,
    private helpers: HelpersProvider,
  ) {
    this.customer = this.auth.retrieveLocalUser();
    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};
    this.tabCustomer = 1;
    this.ismobile = this.device.isMobile();
  }

  ngOnInit() {
    // this.plan = id;
    this.getPaymentList();
    this.updateDropzoneParams(this.avatar);
  }


  getPaymentList() {
    this.purchase.getListPaymentLink().then(
      (res: any) => {
        this.payment_links = res.payment_links;
      },
      (err: any) => {
        console.log(err);
      }
    );
  }



  // dropzone funcs
  onAddedFile(_args: any) {
    //
  }

  onSendingFile(_$event: any) {
    //
  }

  onRemovedFile(_$event: any) {
    //
  }

  onUploadError(_$event: any) {
    //
  }

  onAvatarUploadSuccess($event: any) {
    // console.log($event);
    // console.log($event[1].request);
    this.studentAvatar = $event[1].request + '?' + Date.now();
    (document.getElementById('profile-avatar') as HTMLFormElement).src = this.studentAvatar;
    (document.getElementById('selected-profile-avatar') as HTMLFormElement).src = this.studentAvatar;

    this.toastr.success('A foto foi enviada com sucesso!', '', this.helpers.toastrCfgs);

    this.resetDropzoneUploads();
    this.avatar.url = this.api.API_URL + '/v2/mobile/customer/avatar/';
    this.avatar.params = {
      '_method': 'post',
      'id': this.customer.id,
      'file': this.studentAvatar,
    };

  }

  updateDropzoneParams(dz: any): void {
    dz.headers = this.headers;
    dz.params = {
      'id': this.customer.id,
      'file': (this.studentAvatar === '' ? 'fake_cover_path' : this.studentAvatar),
    };
    dz.url = this.api.API_URL + '/v2/mobile/customer/avatar/';
  }

  resetDropzoneUploads(): void {
    this.componentRef.directiveRef.reset(true);
  }

}
