import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from "rxjs";
import { User } from "./user.model";
import { UserProvider } from "./user";

import { map, catchError, finalize } from "rxjs/operators";


export class UsersDataSource implements DataSource<User> {

  private usersSubject = new BehaviorSubject<User[]>([]);

  private loadingSubject = new BehaviorSubject<boolean>(false);

  private totalSubject = new BehaviorSubject<number>(999999);

  public loading$ = this.loadingSubject.asObservable();

  public total$ = this.totalSubject.asObservable();

  public usersList$ = this.usersSubject.asObservable();

  constructor(
    private userProv: UserProvider,
  ) {
    //
  }

  loadUsers(courseId:number, filter:string, accountType:string, sort:string, sortDirection:string, pageIndex:number, pageSize:number) {
    this.loadingSubject.next(true);

    this.userProv.findUsers(courseId, filter, accountType, sort, sortDirection, pageIndex, pageSize)
      .pipe(
        catchError(() => of([])),
        finalize(() => {
          // console.log("finalize data source");
          this.loadingSubject.next(false);
        })
      )
      .subscribe(users => {
        // console.log("subscribe data source");
        // console.log(users);
        this.totalSubject.next(users['total']);
        this.usersSubject.next(users['users']);
    });

  }

  connect(collectionViewer: CollectionViewer): Observable<User[]> {
    // console.log("Connecting data source");
    return this.usersSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    // console.log("Disconnecting data source");
    this.usersSubject.complete();
    this.loadingSubject.complete();
  }

}