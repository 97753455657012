import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../../ui/dialog/dialog';

import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../providers/auth/auth.service';

import { HelpersProvider } from '../../../providers/helpers/helpers';
import { CustomerProvider } from '../../../providers/customer/customer';

import { codes } from '../../../providers/helpers/countriesJson_ptBR';

@Component({
  selector: 'component-customer-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class CustomerProfileComponent implements OnInit {

  user: any;
  userBirthdate: any;
  userGoalId: number;

  loading = false;

  codes: any = [];
  customerForm = new FormGroup({
    id: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    goal_id: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    password_confirmation: new FormControl('', [Validators.required]),
    details: new FormGroup({
      birthdate: new FormControl(),
      gender: new FormControl(),
      height: new FormControl(),
      weight: new FormControl(),
      ddi: new FormControl(),
      phone: new FormControl(),
    }),
  });


  goals = [
    {'id': 1, 'name': 'Definição Muscular'},
    {'id': 3, 'name': 'Hipertrofia Muscular'},
    {'id': 18, 'name': 'Fortalecimento muscular'},
    {'id': 19, 'name': 'Fortalecimento de Lombar, Abdômen e Core'},
    {'id': 20, 'name': 'Readaptação Muscular e Cardiovascular'},
  ];

  constructor(
    public dialog: MatDialog,
    public auth: AuthService,
    public toastr: ToastrService,
    public customerProv: CustomerProvider,
    private helpers: HelpersProvider,
  ) { }

  ngOnInit() {
    this.codes = this.helpers.sortByProperty(codes, 'nome');
    this.user = this.auth.retrieveLocalUser();
    if (!this.user.account_type) {
      this.updateForm(this.user);
    } else {
      this.user = null;
    }
  }

  updateForm(user: any) {
    this.user = user;
    if (!user.password_confirmation) {
      user.password_confirmation = user.password;
    }
    if (user.details) {
      if (user.details.height) {
        user.details.height = user.details.height.substring(-2, 3);
      }
    } else {
      user.details = { birthdate: null, gender: null, height: null, weight: null }
    }
    this.customerForm.patchValue(user);

    this.userGoalId = this.customerForm.value.goal_id;

    this.userBirthdate = new Date(user.details.birthdate);
    const offsetMs = this.userBirthdate.getTimezoneOffset() * 3 * 60 * 1000;
    // console.log(offsetMs);
    this.userBirthdate = new Date(this.userBirthdate.getTime() + offsetMs);
  }

  save() {
    if (this.customerForm.value.password !== this.customerForm.value.password_confirmation) {
      this.toastr.error('As senhas não conferem!', '', this.helpers.toastrCfgs);
      return false;
    }

    const dataString = JSON.stringify(this.customerForm.value);
    const data = JSON.parse(dataString);
    if (data.details.birthdate) {
      data.details.birthdate = data.details.birthdate.substring(-10, 10);
    }

    this.loading = true;
    const students: any = [];
    students.push(data);

    if (this.auth.isMaster() || this.auth.isAdmin() || this.auth.isTeacher()) {
      this.auth.admCustomerUpdate(students).then(
        (user: any) => {
          console.log(user);
          this.loading = false;
          this.toastr.success('Seus dados foram atualizados com sucesso!', '', this.helpers.toastrCfgs);
        },
        (err: any) => {
          this.loading = false;
          this.toastr.error(err || 'Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
        }
      );

    } else {
      // this.auth.admCustomerUpdate(students).then(
      this.customerProv.updateCustomer(data).then(
        (user: any) => {
          // console.log(user);
          this.auth.storeLocallyUser(user);
          this.loading = false;
          this.toastr.success('Seus dados foram atualizados com sucesso!', '', this.helpers.toastrCfgs);
        },
        (err: any) => {
          this.loading = false;
          console.log(err);
          // this.toastr.error(err || 'Algo não funcionou bem. Tente novamente.');
          this.toastr.error('Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
        }
      );
    }
  }

  delete(student: any): void {
    // if (!this.user.account_type) {
    if (this.auth.hasAdminHash()) {
      this.deleteAdm(student);
    } else {
      this.deleteSelf(student);
    }
  }

  deleteSelf(student: any) {
    const msg = 'Deseja realmente apagar sua conta? Todo o seu progresso será apagado!';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: 'Apagar Conta', message: msg, confirmation: 'deletar'}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'deletar') {
        this.deleteStudentConfirmation(student);
      }
    });
  }

  deleteAdm(student: any): void {
    const msg = 'Deseja realmente apagar este Cliente? Todo o progresso dele será apagado!';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: 'Apagar Aluno', message: msg, confirmation: 'deletar'}
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'deletar') {
        let form = this.customerForm;
        Object.keys(form.controls).forEach(key => { form.get(key).setErrors(null); });
        form.reset({
          id: '',
          name: '',
          email: '',
          goal_id: '',
          password: '',
          password_confirmation: '',
          details: {
            birthdate: '',
            gender: '',
            height: '',
            weight: '',
          },
        });

        this.loading = true;
        this.customerProv.admCustomerDelete(student).then(
          (_res: any) => {
            this.loading = false;
            this.toastr.success('O Aluno foi apagado com sucesso!', '', this.helpers.toastrCfgs);
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(err, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
      }
    });
  }

  deleteStudentConfirmation(student: any) {
    let form = this.customerForm;
    Object.keys(form.controls).forEach(key => { form.get(key).setErrors(null); });
    form.reset({
      id: '',
      name: '',
      email: '',
      goal_id: '',
      password: '',
      password_confirmation: '',
      details: {
        birthdate: '',
        gender: '',
        height: '',
        weight: '',
      },
    });

    this.loading = true;
    this.customerProv.admCustomerDelete(student).then(
      (_res: any) => {
        this.loading = false;
        this.toastr.success('O Aluno foi apagado com sucesso!', '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(err, '', this.helpers.toastrCfgs);
        this.loading = false;
      });
  }

  getRequiredMessage(el: any) {
    if (el.hasError('required')) {
      return 'Este campo é obrigatório';
    }

    if (el.hasError('incorrect')) {
      return 'O valor deste campo é inválido';
    }
  }

}
