import { Component, OnInit } from '@angular/core';

import { DeviceDetectorService } from 'ngx-device-detector';

import { AuthService } from '../../providers/auth/auth.service';
import { HelpersProvider } from '../../providers/helpers/helpers';
import { UserProvider } from '../../providers/user/user';


@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  // styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit {
  ismobile: boolean = false;
  user: any = {};

  constructor(
    public auth: AuthService,
    public userProv: UserProvider,
    public helpers: HelpersProvider,
    private device: DeviceDetectorService,
  ) {
    //
  }

  ngOnInit() {
    this.ismobile = this.device.isMobile();
    this.user = this.auth.retrieveLocalUser();
  }

  openStore() {
    window.open('https://www.stcoach.app/workout-store', '_blank');
  }
}
