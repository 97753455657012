import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { HelpersProvider } from '../../../providers/helpers/helpers';
import { CustomerProvider } from '../../../providers/customer/customer';
import { I18nProvider } from '../../../providers/i18n/i18n';

@Component({
  selector: 'dashboard-alunos-listaespera',
  templateUrl: './alunos-listaespera.html',
})
export class DashboardAlunosListaesperaComponent implements OnChanges, OnInit {

  STRINGS: any;
  PAGE: any;

  loading = true;
  @Input() user: any;
  @Input() mobile: any;
  link: string = 'link não disponível';

  waitline: any = null;

  constructor(
    public toastr: ToastrService,
    public router: Router,
    public student: CustomerProvider,
    private helpers: HelpersProvider,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('DashboardPage');
    // Object.assign(this, { data: this.data });
  }

  ngOnInit() {
    this.getWaitline();
    this.link = window.location.href + '?p=' + this.user.link;
  }

  ngOnChanges() {
    // console.log('mudou');
  }



  onSelect(_data: any): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(_data)));
  }

  onActivate(_data: any): void {
    // console.log('Activate', JSON.parse(JSON.stringify(_data)));
  }

  onDeactivate(_data: any): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  getWaitline() {
    this.loading = true;
    this.student.getCustomersWaiting().then(
      (res: any) => {
        this.waitline = res.customers;
        if (res.customers.length === 0) {
          this.waitline = null;
        }
        this.loading = false;
      },
      (err: any) => {
        this.toastr.error(err, '', this.helpers.toastrCfgs);
        this.loading = false;
      }
    );
  }

  goToWaitline() {
    // this.router.navigate(['professor/fila'], { skipLocationChange: true });
    if (this.user.account_type === 'teacher') this.router.navigate(['professor/alunos/-1'], { skipLocationChange: true });
    if (this.user.account_type === 'nutri') this.router.navigate(['nutri/pacientes/-1'], { skipLocationChange: true });
  }

  goToTutorial() {
    window.open('https://www.stcoach.app/tutoriais-aluno', '_system', 'location=yes');
  }

  copyTransfer(url: string = '') {
    console.log(url);
    if (this.helpers.copyTextToClipboard(url)) {
      this.toastr.success(this.PAGE.link_copiado, '', this.helpers.toastrCfgs);
    } else {
      this.toastr.error(this.PAGE.link_copiado_erro, '', this.helpers.toastrCfgs);
    }
  }    

  share(url: string = ''){
    const navShare: any = window.navigator;
    if (navShare.share !== undefined) {
      // console.log('share ok');
      navShare.share({
        title: 'ST Coach',
        text: this.PAGE.form_cadastro,
        url: url,
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
    } else {
      // console.log('share not ok');
      if (this.helpers.copyTextToClipboard(url)) {
        this.toastr.success(this.PAGE.link_copiado, '', this.helpers.toastrCfgs);
      } else {
        this.toastr.error(this.PAGE.link_copiado_erro, '', this.helpers.toastrCfgs);
      }        
    }
  }    

}
