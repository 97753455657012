import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { I18nProvider } from '../../providers/i18n/i18n';
import { HelpersProvider } from '../../providers/helpers/helpers';

import { environment } from '../../environments/environment';

@Component({
  selector: 'dialog-select-food',
  templateUrl: 'dialog-select-food.html',
})

export class DialogSelectFoodComponent {
  constructor(
    private router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogSelectFoodComponent>,
    public helpers: HelpersProvider,
    public i18n: I18nProvider,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.STRINGS = this.i18n.translate();
  }

  protected API = (environment.API_ENV_URLS).replace('/api', '/');

  PAGE: any;
  STRINGS: any;

  foodId = 0;
  foodGrams = 0;
  dataFood: any = null;

  banners: any = [];
  banner_selected = '';
  user: any = {};

  totalItens = { count: 0 };
  maxItens: number = 20;
  search: any = {
    name: <string> '',
  };



  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  onNoClick(): void {
    this.dialogRef.close('cancelou');
  }

  onOkClick(): void {
    this.dataFood = {id: this.foodId, user_grams: this.foodGrams}
    this.dialogRef.close(this.dataFood);
  }

  select(food: any, _event: any) {
    let elements = document.getElementsByClassName('cover_img');
    for (let i = 0; i < elements.length; i++) { elements[i].setAttribute('style', 'background-color: transparent;'); }​
    _event.target.style.boxShadow = '5px 5px 3px rgba(247, 149, 30, 0.4)';
    this.foodId = food.id;
    this.foodGrams = food.grams;
  }



}

export interface DialogData {
  title: string;
  message: string;
  confirmation: string;
  inner_msg?: string;
  bt_ok?: string;
  bt_ok_class?: string;
  bt_cancel?: string;
  bt_cancel_hide?: boolean;
  show_alert?: boolean;
  url?: string;
}