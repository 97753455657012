import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { MatDialog } from '@angular/material/dialog';

import { ToastrService } from 'ngx-toastr';

import { DialogComponent } from '../../../ui/dialog/dialog';
import { DialogInputCustomComponent } from '../../../ui/dialog-input-custom/dialog-input-custom';
import { DialogSelectBannerComponent } from '../../../ui/dialog-select-banner/dialog-select-banner';
import { DialogSendToTeacherComponent } from '../../../ui/dialog-send-to-teacher/dialog-send-to-teacher';
import { DialogLightComponent } from '../../../ui/dialog-light/dialog-light';

import { ProgramProvider } from '../../../providers/program/program';
import { GoalProvider } from '../../../providers/goal/goal';
import { ProgramCategoryProvider } from '../../../providers/program/category';
import { ApiProvider } from '../../../providers/api/api';
import { HelpersProvider } from '../../../providers/helpers/helpers';
import { UserProvider } from '../../../providers/user/user';

import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-block-programs-master',
  templateUrl: 'programs-master.html',
  styleUrls: ['./programs-master.scss']
})
export class ProgramsMasterComponent implements OnInit, OnChanges {
  @Output() setProgramId = new EventEmitter();
  @Input() ismobile: boolean = false;
  @Input() istablet: boolean = false;

  protected headers: any;
  protected API = (environment.API_ENV_URLS).replace('/api', '/');

  user: any = {};
  noScroll: boolean = false;
  isGrid: boolean = false;

  program: any = {
    id: <number> 0,
    cover_path: '',
    name: <string> null,
    user_id: <number> 1,
    subtitle: <string> null,
    category: <number> null,
    goal: <number> null,
    gender: <string> null,
    location: <string> null,
    focus: <string> 'completo',
    difficulty_level: <string> null,
    video_url: <string> 'https://player.vimeo.com/video/',
    description: <string> null,
    weeks: <string> null,
    days_per_week: <string> null,
    minutes_per_day: <string> null,
    sort: <number> 0,
    pay: <string> null,
    published: <number> 0,
    premium: <number> 0,
    translations: <any> {},
    points: <number> 0,
  };
  pointsNumber: boolean = true;

  totalItens2 = { count: 0 };
  maxItens2: number = 30;
  page2: number = 0;
  totalPages: number = 0;

  searchProgram: any = {
    name: <string> '',
    premium: <any> '',
    published: <any> '',
    workout_muscle_ids: <any> [],
    workout_equip_ids: <any> [],
    workout_method_ids: <any> [],
  };

  cover_url_forceupdate: boolean = false;

  programs: any = [];
  selected_program = 0;
  sanitized_video_url: any = '';

  goals: any = [];
  categories: any = [];
  difficulties: any = [];

  banners: any = [];

  loading: boolean;

  // form validations
  programName = new FormControl('', [Validators.required]);
  // programVideoUrl = new FormControl('', [Validators.required]);
  programVideoUrl = new FormControl();

  cropCheckInterval: any;

  // @ViewChild(DropzoneComponent, {static: false}) componentRef?: DropzoneComponent;
  @ViewChild(DropzoneComponent, {static: false}) dzCover?: DropzoneComponent;
  public config: DropzoneConfigInterface = {
    paramName: 'cover_img',
    thumbnailMethod: 'contain',
    thumbnailHeight: null,
    thumbnailWidth: null,
    addRemoveLinks: true,
    dictRemoveFile: 'REMOVER IMAGEM',
    dictCancelUpload: 'CANCELAR ENVIO',
    dictCancelUploadConfirmation: 'CONFIRMA CANCELAR ENVIO?',
    dictInvalidFileType: 'ARQUIVO INVÁLIDO',
    maxFiles: 1,
    // autoProcessQueue: false,
    transformFile: (file, done) => {
      this.cropCheckInterval = setInterval(() => { if (this.helpers.getLocallyStored('cropOngoing') === false) this.resetDropzoneUploads() }, 250);
      this.helpers.cropImage(file, done, 'dropzone-program-cover', 468, 622);
    }
  };

  @ViewChild(DropzoneComponent, {static: false}) dzSchedule?: DropzoneComponent;
  public video_thumb: DropzoneConfigInterface = {
    paramName: 'video_thumb',
    thumbnailMethod: 'contain',
    thumbnailHeight: null,
    thumbnailWidth: null,
    addRemoveLinks: true,
    dictRemoveFile: 'REMOVER IMAGEM',
    dictCancelUpload: 'CANCELAR ENVIO',
    dictCancelUploadConfirmation: 'CONFIRMA CANCELAR ENVIO?',
    dictInvalidFileType: 'ARQUIVO INVÁLIDO',
    maxFiles: 1,
    // autoProcessQueue: false,
    transformFile: (file, done) => {
      this.cropCheckInterval = setInterval(() => { if (this.helpers.getLocallyStored('cropOngoing') === false) this.resetDropzoneUploads() }, 250);
      this.helpers.cropImage(file, done, 'dropzone-program-schedule', 544, 261);
    }
  };

  @ViewChild(DropzoneComponent, {static: false}) dzFeatured?: DropzoneComponent;
  public featured_banner: DropzoneConfigInterface = {
    paramName: 'featured_banner',
    thumbnailMethod: 'contain',
    thumbnailHeight: null,
    thumbnailWidth: null,
    addRemoveLinks: true,
    dictRemoveFile: 'REMOVER IMAGEM',
    dictCancelUpload: 'CANCELAR ENVIO',
    dictCancelUploadConfirmation: 'CONFIRMA CANCELAR ENVIO?',
    dictInvalidFileType: 'ARQUIVO INVÁLIDO',
    maxFiles: 1,
    // autoProcessQueue: false,
    transformFile: (file, done) => {
      this.cropCheckInterval = setInterval(() => { if (this.helpers.getLocallyStored('cropOngoing') === false) this.resetDropzoneUploads() }, 250);
      // this.helpers.cropImage(file, done, 'dropzone-program-featured', 640, 360);
      this.helpers.cropImage(file, done, 'dropzone-program-featured', 1023, 466);
    }
  };

  account: any = {
    purchase: {
      supertreinos_plan_id: '0',
    },
    students: {
      total: <number> 0,
      max: <number> 0,
      inactives: <number> 0,
    },
    programs: {
      total: <number> 0,
      max: <number> 0,
    }
  }

  translations: number = 0;

  constructor(
    public toastr: ToastrService,
    public programProvider: ProgramProvider,
    public categoryProvider: ProgramCategoryProvider,
    public goalProvider: GoalProvider,
    public userProv: UserProvider,
    public api: ApiProvider,
    public helpers: HelpersProvider,
    public dialog: MatDialog,
    private router: Router,
  ) {
    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};
    this.user = JSON.parse(localStorage.getItem('user'));
    // inicializa os dropzones
    this.updateDropzoneParams(this.config);
    this.updateDropzoneParams(this.video_thumb);
    this.updateDropzoneParams(this.featured_banner);

  }

  ngOnInit() {
    // console.log('OnInit');
    this.loading = false;
    this.user = JSON.parse(localStorage.getItem('user'));
    this.getPrograms();
  }

  ngOnChanges() {
    // console.log('OnChanges');
    if (this.loading === false) {
      this.programs = [];
      this.getPrograms();
    }
  }


  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.programs, event.previousIndex, event.currentIndex);
    this.programsReorder(this.programs);
  }

  mouseScroll(scroll: boolean = true) {
    this.noScroll = !scroll;
    // console.log(scroll);
    // if (scroll === false) window.addEventListener("wheel", e => e.preventDefault(), { passive:false });
    // if (scroll === true) window.addEventListener("wheel", e => e., { passive:false });
  }

  toogleGrid(grid: boolean = false) {
    this.isGrid = grid;
  }

  getRequiredMessage(el: any) {
    if (el.hasError('required')) {
      return 'Este campo é obrigatório';
    }

    if (el.hasError('incorrect')) {
      return 'O valor deste campo é inválido';
    }
  }

  goBack() {
    this.setProgramId.emit(0);
    this.selected_program = 0;
    this.getPrograms();
  }

  openBannerSelect() {
    const dialogRef = this.dialog.open(DialogSelectBannerComponent, {
      data: { account_status: parseInt(this.account.purchase.supertreinos_plan_id) },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== null && result !== '') {
        console.log(`Dialog result (ok): ${result}`);
        this.cover_url_forceupdate = true;
        this.program.cover_path = result;
        this.program.cover_url = result;
      }
      console.log(`Dialog result (no banner): ${result}`);
    });
  }

  del_cache() {
    this.programProvider.deleteCache().then(
      (res: any) => {
        console.log(res);
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
      });
  }

  getPrograms() {
    if (this.user) {
      this.loading = true;

      // this.programProvider.getCache().then(
      this.programProvider.programs().then(
        (res: any) => {
          this.programs = res.programs;
          this.totalItens2.count = res.programs.length;
          this.account.programs.total = res.programs.length;
          this.totalPages = Math.ceil(this.totalItens2.count/this.maxItens2);
          this.checkCloningProcess()
          // console.log(this.programs);
          this.loading = false;
          if (this.user.account_type === 'teacher') {
            this.getSubscriptions();
          } else {
            this.account.programs.max = 999999;
          }
          this.countTranslations();
        },
        (err: any) => {
          console.log(err);
          this.loading = false;
        });
    }
  }

  selectProgram(id: number) {
    if (this.account.programs.total > this.account.programs.max) {
      this.goToSubscribe();
    } else {
      this.getProgram(id);
      this.setProgramId.emit(id);
    }
  }

  getProgram(id: number) {
    this.loading = true;

    this.programProvider.program(id).then(
      (res: any) => {
        this.program = res.program;
        this.selected_program = this.program.id;
        this.updateDropzoneParams(this.video_thumb);
        this.updateDropzoneParams(this.featured_banner);

        if ((this.program.premium === 0) || (this.program.premium === '0')) {
          this.program.premium = false;
        } else {
          this.program.premium = true;
        }

        if (this.program.frequencies.length > 0) {
          let arrFreq = [];
          this.program.frequencies.forEach( function (item: any, _index: any, _arr: any) {
            arrFreq.push(item.frequency.toString());
          });
          this.program.frequency = arrFreq;
        }

        if ((this.program.published === 0) || (this.program.published === '0')) {
          this.program.published = false;
        } else {
          this.program.published = true;
        }

        // if (this.program.cover_path === '1') {
        //   this.program.cover_url = '/img/PROGRAMA-BANNER-PADRAO.jpg';
        // }

        if ((this.program.featured_banner).indexOf('undefined') > -1) {
          this.program.featured_banner = '';
        }

        if (this.program.video_url === null || this.program.video_url === '' || this.program.video_url === 'https://player.vimeo.com/video/') {
          this.program.video_url = 'https://player.vimeo.com/video/';
          this.sanitized_video_url = '';
        }

        if (this.program.video_url !== 'https://player.vimeo.com/video/' && this.program.video_url !== null && this.program.video_url !== '') {
          this.sanitized_video_url = this.helpers.sanitizerVideoURL(this.program.video_url);
        }

        this.config.headers = this.headers;

        this.config.url = this.api.API_URL + '/v2/programs/' + id;
        this.config.params = {
          '_method': 'patch',
          'name': this.program.name,
          'video_url': this.program.video_url,
          'cover_path': this.program.cover_path,
        };
        if (this.program.goal > 0) {
          this.config.params.goal = this.program.goal;
        } else {
          this.config.params.goal = 2;
        }

        this.getGoals();
        this.getCategories();
        this.getDifficulty();
        // this.loading = false;
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
      });
  }

  getGoals() {
    this.loading = true;

    this.goalProvider.index().then(
      (res: any) => {
        this.goals = res.goals;
        // console.log(this.goals);
        if (this.program.id === 0) {
          this.program.goal = this.goals[0].id;
        } else {
          this.program.goal = this.program.goals[0].id;
        }
        this.loading = false;
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
      });
  }


  getCategories() {
    this.loading = true;
    this.categoryProvider.index().then(
      (res: any) => {
        this.categories = res.categories;
        if (this.program.id === 0) {
          this.program.category = this.categories[0].old_id;
        }
        this.loading = false;
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
      });
  }

  getDifficulty() {
    this.difficulties = [
      {"id":"Iniciante",      "name": "Iniciante"},
      {"id":"Intermediário",  "name": "Intermediário"},
      {"id":"Avançado",       "name": "Avançado"},
      {"id":"Expert",         "name": "Expert"},
    ];
    if (this.program.id === 0) {
      this.program.difficulty_level = this.difficulties[0].id;
    }
  }

  new() {
    if (this.user.account_type === 'teacher' && this.account.programs.total >= this.account.programs.max) {
      this.goToSubscribe();
    } else {
      this.loading = true;
      // timeout necessario para inicializar o dropzone
      setTimeout( () => {
        this.selected_program = -1;
        this.sanitized_video_url = '';
        this.program = this.helpers.resetObject(this.program);
        this.program.cover_url = this.API + 'img/PROGRAMA-BANNER-PADRAO.jpg';
        this.program.video_url = 'https://player.vimeo.com/video/';
        this.program.video_url_thumb = this.API + 'img/AGENDA-BANNER-PADRAO.jpg';
        this.program.gender = 'ambos';
        this.program.focus = 'completo';
        this.program.location = 'qualquer';
        this.programName.reset();
        this.programVideoUrl.reset();
        this.programVideoUrl.setValue('https://player.vimeo.com/video/');
        this.getGoals();
        this.getCategories();
        this.getDifficulty();
        this.loading = false;
      }, 1000);
    }
  }

  saveProgram() {
    this.loading = true;
    let valid = true;
    let el: any;

    this.updateTranslation();

    if (this.program.name === '') {
      this.programName.markAsTouched();
      valid = false;
    }
    if (parseInt(this.program.points) > 0) {
      this.program.points = parseInt(this.program.points);
    } else {
      this.toastr.error('Use apenas números no campo Pontos', '', this.helpers.toastrCfgs);
      this.pointsNumber = false;
      valid = false;
    }
    // if (this.program.video_url === '') {
    //   this.programVideoUrl.markAsTouched();
    //   valid = false;
    // }
    // if (this.program.video_url === 'https://player.vimeo.com/video/') {
    //   this.programVideoUrl.setErrors({'incorrect': true});
    //   valid = false;
    // }
    if (this.helpers.validateURL(this.program.video_url)) {
      this.programVideoUrl.setErrors({'incorrect': true});
      valid = false;
    }
    if (valid === false) {
      this.loading = false;
      el = document.getElementById('iframeVideo');
      if (el !== null) el.scrollIntoView();
      return false;
    }

    this.program.pay = this.program.premium;
    if (this.program.clone === null || this.program.clone === '' || this.program.clone === undefined) {
      this.program.clone = 'original';
    }
    this.program.cover_path = (this.program.cover_path === '') ? delete this.program.cover_path : this.program.cover_path;
    if (this.program.id > 0) {
      this.updateProgram();
    }
    if (this.program.id === 0) {
      this.createProgram();
    }
    this.resetDropzoneUploads();
  }

  updateProgram() {
    const programUploaded = this.program;
    if (!this.cover_url_forceupdate) {
      delete programUploaded.cover_path;
    }
    delete programUploaded.cover_url;
    delete programUploaded.video_url_thumb;
    programUploaded.goal = this.program.goal;

    this.programProvider.patchProgram(programUploaded).then(
      (res: any) => {
        this.program = res.program;
        this.toastr.success('Programa atualizado com sucesso!', '', this.helpers.toastrCfgs);
        // this.loading = false;
        this.goBack();
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(err.error.err, '', this.helpers.toastrCfgs);
        // this.loading = false;
        // this.goBack();
      });
  }

  createProgram() {
    const programUploaded = this.program;
    delete programUploaded.video_url_thumb;

    this.programProvider.postProgram(this.program).then(
      (res: any) => {
        this.program = res.program;
        this.toastr.success('O Programa foi cadastrado com sucesso!', '', this.helpers.toastrCfgs);
        this.loading = false;
        this.goBack();
        // this.selectProgram(this.program.id);
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(err.error.err, '', this.helpers.toastrCfgs);
        this.loading = false;
      });
  }

  goToSubscribe() {
    const msg = 'Ops! você alcançou o limite de programas na sua conta. Gostaria de assinar um plano superior? Caso contrário, você precisará apagar os programas excedentes, se houver algum.';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: 'Cadastro de Programas', message: msg, confirmation: 'Sim'}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Sim') {
        this.router.navigate(['professor/perfil'], { skipLocationChange: true });
      }
    });
  }

  cloneProgram(id = null) {
    if (this.account.programs.total >= this.account.programs.max) {
      this.goToSubscribe();
    } else {
      this.cloneProgramConfirmation(id, this.user.id);
    }
  }

  cloneProgramConfirmation(id: number, user_id: number): void {
    const msg = 'Deseja realmente clonar este Programa?';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: 'Clonar Programa', message: msg, confirmation: 'clonar'}
    });

    if (id === null) { id = this.program.id; }

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed: ' + result);
      if (result === 'clonar') {
        this.setProgramId.emit(0);
        this.loading = true;
        this.programProvider.cloneProgram({id, user_id}).then(
          (_res: any) => {
            this.goBack();
            this.toastr.success('O Programa e todos os seus Treinos e Exercícios foram clonados com sucesso!', '', this.helpers.toastrCfgs);
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(err.error.err, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
      }
    });
  }

  sendToTeacher(id: any = null) {
    const msg = 'Deseja realmente enviar este Programa para sua conta de Professor?';
    const dialogRef = this.dialog.open(DialogSendToTeacherComponent, {
      width: '420px',
      data: {title: 'Enviar Programa', message: msg, confirmation: 'enviar'}
    });

    dialogRef.afterClosed().subscribe(teacher_id => {
      if (teacher_id > 0) {
        // console.log(teacher_id);
        // console.log(id);
        this.programProvider.sendProgram({teacher_id: teacher_id, program_id: id}).then(
          (_res: any) => {
            this.goBack();
            this.toastr.success('O Programa foi enviado com sucesso!', '', this.helpers.toastrCfgs);
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(err.error.err, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
    } else {
      console.log('nenhum professor selecionado');
    }
    });
  }

  deleteProgram(id = null) {
    this.deleteProgramConfirmation(id);
  }

  deleteProgramConfirmation(id: number): void {
    const msg = 'Deseja realmente apagar este Programa? Todos os Treinos e Exercícios deste Programa serão apagados também!';
    const dialogRef = this.dialog.open(DialogInputCustomComponent, {
      width: '360px',
      data: {title: 'Apagar Programa', placeholder: 'escreva DELETAR, em caixa alta', message: msg, confirmation: 'Sim'}
    });

    if (id === null) { id = this.program.id; }

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'DELETAR') {
        this.setProgramId.emit(0);
        this.loading = true;
        this.programProvider.deleteProgram(id).then(
          (_res: any) => {
            this.goBack();
            this.toastr.success('O Programa e todos os seus Treinos e Exercícios foram apagados com sucesso!', '', this.helpers.toastrCfgs);
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(err.error.err, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
      }
    });
  }

  programOrderUp(program: any) {
    this.programOrder(program, 'up');
  }

  programOrderDown(program: any) {
    this.programOrder(program, 'down');
  }

  programOrder(program: any, order: any) {
    delete program.cover_path;
    delete program.cover_url;
    program._order = order;

    this.loading = true;
    this.programProvider.patchProgram(program).then(
      (_res: any) => {
        this.toastr.success('Programa reordenado com sucesso!', '', this.helpers.toastrCfgs);
        this.goBack();
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(err.error.err, '', this.helpers.toastrCfgs);
        this.loading = false;
        // this.goBack();
      });
  }

  programsReorder(programs: any) {
    let programs_list = new Array;
    Object.assign(programs_list, programs);
    this.programProvider.reorderPrograms(programs_list.reverse()).then(
      (_res: any) => {
        this.toastr.success('Programas reordenados com sucesso!', '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(err.error.err, '', this.helpers.toastrCfgs);
      });
  }


  helpVideo() {
    let msg = '';
    msg += '<p>Se necessário coloque um vídeo explicativo do programa para o seu cliente.</p>';
    msg += '<p><a href="https://www.stcoach.app/tutoriais-videos/" target="_blank">Saiba mais</a></p>';

    const dialogRef = this.dialog.open(DialogLightComponent, { width: '360px', data: { message: msg } });
    dialogRef.afterClosed().subscribe(_result => {});
  }


  // dropzone events
  changeProgramName() {
    this.resetDropzoneUploads();
    this.updateDropzoneParams(this.config);
  }

  changeProgramVideoUrl() {
    this.resetDropzoneUploads();
    this.updateDropzoneParams(this.config);
    this.sanitized_video_url = '';
    if (this.program.video_url !== '' && this.program.video_url !== null && this.program.video_url !== 'https://player.vimeo.com/video/') {
      this.sanitized_video_url = this.helpers.sanitizerVideoURL(this.program.video_url);
    }
    console.log(this.sanitized_video_url);
  }

  onAddedFile(_args: any) {
    let valid = true;
    if (this.program.name === '') {
      this.loading = false;
      this.resetDropzoneUploads();
      valid = false;
      this.programName.markAsTouched();
    }
    // if (this.program.video_url === '') {
    //   this.loading = false;
    //   this.resetDropzoneUploads();
    //   valid = false;
    //   this.programVideoUrl.markAsTouched();
    // }
    // if (this.program.cover_path === '') this.program.cover_path = 'fake_cover_path';
    if (valid === false) {
      return false;
    }
  }

  onSendingFile(_$event: any) {
    // this.program.cover_path = '';
    // this.program.cover_url = false;
  }

  onRemovedFile(_$event: any) {
    //
  }

  onUploadError(_$event: any) {
    //
  }

  onUploadSuccess($event: any) {
    this.loading = false;
    // this.program = $event[1].program;
    this.program.id = $event[1].program.id;
    this.program.cover_url = $event[1].program.cover_url;
    this.program.cover_path = $event[1].program.cover_url;
    this.program.sort = $event[1].program.sort;
    this.setProgramId.emit(this.program.id);
    this.toastr.success('Banner do Programa enviado com sucesso!', '', this.helpers.toastrCfgs);

    this.resetDropzoneUploads();
    this.config.url = this.api.API_URL + '/v2/programs/' + this.program.id;
    this.config.params = {
      '_method': 'patch',
      'name': this.program.name,
      'video_url': this.program.video_url,
      'cover_path': this.program.cover_path,
    };
    if (this.program.goal > 0) {
      this.config.params.goal = this.program.goal;
    } else {
      this.config.params.goal = 2;
    }
    if (this.program.program_id > 0) {
      this.config.params.program_id = this.program.program_id;
    }
  }

  onThumbUploadSuccess($event: any) {
    this.loading = false;
    // this.program = $event[1].program;
    this.program.id = $event[1].program.id;
    this.program.video_url_thumb = $event[1].program.video_url_thumb;
    this.program.sort = $event[1].program.sort;
    this.setProgramId.emit(this.program.id);
    this.toastr.success('Thumb do Programa enviado com sucesso!', '', this.helpers.toastrCfgs);

    this.resetDropzoneUploads();
    this.video_thumb.url = this.api.API_URL + '/v2/programs/' + this.program.id;
    this.video_thumb.params = {
      '_method': 'patch',
      'name': this.program.name,
      'video_url': this.program.video_url,
      // 'cover_path': this.program.cover_path,
      'video_thumb': this.program.video_thumb,
    };
    if (this.program.goal > 0) {
      this.video_thumb.params.goal = this.program.goal;
    } else {
      this.video_thumb.params.goal = 2;
    }
    if (this.program.program_id > 0) {
      this.video_thumb.params.program_id = this.program.program_id;
    }
  }

  onFeaturedUploadSuccess($event: any) {
    this.loading = false;
    // this.program = $event[1].program;
    this.program.id = $event[1].program.id;
    this.program.featured_banner = $event[1].program.featured_banner;
    this.program.sort = $event[1].program.sort;
    this.setProgramId.emit(this.program.id);
    this.toastr.success('Thumb do Programa enviado com sucesso!', '', this.helpers.toastrCfgs);

    this.resetDropzoneUploads();
    this.featured_banner.url = this.api.API_URL + '/v2/programs/' + this.program.id;
    this.featured_banner.params = {
      '_method': 'patch',
      'name': this.program.name,
      'video_url': this.program.video_url,
      // 'cover_path': this.program.cover_path,
      'featured_banner': this.program.featured_banner,
    };
    if (this.program.goal > 0) {
      this.featured_banner.params.goal = this.program.goal;
    } else {
      this.featured_banner.params.goal = 2;
    }
    if (this.program.program_id > 0) {
      this.featured_banner.params.program_id = this.program.program_id;
    }
  }

  resetDropzoneUploads(): void {
    // this.componentRef.directiveRef.reset(true);
    this.dzCover.directiveRef.reset(true);
    this.dzSchedule.directiveRef.reset(true);
    this.dzFeatured.directiveRef.reset(true);
  }

  updateDropzoneParams(dz: any): void {
    dz.headers = this.headers;

    if (dz.paramName === 'cover_img') {
      dz.params = {
        'name': this.program.name,
        'cover_path': (this.program.cover_path === '' ? 'fake_cover_path' : this.program.cover_path),
      };
    }
    if (dz.paramName === 'video_thumb') {
      dz.params = {
        'name': this.program.name,
        'video_thumb': this.program.video_thumb,
      };
    }
    if (dz.paramName === 'featured_banner') {
      dz.params = {
        'name': this.program.name,
        'featured_banner': this.program.featured_banner,
      };
    }

    if (this.program.id > 0) {
      dz.url = this.api.API_URL + '/v2/programs/' + this.program.id;
      dz.params._method = 'PATCH';
    } else {
      dz.url = this.api.API_URL + '/v2/programs/';
    }

    if (this.program.goal > 0) {
      dz.params.goal = this.program.goal;
    } else {
      dz.params.goal = 2;
    }
  }

  getSubscriptions() {
    this.loading = true;
    this.userProv.subscriptions().then(
      (p: any) => {
        this.account = p;
        this.helpers.storeLocally('teacherAccount', p);
        this.loading = false;
      },
      (err: any) => {
        this.loading = false;
        this.toastr.error(err || 'Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
      }
    );
  }

  checkCloningProcess() {
    if (this.programs) {
      this.programs.map((program: any) => {
        if (program.clone === 'processing') {
          // console.log('programa processando clonagem: ID ' + program.id + '/ Date: ' + new Date);
          setTimeout(() => {
            this.programProvider.program(program.id).then(
              (res: any) => {
                console.log(res);
                (res.program.clone === 'processing') ? this.checkCloningProcessAgain() : this.markProgramAsCloned(res.program.id);
              },
              (err: any) => {
                console.log(err);
              }
            );
          }, 60000);
        }
      });
    }
  }
  checkCloningProcessAgain() {
    this.checkCloningProcess();
  }
  markProgramAsCloned(id: number) {
    for(let i = 0; i < this.programs.length; i = i + 1 ) {
      if (this.programs[i].id === id) {
        // console.log('clonagem finalizada: ID ' + this.programs[i].id + '/ Date: ' + new Date);
        this.programs[i].clone = 'cloned';
      }
    }
    this.checkCloningProcess();
  }

  updateTranslation() {
    const translations = (document.getElementById('translationFieldPrograms') as HTMLInputElement).value;
    // this.programForm.patchValue({ translations: translations});
    this.program.translations = translations;
  }

  processTranslation(value: any) {
    if (value) {
      (document.getElementById('translationFieldPrograms') as HTMLInputElement).value = JSON.stringify(value);
    }
  }

  countTranslations() {
    this.translations = 0;
    this.programs.map((prog: any) => {
      this.translations
      if (prog.translations !== null) {
        this.translations++;
      }
    })
  }

  deleteClone(id = null) {
    this.deleteCloneConfirmation(id);
  }
  deleteCloneConfirmation(id: number): void {
    const msg = 'Ao interromper a clonagem, todos os Treinos e Exercícios deste Programa serão apagados.';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: 'Interromper clonagem', message: msg, confirmation: 'deletar'}
    });

    if (id === null) { id = this.program.id; }

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed: ' + result);
      if (result === 'deletar') {
        this.setProgramId.emit(0);
        this.loading = true;
        this.programProvider.deleteProgram(id).then(
          (_res: any) => {
            this.goBack();
            this.toastr.success('A clonagem foi interrompida com sucesso!', '', this.helpers.toastrCfgs);
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(err.error.err, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
      }
    });
  }

  searchProgramChange() {
    this.totalPages = Math.ceil(this.totalItens2.count/this.maxItens2);
  }

  removePagination(page: number = 0) {
    const pageTarget = this.page2 - page;
    this.changePagination(pageTarget);
  }

  addPagination(page: number = 0) {
    const pageTarget = this.page2 + page;
    this.changePagination(pageTarget);
  }

  changePagination(page: number = 0) {
    this.loading = true;
    // console.log(page);
    // console.log(this.page2);
    // console.log(this.totalPages);

    this.page2 = page;
    if (page <= 0) {
      // console.log('page <= 0');
      this.page2 = 0;
    }
    if (page >= this.totalPages) {
      // console.log('page >= this.totalPages');
      this.page2 = this.totalPages - 1;
    }

    setTimeout(() => {
      this.loading = false;
    }, 250);
  }
}
