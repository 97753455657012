import { Injectable } from '@angular/core';
import { ApiProvider } from '../api/api';

@Injectable()
export class MenuProvider {

  constructor(
    public api: ApiProvider
  ) {
    //
  }

  index() {
    const url = 'v2/menus';
    return this.api.get(url);
  }

  get(id: any) {
    return this.api.get(`v2/menus/${id}`);
  }

  patch(menu: any) {
    menu._method = 'PATCH';
    return this.api.post(`v2/menus/${menu.id}`, menu);
  }

  post(menu: any) {
    return this.api.post(`v2/menus/`, menu);
  }

  clone(menu: any) {
    return this.api.post(`v2/menus/clone`, menu);
  }

  delete(id: any) {
    return this.api.delete(`v2/menus/${id}`);
  }


  async admCustomerToggleAllMenuStudents(mid: number, v: boolean = false) {
    let addremove = 'removeAll';
    if (v) addremove = 'addAll';
    return this.api.post('v2/adm/customer/menus/' + addremove + '/' + mid).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }


  indexDBmenus() {
    return this.api.get(`v2/adm/dbmenus/list`);
  }

}
