import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from '../providers/guards/auth-guard.service';
import { AdmGuardService } from '../providers/guards/adm-guard.service';
import { TeacherGuardService } from '../providers/guards/teacher-guard.service';
import { NutriGuardService } from '../providers/guards/nutri-guard.service';
import { PartnerGuardService } from '../providers/guards/partner-guard.service';

import { LoginComponent } from '../pages/login/login.component';
import { ConfigComponent } from '../pages/config/config.component';
import { ProgramasComponent } from '../pages/programas/programas.component';
import { ExerciciosComponent } from '../pages/exercicios/exercicios.component';
import { TreinosComponent } from '../pages/treinos/treinos.component';
import { PerfilComponent } from '../pages/st-clientes/perfil/perfil.component';
import { ClientesComponent } from '../pages/st-clientes/clientes.component';
import { UsuariosComponent } from '../pages/usuarios/usuarios.component';
import { NotFoundComponent } from '../pages/not-found/not-found.component';
import { BibliotecaComponent } from '../pages/biblioteca/biblioteca.component';
import { NutricaoComponent } from '../pages/nutricao/nutricao.component';
import { AvisosComponent } from '../pages/avisos/avisos.component';
import { CadastroComponent } from '../pages/cadastro/cadastro.component';
import { AlunosComponent } from '../pages/alunos/alunos.component';
import { AlunosWaitlineComponent } from '../pages/alunos/waitline/waitline.component';
import { AlunosPrecadComponent } from '../pages/alunos/precad/precad.component';
import { AlunosAnamneseComponent } from '../pages/alunos/anamnese/anamnese.component';
import { ProfessoresComponent } from '../pages/professores/professores.component';
import { ListaAdmProfessoresComponent } from '../pages/professores/adm-lista/professores.component';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { ArquivosComponent } from '../pages/professores/arquivos/arquivos.component';
import { MenusComponent } from '../pages/professores/menus/menus.component';
import { StoreComponent } from '../pages/store/store.component';

import { environment } from '../environments/environment';

const routes: Routes = [

  // auto select: se em PROD/HOMOLOG vai direto pra dashboard; se em LOCAL, vai pro componente selecionado
  { path: '',
      component: (environment.dev === 'prod' || environment.dev === 'homolog') ? DashboardComponent : ProfessoresComponent,
      // component: (environment.dev === 'prod') ? DashboardComponent : AlunosComponent,
      canActivate: [AuthGuardService]
    },

  // ROTAS ABERTAS
  { path: 'login',                component: LoginComponent },
  { path: 'professor/nova-conta', component: CadastroComponent },
  { path: 'nutri/nova-conta',     component: CadastroComponent },
  { path: 'cliente/nova-conta',   component: AlunosPrecadComponent },
  { path: 'precadastro',          component: AlunosPrecadComponent },
  { path: 'anamnese',             component: AlunosAnamneseComponent },

  // ALUNOS SUPERTREINOS
  { path: 'perfil',               component: PerfilComponent,         canActivate: [AuthGuardService] },

  // PROFESSORES
  { path: 'professor/dashboard',  component: DashboardComponent,      canActivate: [AuthGuardService, TeacherGuardService] },
  { path: 'professor/fila',       component: AlunosWaitlineComponent, canActivate: [AuthGuardService, TeacherGuardService] },
  { path: 'professor/alunos/:id', component: AlunosComponent,         canActivate: [AuthGuardService, TeacherGuardService] },
  { path: 'professor/perfil',     component: ProfessoresComponent,    canActivate: [AuthGuardService, TeacherGuardService] },
  { path: 'professor/programas',  component: ProgramasComponent,      canActivate: [AuthGuardService, TeacherGuardService] },
  { path: 'professor/biblioteca', component: BibliotecaComponent,     canActivate: [AuthGuardService, TeacherGuardService] },
  { path: 'professor/arquivos',   component: ArquivosComponent,       canActivate: [AuthGuardService, TeacherGuardService] },
  { path: 'professor/store',      component: StoreComponent,          canActivate: [AuthGuardService, TeacherGuardService] },
  { path: 'professor/menus',      component: MenusComponent,          canActivate: [AuthGuardService, TeacherGuardService] },

  // NUTRICIONISTAS
  { path: 'nutri/dashboard',      component: DashboardComponent,      canActivate: [AuthGuardService, NutriGuardService] },
  { path: 'nutri/fila',           component: AlunosWaitlineComponent, canActivate: [AuthGuardService, NutriGuardService] },
  { path: 'nutri/pacientes/:id',  component: AlunosComponent,         canActivate: [AuthGuardService, NutriGuardService] },
  { path: 'nutri/perfil',         component: ProfessoresComponent,    canActivate: [AuthGuardService, NutriGuardService] },
  { path: 'nutri/menus',          component: MenusComponent,          canActivate: [AuthGuardService, NutriGuardService] },
  { path: 'nutri/store',          component: StoreComponent,          canActivate: [AuthGuardService, NutriGuardService] },

  // PARCEIROS
  { path: 'parceiro/dashboard',   component: DashboardComponent,      canActivate: [AuthGuardService, PartnerGuardService] },
  // { path: 'parceiro/clientes',    component: DashboardComponent,      canActivate: [AuthGuardService, PartnerGuardService] },

  // MASTER
  { path: 'dashboard',            component: DashboardComponent,      canActivate: [AuthGuardService, AdmGuardService] },
  { path: 'configuracoes',        component: ConfigComponent,         canActivate: [AuthGuardService, AdmGuardService] },
  { path: 'clientes/:id',         component: ClientesComponent,       canActivate: [AuthGuardService, AdmGuardService] },
  { path: 'usuarios/:id',         component: UsuariosComponent,       canActivate: [AuthGuardService, AdmGuardService] },
  { path: 'professores',          component: ListaAdmProfessoresComponent,    canActivate: [AuthGuardService, AdmGuardService] },
  { path: 'programas',            component: ProgramasComponent,      canActivate: [AuthGuardService, AdmGuardService] },
  { path: 'treinos',              component: TreinosComponent,        canActivate: [AuthGuardService, AdmGuardService] },
  { path: 'exercicios',           component: ExerciciosComponent,     canActivate: [AuthGuardService, AdmGuardService] },
  { path: 'biblioteca',           component: BibliotecaComponent,     canActivate: [AuthGuardService, AdmGuardService] },
  { path: 'nutricao',             component: NutricaoComponent,       canActivate: [AuthGuardService, AdmGuardService] },
  { path: 'avisos',               component: AvisosComponent,         canActivate: [AuthGuardService, AdmGuardService] },
  { path: 'store',                component: StoreComponent,          canActivate: [AuthGuardService, AdmGuardService] },

  { path: '**',                   component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
