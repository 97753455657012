import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { ProgramCategoryProvider } from '../../../providers/program/category';
import { HelpersProvider } from '../../../providers/helpers/helpers'


@Component({
  selector: 'app-program-categories',
  templateUrl: './program-categories.component.html',
  styleUrls: ['./program-categories.component.scss']
})
export class ProgramCategoriesComponent implements OnInit {

  loading: boolean = false;

  categoryForm = new FormGroup({
    id: new FormControl(''),
    name: new FormControl(''),
    translations: new FormControl(''),
  });

  categories: any = [];

  constructor(
    public categoryProv: ProgramCategoryProvider,
    public toastr: ToastrService,
    public helpers: HelpersProvider,
  ) {
    //
  }

  ngOnInit() {
    //
    this.loading = true;
    this.categoryProv.index().then(
      (res: any) => {
        this.categories = res.categories;
        this.loading = false;
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
      }
    );
  }

  resetForm() {
    this.categoryForm.reset();
  }


  select(goal: any) {
    this.categoryForm.patchValue(goal);
  }

  save(id: number) {
    this.updateTranslation();
    if(id > 0) {
      this.update(this.categoryForm.value);
    } else {
      this.create();
    }
  }

  update(goal: any) {
    console.log(goal);
    this.loading = true;
    this.categoryProv.patch(goal).then(
      (res: any) => {
        this.categories = res.categories;
        this.toastr.success('Categoria atualizada com sucesso!', '', this.helpers.toastrCfgs);
        this.loading = false;
        this.categoryForm.reset();
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(err.error.err, '', this.helpers.toastrCfgs);
        this.loading = false;
    });
  }

  create() {
    let data = Object.assign({}, this.categoryForm.value);
    delete data.id;
    this.loading = true;
    this.categoryProv.post(data).then(
      (res: any) => {
        this.categories = res.categories;
        this.toastr.success('A Categoria foi cadastrada com sucesso!', '', this.helpers.toastrCfgs);
        this.loading = false;
        this.categoryForm.reset();
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(err.error.err, '', this.helpers.toastrCfgs);
        this.loading = false;
    });
  }

  delete(id: number = 0) {
    if (id === 0) return false;
    this.loading = true;
    this.categoryProv.delete(id).then(
      (res: any) => {
        this.categories = res.categories;
        this.toastr.success('A Categoria foi removida com sucesso!', '', this.helpers.toastrCfgs);
        this.loading = false;
        this.categoryForm.reset();
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(err.error.err, '', this.helpers.toastrCfgs);
        this.loading = false;
    });
  }

  updateTranslation() {
    const translations = (document.getElementById('translationFieldCategories') as HTMLInputElement).value;
    this.categoryForm.patchValue({ translations: translations});
    // console.log(this.goalForm.value);
  }

  processTranslation(value: any) {
    if (value) {
      (document.getElementById('translationFieldCategories') as HTMLInputElement).value = JSON.stringify(value);
    }
  }

}
