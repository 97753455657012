import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-tutorial',
  templateUrl: 'dialog-tutorial.html',
})
export class DialogTutorialComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogTutorialComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    //
  }

  onNoClick(): void {
    this.dialogRef.close('cancelou');
  }

}

export interface DialogData {
  title: string;
  message: string;
  confirmation: string;
}
