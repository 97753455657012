import { Injectable } from '@angular/core';
import { ApiProvider } from '../api/api';

@Injectable()
export class ProgramCategoryProvider {

  constructor(
    public api: ApiProvider
  ) {
    //
  }

  index() {
    const url = 'v2/p/categories';
    return this.api.get(url);
  }

  get(id: any) {
    return this.api.get(`v2/programs/categories/${id}`);
  }

  patch(program: any) {
    program._method = 'PATCH';
    return this.api.post(`v2/p/categories/${program.id}`, program);
  }

  post(program: any) {
    return this.api.post(`v2/p/categories/`, program);
  }

  delete(id: any) {
    return this.api.delete(`v2/p/categories/${id}`);
  }

}
