import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { GroupsMuscleProvider } from '../../../providers/groups-muscle/groups-muscle';
import { HelpersProvider } from '../../../providers/helpers/helpers'


@Component({
  selector: 'component-groups-muscle',
  templateUrl: './groups-muscle.component.html',
  styleUrls: ['./groups-muscle.component.scss']
})
export class GroupsMuscleComponent implements OnInit {

  loading: boolean = false;

  groupsmuscleForm = new FormGroup({
    id: new FormControl(''),
    parent_id: new FormControl('0'),
    name: new FormControl(''),
    translations: new FormControl(''),
  });

  groupsmuscle: any = [];

  constructor(
    public groupsmuscleProv: GroupsMuscleProvider,
    public helpers: HelpersProvider,
    public toastr: ToastrService,
  ) {
    //
  }

  ngOnInit() {
    //
    this.loading = true;
    this.groupsmuscleProv.index().then(
      (res: any) => {
        this.groupsmuscle = res.groupsmuscle;
        console.log(this.groupsmuscle);
        this.loading = false;
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
      }
    );
  }

  resetForm() {
    this.groupsmuscleForm.reset();
    this.groupsmuscleForm.patchValue({parent_id: 0});
  }


  select(muscle: any) {
    this.groupsmuscleForm.patchValue(muscle);
  }

  save(id: number) {
    this.updateTranslation();
    if(id > 0) {
      this.update(this.groupsmuscleForm.value);
    } else {
      this.create();
    }
  }

  update(goal: any) {
    // console.log(goal);
    this.loading = true;
    this.groupsmuscleProv.patch(goal).then(
      (res: any) => {
        this.groupsmuscle = res.groupsmuscle;
        this.toastr.success('músculo/grupo atualizado com sucesso!', '', this.helpers.toastrCfgs);
        this.loading = false;
        this.resetForm();
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(err.error.err, '', this.helpers.toastrCfgs);
        this.loading = false;
    });
  }

  create() {
    let data = Object.assign({}, this.groupsmuscleForm.value);
    // console.log(this.groupsmuscleForm.value);
    delete data.id;
    this.loading = true;
    this.groupsmuscleProv.post(data).then(
      (res: any) => {
        this.groupsmuscle = res.groupsmuscle;
        this.toastr.success('O músculo/grupo foi cadastrado com sucesso!', '', this.helpers.toastrCfgs);
        this.loading = false;
        this.resetForm();
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(err.error.err, '', this.helpers.toastrCfgs);
        this.loading = false;
    });
  }

  delete(id: number = 0) {
    if (id === 0) return false;
    this.loading = true;
    this.groupsmuscleProv.delete(id).then(
      (res: any) => {
        this.groupsmuscle = res.groupsmuscle;
        this.toastr.success('O músculo/grupo foi removido com sucesso!', '', this.helpers.toastrCfgs);
        this.loading = false;
        this.resetForm();
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(err.error.err, '', this.helpers.toastrCfgs);
        this.loading = false;
    });
  }

  updateTranslation() {
    const translations = (document.getElementById('translationFieldGoals') as HTMLInputElement).value;
    this.groupsmuscleForm.patchValue({ translations: translations});
    // console.log(this.groupsmuscleForm.value);
  }

  processTranslation(value: any) {
    if (value) {
      (document.getElementById('translationFieldGoals') as HTMLInputElement).value = JSON.stringify(value);
    }
  }

}
