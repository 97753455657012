import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterlibrary'
})

export class LibraryFilterPipe implements PipeTransform {
  transform(
    value: any,
    name: any = '',
    premium: any = '',
    published: any = '',
    gender: any = '',
    workoutMethodIds: any = [],
    workoutMuscleIds: any = [],
    workoutEquipIds: any = [],
    workoutTypeIds: any = [],
    maxItens: number = 0,
    totalItens: any,
    lang: string,
  ): any {
    // tratando os filtros
    if (name.length < 3) name = '';
    if (premium === '0') premium = 0;
    if (premium === '1') premium = 1;
    if (published === '0') published = 0;
    if (published === '1') published = 1;
    if (gender === 'ambos') gender = '';

    // testando os filtros
    // se o argumento estiver diferente do filtro ou o filtro estiver vazio, descarta
    if (value === undefined || value === null) return null;
    const retorno = value.filter(
      (v: any) => {
        // nome
        // if (name !== '') {
        //   if (this.removeAccents(v.name.toLowerCase()).indexOf(this.removeAccents(name.toLowerCase())) === -1) {
        //     return false;
        //   }
        // }

        if (name !== '') {
          if (lang === 'br') {
            let clean = this.removeAccents(v.name.toLowerCase()).indexOf(this.removeAccents(name.toLowerCase()));
            if (clean === -1) {
              return false;
              // return text.indexOf(search) > -1;
            }            
          }

          if (lang === 'es') {
            if (v.translations !== null) {
              let translated = JSON.parse(v.translations);
              let clean_es = this.removeAccents(translated.strings_es.name.toLowerCase()).indexOf(this.removeAccents(name.toLowerCase()));
              if (clean_es === -1) {
                return false;
              }
            }
            if (v.translations === null) return false;
          }

          if (lang === 'en') {
            if (v.translations !== null) {
              let translated = JSON.parse(v.translations);
              let clean_en = this.removeAccents(translated.strings_en.name.toLowerCase()).indexOf(this.removeAccents(name.toLowerCase()));
              if (clean_en === -1) {
                return false;
              }
            }
            if (v.translations === null) return false;
          }
        }

        // premium
        if (premium !== '') {
          if (v.premium !== premium) {
            return false;
          }
        }

        // publicado
        if (published !== '') {
          if (v.published !== published) {
            return false;
          }
        }

        // genero
        if (gender !== '') {
          if (v.gender !== gender) {
            return false;
          }
        }

        // metodos
        if (workoutMethodIds.length !== 0 && v.workout_method_ids !== null) {
          // return this.FiltrarMatriz(workoutMethodIds, v.workout_method_ids);
          if (this.FiltrarMatriz(workoutMethodIds, v.workout_method_ids) === false) return false;
        }
        if (workoutMethodIds.length !== 0 && v.workout_method_ids === null) {
          return false;
        }

        // musculos
        if (workoutMuscleIds.length !== 0 && v.workout_muscle_ids !== null) {
          if (this.FiltrarMatriz(workoutMuscleIds, v.workout_muscle_ids) === false) return false;
        }
        if (workoutMuscleIds.length !== 0 && v.workout_muscle_ids === null) {
          return false;
        }

        // equipamentos
        if (workoutEquipIds.length !== 0 && v.workout_equip_ids !== null) {
          if (this.FiltrarMatriz(workoutEquipIds, v.workout_equip_ids) === false) return false;
        }
        if (workoutEquipIds.length !== 0 && v.workout_equip_ids === null) {
          return false;
        }

        // tipos
        if (workoutTypeIds.length !== 0 && v.workout_type_ids !== null) {
          if (this.FiltrarMatriz(workoutTypeIds, v.workout_type_ids) === false) return false;
        }
        if (workoutTypeIds.length !== 0 && v.workout_type_ids === null) {
          return false;
        }

        return true;
      }
    );

    totalItens.count = retorno.length;

    return (maxItens === 0) ? retorno : retorno.slice(0, maxItens);
  }

  FiltrarMatriz(filtro: any, propriedade: any) {
    if (filtro.length === 1) {
      for (let a = 0; a < filtro.length; a++) {
        for (let b = 0; b < propriedade.length; b++) {
          if (filtro[a] === propriedade[b]) {
            return true;
          }
        }
      }
      return false;

    } else {
      let contFound = 0;
      for (let a = 0; a < filtro.length; a++) {
        for (let b = 0; b < propriedade.length; b++) {
          if (filtro[a] === propriedade[b]) {
            contFound++;
          }
        }
      }
      if (contFound === filtro.length) {
        return true;
      } else {
        return false;
      }
    }
  }

  removeAccents(source: any) {
    if (source === undefined || source === null) return null;
    var accent = [
      /[\300-\306]/g, /[\340-\346]/g, // A, a
      /[\310-\313]/g, /[\350-\353]/g, // E, e
      /[\314-\317]/g, /[\354-\357]/g, // I, i
      /[\322-\330]/g, /[\362-\370]/g, // O, o
      /[\331-\334]/g, /[\371-\374]/g, // U, u
      /[\321]/g, /[\361]/g, // N, n
      /[\307]/g, /[\347]/g, // C, c
      /[\040-\057]/g,
      /[\072-\077]/g,
      /[\133-\137]/g,
      /[\173-\176]/g,
      /[\100]/g,
      /[\140]/g
    ],
    noaccent = ['A','a','E','e','I','i','O','o','U','u','N','n','C','c', "", "", "", "", "", ""];

    for (var i = 0; i < accent.length; i++){
        source = source.replace(accent[i], noaccent[i]);
    }

    return source;
  };

}
