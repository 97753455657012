import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
// import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../ui/dialog/dialog';
import { DialogLightComponent } from '../../ui/dialog-light/dialog-light';

import { HelpersProvider } from '../../providers/helpers/helpers';
import { FoodProvider } from '../../providers/food/food';
import { AuthService } from '../../providers/auth/auth.service';
import { UserProvider } from '../../providers/user/user';
import { ApiProvider } from '../../providers/api/api';
import { I18nProvider } from '../../providers/i18n/i18n';

@Component({
  selector: 'app-nutricao',
  templateUrl: './nutricao.component.html',
  styleUrls: ['./nutricao.component.scss']
})

export class NutricaoComponent implements OnInit {
  protected headers: any;

  PAGE: any;
  STRINGS: any;

  loading: boolean = false;
  showForm: boolean = false;
  foods: any = [];
  totalFood: number = 0;
  user: any;

  foodSelected: boolean = false;
  foodId: number = 0;
  foodForm = new FormGroup({
    id: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    translations: new FormControl('', [Validators.required]),
    type: new FormControl('g', [Validators.required]),
    grams: new FormControl('', [Validators.required]),
    kcal: new FormControl('', [Validators.required]),
    carbs: new FormControl('', [Validators.required]),
    sugars: new FormControl('', [Validators.required]),
    proteins: new FormControl('', [Validators.required]),
    fat_total: new FormControl('', [Validators.required]),
    fat_saturated: new FormControl('', [Validators.required]),
    fat_mono: new FormControl('', [Validators.required]),
    fat_poli: new FormControl('', [Validators.required]),
    colesterol: new FormControl('', [Validators.required]),
    fibers: new FormControl('', [Validators.required]),
    sodium: new FormControl('', [Validators.required]),
    potassium: new FormControl('', [Validators.required]),
  });

  totalItens = { count: 0 };
  maxItens: number = 12;
  search: any = {
    name: <string> '',
  };


  constructor(
    public auth: AuthService,
    public foodProv: FoodProvider,
    public toastr: ToastrService,
    public dialog: MatDialog,
    public userProv: UserProvider,
    // private router: Router,
    public api: ApiProvider,
    public helpers: HelpersProvider,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('NutricaoComponent');

    this.user = this.auth.retrieveLocalUser();
    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};
  }

  ngOnInit() {
    this.user = this.auth.retrieveLocalUser();
    this.getFoods();
  }

  getFoods() {
    this.loading = true;
    // busca os alimentos
    this.foodProv.index().then(
      (res: any) => {
        this.loading = false;
        this.foods = res.foods;
        this.totalFood = 0;
        this.foods.map((_food: any) => {
          this.totalFood++;
        });

      },
      (err: any) => {
        this.toastr.error(err, '', this.helpers.toastrCfgs);
        this.loading = false;
      }
    );
  }

  back() {
    this.foodForm.reset();
    this.foodForm.value.type = 'g';
    this.foodSelected = false;
    this.foodId = 0;
    this.foodForm.patchValue({ id: 0 });
  }

  new() {
    this.foodForm.reset();
    this.foodForm.value.type = 'g';
    this.foodSelected = false;
    this.foodId = 0;
    this.foodForm.patchValue({ id: 0 });
  }

  select(food: any) {
    this.foodSelected = true;
    this.foodId = food.id;
    this.loadForm(food);
  }

  save() {
    this.validateAllFormFields(this.foodForm);

    this.loading = true;
    this.updateTranslationFood();

    if (this.foodForm.value.id > 0) {
      // edit food
      this.foodProv.patch(this.foodForm.value).then(
        (_res: any) => {
          this.loading = false;
          this.foods = _res.foods;
          this.toastr.success(this.PAGE.alimento_alterado_sucesso, '', this.helpers.toastrCfgs);
        },
        (res: any) => {
          this.loading = false;
          console.log(res);
          this.toastr.error(this.STRINGS.algo, '', this.helpers.toastrCfgs);
        }
      );

    } else {
      // new food
      this.foodProv.post(this.foodForm.value).then(
        (_res: any) => {
          this.loading = false;
          this.foods = _res.foods;
          this.toastr.success(this.PAGE.alimento_cadastrado_sucesso, '', this.helpers.toastrCfgs);
          this.new();
        },
        (res: any) => {
          this.loading = false;
          console.log(res);
          this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        }
      );
    }
  }

  clone(food: any) {
    this.loading = true;
    // edit food
    this.foodProv.clone(food).then(
      (_res: any) => {
        this.loading = false;
        this.foods = _res.foods;
        this.toastr.success(this.PAGE.alimento_clonado_sucesso, '', this.helpers.toastrCfgs);
      },
      (res: any) => {
        this.loading = false;
        console.log(res);
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      }
    );
  }

  delete(food: any) {
    const msg = this.PAGE.alimento_apagar;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.alimento_apagar_title, message: msg, confirmation: 'deletar'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'deletar') {
        this.loading = true;
        this.foodForm.reset();
        this.showForm = false;
        this.foodProv.delete(food.id).then(
          (_res: any) => {
            this.loading = false;
            this.foods = _res.foods;
            this.toastr.success(this.PAGE.alimento_apagar_sucesso, '', this.helpers.toastrCfgs);
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
      }
    });
  }

  selectFood(id: number) {
    console.log(id);
  }

  loadForm(food: any) {
    this.foodForm.patchValue(food);
  }


  getUserFormValidationMessage(el: any) {
    if (el.errors.required) {
      return this.STRINGS.campo_obrigatorio;
    }

    if (el.errors.incorrect || el.errors.pattern) {
      return this.STRINGS.campo_invalido;
    }
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  toggleClass(item: any) {
    item.active = !item.active;
  }

  // modal de ajuda de exemplo
  helpLight() {
    let msg = this.PAGE.ajuda;

    const dialogRef = this.dialog.open(DialogLightComponent, { width: '360px', data: { message: msg } });
    dialogRef.afterClosed().subscribe(_result => {});
  }

  doInfinite(){
    setTimeout(() => {
      this.maxItens = this.maxItens + 12;
    }, 250);
  }

  updateTranslationFood() {
    const translations = (document.getElementById('translationFieldFoods') as HTMLInputElement).value;
    this.foodForm.value.translations = translations;
  }
  processTranslationFood(value: any) {
    if (value) {
      (document.getElementById('translationFieldFoods') as HTMLInputElement).value = JSON.stringify(value);
    }
  }

}
