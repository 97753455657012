import { Injectable } from '@angular/core';
import { ApiProvider } from '../api/api';

@Injectable()
export class CacheProvider {

  constructor(
    public api: ApiProvider
  ) {
    //
  }

  deleteProgramsCache() {
    const url = 'v2/cache/programs';
    return this.api.delete(url);
  }

  deleteLibraryCache() {
    const url = 'v2/cache/library';
    return this.api.delete(url);
  }

  deleteFoodsCache() {
    const url = 'v2/cache/foods';
    return this.api.delete(url);
  }

}
