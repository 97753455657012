import { Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AuthService } from '../../providers/auth/auth.service';
import { HelpersProvider } from '../../providers/helpers/helpers';

@Component({
  selector: 'app-nav-nutri',
  templateUrl: './nav-nutri.component.html',
  styleUrls: ['./nav-nutri.component.scss']
})
export class NavNutriComponent implements OnInit {
  @ViewChild('snav', {static: false}) sidenav: MatSidenav;
  public user: any;
  minimize = false;
  userLogo = 'assets/imgs/mydietpro/logo-topo-mydietpro.png';
  firstName = 'Fulano';
  userPayment = '';
  teacherShowAlertPayment = false;
  ismobile: boolean = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    public auth: AuthService,
    public helpers: HelpersProvider,
    private device: DeviceDetectorService,
  ) {
    //
    this.ismobile = this.device.isMobile();
  }

  ngOnInit() {
    this.user = this.auth.retrieveLocalUser();
    this.firstName = this.auth.getFirstName();
    this.getLogo();
    this.getPaymentStatus();
  }

  toogleMini() {
    // console.log('minimenu');
    this.minimize = !this.minimize;
  }

  closeSnav() {
    this.sidenav.close();
    this.minimize = false;
  }

  getLogo() {
    if (this.userLogo === null || this.userLogo.indexOf('assets/imgs/logo-topo-mydietpro.png') !== -1) this.userLogo = 'assets/imgs/logo-topo-mydietpro.png';
    if (this.user.subs === undefined) return false;
    if (this.user.subs.supertreinos_plan_id && (
        this.user.subs.supertreinos_plan_id === '12' ||
        this.user.subs.supertreinos_plan_id === 12 ))
    {
      this.userLogo = this.user.logo;
      if (this.userLogo === null || this.userLogo.indexOf('assets/imgs/logo-topo-mydietpro.png') !== -1) this.userLogo = 'assets/imgs/logo-topo-mydietpro.png';
    }

    return false;
  }

  getPaymentStatus() {
    if (this.user.subs.supertreinos_plan_id === "0") return false;
    // console.log(this.user);

    if (this.user.subs.pagarme !== null) {
      this.userPayment = this.user.subs.pagarme.status;
      if (this.userPayment === 'pending_payment') {
        this.helpers.storeLocally('teacherShowAlertPayment', true, 0);
      }
    }

    this.teacherShowAlertPayment = this.helpers.getLocallyStored('teacherShowAlertPayment');
  }

  manageSubs() {
    window.open(this.user.subs.pagarme.manage_url, '_blank');
  }

  alertClose() {
    this.helpers.storeLocally('teacherShowAlertPayment', false, 0);
    this.teacherShowAlertPayment = false;
  }


}
