import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { I18nProvider } from '../../providers/i18n/i18n';
import { environment } from '../../environments/environment';

@Component({
  selector: 'dialog-i18n',
  templateUrl: 'dialog-i18n.html',
})

export class DialogI18nComponent {
  constructor(
    public toastr: ToastrService,
    private http: HttpClient,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogI18nComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('DialogI18nComponent');
    //
    this.server = environment.dev;
  }

  server: any;

  PAGE: any;
  STRINGS: any;

  stringsList: any = {
    strings_en: {},
    strings_es: {},
  }
  field_limit: number = 0;
  text: string = '';
  label: string = '';
  name: string = '';
  user: any = [];

  loading: boolean = false;

  ngOnInit() {
    // console.log(this.data);
    this.text = (this.data.text) || '';
    this.label = (this.data.label) || '';
    this.name = (this.data.name) || '';
    this.formatTranslations(this.data.translations);
    this.field_limit = this.data.limit;
    this.user = JSON.parse(window.localStorage.getItem('user'));
  }

  formatTranslations(translations: any) {
    if (this.name !== '') {
      this.stringsList.strings_en[this.name] = '';
      this.stringsList.strings_es[this.name] = '';
      // console.log(translations);
      // console.log(this.stringsList);
      if (translations !== undefined && translations !== null && translations !== '') {
        const jsonTranslations = JSON.parse(translations);
        if (jsonTranslations.strings_en !== undefined) {
          this.stringsList.strings_en = jsonTranslations.strings_en;
          this.stringsList.strings_en[this.name] = (jsonTranslations.strings_en[this.name] !== undefined) ? (jsonTranslations.strings_en[this.name]) : '';
        }
        if (jsonTranslations.strings_es !== undefined) {
          this.stringsList.strings_es = jsonTranslations.strings_es;
          this.stringsList.strings_es[this.name] = (jsonTranslations.strings_es[this.name] !== undefined) ? (jsonTranslations.strings_es[this.name]) : '';
        }
      }
    }
  }

  return() {
    return this.stringsList;
  }

  close() {
    return false;
  }

  autoTranslate(targetLang: string = 'en', field: string = '') {
    if (this.text === undefined || this.text === null || this.text === '') {
      this.toastr.warning(this.PAGE.campo_vazio, '', { timeOut: 2500, positionClass: 'toast-bottom-right' });
      return false;
    }

    this.loading = true;
    const string_var = 'strings_' + targetLang;
    let sourceText = this.text;
    let sourceLang = 'pt';
    const url = "https://translate.googleapis.com/translate_a/single?client=gtx&sl="
              + sourceLang + "&tl=" + targetLang + "&dt=t&q=" + encodeURIComponent(sourceText);

    this.http.get(url).toPromise().then(
      (res: any) => {
        this.stringsList[string_var][field] = '';
        for (var i = 0; i < (res[0].length); i++) {
          this.stringsList[string_var][field] = this.stringsList[string_var][field] + res[0][i][0];
        }
        this.loading = false;
        // console.log(this.stringsList);
      },
      (err: any) => {
        console.log(err);
      }
    );

  }

}
