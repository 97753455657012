import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { AuthService } from '../../providers/auth/auth.service';

@Component({
  selector: 'app-material-sidenav',
  templateUrl: './material-sidenav.component.html',
  // styleUrls: ['./material-sidenav.component.scss']
})
export class MaterialSidenavComponent implements OnInit {

  public user: any;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    public auth: AuthService,
    private router: Router,
  ) {
    //
  }

  ngOnInit() {
    this.user = this.auth.retrieveLocalUser();
  }


  goToPrograms() {
    if (!this.user) {
      this.router.navigate(['login'], { skipLocationChange: true });
      return true;
    }

    if (this.user.account_type) {
      this.router.navigate(['programas'], { skipLocationChange: true });
      return true;
    }

    this.router.navigate(['perfil'], { skipLocationChange: true });
  }
}
