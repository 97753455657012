import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'dialog-input',
  templateUrl: 'dialog-input.html',
})

export class DialogInputComponent {
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogInputComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    //
  }

  input = null;
  user: any = [];

  ngOnInit() {
    this.input = this.data.input;
    // console.log('data.input: ' + this.data.input);
    // console.log('dialog.input: ' + this.input);
  }

  onNoClick(): void {
    this.dialogRef.close('cancelou');
  }


}
