// db.ts
import Dexie, { Table } from 'dexie';


export interface CacheDB {
  id?: number;
  name: string;
  expires: number;
  data: string;
}

export class AppDB extends Dexie {
  cacheDB!: Table<CacheDB, number>;

  constructor() {
    super('ngdexieliveQuery');
    this.version(3).stores({
      cacheDB: '++id, &name',
    });

    this.on('populate', () => this.populate());
  }

  async populate() {
    // await db.cacheDB.bulkAdd([
    //   {
    //     name: 'CacheTeste',
    //     data: '{teste: 1}',
    //   },
    // ]);
  }
}

export const db = new AppDB();
