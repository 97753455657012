import { Component, EventEmitter, OnInit, OnChanges, Output, Input, ViewChild } from '@angular/core';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { MatTab } from '@angular/material/tabs';

import { ToastrService } from 'ngx-toastr';

import { DialogComponent } from '../../ui/dialog/dialog';

import { LibraryProvider } from '../../providers/library/library';
import { WorkoutProvider } from '../../providers/workout/workout';
import { GoalProvider } from '../../providers/goal/goal';
import { ApiProvider } from '../../providers/api/api';
import { HelpersProvider } from '../../providers/helpers/helpers';
import { AuthService } from '../../providers/auth/auth.service';
import { I18nProvider } from '../../providers/i18n/i18n';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-block-library',
  templateUrl: 'library.html',
})
export class LibraryComponent implements OnInit, OnChanges {
  STRINGS: any;
  PAGE: any;

  @ViewChild('dzLibraryCover', {static: false}) dzLibraryCover?: DropzoneComponent;
  @ViewChild(MatTab) libraryTab: MatTab;

  @Output() setLibraryItem = new EventEmitter();
  @Output() setReloadLibrary = new EventEmitter();

  @Input() item: any = null;
  @Input() form: boolean = false;
  @Input() reloadLibrary: number = 0;
  @Input() methods: any = [];
  @Input() muscles: any = [];
  @Input() equips: any = [];
  @Input() types: any = [];
  @Input() clone: boolean = false;
  @Input() ismobile: boolean = false;
  @Input() istablet: boolean = false;
  @Input() listItens: number = 12;
  @Input() training: any = null;

  protected headers: any;
  protected API = (environment.API_ENV_URLS).replace('/api', '/');

  @Input() workout: any = {
    id: <number> 0,
    premium: <number> 0,
    published: <number> 1,
    cover_url: <string> '',
    cover_path: <string> '',
    video_url: <string> 'https://player.vimeo.com/video/',
    name: <string> '',
    gender: <string> '',
    description: <string> '',
    series_repetitions: <string> '',
    intervals: <number> 0,
    sort: <number> 0,
    workout_muscle_ids: <any> [],
    workout_equip_ids: <any> [],
    workout_method_ids: <any> [],
    workout_type_ids: <any> [],
    translations: <string> '',
  };

  user: any = [];

  training_limit: number = 30;
  totalItens = { count: 0 };
  maxItens: number = 12;
  search: any = {
    premium: <any> '',
    published: <any> '',
    name: <string> '',
    gender: <string> '',
    workout_muscle_ids: <any> [],
    workout_equip_ids: <any> [],
    workout_method_ids: <any> [],
    workout_type_ids: <any> [],
  };

  workouts: any = [];
  library: any = {
    workouts: [] as any,
    total: 0 as any,
  };
  sanitized_video_url: any;

  loading: boolean = true;
  dropSearch: boolean = false;

  workoutName = new FormControl('', [Validators.required]);
  workoutVideoUrl = new FormControl('', [Validators.required]);

  total_selected = 0;
  allComplete: boolean = false;
  checkAllIndeterminate: boolean = false;
  selected_workouts = [];

  forceReload: boolean = false;

  librarySource: number = 0;

  cropCheckInterval: any;

  public config: DropzoneConfigInterface;

  constructor(
    public toastr: ToastrService,
    public libraryProvider: LibraryProvider,
    public workoutProvider: WorkoutProvider,
    public auth: AuthService,
    public goalProvider: GoalProvider,
    public api: ApiProvider,
    public helpers: HelpersProvider,
    public dialog: MatDialog,
    private router: Router,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('ExerciciosPage');

    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};
    this.user = this.auth.retrieveLocalUser();

    this.config = {
      paramName: 'cover_img',
      thumbnailMethod: 'contain',
      thumbnailHeight: null,
      thumbnailWidth: null,
      addRemoveLinks: true,
      dictRemoveFile: this.STRINGS.dz_remover_imagem,
      dictCancelUpload: this.STRINGS.dz_cancelar_envio,
      dictCancelUploadConfirmation: this.STRINGS.dz_confirma_cancelar_envio,
      dictInvalidFileType: this.STRINGS.dz_arquivo_invalido,
      maxFiles: 1,
      transformFile: (file, done) => {
        this.cropCheckInterval = setInterval(() => { if (this.helpers.getLocallyStored('cropOngoing') === false) this.resetDropzoneUploads() }, 250);
        this.helpers.cropImage(file, done, 'dropzone-library-cover', 300, 300);
      }
      // autoProcessQueue: false,
    };    
  }

  ngOnInit() {
    // console.log('LibraryComponent - ngOnInit');
    // this.loading = false;
    if (this.form === true) {
      this.loading = false;
    } else {
      // console.log('LibraryComponent - ngOnInit - getWorkouts');
      // this.getWorkouts();

      this.librarySource = this.helpers.getLocallyStored('librarySource');
      if (this.librarySource === null || this.librarySource === 0) {
        this.getWorkouts();
      } else {
        this.getWorkoutsTeacher();
      }

    }

    this.checkLibrarySource();
  }

  ngOnChanges() {
    // console.log('reloadLibrary: ' + this.reloadLibrary);
    this.maxItens = this.listItens;
    // console.log(this.training);
    if (this.form === true) {
      this.prepareForm();
    } else {
      if (this.reloadLibrary > 0) {
        // console.log('LibraryComponent - ngOnChanges - getWorkouts');
        // this.getWorkouts();

        this.librarySource = this.helpers.getLocallyStored('librarySource');
        if (this.librarySource === null || this.librarySource === 0) {
          this.getWorkouts();
        } else {
          this.getWorkoutsTeacher();
        }
      }
    }
    if (this.item !== null) {
      this.prepareForm();
      this.workout = Object.assign({}, this.item);
      this.sanitized_video_url = this.helpers.sanitizerVideoURL(this.workout.video_url);
      if ((this.workout.published === 0) || (this.workout.published === '0')) {
        this.workout.published = false;
      } else {
        this.workout.published = true;
      }
      if ((this.workout.premium === 0) || (this.workout.premium === '0')) {
        this.workout.premium = false;
      } else {
        this.workout.premium = true;
      }
      if (this.workout.video_url === null) {
        this.workout.video_url = 'https://player.vimeo.com/video/';
      }
      this.workoutVideoUrl.setValue(this.workout.video_url);
      this.updateDropzoneParams();
    }

    // console.log(this.training);
    // this.training_limit = (this.training !== null && this.training !== 0) ? (30 - this.training.workouts.length) : 30;
  }


  getRequiredMessage(el: any) {
    if (el.hasError('required')) {
      return this.STRINGS.campo_obrigatorio;
    }

    if (el.hasError('incorrect')) {
      return this.STRINGS.campo_invalido;
    }
  }

  goBack() {
    this.setAll(false);
    this.reloadLibrary++;
    this.setReloadLibrary.emit(this.reloadLibrary++);
  }

  getWorkouts() {
    if (this.form) {
      this.loading = false;
      return;
    }

    this.helpers.getCacheDB('cachedLibrary').then(
      (cache: any) => {
        if (cache === undefined || cache === null) {

          this.loading = true;
          if (this.library === null || this.library.total === 0 || this.forceReload) {
            this.forceReload = false;
            this.libraryProvider.library().then(
              (res: any) => {
                this.helpers.addCacheDB('cachedLibrary', res);
                this.library = res;
                // console.log(this.library);
                this.workouts = res.workouts;
                this.loading = false;
                this.getTrainingLimit();
              },
              (err: any) => {
                console.log(err);
                this.loading = false;
              });
          } else {
            this.workouts = this.library.workouts
            this.loading = false;
          }

        } else {
          const data = JSON.parse(cache.data);
          this.library = data;
          // console.log(this.library);
          this.workouts = data.workouts;
          this.loading = false;
          this.getTrainingLimit();
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  getWorkoutsTeacher() {
    if (this.form) {
      this.loading = false;
      return;
    }

    this.helpers.getCacheDB('cachedLibraryTeacher').then(
      (cache: any) => {
        // console.log(cache);
        if (cache === undefined || cache === null) {

          this.loading = true;
          this.libraryProvider.libraryTeacher().then(
            (res: any) => {
              this.helpers.addCacheDB('cachedLibraryTeacher', res);
              this.library = res;
              this.workouts = res.workouts;
              this.loading = false;
              this.getTrainingLimit();
            },
            (err: any) => {
              console.log(err);
              this.loading = false;
            }
          );

        } else {
          const data = JSON.parse(cache.data);
          this.library = data;
          this.workouts = data.workouts;
          this.loading = false;
          this.getTrainingLimit();
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  getTrainingLimit() {
    if (this.training !== null) {
      this.loading = true;
      this.workoutProvider.workouts(this.training.id).then(
        (res: any) => {
          this.training_limit = (30 - res.workouts.length);
          this.loading = false;
        },
        (err: any) => {
          console.log(err);
          this.loading = false;
        }
      );
    }
  }

  selectWorkout(workout: any) {
    if (this.user.id === 1) {
      this.setLibraryItem.emit(workout);
      if (this.ismobile || this.istablet) this.closeLibrary();
    }
  }

  addToTraining(itens: any) {
    if (this.user.account_type === 'teacher' && this.librarySource === 1) {
      if (this.user.subs) {
        if (this.user.subs.supertreinos_plan_id < 3) {
          this.callToSubs();
          return false;
        }
      }
    }

    const msg = this.PAGE.adicionar_exercicio_biblioteca;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.cadastro_exercicios, message: msg, confirmation: 'Sim'}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Sim') {
        let workouts = [];
        itens.forEach((item: any) => {
          if (item.checked === true) {
            workouts.push(item);
          }
        });
        let data = {
          training: this.training,
          workouts: workouts,
          lang: this.STRINGS.lang,
        }
        this.loading = true;
        this.libraryProvider.copyLibraryToTraining(data).then(
          (_res: any) => {
            this.loading = false;
            this.closeLibrary();
          },
          (err: any) => {
            console.log(err);
            this.loading = false;
          }
        );
      }
    });
  }

  getWorkout(id: any) {
    this.loading = true;

    this.libraryProvider.libraryItem(id).then(
      (res: any) => {
        this.workout = res.workout;

        this.sanitized_video_url = this.helpers.sanitizerVideoURL(this.workout.video_url);

        this.config.headers = this.headers;

        this.config.url = this.api.API_URL + '/v2/library/' + id;
        this.config.params = {
          '_method': 'patch',
          'name': this.workout.name,
          'video_url': this.workout.video_url,
        };
        this.loading = false;
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
      });
  }

  prepareForm() {
    this.setLibraryItem.emit(null);
    this.sanitized_video_url = '';
    this.workout = this.helpers.resetObject(this.workout);
    this.workout.published = 1;
    this.workout.video_url = 'https://player.vimeo.com/video/';
    // this.workout.cover_url = this.API + 'img/default-video.jpg';
    this.workout.cover_url = this.API + 'img/EXERCICIO-BANNER-PADRAO.jpg';
    this.workoutName.reset();
    this.workoutName.setValue('');
    this.workoutVideoUrl.reset();
    this.workoutVideoUrl.setValue('https://player.vimeo.com/video/');
  }

  saveWorkout() {
    this.loading = true;
    let valid = true;
    let el: any;
    this.updateTranslation();

    if (this.workout.name === '') {
      this.workoutName.markAsTouched();
      valid = false;
    }
    if (this.workout.video_url === 'https://player.vimeo.com/video/') {
      this.workout.video_url = '';
    }
    if (this.helpers.validateURL(this.workout.video_url)) {
      this.workoutVideoUrl.setErrors({'incorrect': true});
      valid = false;
    }
    if (valid === false) {
      this.loading = false;
      el = document.getElementById('iframeWorkoutVideo');
      el.scrollIntoView();
      return false;
    }
    if (this.workout.id > 0) {
      this.updateWorkout();
    }
    if (this.workout.id === 0) {
      this.createWorkout();
    }
  }

  updateWorkout() {
    const workoutUploaded = Object.assign({}, this.workout);
    delete workoutUploaded.cover_path;
    delete workoutUploaded.cover_url;

    this.libraryProvider.patchLibraryItem(workoutUploaded).then(
      (res: any) => {
        this.workout = res.workout;
        this.sanitized_video_url = this.helpers.sanitizerVideoURL(this.workout.video_url);
        this.toastr.success(this.PAGE.atualizado_sucesso, '', this.helpers.toastrCfgs);
        this.loading = false;
        this.goBack();
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        this.loading = false;
      });
  }

  createWorkout() {
    this.libraryProvider.postLibraryItem(this.workout).then(
      (res: any) => {
        this.workout = res.workout;
        this.sanitized_video_url = this.helpers.sanitizerVideoURL(this.workout.video_url);
        this.toastr.success(this.PAGE.cadastrado_sucesso, '', this.helpers.toastrCfgs);
        this.loading = false;
        this.reloadLibrary++;
        this.prepareForm();
        this.goBack();
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        this.loading = false;
      });
  }

  deleteWorkout(id = null) {
    this.deleteWorkoutConfirmation(id);
  }

  deleteWorkoutConfirmation(id: any): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.apagar_exercicio_title, message: this.PAGE.apagar_exercicio, confirmation: 'deletar'}
    });

    if (id === null) { id = this.workout.id; }

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed: ' + result);
      if (result === 'deletar') {
        this.loading = true;
        this.libraryProvider.deleteLibraryItem(id).then(
          (_res: any) => {
            this.toastr.success(this.PAGE.apagar_exercicio_sucesso, '', this.helpers.toastrCfgs);
            this.prepareForm();
            this.getWorkouts();
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
      }
    });
  }

  workoutOrderUp(workout: any) {
    this.workoutOrder(workout, 'up');
  }

  workoutOrderDown(workout: any) {
    this.workoutOrder(workout, 'down');
  }

  workoutOrder(workout: any, order: any) {
    delete workout.cover_path;
    delete workout.cover_url;
    workout._order = order;

    this.loading = true;
    this.libraryProvider.patchLibraryItem(workout).then(
      (_res: any) => {
        this.toastr.success(this.PAGE.reordenar_exercicio_sucesso, '', this.helpers.toastrCfgs);
        this.getWorkouts();
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        this.loading = false;
      });
  }


  // dropzone events
  changeWorkoutName() {
    this.resetDropzoneUploads();
    this.updateDropzoneParams();
  }

  onAddedFile(_args: any) {
    // console.log('adicionando arquivo...');
    let valid = true;
    if (this.workout.name === '') {
      this.loading = false;
      this.resetDropzoneUploads();
      valid = false;
      this.workoutName.markAsTouched();
    }
    if (this.workout.video_url === '') {
      this.loading = false;
      this.resetDropzoneUploads();
      valid = false;
      this.workoutVideoUrl.markAsTouched();
    }
    if (valid === false) {
      return false;
    }
  }

  onSendingFile(_$event: any) {
    this.workout.cover_path = '';
    this.workout.cover_url = false;
  }

  onRemovedFile(_$event: any) {
    //
  }

  onUploadError(_$event: any) {
    console.log('upload error');
    console.log(_$event);
  }

  onUploadSuccess($event: any) {
    this.loading = false;
    // this.workout = $event[1].workout;
    this.workout.id = $event[1].workout.id;
    this.workout.cover_url = $event[1].workout.cover_url;
    this.workout.sort = $event[1].workout.sort;
    this.toastr.success(this.PAGE.banner_upload_sucesso, '', this.helpers.toastrCfgs);

    this.resetDropzoneUploads();
    this.config.url = this.api.API_URL + '/v2/library/' + this.workout.id;
    this.config.params = {
      '_method': 'patch',
      'name': this.workout.name,
      'video_url': this.workout.video_url,
    };
  }

  resetDropzoneUploads(): void {
    this.dzLibraryCover.directiveRef.reset(true);
  }

  updateDropzoneParams(): void {
    this.config.headers = this.headers;

    this.config.params = {
      'name': this.workout.name,
      'video_url': this.workout.video_url,
    };

    if (this.workout.id > 0) {
      this.config.url = this.api.API_URL + '/v2/library/' + this.workout.id;
      this.config.params._method = 'PATCH';
    } else {
      this.config.url = this.api.API_URL + '/v2/library/';
    }
  }

  toggleFilter() {
    this.dropSearch = !this.dropSearch;
  }

  clearFilter() {
    this.search = {
      premium: <any> '',
      published: <any> '',
      name: <string> '',
      gender: <string> '',
      workout_muscle_ids: <any> [],
      workout_equip_ids: <any> [],
      workout_method_ids: <any> [],
      workout_type_ids: <any> [],
    };
  }

  closeLibrary() {
    this.setLibraryItem.emit(null);
  }

  doInfinite(){
    setTimeout(() => {
      this.maxItens = this.maxItens + 12;
    }, 250);
  }

  updateTranslation() {
    const translations = (document.getElementById('translationFieldLibrary') as HTMLInputElement).value;
    // this.programForm.patchValue({ translations: translations});
    this.workout.translations = translations;
  }

  processTranslation(value: any) {
    if (value) {
      (document.getElementById('translationFieldLibrary') as HTMLInputElement).value = JSON.stringify(value);
    }
  }

  changeCheck(checked: boolean) {
    this.total_selected += (checked) ? + 1 : - 1;
    this.training_limit += (checked) ? - 1 : + 1;
  }

  updateAllComplete() {
    let leastOne: boolean = false
    for (let i = 0; i < this.workouts.length; i++) {
      if (this.selected_workouts[this.workouts[i].id]) {
        this.allComplete = ((i + 1) === this.workouts.length && !leastOne) ? false : true;
        leastOne = true;
      } else {
        this.allComplete = false;
        if (leastOne) break;
      }
    }
    if (!this.allComplete && leastOne) this.checkAllIndeterminate = true;
    if (this.allComplete) this.checkAllIndeterminate = false;
    if (!leastOne) this.checkAllIndeterminate = false;
  }

  setAll(checked: boolean) {
    if (this.selected_workouts == null) return;
    if (!checked) this.checkAllIndeterminate = false;
    let count: number = 0;
    for (let i = 0; i < this.workouts.length; i++) {
      this.selected_workouts[this.workouts[i].id] = checked;
      count++;
    }
    this.total_selected = (checked) ? count : 0;
  }

  setAllAtt(att: string = '', value: any = null) {
    this.library.workouts.forEach((item: any) => {
      if (item.checked === true) {
        item[att] = value;
      }
    });
    this.toastr.success(this.PAGE.campos_atualizados, '', this.helpers.toastrCfgs);
  }

  changeWorkoutVideoUrl(workout: any = null) {
  console.log(workout);
    workout.sanitized_video_url = '';
    if (workout.video_url !== '' && workout.video_url !== null && workout.video_url !== 'https://player.vimeo.com/video/') {
      workout.sanitized_video_url = this.helpers.sanitizerVideoURL(workout.video_url);
    }

    return workout.sanitized_video_url;
  }

  checkLibrarySource() {
    this.librarySource = this.helpers.getLocallyStored('librarySource');
    if (this.librarySource === null) {
      this.librarySource = 0;
      this.helpers.storeLocally('librarySource', this.librarySource);
    }
  }

  librarySourceModelChange(evt: any) {
    this.librarySource = evt.index;

    setTimeout(() => {
      if (this.user.account_type === 'teacher' && evt.index === 1) {
        if (this.user.subs) {
          if (this.user.subs.supertreinos_plan_id < 3) {
            this.callToSubs();
            return false;
          }
        }
      }

      this.loading = true;
      this.helpers.storeLocally('librarySource', this.librarySource);
      if (this.librarySource === 0) {
        // console.log('library sc');
        this.getWorkouts();
      } else {
        // console.log('library pro');
        this.getWorkoutsTeacher();
      }
    }, 400);
  }

  callToSubs() {
    const msg = this.STRINGS.recurso_exclusivo_black;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.minha_biblioteca, inner_msg: msg, confirmation: 'Sim', bt_ok: 'Upgrade', bt_cancel: this.STRINGS.bt_cancelar}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Sim') {
        this.router.navigate(['professor/perfil'], { skipLocationChange: true, queryParams: { tab: 1 } } );
      } else {
        this.librarySource = 0;
      }
    });
  }


}
