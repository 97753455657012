import { Injectable } from '@angular/core';
import { ApiProvider } from '../api/api';

@Injectable()
export class GoalProvider {

  constructor(
    public api: ApiProvider
  ) {
    //
  }

  index() {
    const url = 'v2/goals';
    return this.api.get(url);
  }

  indexOpen() {
    const url = 'v2/goals/index';
    return this.api.get(url);
  }

  get(id: any) {
    return this.api.get(`v2/goals/${id}`);
  }

  patch(goal: any) {
    goal._method = 'PATCH';
    return this.api.post(`v2/goals/${goal.id}`, goal);
  }

  post(goal: any) {
    return this.api.post(`v2/goals/`, goal);
  }

  delete(id: any) {
    return this.api.delete(`v2/goals/${id}`);
  }

}
