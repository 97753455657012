import { Injectable } from '@angular/core';
import { ApiProvider } from '../api/api';

@Injectable()
export class FoodProvider {

  constructor(
    public api: ApiProvider
  ) {
    //
  }

  index() {
    const url = 'v2/foods';
    return this.api.get(url);
  }

  get(id: any) {
    return this.api.get(`v2/foods/${id}`);
  }

  patch(food: any) {
    food._method = 'PATCH';
    return this.api.post(`v2/foods/${food.id}`, food);
  }

  post(food: any) {
    return this.api.post(`v2/foods/`, food);
  }

  clone(food: any) {
    return this.api.post(`v2/foods/clone`, food);
  }

  delete(id: any) {
    return this.api.delete(`v2/foods/${id}`);
  }

}
