import { Injectable } from '@angular/core';
import { ApiProvider } from '../api/api';


@Injectable()
export class CustomerProvider {

  user: any;

  constructor(
    public api: ApiProvider,
  ) {
    //
  }


  async getCustomerLogin(email: any, password: any) {
    let message: any;
    return this.api.post('mobile/login', { email, password }).then(
      (res: any) => {
        return res;
      }
    ).catch(
      (err: any) => {
        if (err.status === 422 || err.status === 404) {
          message = err.error.err;
        } else {
          message = 'Algo não funcionou bem. Tente novamente.';
        }
        throw new Error(message);
      }
    );
  }

  async createCustomer(data: any) {
    return this.api.post('v2/customer/create', data).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async updateCustomer(data: any) {
    return this.api.put('v2/mobile/customer/update', data).then(
      (res: any) => {
        return res.customer;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async sendAnamnesis(data: any) {
    return this.api.post(`v2/mobile/customer/anamnesis`, data ).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async getSubscription() {
    return this.api.get(`mobile/payment/status`).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async subscribe(data: any) {
    return this.api.post('mobile/pagarme/subscription/create', data).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async cancelSubs(data: any) {
    return this.api.post('mobile/pagarme/subscription/cancel', data).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }


  async getCustomers() {
    let message: any;
    return this.api.get('v2/adm/customer/index').then(
      (res: any) => {
        return res;
      }
    ).catch(
      (err: any) => {
        if (err.status === 422 || err.status === 404) {
          message = err.error.err;
        } else {
          message = 'Algo não funcionou bem. Tente novamente.';
        }
        throw new Error(message);
      }
    );
  }


  async getCustomersPremium() {
    let message: any;
    return this.api.get('v2/adm/customer/index?p=1').then(
      (res: any) => {
        return res;
      }
    ).catch(
      (err: any) => {
        if (err.status === 422 || err.status === 404) {
          message = err.error.err;
        } else {
          message = 'Algo não funcionou bem. Tente novamente.';
        }
        throw new Error(message);
      }
    );
  }

  async getCustomersWaiting() {
    let message: any;
    return this.api.get('v2/adm/customer/index?w=1').then(
      (res: any) => {
        return res;
      }
    ).catch(
      (err: any) => {
        if (err.status === 422 || err.status === 404) {
          message = err.error.err;
        } else {
          message = 'Algo não funcionou bem. Tente novamente.';
        }
        throw new Error(message);
      }
    );
  }

  async getCustomersDeleted() {
    let message: any;
    return this.api.get('v2/adm/customer/index?d=1').then(
      (res: any) => {
        return res;
      }
    ).catch(
      (err: any) => {
        if (err.status === 422 || err.status === 404) {
          message = err.error.err;
        } else {
          message = 'Algo não funcionou bem. Tente novamente.';
        }
        throw new Error(message);
      }
    );
  }

  async admCustomerUpdate(data: any) {
    return this.api.put('v2/adm/customer/account', data).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async admCustomerCreate(data: any) {
    return this.api.post('v2/adm/customer/account', data).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async admCustomerDelete(data: any) {
    return this.api.delete(`v2/adm/customer/account/${data.id}`).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async admCustomerMassDelete(data: any) {
    return this.api.post(`v2/adm/customer/account/massdelete`, data).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async admCustomerMassRestore(data: any) {
    return this.api.post(`v2/adm/customer/account/massrestore`, data).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async admCustomerMassDestroy(data: any) {
    return this.api.post(`v2/adm/customer/account/massdestroy`, data).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async admCustomerToggleProgram(data: any) {
    return this.api.post('v2/adm/customer/account/program', data).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async admCustomerToggleMenu(data: any) {
    return this.api.post('v2/adm/customer/account/menu', data).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async admCustomerToggleFile(data: any) {
    return this.api.post('v2/adm/customer/account/file', data).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async getUserFiles(cid: number = 0) {
    return this.api.get('v2/adm/customer/account/files/' + cid).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async getHistory(studentId: any) {
    return this.api.get(`v2/adm/customer/schedule/list/${studentId}`).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async getLastDone() {
    return this.api.get(`v2/adm/customer/schedule/done/last/`).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async admSubsAndCancels() {
    return this.api.get(`dash/adm/subs-cancels/`).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async admClearData(data: any) {
    return this.api.post(`v2/adm/customer/clear-data/`, data ).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async getAnamnesis(id: number = 0) {
    if (id === 0) throw new Error('Id não definido');
    return this.api.get(`v2/adm/customer/anamnesis/${id}`).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async admDeleteAnamnesis(id: any) {
    return this.api.delete(`v2/adm/customer/anamnesis/${id}`).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async admCustomerDeleteAllExpired() {
    return this.api.delete(`v2/adm/customer/expired/`).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async admCustomerUnlockAllUnexpired() {
    return this.api.post(`v2/adm/customer/unlock/`).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async getAllFeedbacks() {
    return this.api.get(`v2/adm/customer/feedback/`).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async getFeedbacks(cid: number = 0) {
    return this.api.get(`v2/adm/customer/feedback/list/${cid}`).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

}
