import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DeviceDetectorService } from 'ngx-device-detector';

import { ProgramProvider } from '../../../providers/program/program';
import { CustomerProvider } from '../../../providers/customer/customer'
import { I18nProvider } from '../../../providers/i18n/i18n';
import { HelpersProvider } from '../../../providers/helpers/helpers';

import { DialogComponent } from '../../../ui/dialog/dialog';
import { DialogFeedbacksComponent } from '../../../ui/dialog-feedbacks/dialog-feedbacks';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'dashboard-feedbacks',
  templateUrl: './feedbacks.html',
})
export class DashboardFeedbacksComponent implements OnInit {

  STRINGS: any;
  PAGE: any;
  ENV: any;

    @Input() user: any;
    loading = true;

    feedbacks: any = [];

    ismobile: boolean = false;

    constructor(
      public toastr: ToastrService,
      public programProv: ProgramProvider,
      public customerProv: CustomerProvider,
      private router: Router,
      private device: DeviceDetectorService,
      public i18n: I18nProvider,
      public helpers: HelpersProvider,
      public dialog: MatDialog,
    ) {
      //
      // Object.assign(this, { data: this.data });
      this.STRINGS = this.i18n.translate();
      this.PAGE = this.i18n.translate('DashboardFeedbacksComponent');
    }

    ngOnInit() {
      this.ENV = environment;
      this.ismobile = this.device.isMobile();

      this.customerProv.getAllFeedbacks().then(
        (res: any) => {
          this.feedbacks = res.feedbacks;
        },
        (err: any) => {
          console.log(err);
        }
      );
    }

    goToStudent(student: any) {
      // console.log(student);
      if (this.user.account_type === 'teacher') {
        this.router.navigate(['professor/alunos/' + student.id], { skipLocationChange: true });
      } else {
        this.router.navigate(['clientes/' + student.email], { skipLocationChange: true });
      }
    }

    goToStudents() {
      if (this.user.account_type === 'teacher') {
        this.router.navigate(['professor/alunos/0'], { skipLocationChange: true });
      } else {
        this.router.navigate(['clientes/0'], { skipLocationChange: true });
      }
    }

    goToStudentHistory(student: any) {
      // console.log(student);
      this.router.navigate(['professor/alunos/' + student.id], { skipLocationChange: true });
    }

    goToFeedback() {
      window.open('https://www.stcoach.app/tutoriais-aluno', '_system', 'location=yes');
    }

    openFeedback(f: any = null) {
      const title = this.PAGE.feedback_title;
      const inner_msg = '' +
        '<p><i>'+this.PAGE.aluno+':</i> '+f.customer_name+'</p>' +
        '<p><i>'+this.PAGE.titulo_programa+':</i> '+f.program_name+'</p>' +
        '<p><i>'+this.PAGE.titulo_treino+':</i> '+f.training_name+'</p>' +
        '<pre><i>'+this.PAGE.feedback+':</i> '+f.feedback+'</pre>' +
        '';
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '90%',
        panelClass: 'mat-dialog-feedback-details',
        data: {title: title, inner_msg: inner_msg, confirmation: 'deletar', bt_cancel_hide: true}
      });
    }

    openFeedbackList(feedbacks: any = null) {
      const title = this.PAGE.feedback_title;

      const dialogRef = this.dialog.open(DialogFeedbacksComponent, {
        width: '90%',
        panelClass: 'mat-dialog-feedback-list',
        data: {title: title, feedbacks: this.feedbacks, confirmation: 'deletar', bt_cancel_hide: true}
      });
    }    

}
