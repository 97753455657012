import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

import { HelpersProvider } from '../../providers/helpers/helpers';
import { ApiProvider } from '../../providers/api/api';
import { CustomerProfileComponent } from '../../components/customer/profile/profile.component';
import { AdminAccountComponent } from '../../components/admin-account/admin-account';

import { LibraryProvider } from '../../providers/library/library';


@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss']
})
export class ClientesComponent implements OnInit {
  @ViewChild(CustomerProfileComponent, {static: false}) profile: any;
  @ViewChild(AdminAccountComponent, {static: false}) admAccount: any;

  loading = false;
  selected_user: any;

  customerSearchForm = new FormGroup({
    email: new FormControl(''),
    pagarme_subscriber_id: new FormControl(''),
  });

  customerForm: any;
  customers: any;
  route_id: string = '0';

  constructor(
    public api: ApiProvider,
    public toastr: ToastrService,
    public libraryProvider: LibraryProvider,
    private route: ActivatedRoute,
    private helpers: HelpersProvider,
  ) {
    //
  }

  ngOnInit() {
    this.selected_user = { user: 'empty' };
    this.route_id = this.route.snapshot.params.id;

    if (this.route_id !== '0') {
      this.customerSearchForm.patchValue({email : this.route_id});
      this.customerSearch();
    }
  }

  async customerSearch() {
    this.loading = true;
    const data = this.customerSearchForm.value;
    return this.api.post('v2/adm/customer/show', data).then(
      (res: any) => {
        this.loading = false;
        if (res.customers.length === 1) {
          this.selected_user = res.customers[0];
          this.profile.updateForm(this.selected_user);
          this.admAccount.updateForm(this.selected_user);
        } else {
          this.customers = res.customers;
        }
      },
      (err: any) => {
        this.toastr.error('Usuário não encontrado!', '', this.helpers.toastrCfgs);
        this.loading = false;
        throw new Error(err.error.err);
      }
    );
  }

  selectCustomer(customer: any) {
    this.selected_user = customer;
    this.profile.updateForm(this.selected_user);
    this.admAccount.updateForm(this.selected_user);
    this.customers = null;
  }

  async getAllClients() {
    this.loading = true;
    return this.api.get('v2/adm/customer/index').then(
      (res: any) => {
        this.customers = res.customers;
        this.loading = false;
      },
      (err: any) => {
        console.log(err);
        this.toastr.error('Ocorreu um erro inesperado. Contate o desenvolvedor.', '', this.helpers.toastrCfgs);
        this.loading = false;
        throw new Error(err.error.err);
      }
    );
  }


}
