import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-link',
  templateUrl: 'dialog-link.html',
})
export class DialogLinkComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogLinkComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    //
  }

  onNoClick(): void {
    this.dialogRef.close('cancelou');
  }

  onOkClick(): void {
    this.dialogRef.close('ok');
  }

}

export interface DialogData {
  title: string;
  message: string;
  confirmation: string;
  inner_msg?: string;
  bt_ok?: string;
  bt_cancel?: string;
  show_alert?: boolean;
  url?: string;
}
