import { Injectable } from '@angular/core';
import { ApiProvider } from '../api/api';

@Injectable()
export class ValuationProvider {

  constructor(
    public api: ApiProvider
  ) {
    //
  }

  index(customer_id: any) {
    const url = `v2/adm/customer/valuation/list/${customer_id}`;
    return this.api.get(url);
  }

  get(id: any) {
    return this.api.get(`v2/adm/customer/valuation/${id}`);
  }

  put(valuation: any) {
    return this.api.put(`v2/adm/customer/valuation/${valuation.id}`, valuation);
  }

  post(valuation: any) {
    return this.api.post(`v2/adm/customer/valuation/`, valuation);
  }


  delete(id: any) {
    return this.api.delete(`v2/adm/customer/valuation/${id}`);
  }


}
