import { Component, OnChanges, OnInit, Input } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { HelpersProvider } from '../../../providers/helpers/helpers';
import { CustomerProvider } from '../../../providers/customer/customer';


@Component({
  selector: 'dashboard-total-subs-and-cancels-sc-pp',
  templateUrl: './total-subs-and-cancels-sc-pp.html',
})
export class DashboardTotalSubsAndCancelsSCPPComponent implements OnChanges, OnInit {

  @Input() user: any;
  loading = true;

  data: any[];
  total: number = 0;
  values: any = null;

  // options
  label: string = 'carregando...';
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  view: any[] = [640, 360];

  colorScheme = {
    domain: [ '#fe7e26', '#00dd00', '#dd51ff', '#000000', ]
  };
  cardColor: string = '#232837';

  constructor(
    public toastr: ToastrService,
    public customerProv: CustomerProvider,
    // private router: Router,
    private helpers: HelpersProvider,
  ) {
    //
  }

  ngOnInit() {
    this.getSubsAndCancels();
  }

  ngOnChanges() {
    // this.getSubsAndCancels();
    // this.loading = false;
  }

  processData(data: any) {
    // console.log(data);
    this.data = [
      { "name": "Basic",
        "value": data.supercoachBasicPP,
        // "value": 7,
      },
      { "name": "Standard",
        "value": data.supercoachStandardPP,
        // "value": 8,
      },
      { "name": "Pro",
        "value": data.supercoachProPP,
        // "value": 9,
      },
      { "name": "Black",
        "value": data.supercoachBlackPP,
        // "value": 9,
      },
    ];
    this.values = data;
    // this.label = 'ST Coach - Assinaturas Ativas (' + data.cancelamentos + ' canceladas hoje)';
    this.label = 'ST Coach - Assinaturas Ativas - Pré Pago';
    this.total = data.supercoachBasicPP + data.supercoachStandardPP + data.supercoachProPP + data.supercoachBlackPP;
  }



  onSelect(_data: any): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(_data)));
  }

  onActivate(_data: any): void {
    // console.log('Activate', JSON.parse(JSON.stringify(_data)));
  }

  onDeactivate(_data: any): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(_data)));
  }


  getSubsAndCancels() {
    this.customerProv.admSubsAndCancels().then(
      (res: any) => {
        this.loading = false;
        this.processData(res);
        // console.log(res);
      },
      (err: any) => {
        this.toastr.error(err, '', this.helpers.toastrCfgs);
        this.loading = false;
      }
    );
  }

}
