import { Injectable } from '@angular/core';

// import includes from 'lodash/includes';

import { BR } from './strings_br';
import { EN } from './strings_en';
import { ES } from './strings_es';

@Injectable()
export class I18nProvider {

  protected language: string = 'br';

  constructor(
    //
  ) {
    //
  }

  getLanguage() {
    let selectedLanguage = this.getLocallyStored('language');
    if (selectedLanguage === null) selectedLanguage = 'br'
    return selectedLanguage;
  }

  translate(page: string = null) {
    const selectedLanguage = this.getLocallyStored('language');
    switch (selectedLanguage) {
      case 'br':
        return (page) ? BR[page] : BR;
        // break;
      case 'en':
        return (page) ? EN[page] : EN;
        // break;
      case 'es':
        return (page) ? ES[page] : ES;
        // break;
      default:
        return (page) ? BR[page] : BR;
    }
  }

  translateByDB(model: any, field: string) {
    const selectedLanguage = this.getLocallyStored('language');
    if (selectedLanguage === 'br') {
      return model[field];
    } else {
      if (model.translations === null) {
        return model[field];
      } else {
        if (model.translations) {
          if (model.translations['strings_' + selectedLanguage]) {
            if (model.translations['strings_' + selectedLanguage][field]) {
              return model.translations['strings_' + selectedLanguage][field];
            }
          }
        }
        return model[field];
      }
    }
  }

  returnDiffTranslate(id: string = null) {
    const STRINGS = this.translate();
    switch (id) {
      case 'Iniciante':
        return STRINGS.iniciante;
        break;

      case 'Intermediário':
        return STRINGS.intermediario;
        break;

      case 'Avançado':
        return STRINGS.avancado;
        break;

      case 'Expert':
        return STRINGS.expert;
        break;
    
      default:
        return STRINGS.iniciante;
        break;
    }
  }

  getLocallyStored(storeName: string, returnExpired: boolean = false) {
    const result: any = JSON.parse(window.localStorage.getItem(storeName));
    const now = Date.now();
    if ((!result || result.expires < now) && returnExpired === false) {
      return null;
    }
    return (result.data);
  }


}


// interface ToastOptions {
//   cssClass?: string;
// }
