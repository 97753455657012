import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-avisos',
  templateUrl: './avisos.component.html',
  styleUrls: ['./avisos.component.scss']
})
export class AvisosComponent implements OnInit {
  bannerItem: any = null;
  reloadBanners: number = 0;

  constructor() { }

  ngOnInit() {
    console.log('page avisos - init');
  }


  getBannerItem(banner: any) {
    this.bannerItem = banner;
  }

  getReloadBanners(reload: number) {
    this.reloadBanners = reload;
  }

}
