import { Component, OnChanges, OnInit, Input } from '@angular/core';
// import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { HelpersProvider } from '../../../providers/helpers/helpers';
// import { AuthService } from '../../providers/auth/auth.service';
import { CustomerProvider } from '../../../providers/customer/customer';


@Component({
  selector: 'dashboard-total-subs-and-cancels',
  templateUrl: './total-subs-and-cancels.html',
})
export class DashboardTotalSubsAndCancelsComponent implements OnChanges, OnInit {

  @Input() user: any;
  loading = true;

  data: any[];
  total: number = 0;
  values: any = null;

  // options
  label: string = 'carregando...';
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  view: any[] = [640, 360];

  colorScheme = {
    domain: [ '#1992DD', '#fff300', '#005b13', '#009913',
              // '#CC00CC', '#990000', '#996600',
              // '#ff6800', '#33CC00', '#9900CC', '#000000',
            ]
  };
  cardColor: string = '#232837';

  constructor(
    public toastr: ToastrService,
    public customerProv: CustomerProvider,
    // private router: Router,
    private helpers: HelpersProvider,
  ) {
    //
  }

  ngOnInit() {
    this.getSubsAndCancels();
  }

  ngOnChanges() {
    // this.getSubsAndCancels();
    // this.loading = false;
  }

  processData(data: any) {
    // console.log(data);
    this.data = [
      // { "name": "Assinaturas",
      //   "value": data.total,
      //   // "value": 1,
      // },
      { "name": "Apple",
        "value": data.apple,
        // "value": 2,
      },
      { "name": "Google",
        "value": data.google,
        // "value": 2,
      },
      { "name": "PagarMe",
        "value": data.pagarme,
        // "value": 3,
      },
      { "name": "PgM Novo",
        "value": data.pagarmenovo,
        // "value": 3,
      },
      // { "name": "STr 3M",
      //   "value": data.meses3,
      //   // "value": 4,
      // },
      // { "name": "STr 6M",
      //   "value": data.meses6,
      //   // "value": 5,
      // },
      // { "name": "STr 12M",
      //   "value": data.meses12,
      //   // "value": 6,
      // },
    ];
    this.values = data;
    this.label = 'Super Treinos - Assinaturas Ativas (' + data.cancelamentos + ' canceladas hoje)';
  }



  onSelect(_data: any): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(_data)));
  }

  onActivate(_data: any): void {
    // console.log('Activate', JSON.parse(JSON.stringify(_data)));
  }

  onDeactivate(_data: any): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(_data)));
  }


  getSubsAndCancels() {
    this.customerProv.admSubsAndCancels().then(
      (res: any) => {
        this.loading = false;
        this.processData(res);
        this.total = res.total;
        // console.log(res);
      },
      (err: any) => {
        this.toastr.error(err, '', this.helpers.toastrCfgs);
        this.loading = false;
      }
    );
  }

}
