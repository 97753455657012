import { Injectable } from '@angular/core';
import { ApiProvider } from '../api/api';

@Injectable()
export class ConfigProvider {

  constructor(
    public api: ApiProvider
  ) {
    //
  }

  programs(programId = '') {
    const url = 'v2/programs';
    return this.api.get(url, { params: { pid: programId } });
  }

  program(id: any) {
    return this.api.get(`v2/programs/${id}`);
  }

  patchProgram(program: any) {
    program._method = 'PATCH';
    return this.api.post(`v2/programs/${program.id}`, program);
  }

  postProgram(program: any) {
    return this.api.post(`v2/programs/`, program);
  }

  deleteProgram(id: any) {
    return this.api.delete(`v2/programs/${id}`);
  }

}
