import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ToastrService } from 'ngx-toastr';

import { DialogComponent } from '../../ui/dialog/dialog';
import { UserProvider } from '../../providers/user/user';
import { HelpersProvider } from '../../providers/helpers/helpers';

import { environment } from '../../environments/environment';
import { banners } from '../../ui/dialog-select-banner/banners';

@Component({
  selector: 'dialog-send-to-teacher',
  templateUrl: 'dialog-send-to-teacher.html',
})

export class DialogSendToTeacherComponent {
  constructor(
    public userProv: UserProvider,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogSendToTeacherComponent>,
    private toastr: ToastrService,
    private helpers: HelpersProvider,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    //
  }

  protected API = (environment.API_ENV_URLS).replace('/api', '/');

  banners: any = banners.free;
  banners_std: any = banners.std;
  banner_selected = '';
  user: any = {};
  teachers: any = [];
  totalTeachers: number = 0;
  loading: boolean = false;

  totalItens = { count: 0 };
  maxItens: number = 12;
  search: any = {
    name: <string> '',
    email: <string> '',
  };

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.getTeacherList();
  }


  doInfinite(){
    setTimeout(() => {
      this.maxItens = this.maxItens + 12;
    }, 250);
  }

  getTeacherList() {
    this.loading = true;
    this.userProv.getTeacherList().then(
      (res: any) => {
        this.loading = false;
        this.teachers = res;
        this.totalTeachers = 0;
        this.teachers.map((_prof: any) => {
          this.totalTeachers++;
        });
      },
      (err: any) => {
        this.loading = false;
        console.log(err);
      }
    );
  }


  select(teacher: any, _event: any) {
    // this.dialogRef.close(teacher);

    const msg = 'Deseja realmente enviar este Programa para sua conta de ' + teacher.name + '?';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: 'Exportar Programa', message: msg, confirmation: 'Sim'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Sim') {
        this.dialogRef.close(teacher.id);
      }
    });
  }

  checkPlan(teacher: any) {
    teacher.bt_plan = '<span class="checking">verificando...</span>';

    this.userProv.subscription(teacher.id).then(
      (res: any) => {
        const plan = res.purchase.supertreinos_plan_title.split(' ');
        const pagarme = (res.purchase.pagarme === null) ? '' : '(recorrente)';
        const expires = (res.teacher.premium_expires_date === null) ? null : res.teacher.premium_expires_date.split(' ');
        const expiration = (expires === null || expires[0] === undefined) ? '' : '(' + expires[0] + ')';

        teacher.students = res.students;
        teacher.programs = res.programs;
        teacher.files = res.files;

        if (plan[3] === undefined) {
          teacher.bt_plan = '<span class="free">Gratuito</span>';
        } else {
          teacher.bt_plan = '<span class="' + plan[3] + '">' + plan[3] + ' ' + pagarme + ' ' + expiration + '</span>';
        }

        return teacher.premium_id;
      },
      (err: any) => {
        this.loading = false;
        teacher.bt_plan = '<span class="erro">erro</span>';
        this.toastr.error(err || 'Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
      }
    );

  }

}
