import { Component, OnInit, OnChanges, ViewChild } from '@angular/core';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';

import { DialogComponent } from '../../ui/dialog/dialog';
import { DialogInputCustomComponent } from '../../ui/dialog-input-custom/dialog-input-custom';
import { DialogLightComponent } from '../../ui/dialog-light/dialog-light';


import { LibraryProvider } from '../../providers/library/library';
import { WorkoutProvider } from '../../providers/workout/workout';
import { GoalProvider } from '../../providers/goal/goal';
import { ApiProvider } from '../../providers/api/api';
import { HelpersProvider } from '../../providers/helpers/helpers';
import { AuthService } from '../../providers/auth/auth.service';
import { I18nProvider } from '../../providers/i18n/i18n';

import { LibraryFilterPipe } from '../../components/library/library.filter.pipe';

import { environment } from '../../environments/environment';

@Component({
  selector: 'page-biblioteca',
  templateUrl: './biblioteca.component.html',
  styleUrls: ['./biblioteca.component.scss']
})
export class BibliotecaComponent implements OnInit, OnChanges {
  PAGE: any;
  STRINGS: any;
  
  libraryItem: any = null;
  reloadLibrary: boolean = true;

  methodsList: any = [];
  musclesList: any = [];
  equipsList: any = [];
  typesList: any = [];

  protected headers: any;
  protected API = (environment.API_ENV_URLS).replace('/api', '/');

  dataSource: MatTableDataSource<any>;
  displayedColumns = ['select', 'published', 'name_clear', 'gender', 'premium'];
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loading: boolean = false;
  form_loading: boolean = false;
  user: any = [];

  // converter em form control
  workoutName = new FormControl('', [Validators.required]);
  workoutVideoUrl = new FormControl('', [Validators.required]);

  workout: any = {
    id: <number> 0,
    premium: <number> 0,
    published: <number> 1,
    cover_url: <string> '',
    cover_path: <string> '',
    video_url: <string> 'https://player.vimeo.com/video/',
    name: <string> '',
    gender: <string> 'ambos',
    description: <string> '',
    series_repetitions: <string> '',
    intervals: <number> 0,
    sort: <number> 0,
    workout_muscle_ids: <any> [],
    workout_equip_ids: <any> [],
    workout_method_ids: <any> [],
    workout_type_ids: <any> [],
    translations: <string> '',
  };

  workouts: any = [];
  workoutId: any;
  workoutsOriList: any = [];
  totalWorkouts: number = 0;
  library: any = {
    workouts: [] as any,
    total: 0 as any,
  };
  libraryLimit: number = 300;

  allComplete: boolean = false;
  checkAllIndeterminate: boolean = false;
  checkedWorkouts = [];
  selectedWorkouts = [];
  total_selected = 0;

  dropSearch: boolean = false;

  totalItens = { count: 0 };
  maxItens: number = 0;
  search: any = {
    premium: <any> '',
    published: <any> '',
    name: <string> '',
    gender: <string> '',
    workout_muscle_ids: <any> [],
    workout_equip_ids: <any> [],
    workout_method_ids: <any> [],
    workout_type_ids: <any> [],
  };

  ismobile: boolean;
  istablet: boolean;
  menuSelected: string = '';

  sanitized_video_url: any;
  
  cropCheckInterval: any;
  @ViewChild('dzLibraryCover', {static: false}) dzLibraryCover?: DropzoneComponent;
  public config: DropzoneConfigInterface;

  constructor(
    private router: Router,
    public toastr: ToastrService,
    public libraryProvider: LibraryProvider,
    public workoutProvider: WorkoutProvider,
    public auth: AuthService,
    public goalProvider: GoalProvider,
    public api: ApiProvider,
    public helpers: HelpersProvider,
    public dialog: MatDialog,
    private libraryFilterPipe: LibraryFilterPipe,
    private device: DeviceDetectorService,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('BibliotecaComponent');

    this.ismobile = this.device.isMobile();
    this.istablet = this.device.isTablet();
    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};
    this.user = this.auth.retrieveLocalUser();
    this.dataSource = new MatTableDataSource();

    this.config = {
      paramName: 'cover_img',
      thumbnailMethod: 'contain',
      thumbnailHeight: null,
      thumbnailWidth: null,
      addRemoveLinks: true,
      dictRemoveFile: this.STRINGS.dz_remover_imagem,
      dictCancelUpload: this.STRINGS.dz_cancelar_envio,
      dictCancelUploadConfirmation: this.STRINGS.dz_confirma_cancelar_envio,
      dictInvalidFileType: this.STRINGS.dz_arquivo_invalido,
      maxFiles: 1,
      transformFile: (file, done) => {
        this.cropCheckInterval = setInterval(() => { if (this.helpers.getLocallyStored('cropOngoing') === false) this.resetDropzoneUploads() }, 250);
        this.helpers.cropImage(file, done, 'dropzone-library-cover', 300, 300);
      }
      // autoProcessQueue: false,
    };
  }

  ngOnInit() {
    // console.log('page biblioteca - init');
    this.user = this.auth.retrieveLocalUser();
    this.prepareForm();
    this.updateDropzoneParams();
    this.getWorkouts();
    this.getMethods();
    this.getMuscles();
    this.getEquips();
    this.getTypes();
  }

  ngOnChanges() {
    this.sanitized_video_url = this.helpers.sanitizerVideoURL(this.workout.video_url);
    this.updateDropzoneParams();
  }


  getLibraryItem(workout: any) {
    this.libraryItem = workout;
  }

  setReloadLibrary(reload: boolean) {
    this.reloadLibrary = reload;
  }

  handlePageEvent(evt: any = null) {
    this.helpers.storeLocally('lastPageSizeLibrary', evt.pageSize);
  }

  changeSelect(evt: any) {
    console.log(evt);
  }

  applyPipe(value: any) {
    const workouts = this.libraryFilterPipe.transform(
      value,
      this.search.name,
      this.search.premium,
      this.search.published,
      this.search.gender,
      this.search.workout_method_ids,
      this.search.workout_muscle_ids,
      this.search.workout_equip_ids,
      this.search.workout_type_ids,
      this.maxItens,
      this.totalItens,
      this.STRINGS.lang
    );

    this.totalWorkouts = workouts.length;
    return workouts;
  }

  changeFilter(evt: any) {
    let name = (evt?.source?.name);
    let value = (evt?.source?.value);
    if (name === undefined) name = (evt?.source?.ngControl?.name);
    if (value === undefined) value = (evt?.source?.ngControl?.value);
    this.search[name] = value;

    const filteredItems: any = this.applyPipe(this.workouts);
    console.log(filteredItems);
    // this.search[evt.source.name] = evt.value;
    // let filteredItems: any = this.applyPipe(this.workouts);

    this.dataSource = new MatTableDataSource(filteredItems);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  toggleFilter() {
    this.dropSearch = !this.dropSearch;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  getWorkouts(force: boolean = false) {
    if(this.user.account_type === 'teacher') {
      this.getLibraryTeacher();
    } else {
      this.getLibraryMaster(force);
    }
  }

  getLibraryMaster(force: boolean = false) {
    this.loading = true;
    if (force) {
      this.helpers.delCacheDB('cachedLibrary');
      this.libraryProvider.library().then(
        (res: any) => {
          this.helpers.addCacheDB('cachedLibrary', res);
          this.processLibraryList(res);
        },
        (err: any) => {
          console.log(err);
          this.loading = false;
        }
      );
    } else {
      this.helpers.getCacheDB('cachedLibrary').then(
        (cache: any) => {
          const lastPageSize = this.helpers.getLocallyStored('lastPageSizeLibrary');
          this.paginator._changePageSize(lastPageSize || 25);
          this.dataSource = new MatTableDataSource();

          if (cache === undefined || cache === null) {

            this.libraryProvider.library().then(
              (res: any) => {
                this.helpers.addCacheDB('cachedLibrary', res);
                this.processLibraryList(res);
              },
              (err: any) => {
                console.log(err);
                this.loading = false;
              }
            );

          } else {
            const data = JSON.parse(cache.data);
            this.library = data;
            this.workouts = data.workouts;
            this.processLibraryList(data);
          }
        },
        (err: any) => {
          console.log(err);
        }
      );
    }
  }

  getLibraryTeacher() {
    this.loading = true;
    this.helpers.delCacheDB('cachedLibraryTeacher');
    this.libraryProvider.libraryTeacher().then(
      (res: any) => {
        this.processLibraryList(res);
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
      }
    );
  }

  processLibraryList(res: any = null) {
    this.library = res;
    this.workouts = res.workouts;
    this.workoutsOriList = res.workouts;
    this.totalWorkouts = 0;
    // this.students.map(() => { this.totalStudents++; });
    for (let i=0; i<this.workouts.length;i++) {
      this.totalWorkouts++;
      this.workouts[i].total_workouts = this.workouts.length;
      this.workouts[i].name_clear = this.helpers.removeDiacritics(this.workouts[i].name);
    }
    // console.log(this.students);

    const filteredItems: any = this.applyPipe(this.workouts);
    this.dataSource = new MatTableDataSource(filteredItems);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.loading = false;
  }

  backToList() {
    this.menuSelected = '';
  }

  new() {
    this.menuSelected = 'form';
    this.prepareForm();
  }

  select(workout: any) {
    this.menuSelected = 'form';
    this.form_loading = true;
    this.prepareForm();

    setTimeout(() => {
      this.workout = Object.assign({}, workout);
      this.sanitized_video_url = this.helpers.sanitizerVideoURL(this.workout.video_url);
      if ((this.workout.published === 0) || (this.workout.published === '0')) {
        this.workout.published = false;
      } else {
        this.workout.published = true;
      }
      if ((this.workout.premium === 0) || (this.workout.premium === '0')) {
        this.workout.premium = false;
      } else {
        this.workout.premium = true;
      }
      if (this.workout.video_url === null) {
        this.workout.video_url = 'https://player.vimeo.com/video/';
      }
      this.workoutVideoUrl.setValue(this.workout.video_url);
      this.updateDropzoneParams();
    }, 250);

    setTimeout(() => { this.form_loading = false; }, 1000);
  }

  saveWorkout() {
    // if (this.user.account_type === 'teacher') {
    //   if (this.user.subs) {
    //     if (this.user.subs.supertreinos_plan_id < 3) {
    //       this.callToSubs();
    //       return false;
    //     }
    //   }
    // }

    this.loading = true;
    let valid = true;
    let el: any;
    this.updateTranslation();

    this.workout.user_id = this.user.id;

    if (this.workout.name === '') {
      this.workoutName.markAsTouched();
      valid = false;
    }
    if (this.workout.video_url === 'https://player.vimeo.com/video/') {
      this.workout.video_url = '';
    }
    if (this.helpers.validateURL(this.workout.video_url)) {
      this.workoutVideoUrl.setErrors({'incorrect': true});
      valid = false;
    }
    if (valid === false) {
      this.loading = false;
      el = document.getElementById('iframeWorkoutVideo');
      el.scrollIntoView();
      return false;
    }
    if (this.workout.id > 0) {
      this.updateWorkout();
    }
    if (this.workout.id === 0) {
      this.createWorkout();
    }
  }

  publishedToggle(workout: any) {
    workout.published = !workout.published;
    this.libraryProvider.patchLibraryItem(workout).then(
      (_res: any) => {
        this.helpers.delCacheDB('cachedLibrary');
        // this.workout = _res.workout;
        // this.sanitized_video_url = this.helpers.sanitizerVideoURL(this.workout.video_url);
        this.toastr.success(this.PAGE.exercicio_atualizado_sucesso, '', this.helpers.toastrCfgs);
        // this.loading = false;
        // this.getWorkouts(this.reloadLibrary);
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        // this.loading = false;
      });
  }

  updateWorkout() {
    const workoutUpload = Object.assign({}, this.workout);
    delete workoutUpload.cover_path;
    delete workoutUpload.cover_url;

    this.libraryProvider.patchLibraryItem(workoutUpload).then(
      (res: any) => {
        this.workout = res.workout;
        this.sanitized_video_url = this.helpers.sanitizerVideoURL(this.workout.video_url);
        this.toastr.success(this.PAGE.exercicio_atualizado_sucesso, '', this.helpers.toastrCfgs);
        this.loading = false;
        this.prepareForm();
        this.getWorkouts(this.reloadLibrary);
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        this.loading = false;
      });
  }

  createWorkout() {
    // console.log(this.workout);
    this.libraryProvider.postLibraryItem(this.workout).then(
      (res: any) => {
        this.workout = res.workout;
        this.sanitized_video_url = this.helpers.sanitizerVideoURL(this.workout.video_url);
        this.toastr.success(this.PAGE.exercicio_cadastrado_sucesso, '', this.helpers.toastrCfgs);
        this.loading = false;
        this.prepareForm();
        this.getWorkouts(this.reloadLibrary);
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        this.loading = false;
      });
  }

  deleteWorkout(id = null) {
    this.deleteWorkoutConfirmation(id);
  }

  deleteWorkoutConfirmation(id: any): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.apagar_exercicio_title, message: this.PAGE.apagar_exercicio, confirmation: 'deletar'}
    });

    if (id === null) { id = this.workout.id; }

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed: ' + result);
      if (result === 'deletar') {
        this.loading = true;
        this.libraryProvider.deleteLibraryItem(id).then(
          (_res: any) => {
            this.toastr.success(this.PAGE.apagar_exercicio_sucesso, '', this.helpers.toastrCfgs);
            this.prepareForm();
            this.getWorkouts(this.reloadLibrary);
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
      }
    });
  }

  deleteSelected() {
    if (this.selectedWorkouts.length < 1) {
      this.toastr.error(this.PAGE.nenhum_selecionado, '', this.helpers.toastrCfgs);
      return false;
    }

    // console.log(this.selectedWorkouts);

    const msg = this.PAGE.apagar_exercicio_certeza;
    const dialogRef = this.dialog.open(DialogInputCustomComponent, {
      width: '360px',
      data: {title: this.PAGE.apagar_exercicio_certeza_title, placeholder: this.PAGE.apagar_exercicio_certeza_placeholder, inner_msg: msg, confirmation: 'sim'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === this.PAGE.apagar_exercicio_certeza_confirmacao) {
        this.loading = true;
        let idx = 0;
        for (let i = 0; i < (this.selectedWorkouts.length); i++) {
          this.libraryProvider.deleteLibraryItem(this.selectedWorkouts[i].id).then(
            (_res: any) => {
              idx++;
              if (idx === this.selectedWorkouts.length) {
                this.getWorkouts(this.reloadLibrary);
                this.selectedWorkouts = [];
                this.checkedWorkouts = [];
                this.allComplete = false;
                this.checkAllIndeterminate = false;
                let msg = (this.selectedWorkouts.length > 1) ? this.PAGE.apagar_exercicios_certeza_sucesso : this.PAGE.apagar_exercicio_certeza_sucesso;
                this.toastr.success(msg, '', this.helpers.toastrCfgs);
              }
            },
            (err: any) => {
              this.loading = false;
              console.log(err);
              this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
            }
          );
        }
      }
    });
  }

  getMethods() {
    this.libraryProvider.libraryMethods().then(
      (res: any) => {
        this.methodsList = res.methods;
      },
      (err: any) => {
        console.log(err);
      });
  }

  getMuscles() {
    this.libraryProvider.libraryMuscles().then(
      (res: any) => {
        this.musclesList = res.muscles;
      },
      (err: any) => {
        console.log(err);
      });
  }

  getEquips() {
    this.libraryProvider.libraryEquips().then(
      (res: any) => {
        this.equipsList = res.equips;
      },
      (err: any) => {
        console.log(err);
      });
  }

  getTypes() {
    this.libraryProvider.libraryTypes().then(
      (res: any) => {
        this.typesList = res.types;
      },
      (err: any) => {
        console.log(err);
      });
  }

  prepareForm() {
    this.sanitized_video_url = '';
    this.workout = this.helpers.resetObject(this.workout);
    this.workout.id = 0;
    this.workout.published = 1;
    this.workout.video_url = 'https://player.vimeo.com/video/';
    this.workout.gender = 'ambos';
    // this.workout.cover_url = this.API + 'img/default-video.jpg';
    // this.workout.cover_url = this.API + 'img/EXERCICIO-BANNER-PADRAO.jpg';
    this.workout.cover_url = this.API + 'storage/video-default.jpg';
    this.workoutName.reset();
    this.workoutName.setValue('');
    this.workoutVideoUrl.reset();
    this.workoutVideoUrl.setValue('https://player.vimeo.com/video/');
    this.updateDropzoneParams();
  }

  setAll(checked: boolean) {
    if (this.checkedWorkouts == null) return;
    if (!checked) this.checkAllIndeterminate = false;
    let count: number = 0;
    for (let i = 0; i < this.workouts.length; i++) {
      this.checkedWorkouts[i] = checked;
      if (checked) this.selectedWorkouts.push(this.workouts[i]);
      count++;
    }
    this.total_selected = (checked) ? count : 0;
  }
  changeCheck(checked: boolean, student: any = null) {
    this.total_selected += (checked) ? + 1 : - 1;
    if (checked) {
      this.selectedWorkouts.push(student);
    } else {
      for (let i = 0; i < this.selectedWorkouts.length; i++) {
        if (this.selectedWorkouts[i].id === student.id) {
          this.selectedWorkouts.splice(i, 1);
        }
      }
    }
  }
  updateAllComplete() {
    let leastOne: boolean = false
    for (let i = 0; i < this.workouts.length; i++) {
      if (this.checkedWorkouts[i]) {
        this.allComplete = ((i + 1) === this.workouts.length && !leastOne) ? false : true;
        leastOne = true;
      } else {
        this.allComplete = false;
        if (leastOne) break;
      }
    }
    if (!this.allComplete && leastOne) this.checkAllIndeterminate = true;
    if (this.allComplete) this.checkAllIndeterminate = false;
    if (!leastOne) this.checkAllIndeterminate = false;
  }

  clearFilter() {
    this.search = {
      premium: <any> '',
      published: <any> '',
      name: <string> '',
      gender: <string> '',
      workout_muscle_ids: <any> [],
      workout_equip_ids: <any> [],
      workout_method_ids: <any> [],
      workout_type_ids: <any> [],
    };

    const filteredItems: any = this.applyPipe(this.workouts);
    this.dataSource = new MatTableDataSource(filteredItems);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  updateTranslation() {
    const translations = (document.getElementById('translationFieldLibrary') as HTMLInputElement).value;
    // this.programForm.patchValue({ translations: translations});
    this.workout.translations = translations;
  }

  processTranslation(value: any) {
    if (value) {
      (document.getElementById('translationFieldLibrary') as HTMLInputElement).value = JSON.stringify(value);
    }
  }

  changeWorkoutVideoUrl(workout: any = null) {
    // console.log(workout);
    this.sanitized_video_url = '';
    if (workout.video_url !== '' && workout.video_url !== null && workout.video_url !== 'https://player.vimeo.com/video/') {
      this.sanitized_video_url = this.helpers.sanitizerVideoURL(workout.video_url);
    }

    return this.sanitized_video_url;
  }

  getRequiredMessage(el: any) {
    if (el.hasError('required')) {
      return this.STRINGS.campo_obrigatorio;
    }

    if (el.hasError('incorrect')) {
      return this.STRINGS.campo_invalido;
    }
  }


  // dropzone events
  changeWorkoutName() {
    this.resetDropzoneUploads();
    this.updateDropzoneParams();
  }

  onAddedFile(_args: any) {
    // console.log('adicionando arquivo...');
    this.loading = true;
    let valid = true;
    if (this.workout.name === '') {
      this.loading = false;
      this.resetDropzoneUploads();
      valid = false;
      this.workoutName.markAsTouched();
    }
    if (this.workout.video_url === '') {
      this.loading = false;
      this.resetDropzoneUploads();
      valid = false;
      this.workoutVideoUrl.markAsTouched();
    }
    if (valid === false) {
      this.loading = false;
      return false;
    }
  }

  onSendingFile(_$event: any) {
    this.workout.cover_path = '';
    this.workout.cover_url = false;
  }

  onRemovedFile(_$event: any) {
    //
  }

  onUploadError(_$event: any) {
    this.loading = false;
    console.log('upload error');
    console.log(_$event);
  }

  onUploadSuccess($event: any) {
    this.loading = false;
    // this.workout = $event[1].workout;
    this.workout.id = $event[1].workout.id;
    this.workout.cover_url = $event[1].workout.cover_url;
    this.workout.sort = $event[1].workout.sort;
    this.toastr.success(this.PAGE.banner_enviado_sucesso, '', this.helpers.toastrCfgs);

    this.resetDropzoneUploads();
    this.config.url = this.api.API_URL + '/v2/library/' + this.workout.id;
    this.config.params = {
      '_method': 'patch',
      'name': this.workout.name,
      'video_url': this.workout.video_url,
      'user_id': this.user.id,
    };
  }

  resetDropzoneUploads(): void {
    this.dzLibraryCover.directiveRef.reset(true);
  }

  updateDropzoneParams(): void {
    this.config.headers = this.headers;

    this.config.params = {
      'name': this.workout.name,
      'video_url': this.workout.video_url,
      'user_id': this.user.id,
    };

    if (this.workout.id > 0) {
      this.config.url = this.api.API_URL + '/v2/library/' + this.workout.id;
      this.config.params._method = 'PATCH';
    } else {
      this.config.url = this.api.API_URL + '/v2/library/';
    }
  }
  // dropzone events

  helpLibrary() {
    let msg = this.PAGE.biblioteca_ajuda

    const dialogRef = this.dialog.open(DialogLightComponent, { width: '380px', data: { message: msg } });
    dialogRef.afterClosed().subscribe(_result => {});
  }

  callToSubs() {
    const msg = this.PAGE.exclusivo_pro_plus;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.exclusivo_pro_plus_title, message: msg, confirmation: 'Sim'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Sim') {
        this.router.navigate(['professor/perfil'], { skipLocationChange: true, queryParams: { tab: 1 } } );
      }
    });
  }


}
