import { Component, OnChanges, OnInit, Input } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { HelpersProvider } from '../../../providers/helpers/helpers';
import { CustomerProvider } from '../../../providers/customer/customer';


@Component({
  selector: 'dashboard-total-subs-and-cancels-dp',
  templateUrl: './total-subs-and-cancels-dp.html',
})
export class DashboardTotalSubsAndCancelsDPComponent implements OnChanges, OnInit {

  @Input() user: any;
  loading = true;

  data: any[];
  total: number = 0;
  values: any = null;

  // options
  label: string = 'carregando...';
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  view: any[] = [640, 360];

  colorScheme = {
    domain: [ '#5ccfa6', '#f8c34b', '#00764c', '#996a01', ]
  };
  cardColor: string = '#232837';

  constructor(
    public toastr: ToastrService,
    public customerProv: CustomerProvider,
    // private router: Router,
    private helpers: HelpersProvider,
  ) {
    //
  }

  ngOnInit() {
    this.getSubsAndCancels();
  }

  ngOnChanges() {
    // this.getSubsAndCancels();
    // this.loading = false;
  }

  processData(data: any) {
    // console.log(data);
    this.data = [
      { "name": "Lite",
        "value": data.mydietproLite,
        // "value": 7,
      },
      { "name": "Gold",
        "value": data.mydietproGold,
        // "value": 8,
      },
      { "name": "Lite Pré Pago",
        "value": data.mydietproLitePP,
        // "value": 9,
      },
      { "name": "Gold Pré Pago",
        "value": data.mydietproGoldPP,
        // "value": 9,
      },
    ];
    this.values = data;
    // this.label = 'ST Coach - Assinaturas Ativas (' + data.cancelamentos + ' canceladas hoje)';
    this.label = 'MyDietPro - Assinaturas Ativas';
  }



  onSelect(_data: any): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(_data)));
  }

  onActivate(_data: any): void {
    // console.log('Activate', JSON.parse(JSON.stringify(_data)));
  }

  onDeactivate(_data: any): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(_data)));
  }


  getSubsAndCancels() {
    this.customerProv.admSubsAndCancels().then(
      (res: any) => {
        this.loading = false;
        this.processData(res);
        this.total = res.mydietproLite + res.mydietproGold + res.mydietproLitePP + res.mydietproGoldPP;
        // console.log(res);
      },
      (err: any) => {
        this.toastr.error(err, '', this.helpers.toastrCfgs);
        this.loading = false;
      }
    );
  }

}
