import { Component, OnInit } from '@angular/core';

import { LibraryProvider } from '../../providers/library/library';

@Component({
  selector: 'app-treinos',
  templateUrl: './treinos.component.html',
  // styleUrls: ['./treinos.component.css']
})
export class TreinosComponent implements OnInit {
  pid: any = 0;
  tid: any = 0;
  cloned_library: any = null;
  moveLeft: boolean = false;
  methodsList: any = [];
  musclesList: any = [];
  equipsList: any = [];

  constructor(
    public libraryProvider: LibraryProvider,
  ) {
    //
  }

  ngOnInit() {
    console.log('page treinos - init');
    this.pid = '';
  }

  getProgramId(id: any) {
    this.pid = id;
  }

  getTrainingId(id: any) {
    this.tid = id;
  }

  toggleLibrary(_val: any) {
    console.log(this.moveLeft);
    this.moveLeft = !this.moveLeft;

    if (this.moveLeft) {
      this.getMethods();
      this.getMuscles();
      this.getEquips();
    }
  }

  getClonedLibrary(cloned_library: any = null) {
    this.cloned_library = cloned_library;
  }

  getMethods() {
    this.libraryProvider.libraryMethods().then(
      (res: any) => {
        this.methodsList = res.methods;
      },
      (err: any) => {
        console.log(err);
      });
  }

  getMuscles() {
    this.libraryProvider.libraryMuscles().then(
      (res: any) => {
        this.musclesList = res.muscles;
      },
      (err: any) => {
        console.log(err);
      });
  }

  getEquips() {
    this.libraryProvider.libraryEquips().then(
      (res: any) => {
        this.equipsList = res.equips;
      },
      (err: any) => {
        console.log(err);
      });
  }

}
