import { Component, OnInit, Input } from '@angular/core';
// import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
// import { ActivatedRoute } from '@angular/router';

import { ApiProvider } from '../../../providers/api/api';
import { HelpersProvider } from '../../../providers/helpers/helpers';
import { AuthService } from '../../../providers/auth/auth.service';
import { CustomerProvider } from '../../../providers/customer/customer';
import { UserProvider } from '../../../providers/user/user';
import { I18nProvider } from '../../../providers/i18n/i18n';


@Component({
  selector: 'component-customer-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class CustomerNotesComponent implements OnInit {
  protected headers: any;
  @Input() studentData: any = null;

  loading: boolean = false;
  notes = [];

  PAGE: any;
  STRINGS: any;


  constructor(
    public toastr: ToastrService,
    public auth: AuthService,
    public api: ApiProvider,
    public helpers: HelpersProvider,
    public dialog: MatDialog,
    // private route: ActivatedRoute,
    public studentProv: CustomerProvider,
    public userProv: UserProvider,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('CustomerNotesComponent');

    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};
  }

  ngOnInit() {
    this.getNotes(this.studentData.id);
  }
  
  ngOnChanges() {
    this.getNotes(this.studentData.id);
    // this.loading = true;
    // setTimeout(() => {
    //   this.loading = false;
    // }, 400);
  }


  getNotes(id: number = 0) {
    this.loading = true;
    // busca o avatar e saudacao do professor
    this.userProv.getWorkoustNotes(id).then(
      (res: any) => {
        this.loading = false;
        this.notes = res.notes;
      },
      (err: any) => {
        console.log(err);
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        this.notes = null;
        this.loading = false;
      }
    );
  }

  select(workout: any) {
    console.log(workout);
  }


}
