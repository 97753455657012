// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BASE_URL: 'https://www.api.homolog.supertreinosapp.com',

  // API_ENV_URLS: 'http://localhost/api',
  // API_ENV_URLS: 'http://supertreinos.loc/api',
  // API_ENV_URLS: 'http://142.93.235.119/api',
  // API_ENV_URLS: 'https://142.93.235.119/api',

  // API_ENV_URLS: 'https://supertreinosapp.com/api',
  API_ENV_URLS: 'https://www.api.homolog.supertreinosapp.com/api',

  // dev: 'local',
  // dev: 'prod',
  dev: 'homolog',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


// senha generica 123123
// $2a$12$oL8dvlbXhQLKAsEVU4QvcuQQa0h7RHK0ozLwKXQJ3btGm9uI.PAwi
