import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { GoalProvider } from '../../../providers/goal/goal';
import { HelpersProvider } from '../../../providers/helpers/helpers'


@Component({
  selector: 'app-customer-goals',
  templateUrl: './customer-goals.component.html',
  styleUrls: ['./customer-goals.component.scss']
})
export class CustomerGoalsComponent implements OnInit {

  loading: boolean = false;

  goalForm = new FormGroup({
    id: new FormControl(''),
    name: new FormControl(''),
    translations: new FormControl(''),
  });

  goals: any = [];

  constructor(
    public goalProv: GoalProvider,
    public helpers: HelpersProvider,
    public toastr: ToastrService,
  ) {
    //
  }

  ngOnInit() {
    //
    this.loading = true;
    this.goalProv.index().then(
      (res: any) => {
        this.goals = res.goals;
        this.loading = false;
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
      }
    );
  }

  resetForm() {
    this.goalForm.reset();
  }


  select(goal: any) {
    this.goalForm.patchValue(goal);
  }

  save(id: number) {
    this.updateTranslation();
    if(id > 0) {
      this.update(this.goalForm.value);
    } else {
      this.create();
    }
  }

  update(goal: any) {
    // console.log(goal);
    this.loading = true;
    this.goalProv.patch(goal).then(
      (res: any) => {
        this.goals = res.goals;
        this.toastr.success('Objetivo atualizado com sucesso!', '', this.helpers.toastrCfgs);
        this.loading = false;
        this.goalForm.reset();
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(err.error.err, '', this.helpers.toastrCfgs);
        this.loading = false;
    });
  }

  create() {
    let data = Object.assign({}, this.goalForm.value);
    // console.log(this.goalForm.value);
    delete data.id;
    this.loading = true;
    this.goalProv.post(data).then(
      (res: any) => {
        this.goals = res.goals;
        this.toastr.success('O Objetivo foi cadastrado com sucesso!', '', this.helpers.toastrCfgs);
        this.loading = false;
        this.goalForm.reset();
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(err.error.err, '', this.helpers.toastrCfgs);
        this.loading = false;
    });
  }

  delete(id: number = 0) {
    if (id === 0) return false;
    this.loading = true;
    this.goalProv.delete(id).then(
      (res: any) => {
        this.goals = res.goals;
        this.toastr.success('O Objetivo foi removido com sucesso!', '', this.helpers.toastrCfgs);
        this.loading = false;
        this.goalForm.reset();
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(err.error.err, '', this.helpers.toastrCfgs);
        this.loading = false;
    });
  }

  updateTranslation() {
    const translations = (document.getElementById('translationFieldGoals') as HTMLInputElement).value;
    this.goalForm.patchValue({ translations: translations});
    // console.log(this.goalForm.value);
  }

  processTranslation(value: any) {
    if (value) {
      (document.getElementById('translationFieldGoals') as HTMLInputElement).value = JSON.stringify(value);
    }
  }

}
