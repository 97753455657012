import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { ApiProvider } from '../../../providers/api/api';
import { HelpersProvider } from '../../../providers/helpers/helpers';
import { AuthService } from '../../../providers/auth/auth.service';
import { CustomerProvider } from '../../../providers/customer/customer';
import { UserProvider } from '../../../providers/user/user';
import { I18nProvider } from '../../../providers/i18n/i18n';


@Component({
  selector: 'component-customer-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class CustomerHistoryComponent implements OnInit {
  protected headers: any;
  @Input() studentData: any = null;

  loading: boolean = false;
  history = [];

  PAGE: any;
  STRINGS: any;

  constructor(
    public toastr: ToastrService,
    public auth: AuthService,
    public api: ApiProvider,
    public helpers: HelpersProvider,
    public dialog: MatDialog,
    public studentProv: CustomerProvider,
    public userProv: UserProvider,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('CustomerHistoryComponent');

    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};

  }
  ngOnInit() {
    this.getHistory(this.studentData.id);
  }

  ngOnChanges() {
    this.getHistory(this.studentData.id);
    // this.loading = true;
    // setTimeout(() => {
    //   this.loading = false;
    // }, 400);
  }

  getHistory(id: any = 0) {
    this.loading = true;
    this.studentProv.getHistory(id).then(
      (res: any) => {
        this.loading = false;
        this.history = res.history;
      },
      (err: any) => {
        this.loading = false;
        console.log(err);
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      }
    );
  }

  toggleClass(item: any) {
    item.active = !item.active;
  }

}
