import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, fromEvent } from "rxjs";
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';

// import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';

import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../ui/dialog/dialog';
import { DialogSelectDateComponent } from '../../ui/dialog-select-date/dialog-select-date';
import { DialogInputCustomComponent } from '../../ui/dialog-input-custom/dialog-input-custom';

import { CustomerProvider } from '../../providers/customer/customer';
import { ProgramProvider } from '../../providers/program/program';
import { GoalProvider } from '../../providers/goal/goal';
import { AuthService } from '../../providers/auth/auth.service';
import { ApiProvider } from '../../providers/api/api';
import { UserProvider } from '../../providers/user/user';
import { User } from '../../providers/user/user.model';
import { UsersDataSource } from '../../providers/user/user.datasource';

import { HelpersProvider } from '../../providers/helpers/helpers';
import { codes } from '../../providers/helpers/countriesJson_ptBR';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  // styleUrls: ['./alunos.component.scss']
})
export class UsuariosComponent implements OnInit, AfterViewInit {
  protected headers: any;
  teacherCode: string = '';
  codes: any;
  ismobile: boolean = false;
  istablet: boolean = false;

  customerLegend: string = 'Usuário';

  // dataSource: MatTableDataSource<any>;
  dataSourceClient: UsersDataSource;
  displayedColumnsClient = ['select', 'last_access', 'name_clear', 'created_at', 'premium_expires_date', 'total_programs', 'total_clients', 'total_menus', 'total_avaliacoes', 'menu'];
  @ViewChild(MatPaginator, { static: true }) paginatorClient: MatPaginator;
  @ViewChild(MatSort, { static: true }) sortClient: MatSort;
  @ViewChild('inputName', { static: true }) inputName: ElementRef;  
 
  tabUser: number = 0;
  filterValue: string = '';
  accountType: string = 'teacher';

  loading: boolean = false;
  loadingForm: boolean = false;
  expired: boolean = false;

  userPremiumExpires: any;
  users: any = [];
  usersOriList: any = [];
  totalUsers: number = 0;
  waitline: any = [];
  deleteds: any = [];
  userBirthdate: any;
  userGoalId: number;
  goals: any = [];
  programs: any = [];
  originalPrograms: any = [];
  totalPrograms: number = 0;
  user: any = [];
  route_id: string = '0';

  // student data
  student: any = null;
  studentData: any = null;
  studentId: number = 0;
  studentAvatar: string = '';
  studentAnamnesis: string = '';
  //

  userId: number = 0;
  userData: any = null;
  
  userSelected: any = null;

  menuSelected: string = '';

  allComplete: boolean = false;
  checkAllIndeterminate: boolean = false;
  checkedList = [];
  selectedListItem = [];
  total_selected = 0;

  allCompleteWait: boolean = false;
  checkAllIndeterminateWait: boolean = false;
  checkedListWait = [];
  selectedListItemWait = [];
  total_selected_wait = 0;

  allCompleteDeleted: boolean = false;
  checkAllIndeterminateDeleted: boolean = false;
  checkedListDeleted = [];
  selectedListItemDeleted = [];
  total_selected_deleted = 0;

  imgrnd: number;


  constructor(
    public auth: AuthService,
    public studentProvider: CustomerProvider,
    public programProvider: ProgramProvider,
    public toastr: ToastrService,
    public goalProvider: GoalProvider,
    public dialog: MatDialog,
    public userProv: UserProvider,
    private router: Router,
    private route: ActivatedRoute,
    public api: ApiProvider,
    public helpers: HelpersProvider,
    private device: DeviceDetectorService,
  ) {
    this.ismobile = this.device.isMobile();
    this.istablet = this.device.isTablet();
    this.user = this.auth.retrieveLocalUser();
    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};
    this.teacherCode = this.route.snapshot.params.code;
    this.imgrnd = this.helpers.random(1000001,9999999);
    
  }

  ngOnInit() {
    this.helpers.storeLocally('ddi_codes', this.helpers.sortByProperty(codes, 'nome'), 0);
    this.codes = this.helpers.sortByProperty(codes, 'nome');
    this.helpers.clearState();

    // this.getUsers();

    this.dataSourceClient = new UsersDataSource(this.userProv);
    this.dataSourceClient.loadUsers(0, '', this.accountType, 'created_at', 'desc', 0, 25);
  }

  ngAfterViewInit() {
    // console.log(this.sortClient);
    this.sortClient.sortChange.subscribe(() => {
      this.paginatorClient.pageIndex = 0;
    });


    merge(this.sortClient.sortChange, this.paginatorClient.page)
    .pipe(
      tap(() => this.loadUsersPage())
    )
    .subscribe();
  }  

  loadUsersPage() {
    this.dataSourceClient.loadUsers(0, '', this.accountType, this.sortClient.active, this.sortClient.direction, this.paginatorClient.pageIndex, this.paginatorClient.pageSize);
  }

  loadUserAvatar(user: any) {
    if (user.avatar !== null) {
      if ((user.avatar).indexOf('http') === -1) {
        user.avatar = environment.BASE_URL + '/' + user.avatar;
      }
      let avatar = (user.avatar || 'assets/imgs/default_avatar.png') + '?' + this.imgrnd;
      return avatar;
  
    } else {
      return 'assets/imgs/default_avatar.png';
    }
  }

  applyFilter(filterValue: string, tabIndex: number = 0) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches

    this.accountType = (tabIndex === 0) ? 'teacher' : 'nutri';
    this.filterValue = filterValue;
    this.dataSourceClient.loadUsers(0, filterValue, this.accountType, this.sortClient.active, this.sortClient.direction, this.paginatorClient.pageIndex, this.paginatorClient.pageSize);
  }

  handlePageEvent(evt: any = null) {
    this.helpers.storeLocally('lastPageSize', evt.pageSize);
  }

  changeTab(evt: any = null) {
    let inputName = document.getElementById('inputName') as HTMLInputElement;
    inputName.value = '';
    this.tabUser = evt.index;
    this.accountType = (evt.index === 0) ? 'teacher' : 'nutri';
    if (!this.ismobile && !this.istablet) {
      if (evt.index === 1) {
        this.menuSelected = 'waitline';
        this.dataSourceClient.loadUsers(0, '', this.accountType, this.sortClient.active, "desc", this.paginatorClient.pageIndex, this.paginatorClient.pageSize);
      } else {
        this.menuSelected = 'cadastro';
        this.dataSourceClient.loadUsers(0, '', this.accountType, this.sortClient.active, "desc", this.paginatorClient.pageIndex, this.paginatorClient.pageSize);
      }
    }
  }

  getUserUpdated(_user: any = null) {
    this.menuSelected = '';
    this.studentId = 0;
    // this.getStudent();
    this.setAll(false);
    this.dataSourceClient.loadUsers(0, this.filterValue, this.accountType, this.sortClient.active, "desc", this.paginatorClient.pageIndex, this.paginatorClient.pageSize);

  }

  getUserListUpdated(users: any = null) {
    // this.processUserList(users);
  }

  getUsers() {
    this.imgrnd = this.helpers.random(1000001,9999999);
    this.loading = true;

    // busca os alunos
    this.userProv.getMasterList().then(
      (res: any) => {
        this.loading = false;
        this.processUserList(res);
        this.clearForm();
      },
      (err: any) => {
        this.toastr.error(err, '', this.helpers.toastrCfgs);
        this.loading = false;
      }
    );
  }


  processUserList(res: any = null) {
    this.users = res;
    this.usersOriList = res;
    this.totalUsers = 0;
    for (let i=0; i<this.users.length;i++) {
      this.totalUsers++;
      this.users[i].name_clear = this.helpers.removeDiacritics(this.users[i].name);
    }
    // console.log(this.users);
  }


  backToList() {
    this.menuSelected = '';
  }

  back() {
    this.userSelected = false;
    this.menuSelected = '';
    this.studentId = 0;
    this.route_id = '0';
  }

  editGreetings() {
    this.loading = true;
    this.clearForm();
    setTimeout(() => {
      this.menuSelected = 'waitline';
      this.loading = false;
    },350);
  }

  new() {
    this.loading = true;
    this.clearForm();
    setTimeout(() => {
      this.menuSelected = 'cadastro';
      this.loading = false;
    },350);
  }

  clearForm() {
    this.route_id = '0';
    this.userSelected = false;
    this.student = { id: 0, details : { ddi: '0055' } };
    this.studentData = { id: 0, details : { ddi: '0055' } };
    this.studentId = 0;
    if (!this.ismobile && !this.istablet) this.menuSelected = 'cadastro';
  }

  select(user: any) {
    if (user === null) return false;
    this.loadingForm = true;
    this.userProv.findUser(user.id).then(
      (u: any) => {
        this.user = u;
        this.back();
        this.userSelected = true;
        this.userData = u;
        this.userId = u.id;
        this.route_id = this.userId.toString();
        this.loadingForm = false;
          },
      (err: any) => {
        this.toastr.error(err, '', this.helpers.toastrCfgs);
        this.loadingForm = false;
      }
    );
  }


  section(section: string = '') {
    this.menuSelected = section;
  }


  delete(student: any) {
    this.deleteStudentConfirmation(student);
  }

  deleteStudentConfirmation(student: any): void {
    // console.log(student);
    const msg = 'Deseja realmente apagar o Aluno <b class="name">' + student.name + '</b>? Todo o progresso dele será apagado!';
    const dialogRef = this.dialog.open(DialogInputCustomComponent, {
      width: '360px',
      data: {title: 'Apagar Aluno', placeholder: 'escreva DELETAR, em caixa alta', inner_msg: msg, confirmation: 'sim'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'DELETAR') {
        this.loading = true;
        this.studentProvider.admCustomerDelete(student).then(
          (_res: any) => {
            this.loading = false;
            this.toastr.success('O Aluno  (' + student.name.split(' ')[0] + ') foi apagado com sucesso!', '', this.helpers.toastrCfgs);
            if (this.ismobile || this.istablet) this.backToList();
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(err, '', this.helpers.toastrCfgs);
            this.loading = false;
            if (this.ismobile || this.istablet) this.backToList();
          });
      }
    });
  }

  expire(student: any) {
    // console.log('seta expiracao do aluno');
    const dialogRef = this.dialog.open(DialogSelectDateComponent, {
      data: {
        title_innerhtml: '<img src="assets/imgs/timer.svg" alt="">Marque o tempo de consultoria do ' + this.customerLegend,
        label_date: 'Expiração Atual:',
        date_selected: student.premium_expires_date },
      panelClass: (this.accountType === 'nutri' ? 'np-dialog' : 'st-dialog'),
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('result: ' + result);
      if (result === undefined) return false;
      if (result !== '' || result !== null || result !== undefined) {
        let get = JSON.parse(JSON.stringify(result));
        if (get.date || get.clear) {
          if (get.date) student.premium_expires_date = get.date.substring(-10, 10);
          if (get.clear) student.premium_expires_date = null;

          const students: any = [];
          students.push(student);

          this.studentProvider.admCustomerUpdate(students).then(
            (_res: any) => {
              this.loading = false;
              this.toastr.success('A validade do plano do aluno (' + student.name.split(' ')[0] + ') foi atualizada com sucesso!', '', this.helpers.toastrCfgs);
              // this.getSubscriptions();
            },
            (err: any) => {
              this.loading = false;
              this.toastr.warning(err, '', this.helpers.toastrCfgs);
              console.log(err);
            }
          );
        }
      }
    });
  }
  convertDate(user: any) {
    if (user.premium_expires_date) {
      this.userPremiumExpires = new Date(user.premium_expires_date);
      // const offsetMs = this.userPremiumExpires.getTimezoneOffset() * 3 * 60 * 1000;
      // this.userPremiumExpires = new Date(this.userPremiumExpires.getTime() - offsetMs);
      this.userPremiumExpires = new Date(this.userPremiumExpires.getTime());
    }
  }
  normalizeDate(date: any) {
    if (date === null) return date;
      return date.replace(/\s/g, "T");
  }


  getUserFormValidationMessage(el: any) {
    if (el.errors.required) {
      return 'Este campo é obrigatório';
    }

    if (el.errors.incorrect || el.errors.pattern) {
      return 'O valor deste campo é inválido';
    }
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  checkExpire(student: any, mode: string = 'date') {
    if (!student.premium_expires_date) return null;
    const expires = new Date(student.premium_expires_date.replace(' ', 'T')).getTime();
    const now = Date.now();

    let distance: any = expires - now;

    if (distance < 0) {
      // return false;
      return true;
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    // const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    // const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    distance = '';

    if (mode === 'date') {
      if (days > 0 && days < 2) distance += days + ' dia'; // + minutes + "m " + seconds + "s ";
      if (days > 1) distance += days + ' dias';

      if (days > 0 && hours > 0) distance += ' e ';

      if (hours > 0 && hours < 2) distance += hours + ' h'; // hora
      if (hours > 1) distance += hours + ' h'; // horas
    }

    if (mode === '%') {
      distance = (((days * 24) + hours) / (30 * 24)) * 100;
      if (days > 30) {
        distance = 100;
      }
    }

    // return distance;
    return false;
  }

  toggleClass(item: any) {
    item.active = !item.active;
  }

  returnDaysLeft(deleted_at: any) {
    if (!deleted_at) return null;
    const deleted = new Date(deleted_at.replace(' ', 'T')).getTime();
    const now = Date.now();
    const distance: any = deleted - now;
    const days_left = Math.floor(distance / (1000 * 60 * 60 * 24)) + 7; // 21 is the base days to perma delete 

    return (days_left > 0 && days_left < 2) ? days_left + ' dia' : days_left + ' dias';
  }
  

  setAll(checked: boolean) {
    this.users = this.dataSourceClient.usersList$;
    this.users = this.users.source._value;

    if (this.checkedList == null) return;
    if (!checked) this.checkAllIndeterminate = false;
    let count: number = 0;
    this.selectedListItem = [];
    for (let i = 0; i < this.users.length; i++) {
      this.checkedList[i] = checked;
      if (checked) this.selectedListItem.push(this.users[i]);
      count++;
    }
    this.total_selected = (checked) ? count : 0;
  }
  changeCheck(checked: boolean, student: any = null) {
    this.total_selected += (checked) ? + 1 : - 1;
    if (checked) {
      this.selectedListItem.push(student);
    } else {
      for (let i = 0; i < this.selectedListItem.length; i++) {
        if (this.selectedListItem[i].id === student.id) {
          this.selectedListItem.splice(i, 1);
        }
      }
    }
  }
  updateAllComplete() {
    let leastOne: boolean = false
    for (let i = 0; i < this.users.length; i++) {
      if (this.checkedList[i]) {
        this.allComplete = ((i + 1) === this.users.length && !leastOne) ? false : true;
        leastOne = true;
      } else {
        this.allComplete = false;
        if (leastOne) break;
      }
    }
    if (!this.allComplete && leastOne) this.checkAllIndeterminate = true;
    if (this.allComplete) this.checkAllIndeterminate = false;
    if (!leastOne) this.checkAllIndeterminate = false;
  }

  deleteSelected() {
    if (this.selectedListItem.length < 1) {
      this.loading = false;
      this.toastr.error('Nenhum '+this.customerLegend+' foi selecionado', '', this.helpers.toastrCfgs);
      return false;
    }

    // console.log(student);
    let msg = '';
    if (this.total_selected === this.users.length) {
      msg += '<p class="danger">ATENÇÃO!<br> Você selecionou TODOS os seus '+this.customerLegend+'s!</p>'
    }
    if (this.total_selected > 5 && this.total_selected < this.users.length) {
      msg += '<p class="danger">ATENÇÃO!<br> Você selecionou mais de 5 '+this.customerLegend+'s!</p>'
    }
    msg += '<p>Deseja realmente apagar os '+this.customerLegend+'s selecionados? Todos os progressos e atividades deles serão apagados!</p>';
    const dialogRef = this.dialog.open(DialogInputCustomComponent, {
      width: '360px',
      data: {title: 'Apagar '+this.customerLegend+'s', placeholder: 'escreva DELETAR, em caixa alta', inner_msg: msg, confirmation: 'sim'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'DELETAR') {
        this.loading = true;
        this.userProv.admUserMassDelete(this.selectedListItem).then(
          (_res: any) => {
            this.loading = false;
            this.setAll(false);
            this.allComplete = false;
            this.checkAllIndeterminate = false;
            this.selectedListItem = [];
            this.checkedList = [];
            this.toastr.success('Os '+this.customerLegend+'s foram apagados com sucesso!', '', this.helpers.toastrCfgs);
            this.dataSourceClient.loadUsers(0, '', this.accountType, this.sortClient.active, "desc", this.paginatorClient.pageIndex, this.paginatorClient.pageSize);
            if (this.ismobile || this.istablet) this.backToList();
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(err, '', this.helpers.toastrCfgs);
            this.loading = false;
            if (this.ismobile || this.istablet) this.backToList();
          });
      } else {
        let opts = this.helpers.toastrCfgs; opts.timeOut = 5000;
        this.toastr.info('A ação foi cancelada. Verifique se digitou corretamente o texto de confirmação.', '', opts);
      }
    });
  }

}
