import { environment } from '../../environments/environment';

let API = (environment.API_ENV_URLS).replace('/api', '/');
// let API = (environment.API_ENV_URLS);

export const banners = {
  std: [
    {url: API + 'img/standard/1.female_01.jpg'},
    {url: API + 'img/standard/1.female_02.jpg'},
    {url: API + 'img/standard/1.female_03.jpg'},
    {url: API + 'img/standard/1.female_04.jpg'},
    {url: API + 'img/standard/1.female_05.jpg'},
    {url: API + 'img/standard/1.female_06.jpg'},
    {url: API + 'img/standard/1.female_07.jpg'},
    {url: API + 'img/standard/1.female_08.jpg'},
    {url: API + 'img/standard/1.female_09.jpg'},
    {url: API + 'img/standard/1.female_10.jpg'},
    {url: API + 'img/standard/1.female_11.jpg'},
    {url: API + 'img/standard/1.female_12.jpg'},
    {url: API + 'img/standard/1.female_13.jpg'},
    {url: API + 'img/standard/1.female_14.jpg'},
    {url: API + 'img/standard/1.female_15.jpg'},
    {url: API + 'img/standard/1.female_16.jpg'},
    {url: API + 'img/standard/1.female_17.jpg'},
    {url: API + 'img/standard/1.female_18.jpg'},
    {url: API + 'img/standard/1.female_19.jpg'},
    {url: API + 'img/standard/1.female_20.jpg'},
    {url: API + 'img/standard/1.female_21.jpg'},
    {url: API + 'img/standard/1.female_22.jpg'},
    {url: API + 'img/standard/1.female_23.jpg'},
    {url: API + 'img/standard/1.female_24.jpg'},
    {url: API + 'img/standard/1.female_25.jpg'},
    {url: API + 'img/standard/1.female_26.jpg'},
    {url: API + 'img/standard/1.female_27.jpg'},

    {url: API + 'img/standard/2.Male_01.jpg'},
    {url: API + 'img/standard/2.Male_02.jpg'},
    {url: API + 'img/standard/2.Male_03.jpg'},
    {url: API + 'img/standard/2.Male_04.jpg'},
    {url: API + 'img/standard/2.Male_05.jpg'},
    {url: API + 'img/standard/2.Male_06.jpg'},
    {url: API + 'img/standard/2.Male_07.jpg'},
    {url: API + 'img/standard/2.Male_08.jpg'},
    {url: API + 'img/standard/2.Male_09.jpg'},
    {url: API + 'img/standard/2.Male_10.jpg'},
    {url: API + 'img/standard/2.Male_11.jpg'},
    {url: API + 'img/standard/2.Male_12.jpg'},
    {url: API + 'img/standard/2.Male_13.jpg'},
    {url: API + 'img/standard/2.Male_14.jpg'},
    {url: API + 'img/standard/2.Male_15.jpg'},
    {url: API + 'img/standard/2.Male_16.jpg'},
    {url: API + 'img/standard/2.Male_17.jpg'},
    {url: API + 'img/standard/2.Male_18.jpg'},
    {url: API + 'img/standard/2.Male_19.jpg'},
    {url: API + 'img/standard/2.Male_20.jpg'},
    {url: API + 'img/standard/2.Male_21.jpg'},
    {url: API + 'img/standard/2.Male_22.jpg'},
    {url: API + 'img/standard/2.Male_23.jpg'},
    {url: API + 'img/standard/2.Male_24.jpg'},
    {url: API + 'img/standard/2.Male_25.jpg'},
    {url: API + 'img/standard/2.Male_26.jpg'},
    {url: API + 'img/standard/2.Male_27.jpg'},
    {url: API + 'img/standard/2.Male_28.jpg'},
    {url: API + 'img/standard/2.Male_29.jpg'},
    
    {url: API + 'img/standard/FORCA.jpg'},
    {url: API + 'img/standard/Banner0.jpg'},
    {url: API + 'img/standard/Banner1.jpg'},
    {url: API + 'img/standard/Banner2.jpg'},
    {url: API + 'img/standard/Banner3.jpg'},
    {url: API + 'img/standard/Banner4.jpg'},
    {url: API + 'img/standard/Banner5.jpg'},
    {url: API + 'img/standard/Banner8.jpg'},
    {url: API + 'img/standard/Banner10.jpg'},
    {url: API + 'img/standard/Banner11.jpg'},
    {url: API + 'img/standard/Banner16.jpg'},
    {url: API + 'img/standard/Banner18.jpg'},
    {url: API + 'img/standard/FUNCIONAL.jpg'},
    {url: API + 'img/standard/ADAP-FEMALE.jpg'},
    {url: API + 'img/standard/ADAP-MALE.jpg'},
    {url: API + 'img/standard/CARDIO-FEMALE.jpg'},
    {url: API + 'img/standard/CARDIO-MALE.jpg'},
    {url: API + 'img/standard/DEFINICAO-FEMALE.jpg'},
    {url: API + 'img/standard/DEFINICAO-MALE.jpg'},
    {url: API + 'img/standard/HIPERTROFIA-FEMALE.jpg'},
    {url: API + 'img/standard/HIPERTROFIA-MALE.jpg'},
    {url: API + 'img/standard/AEROBIOS-FEMALE.jpg'},
    {url: API + 'img/standard/HOME-WORKOUT1-FEMALE.jpg'},
    {url: API + 'img/standard/ALONGAMENTOS-MALE3.jpg'},
    {url: API + 'img/standard/DEFINICAO-FEMALE-FASE1.jpg'},
    {url: API + 'img/standard/DEFINICAO-FEMALE-FASE2.jpg'},
    {url: API + 'img/standard/DEFINICAO-FEMALE-FASE3.jpg'},
    {url: API + 'img/standard/DEFINICAO-FEMALE-FASE4.jpg'},
    {url: API + 'img/standard/DEFINICAO-FEMALE-FASE5.jpg'},
    {url: API + 'img/standard/DEFINICAO-FEMALE-PROGRAMA-GERAL.jpg'},
    {url: API + 'img/standard/FUNCIONAL-FASE1-LARANJA.jpg'},
    {url: API + 'img/standard/FUNCIONAL-FASE2-RED.jpg'},
    {url: API + 'img/standard/FUNCIONAL-FASE3-LIME.jpg'},
    {url: API + 'img/standard/FUNCIONAL-FASE4-BLUE.jpg'},
    {url: API + 'img/standard/FUNCIONAL-FASE5-BLACK.jpg'},
    {url: API + 'img/standard/FUNCIONAL-TREINO-GERAL-BLACK.jpg'},
    {url: API + 'img/standard/THOR-HIPERTROFIA-BLACK.jpg'},
    {url: API + 'img/standard/GESTANTES-FASE1.jpg'},
    {url: API + 'img/standard/GESTANTES-FASE2.jpg'},
    {url: API + 'img/standard/GESTANTES-FASE3.jpg'},
    {url: API + 'img/standard/TREINE-EM-CASA-FEMALE2.jpg'},
    {url: API + 'img/standard/TREINE-EM-CASA-FEMALE3.jpg'},
    {url: API + 'img/standard/TREINOS-EM-CASA-MALE2.jpg'},
  ],

  free: [
    {url: API + 'img/free/ADAPTACAO.jpg'},
    {url: API + 'img/free/AEROBIOS.jpg'},
    {url: API + 'img/free/ANTIAGING.jpg'},
    {url: API + 'img/free/BASICO.jpg'},
    {url: API + 'img/free/COMPETICAO.jpg'},
    {url: API + 'img/free/DEFINICAO.jpg'},
    {url: API + 'img/free/EMAGRECER.jpg'},
    {url: API + 'img/free/FORCA.jpg'},
    {url: API + 'img/free/FORTALECER.jpg'},
    {url: API + 'img/free/FULLBODY.jpg'},
    {url: API + 'img/free/FUNCIONAL.jpg'},
    {url: API + 'img/free/HIPERTROFIA.jpg'},
    {url: API + 'img/free/HOME-VIDEO-AULAS.jpg'},
    {url: API + 'img/free/HOME-WORKOUT.jpg'},
    {url: API + 'img/free/MANUTENCAO.jpg'},
    {url: API + 'img/free/REGENERACAO.jpg'},
    {url: API + 'img/free/RETORNO.jpg'},
  ]
}
