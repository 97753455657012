import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'lodash';

import { environment } from '../../environments/environment';


@Injectable()
export class ApiProvider {

  protected headers: any;

  // API_ENV_URLS = {
  //   prod: 'https://supertreinosapp.com/api',
  //   homolog: 'http://142.93.235.119/api',
  //   local: 'http://supertreinos.loc/api'
  // };
  // API_URL = this.API_ENV_URLS.local;
  // login: piffardini@hotmail.com
  // pwd: $2y$10$3RaL/9J5kCRM9.0VBu/4Gu9N.dPhVX7.FiOGddVdAtWUq2sqzQDVq
  // pwdnew: $2y$10$/GXgl3IFi/GWBqCWVCw/OOYwQjN8/fotJBAwOv77vMdG6EQCpUr.K

  API_URL = environment.API_ENV_URLS;

  constructor(
    public http: HttpClient
  ) {
    this.headers = new HttpHeaders();
  }


  setToken(token: any) {
    if (!token) { return; }
    this.headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
  }

  getOptions(options: any) {
    this.setToken(localStorage.getItem('LoggedInUser'));

    let params = new HttpParams();
    map(options.params, (value: any, key: any) => params = params.append(key, value));
    return {
      headers: this.headers,
      params
    };
  }

  get(path: any, options: Options = {}) {
    return this.http.get(`${this.API_URL}/${path}`, this.getOptions(options)).toPromise();
  }

  post(path: any, data = {}, options: Options = { headers: null }) {
    return this.http.post(`${this.API_URL}/${path}`, data, this.getOptions(options)).toPromise();
  }

  put(path: any, data = {}, options: Options = { headers: null }) {
    return this.http.put(`${this.API_URL}/${path}`, data, this.getOptions(options)).toPromise();
  }

  delete(path: any, options: Options = { headers: null }) {
    return this.http.delete(`${this.API_URL}/${path}`, this.getOptions(options)).toPromise();
  }

  download(path: any, options: Options = {}) {
    this.headers.append('responseType', 'arraybuffer');
    return this.http.get(`${this.API_URL}/${path}`, this.getOptions(options)).toPromise();
  }

}


interface Options {
  headers?: HttpHeaders;
  params?: object;
}
