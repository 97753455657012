import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { DialogComponent } from '../../../ui/dialog/dialog';
import { DialogLightComponent } from '../../../ui/dialog-light/dialog-light';

import { CustomerProvider } from '../../../providers/customer/customer';
import { ProgramProvider } from '../../../providers/program/program';
import { GoalProvider } from '../../../providers/goal/goal';
import { AuthService } from '../../../providers/auth/auth.service';
import { UserProvider } from '../../../providers/user/user';
import { ApiProvider } from '../../../providers/api/api';
import { HelpersProvider } from '../../../providers/helpers/helpers';

@Component({
  selector: 'app-alunos-espera',
  templateUrl: './waitline.component.html',
  styleUrls: ['./waitline.component.scss']
})
export class AlunosWaitlineComponent implements OnInit {
  @ViewChild(DropzoneComponent, {static: false}) componentRef?: DropzoneComponent;
  protected headers: any;
  teacherCode: string = '';

  public avatar: DropzoneConfigInterface = {
    paramName: 'file',
    thumbnailMethod: 'contain',
    thumbnailHeight: null,
    thumbnailWidth: null,
    addRemoveLinks: true,
    dictRemoveFile: 'REMOVER IMAGEM',
    dictCancelUpload: 'CANCELAR ENVIO',
    dictCancelUploadConfirmation: 'CONFIRMA CANCELAR ENVIO?',
    dictInvalidFileType: 'ARQUIVO INVÁLIDO',
    maxFiles: 1,
  };

  loading: boolean = false;
  expired: boolean = false;
  userPremiumExpires: any;
  showForm: boolean = false;
  showHistory: boolean = false;
  students: any = [];
  totalStudents: number = 0;
  waitline: any = [];
  userBirthdate: any;
  userGoalId: number;
  goals: any = [];
  programs: any = [];
  totalPrograms: number = 0;
  history: any [];
  user: any = [];
  route_id: string = '0';

  studentSelected: boolean = false;
  studentId: number = 0;
  studentAvatar: string = '';
  studentForm = new FormGroup({
    id: new FormControl('', [Validators.required]),
    status: new FormControl(''),
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    goal_id: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    password_confirmation: new FormControl('', [Validators.required]),
    details: new FormGroup({
      phone: new FormControl(),
      birthdate: new FormControl(),
      gender: new FormControl(),
      height: new FormControl(),
      weight: new FormControl(),
    }),
  });

  totalItens1 = { count: 0 };
  maxItens1: number = 12;
  searchStudent: any = {
    name: <string> '',
    email: <string> '',
  };

  totalItens2 = { count: 0 };
  maxItens2: number = 12;
  searchProgram: any = {
    name: <string> '',
    premium: <any> '',
    published: <any> '',
    workout_muscle_ids: <any> [],
    workout_equip_ids: <any> [],
    workout_method_ids: <any> [],
  };


  account: {
    purchase: {
      supertreinos_plan_id: 0,
    },
    students: {
      total: 0,
      max: 0,
      inactives: 0,
    },
    programs: {
      total: 0,
      max: 0,
    },
    teacher : {
      link: any,
    }
  }


  constructor(
    public auth: AuthService,
    public student: CustomerProvider,
    public programProvider: ProgramProvider,
    public toastr: ToastrService,
    public goalProvider: GoalProvider,
    public dialog: MatDialog,
    public userProv: UserProvider,
    private route: ActivatedRoute,
    public api: ApiProvider,
    public helpers: HelpersProvider,
  ) {
    this.user = this.auth.retrieveLocalUser();
    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};
    this.teacherCode = this.route.snapshot.params.code;
  }

  ngOnInit() {
    if (this.teacherCode) {
      this.studentForm.reset();
      this.showForm = true;
      this.studentSelected = false;
      this.studentId = 0;
      this.studentForm.patchValue({ status: 8 });
    } else {
      this.user = this.auth.retrieveLocalUser();
      this.getCustomersWaiting(true);
    }
  }


  // busca a fila de espera (status 8)
  getCustomersWaiting(fullReload: boolean = false) {
    this.loading = true;
    this.student.getCustomersWaiting().then(
      (res: any) => {
        this.waitline = res.customers;
        if (res.customers.length === 0) {
          this.waitline = null;
        }
        this.loading = false;
        if (fullReload) this.getSubscriptions();
      },
      (err: any) => {
        this.toastr.error(err, '', this.helpers.toastrCfgs);
        this.loading = false;
      }
    );
  }


  getSubscriptions() {
    this.loading = true;
    this.studentForm.reset();
    this.studentSelected = false;
    this.studentId = 0;
    this.showForm = false;
    this.userProv.subscriptions().then(
      (p: any) => {
        this.account = p;
        this.helpers.clearState();
        this.account.teacher.link = window.location.href + '?p=' + this.account.teacher.link;
        this.loading = false;
      },
      (err: any) => {
        this.loading = false;
        this.toastr.error(err || 'Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
      }
    );
  }

  checkExpire(student: any, mode: string = 'date') {
    if (!student.premium_expires_date) return null;
    const expires = new Date(student.premium_expires_date.replace(' ', 'T')).getTime();
    const now = Date.now();

    let distance: any = expires - now;

    if (distance < 0) {
      // return false;
      return true;
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    // const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    // const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    distance = '';

    if (mode === 'date') {
      if (days > 0 && days < 2) distance += days + ' dia'; // + minutes + "m " + seconds + "s ";
      if (days > 1) distance += days + ' dias';

      if (days > 0 && hours > 0) distance += ' e ';

      if (hours > 0 && hours < 2) distance += hours + ' h'; // hora
      if (hours > 1) distance += hours + ' h'; // horas
    }

    if (mode === '%') {
      distance = (((days * 24) + hours) / (30 * 24)) * 100;
      if (days > 30) {
        distance = 100;
      }
    }

    // return distance;
    return false;
  }

  callHistory() {
    this.showHistory = !this.showHistory;
    if (this.showHistory) {
      this.loading = true;
      this.student.getHistory(this.studentId).then(
        (res: any) => {
          this.loading = false;
          this.history = res.history;
        },
        (err: any) => {
          this.loading = false;
          this.toastr.error(err, '', this.helpers.toastrCfgs);
        }
      );
    }
  }

  toggleClass(item: any) {
    item.active = !item.active;
  }

  copyTransfer(url: string = '') {
    console.log(url);
    if (this.helpers.copyTextToClipboard(url)) {
      this.toastr.success('O link foi copiado para Área de Transferência', '', this.helpers.toastrCfgs);
    } else {
      this.toastr.error('Seu sistema não permite a cópia automatizada. Copie e cole manualmente.', '', this.helpers.toastrCfgs);
    }
  }


  add(student: any) {
    console.log(student);
    // const msg = 'Deseja realmente adicionar este Aluno do Pré-cadastro?';
    const msg = 'Tem certeza que deseja adicionar este pré-cadastro na sua lista de alunos?';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: 'Aceitar novo aluno', message: msg, confirmation: 'Sim'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Sim') {
        this.loading = true;
        student.status = 9;
        this.student.admCustomerUpdate(student).then(
          (_res: any) => {
            this.loading = false;
            this.toastr.success('O Aluno foi aceito com sucesso!', '', this.helpers.toastrCfgs);
            this.getCustomersWaiting(true);
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(err, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
      }
    });
  }

  refuse(student: any) {
    console.log(student);
    // const msg = 'Deseja realmente adicionar este Aluno do Pré-cadastro?';
    const msg = 'Tem certeza que deseja remover este pré-cadastro da sua lista de espera?';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: 'Rejeitar novo aluno', message: msg, confirmation: 'Sim'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Sim') {
        this.loading = true;
        this.studentForm.reset();
        this.showForm = false;
        this.student.admCustomerDelete(student).then(
          (_res: any) => {
            this.loading = false;
            this.toastr.success('O Aluno foi recusado com sucesso!', '', this.helpers.toastrCfgs);
            this.getCustomersWaiting();
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(err, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
      }
    });
  }

  saveGreeting() {
    const msg = 'Tem certeza que deseja alterar a saudação da sua lista de espera?';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: 'Alterar saudação', message: msg, confirmation: 'Sim'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Sim') {
        this.loading = true;
        this.userProv.updateUser(this.user).then(
          (_res: any) => {
            this.loading = false;
            this.toastr.success('Sua saudação foi atualizada com sucesso!', '', this.helpers.toastrCfgs);
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(err, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
      }
    });
  }

  helpGreeting() {
    let msg = '';
    msg += '<p>Frase de saudação após o aluno abrir o link de pré-cadastro.</p>';
    msg += '<p>Capriche na frase ;)</p>';

    const dialogRef = this.dialog.open(DialogLightComponent, { width: '360px', data: { message: msg } });
    dialogRef.afterClosed().subscribe(_result => {});
  }

  helpWaitline() {
    let msg = '';
    msg += '<p>Para obter alunos na lista, divulgue o link acima em seu site e nas suas redes sociais.</p>';
    msg += '<p>Os alunos pré-cadastrados não somarão como alunos efetivos no seu plano, exceto se forem aceitos.</p>';
    msg += '<p>Procure aceitar possíveis alunos para um período grátis e aumentar suas chances de conversão.</p>';

    const dialogRef = this.dialog.open(DialogLightComponent, { width: '360px', data: { message: msg } });
    dialogRef.afterClosed().subscribe(_result => {});
  }

  helpSendPrograms() {
    let msg = '';
    msg += '<p>Primeiro escolha o aluno que deseja editar. Assim a Lista de Programas e a guia Formulário ficarão disponíveis.</p>';
    msg += '<p>Depois selecione os programas que deseja enviar para o cliente clicando na chave “oculto/selecionado”.</p>';
    msg += '<p>O programa será enviado imediatamente.</p>';
    msg += '<p>Envie quantos programas precisar.</p>';
    msg += '<p>Se quiser ver a Lista de Espera novamente, clique no botão VOLTAR da guia Formulário.</p>';

    const dialogRef = this.dialog.open(DialogLightComponent, { width: '360px', data: { message: msg } });
    dialogRef.afterClosed().subscribe(_result => {});
  }

  clearText() {
     this.user.greeting = this.user.greeting.replace( /[^a-zA-Zà-úÀ-Ú0-9!?.,;* ]/gm, '');
  }

}
