import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { AuthService } from '../../providers/auth/auth.service';
import { ProgramProvider } from '../../providers/program/program';
import { TrainingProvider } from '../../providers/training/training';
import { UserProvider } from '../../providers/user/user';
import { CustomerProvider } from '../../providers/customer/customer';
import { HelpersProvider } from '../../providers/helpers/helpers';
import { I18nProvider } from '../../providers/i18n/i18n';

import { DialogTutorialComponent } from '../../ui/dialog-tutorial/dialog-tutorial';

import { environment } from '../../environments/environment';
import packageJson from '../../../package.json';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  PAGE: any;
  STRINGS: any;

  user: any = {};
  user_plan: any = null;
  programs: any = {};
  menus: any = {};
  ismobile: boolean = false;
  ENV = environment;
  public version: string = packageJson.version;

  constructor(
    public auth: AuthService,
    public programProv: ProgramProvider,
    public trainingProv: TrainingProvider,
    public userProv: UserProvider,
    public customerProv: CustomerProvider,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private helpers: HelpersProvider,
    private device: DeviceDetectorService,
    private route: Router,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('DashboardPage');

    //
  }

  ngOnInit() {
    console.log('ngOnInit dashboard page');
    this.ismobile = this.device.isMobile();
    this.user = this.auth.retrieveLocalUser();

    if (this.user.cpf === '' || this.user.cpf === null) {
      if (this.user.id === 1 || this.user.nobr === 1 || this.STRINGS.lang !== 'br') {
        //
      } else {
        let msg = 'Para garantir a segurança e a conformidade do sistema ST Coach, é essencial que todos os professores completem o cadastro com seu CPF. Acesse MINHA CONTA > PERFIL. ';
        let cfg = { timeOut: 175000, positionClass: 'toast-bottom-left', toastClass: 'ngx-toastr full-toastr', disableTimeOut: true, closeButton: true }
        this.toastr.warning(msg, '', cfg);
      }
    }

    if (this.user.account_type === 'teacher') {
      if (this.user.subs !== undefined) {
        if (this.user.subs.supertreinos_plan_title) {
          this.user_plan = this.user.subs.supertreinos_plan_title;
          this.user_plan = this.user_plan.split(' ')[3];
        }
      }
      this.getTrainingsTags();
      this.getPrograms();
      this.getSubscriptions();
      this.getSubsAndCancels();
      this.callFirstTimeModal();
      return true;
    }

    if (this.user.account_type === 'nutri') {
      if (this.user.subs !== undefined) {
        if (this.user.subs.supertreinos_plan_title) {
          this.user_plan = this.user.subs.supertreinos_plan_title;
          this.user_plan = this.user_plan.split(' ')[2];
        }
      }
      this.getSubscriptions();
      this.callFirstTimeModalNutri();
      return true;
    }    

    if (this.user.account_type === 'master' || this.user.account_type === 'admin') {
      this.getTrainingsTags();
      this.getPrograms();
      return true;
    }

    this.route.navigate(['perfil/'], { skipLocationChange: true });
  }

  callFirstTimeModal() {
    // se o usuario ja tiver visto ou ter a conta criada ha mais de uma semana
    const now7 = new Date(Date.now() - (7*24*60*60*1000));
    const userRegister = new Date(this.user.created_at);
    const modalTutorial = this.helpers.getLocallyStored('modalTutorial');
    const forceTutorial = false;

    if (!modalTutorial || forceTutorial) {
    // if (true) {
      this.helpers.storeLocally('modalTutorial', true, 0);
      if ((userRegister > now7) || forceTutorial) {
        const title = 'Bem vind' + (this.user.gender === 'f' ? 'a' : 'o') + ', ' + (this.user.name.split(' ')[0] || 'Fulano') + '!';
        const msg = 'Tutoriais rápidos e direto ao ponto';
        const dialogRef = this.dialog.open(DialogTutorialComponent, {
          width: '640px',
          data: {title: title, message: msg, confirmation: 'Ver Tutoriais'}
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result === 'Sim') {
            // this.cancelSubs();
          }
        });
      }
    }
  }

  callFirstTimeModalNutri() {
    // se o usuario ja tiver visto ou ter a conta criada ha mais de uma semana
    // const now7 = new Date(Date.now() - (7*24*60*60*1000));
    // const userRegister = new Date(this.user.created_at);
    // const modalTutorial = this.helpers.getLocallyStored('modalTutorial');
    // const forceTutorial = false;

    // if (!modalTutorial || forceTutorial) {
    // // if (true) {
    //   this.helpers.storeLocally('modalTutorial', true, 0);
    //   if ((userRegister > now7) || forceTutorial) {
    //     const title = 'Bem vind' + (this.user.gender === 'f' ? 'a' : 'o') + ', ' + (this.user.name.split(' ')[0] || 'Fulano') + '!';
    //     const msg = 'Tutoriais rápidos e direto ao ponto';
    //     const dialogRef = this.dialog.open(DialogTutorialComponent, {
    //       width: '640px',
    //       data: {title: title, message: msg, confirmation: 'Ver Tutoriais'}
    //     });

    //     dialogRef.afterClosed().subscribe(result => {
    //       if (result === 'Sim') {
    //         // this.cancelSubs();
    //       }
    //     });
    //   }
    // }
  }  

  getSubscriptions() {
    this.userProv.subscriptions().then(
      (p: any) => {
        this.user = this.auth.retrieveLocalUser();
        this.user.subs = p;
        // this.user_plan = this.user.subs.supertreinos_plan_title;
        this.user_plan = p.purchase.supertreinos_plan_title;

        if (this.user.account_type === 'teacher') this.user_plan = (this.user_plan === undefined) ? '' : this.user_plan.split(' ')[3];
        if (this.user.account_type === 'nutri') this.user_plan = (this.user_plan === undefined) ? '' : this.user_plan.split(' ')[2];

      },
      (err: any) => {
        this.toastr.error(err || 'Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
      }
    );
  }


  getPrograms() {
    this.programProv.programs().then(
      (res: any) => {
        // console.log('carregou');
        this.programs = res.programs;
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  getTrainingsTags() {
    this.trainingProv.tags().then(
      (res: any) => {
        this.helpers.storeLocally('trainingTags', res.tags, 0);
      },
      (err: any) => {
        console.log(err);
      }
    );    
  }


  getSubsAndCancels() {
    this.customerProv.admSubsAndCancels().then(
      (res: any) => {
        // this.processData(res);
        // this.total = res.totalSC;
        // console.log(res);
      },
      (err: any) => {
        this.toastr.error(err, '', this.helpers.toastrCfgs);
      }
    );
  }

}
