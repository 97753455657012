import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';


import { AuthService } from '../../providers/auth/auth.service';
import { HelpersProvider } from '../../providers/helpers/helpers';
import { WorkoutProvider } from '../../providers/workout/workout';
import { UserProvider } from '../../providers/user/user';

import { environment } from '../../environments/environment';
import { I18nProvider } from '../../providers/i18n/i18n';

import jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';


@Component({
  selector: 'dialog-export-pdf',
  templateUrl: 'dialog-export-pdf.html',
})

export class DialogExportPDFComponent {
  constructor(
    public auth: AuthService,
    public toastr: ToastrService,
    public workoutProvider: WorkoutProvider,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogExportPDFComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = {user: null, valuation: null},
    public helpers: HelpersProvider,
    public domSanitizer: DomSanitizer,
    public userProvider: UserProvider,
    public router: Router,    
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('TreinosPage');
  }

  STRINGS: any;
  PAGE: any;

  protected API = (environment.API_ENV_URLS).replace('/api', '/');

  loading: boolean = false;
  program: any;
  all_trainings: any;
  selected_trainings: any;
  workouts: any;
  user: any;
  account: any;

  @ViewChild('pdfTable') pdfTable: ElementRef;
  userLogo: any = 'assets/imgs/mydietpro/logo-topo-mydietpro.png';
  userLogoPDF: any = 'assets/imgs/mydietpro/logo-topo-mydietpro-roxo.png';
  userLogoPDFB64: any = '';
  backgroundLogo: '#4E4393';


  async ngOnInit() {
    this.program = this.data.program;
    this.selected_trainings = this.data.selected_trainings;
    this.all_trainings = this.data.all_trainings;
    this.account = this.data.account;
    this.user = this.auth.retrieveLocalUser();
    
    if (this.account.purchase.supertreinos_plan_id === 4 || this.account.purchase.supertreinos_plan_id === '4') {
      this.loading = true;
      this.getLogo();
      await this.helpers.getBase64ImageFromUrl(this.userLogoPDF).then((result: any) => { this.userLogoPDFB64 = result; }).catch(err => console.error(err));
      if (this.program === null) {
        // this.workouts = this.getWorkouts(this.selected_trainings[0].id);
        this.getWorkouts(this.selected_trainings[0].id);
      } else {
        this.selected_trainings = this.all_trainings;
        this.loading = false;
      }
    }

    // console.log(this.program);
    // console.log(this.selected_trainings);
  }

  getWorkouts(tid: any) {
    if (tid === 0 || tid === undefined) {
      return;
    }
    this.workouts = [];

    this.workoutProvider.workouts(tid).then(
      (res: any) => {
        this.selected_trainings[0].workouts = res.workouts;
        // this.workouts = res.workouts;
        // this.total_workouts = res.total_workouts_not_merged;
        this.loading = false;
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
      });    
  }

  export() {
    // console.log(this.allMeals);
    const doc = new jsPDF();
    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML, {
      tableAutoSize: true,
      defaultStyles:{
        th:{
          fillColor:'#EEE',
        },
        thTransparent: {
          fillColor:'#FFF',
        }
      }      
    });
    let textoMarcaDagua = '';
    // disabling watermark
    // if (this.user.account_type === 'teacher' && (this.account.purchase.supertreinos_plan_id !== '3' && this.account.purchase.supertreinos_plan_id !== '4' && this.account.purchase.supertreinos_plan_id !== 3 && this.account.purchase.supertreinos_plan_id !== 4)) textoMarcaDagua = 'FUNÇÃO EXCLUSIVA PARA ASSINANTES PRO';
    // if (this.user.account_type === 'teacher' && (this.account.purchase.supertreinos_plan_id === '0' || this.account.purchase.supertreinos_plan_id === 0)) textoMarcaDagua = 'STCOACH EM MODO GRATUITO';
    const documentDefinition = { 
      watermark: { text: textoMarcaDagua, opacity: 0.25, bold: true },
      content: html,
    };
    pdfMake.createPdf(documentDefinition).open();         
  }

  goToSubscribe() {
    this.userProvider.goToSubsPage(this.router);
  }

  getLogo() {
    this.user = this.auth.retrieveLocalUser();

    if (this.user.account_type === 'teacher') {
      this.userLogo = 'assets/imgs/logo-topo-stcoach.png';
      this.userLogoPDF = 'assets/imgs/logo-stcoach-pdf.png';
    }
   
    if (this.user.subs === undefined) return false;

    if (this.user.account_type === 'teacher') {
      if (this.user.subs.supertreinos_plan_id && (
        this.user.subs.supertreinos_plan_id === '3' ||
        this.user.subs.supertreinos_plan_id === '4' ||
        this.user.subs.supertreinos_plan_id === 3 ||
        this.user.subs.supertreinos_plan_id === 4 ))
      {
        if (this.user.logo !== null && this.user.logo !== '') this.userLogo = this.user.logo;
        if (this.user.logo_pdf !== null && this.user.logo_pdf !== '') this.userLogoPDF = this.user.logo_pdf;
      }
    }

    return true;
  }  
}
