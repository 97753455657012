import { Component, EventEmitter, OnInit, OnChanges, Output, Input, ViewChild } from '@angular/core';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { MatDialog } from '@angular/material/dialog';

import { ToastrService } from 'ngx-toastr';

import { DialogComponent } from '../../ui/dialog/dialog';
import { DialogLightComponent } from '../../ui/dialog-light/dialog-light';

import { WorkoutProvider } from '../../providers/workout/workout';
import { GoalProvider } from '../../providers/goal/goal';
import { ApiProvider } from '../../providers/api/api';
import { LibraryProvider } from '../../providers/library/library';
import { HelpersProvider } from '../../providers/helpers/helpers';
import { I18nProvider } from '../../providers/i18n/i18n';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-block-workouts',
  templateUrl: 'workouts.html',
})
export class WorkoutsComponent implements OnInit, OnChanges {
  @Input() training_id: any;
  @Input() cloned_library: any = null;
  @Input() opened_library: boolean = false;
  @Input() workout_id = 0;
  @Input() ismobile: boolean = false;
  @Input() istablet: boolean = false;
  @Input() reset: boolean = false;
  @ViewChild('dzWorkoutCover', {static: false}) dzWorkoutCover?: DropzoneComponent;
  @ViewChild('workoutForm', {static: false}) workoutForm: HTMLFormElement;
  @ViewChild('checkAll') checkAll: HTMLFormElement;
  @Output() setWorkoutId = new EventEmitter();
  @Output() setOpenLibrary = new EventEmitter();

  STRINGS: any;
  PAGE: any;

  protected limit: number = 25;
  protected headers: any;
  protected API = (environment.API_ENV_URLS).replace('/api', '/');

  @Input() workout: any = {
    id: <number> 0,
    cover_url: <string> null,
    cover_path: <string> null,
    video_url: <string> 'https://player.vimeo.com/video/',
    name: <string> null,
    description: <string> null,
    series_repetitions: <string> null,
    weight_suggestion: <string> null,
    intervals: <number> 0,
    sort: <number> 0,
    training_id: <number> 0,
    translations: <string> '',
  };

  user: any = {};
  workouts: any = [];
  total_workouts = 0;
  total_workouts_without_series = 0;
  selected_workout = 0;
  sanitized_video_url: any;
  library: any = [];

  loading: boolean;
  loadingLibrary: boolean;
  call_form = 0;
  showSerieForm: boolean = false;
  serieWorkout: any;

  serieForm = new FormGroup({
    id: new FormControl('0'),
    name: new FormControl('', [Validators.required]),
    training_id: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    color: new FormControl('#707070', [Validators.required]),
    selected: new FormControl('0', [Validators.required]),
    translations: new FormControl(''),
  });

  workoutName = new FormControl('', [Validators.required]);
  workoutVideoUrl = new FormControl('', [Validators.required]);

  public config: DropzoneConfigInterface;

  checkAllComplete: boolean = false;
  checkAllIndeterminate: boolean = false;
  total_selected = 0;
  selected_workouts = [];

  cropCheckInterval: any;

  constructor(
    public toastr: ToastrService,
    public workoutProvider: WorkoutProvider,
    public goalProvider: GoalProvider,
    public api: ApiProvider,
    public helpers: HelpersProvider,
    public dialog: MatDialog,
    public libraryProvider: LibraryProvider,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('ExerciciosPage');

    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};
    this.user = JSON.parse(localStorage.getItem('user'));
    // console.log('constructor:');

    this.config = {
      paramName: 'cover_img',
      thumbnailMethod: 'contain',
      thumbnailHeight: null,
      thumbnailWidth: null,
      addRemoveLinks: true,
      dictRemoveFile: this.STRINGS.dz_remover_imagem,
      dictCancelUpload: this.STRINGS.dz_cancelar_envio,
      dictCancelUploadConfirmation: this.STRINGS.dz_confirma_cancelar_envio,
      dictInvalidFileType: this.STRINGS.dz_arquivo_invalido,
      maxFiles: 1,
      transformFile: (file, done) => {
        this.cropCheckInterval = setInterval(() => { if (this.helpers.getLocallyStored('cropOngoing') === false) this.resetDropzoneUploads() }, 250);
        this.helpers.cropImage(file, done, 'dropzone-workout-cover', 300, 300);
      }
      // autoProcessQueue: false,
    };
  }

  getRequiredMessage(el: any) {
    if (el.hasError('required')) {
      return this.STRINGS.campo_obrigatorio;
    }

    if (el.hasError('incorrect')) {
      return this.STRINGS.campo_invalido;
    }
  }

  ngOnInit() {
    this.loading = false;
    this.showSerieForm = false;
    this.serieForm.reset();
    this.user = JSON.parse(localStorage.getItem('user'));
    // this.getWorkouts(this.training_id);
    this.config.dictDefaultMessage = ((this.ismobile  || this.istablet)
        ? this.PAGE.click_enviar_banner
        : this.PAGE.arraste_banner
    )
  }

  ngOnChanges() {
    this.workout = {};

    if (this.reset === true) {
      setTimeout(() => {
        this.goBack(false);
        return true;
      },25);
    } else {
      if (this.loading === false && this.opened_library === false && this.call_form === 0) {
        this.workouts = [];
        this.selected_workouts = [];
        this.total_selected = 0;
        this.goBack();
        // console.log('loading false');
      }
      if (this.workout_id > 0) {
        this.training_id = 0;
        this.getWorkout(this.workout_id);
        // console.log('wid > 0');
      }
      if (this.workout_id === -1) {
        this.training_id = 0;
        this.selected_workouts = [];
        this.total_selected = 0;
        this.prepareForm();
        // console.log('wid = -1');
      }
      if (this.cloned_library) {
        this.setClonedForm(this.cloned_library);
        // console.log('cloned lib');
        // console.log(this.cloned_library);
      }
      if (this.workout.training_id !== this.training_id && this.selected_workout === 0) {
        this.getWorkouts(this.training_id);
      }
      if (this.training_id === 0) {
        this.call_form = 0;
        this.selected_workout = 0;
        this.selected_workouts = [];
        this.total_selected = 0;
        // console.log('reset data');
      }
    }
  }


  goBack(reload: boolean = true) {
    // this.opened_library = false;
    this.closeLibrary();
    this.loading = false;
    this.selected_workout = 0;
    this.total_selected = 0;
    this.selected_workouts = [];
    this.call_form = 0;
    this.workout_id = 0;
    this.workout = this.helpers.resetObject(this.workout);
    this.prepareForm();
    if (reload) this.getWorkouts(this.training_id);
  }

  getWorkouts(tid: any) {
    if (this.training_id === 0 || tid === undefined) {
      return;
    }
    this.workouts = [];

    this.loading = true;
    this.workoutProvider.workouts(tid).then(
      (res: any) => {
        this.workouts = res.workouts;
        this.total_workouts = res.total_workouts_not_merged;
        this.call_form = 0;
        for (let i = 0; i < this.workouts.length; i++) {
          this.selected_workouts[i] = false;
          if (!this.workouts[i].is_serie) {
            this.total_workouts_without_series++;
          }
        }

        this.loading = false;
        if(this.checkAll) {
          this.checkAll.indeterminate = false;
          this.checkAll.checked = false;
        }
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
      });
  }

  selectWorkout(id: any) {
    this.selected_workout = id;
    this.call_form = 1;
  }

  getWorkout(id: any) {
    if (this.training_id === null) {
      if (this.workout_id === 0) {
        this.setWorkoutId.emit(id);
        return;
      }
      id = this.workout_id;
    }

    this.loading = true;

    this.workoutProvider.workout(id).then(
      (res: any) => {
        this.workout = res.workout;
        this.selected_workout = this.workout.id;

        // this.sanitized_video_url = this.helpers.sanitizerVideoURL(this.workout.video_url);
        if (this.workout.video_url === null || this.workout.video_url === '' || this.workout.video_url === 'https://player.vimeo.com/video/') {
          this.workout.video_url = 'https://player.vimeo.com/video/';
          this.sanitized_video_url = '';
        }

        if (this.workout.video_url !== 'https://player.vimeo.com/video/' && this.workout.video_url !== null && this.workout.video_url !== '') {
          this.sanitized_video_url = this.helpers.sanitizerVideoURL(this.workout.video_url);
        }

        if (this.workout.video_url === null) {
          this.workout.video_url = 'https://player.vimeo.com/video/';
        }

        this.config.headers = this.headers;

        this.config.url = this.api.API_URL + '/v2/workouts/' + id;
        this.config.params = {
          '_method': 'patch',
          'name': this.workout.name,
          'video_url': this.workout.video_url,
          'training_id': this.workout.training_id,
        };
        this.call_form = 1;
        this.loading = false;
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
      });
  }

  addWorkout() {
    // console.log('addWorkout:');
    this.selected_workout = -1;
    this.opened_library = false;
    if (this.training_id === null) {
      if (this.workout_id === 0) {
        this.setWorkoutId.emit(-1);
        return;
      }
    }
    this.prepareForm();
  }

  prepareForm() {
    this.call_form = 1;
    this.sanitized_video_url = '';
    this.workout = this.helpers.resetObject(this.workout);
    this.workout.training_id = (this.training_id === null) ? 0 : this.training_id;
    this.workout.cover_url = this.API + 'img/EXERCICIO-BANNER-PADRAO.jpg';
    this.workout.video_url = 'https://player.vimeo.com/video/';
    this.workoutName.reset();
    this.workoutVideoUrl.reset();
    this.workoutVideoUrl.setValue('https://player.vimeo.com/video/');
  }

  saveWorkout() {
    this.closeLibrary();
    this.loading = true;
    let valid = true;
    let el: any;
    this.updateTranslation();

    valid = this.validateForm();

    if (valid === false) {
      this.loading = false;
      el = document.getElementById('iframeWorkoutVideo');
      el.scrollIntoView();
      return false;
    }
    if (this.workout.id > 0) {
      this.updateWorkout();
    }
    if (this.workout.id === undefined || this.workout.id === 0) {
      this.createWorkout();
    }
  }

  updateWorkout() {
    const workoutUploaded = this.workout;
    delete workoutUploaded.cover_path;
    delete workoutUploaded.cover_url;
    workoutUploaded.goal = this.workout.goal;

    this.workoutProvider.patchWorkout(workoutUploaded).then(
      (res: any) => {
        this.workout = res.workout;
        this.toastr.success(this.PAGE.atualizado_sucesso, '', this.helpers.toastrCfgs);
        // this.loading = false;
        this.goBack();
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        // this.loading = false;
        // this.goBack();
      });
  }

  createWorkout() {
    this.workoutProvider.postWorkout(this.workout).then(
      (res: any) => {
        this.workout = res.workout;
        this.toastr.success(this.PAGE.cadastrado_sucesso, '', this.helpers.toastrCfgs);
        this.cloned_library = null;
        this.loading = false;
        this.goBack();
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        this.loading = false;
      });
  }

  deleteWorkout(id = null) {
    this.deleteWorkoutConfirmation(id);
  }

  deleteWorkoutConfirmation(id: any): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.apagar_exercicio_title, message: this.PAGE.apagar_exercicio, confirmation: 'deletar'}
    });

    if (id === null) { id = this.workout.id; }

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed: ' + result);
      if (result === 'deletar') {
        this.loading = true;
        this.workoutProvider.deleteWorkout(id).then(
          (_res: any) => {
            this.goBack();
            this.toastr.success(this.PAGE.apagar_exercicio_sucesso, '', this.helpers.toastrCfgs);
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    // console.log(event.previousIndex);
    // console.log(event.currentIndex);
    moveItemInArray(this.workouts, event.previousIndex, event.currentIndex);
    this.workoutReorder(this.workouts);
  }

  workoutOrderUp(_workout: any, index = 0) {
    // this.workoutOrder(workout, 'up');
    this.loading = true;
    moveItemInArray(this.workouts, index, index-1);
    this.workoutReorder(this.workouts);
  }

  workoutOrderDown(_workout: any, index = 0) {
    // this.workoutOrder(workout, 'down');
    this.loading = true;
    moveItemInArray(this.workouts, index, index+1);
    this.workoutReorder(this.workouts);
  }

  workoutOrder(workout: any, order: any) {
    delete workout.cover_path;
    delete workout.cover_url;
    workout._order = order;

    this.loading = true;
    this.workoutProvider.patchWorkout(workout).then(
      (_res: any) => {
        this.toastr.success(this.PAGE.reordenar_exercicio_sucesso, '', this.helpers.toastrCfgs);
        this.goBack();
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        this.loading = false;
        // this.goBack();
      });
  }

  workoutReorder(workouts: any) {
    let workouts_list = new Array;
    Object.assign(workouts_list, workouts);
    // console.log(workouts_list);

    this.workoutProvider.reorderWorkout(workouts_list.reverse()).then(
      (_res: any) => {
        this.loading = false;
        this.toastr.success(this.PAGE.reordenar_exercicios_sucesso, '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
        this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      });
  }


  // dropzone events
  changeWorkoutName() {
    this.resetDropzoneUploads();
    this.updateDropzoneParams();
  }

  changeWorkoutVideoUrl() {
    this.resetDropzoneUploads();
    this.updateDropzoneParams();
    this.sanitized_video_url = '';
    if (this.workout.video_url !== '' && this.workout.video_url !== null && this.workout.video_url !== 'https://player.vimeo.com/video/') {
      this.sanitized_video_url = this.helpers.sanitizerVideoURL(this.workout.video_url);
      this.workout.video_url = this.workout.video_url.replace('shorts/', 'watch?v=');
    }

  }

  onAddedFile(_args: any) {
    // console.log('adicionando arquivo...');
    let valid = true;
    if (this.workout.name === '') {
      this.loading = false;
      this.resetDropzoneUploads();
      valid = false;
      this.workoutName.markAsTouched();
    }
    if (this.workout.video_url === '') {
      this.loading = false;
      this.resetDropzoneUploads();
      valid = false;
      this.workoutVideoUrl.markAsTouched();
    }
    if (valid === false) {
      return false;
    }
  }

  onSendingFile(_$event: any) {
    this.workout.cover_path = '';
    this.workout.cover_url = false;
  }

  onRemovedFile(_$event: any) {
    //
  }

  onUploadError(_$event: any) {
    console.log('upload error');
    console.log(_$event);
  }

  onUploadSuccess($event: any) {
    this.loading = false;
    // this.workout = $event[1].workout;
    this.workout.id = $event[1].workout.id;
    this.workout.cover_url = $event[1].workout.cover_url;
    this.workout.sort = $event[1].workout.sort;
    this.toastr.success(this.PAGE.banner_upload_sucesso, '', this.helpers.toastrCfgs);

    this.resetDropzoneUploads();
    this.config.url = this.api.API_URL + '/v2/workouts/' + this.workout.id;
    this.config.params = {
      '_method': 'patch',
      'name': this.workout.name,
      'video_url': this.workout.video_url,
      'training_id': this.workout.training_id,
    };
  }

  resetDropzoneUploads(): void {
    this.helpers.deleteLocallyStored('cropOngoing');
    clearInterval(this.cropCheckInterval);

    this.dzWorkoutCover.directiveRef.reset(true);
  }

  updateDropzoneParams(): void {
    this.config.headers = this.headers;

    this.config.params = {
      'name': this.workout.name,
      'video_url': this.workout.video_url,
      'training_id': this.workout.training_id,
    };

    if (this.workout.id > 0) {
      this.config.url = this.api.API_URL + '/v2/workouts/' + this.workout.id;
      this.config.params._method = 'PATCH';
    } else {
      this.config.url = this.api.API_URL + '/v2/workouts/';
    }
  }


  closeLibrary() {
    this.setOpenLibrary.emit(false);
    this.opened_library = true;
  }

  openLibrary() {
    this.setOpenLibrary.emit(true);
    // this.loadLibrary();
  }

  loadLibrary() {
    this.loadingLibrary = true;

    this.libraryProvider.library().then(
      (res: any) => {
        this.library = res.workouts;
        this.loadingLibrary = false;
      },
      (err: any) => {
        console.log(err);
        this.loadingLibrary = false;
      });
  }

  setClonedForm(cloned: any = null) {
    if (cloned.length !== null) {
      this.addWorkout();
      this.workout = Object.assign({}, cloned);
      this.workout.training_id = this.training_id;
      this.workout.library_workout_id = cloned.id;
      this.workout.id = 0;
      this.workoutName.setValue(cloned.name);
      this.workoutVideoUrl.setValue(this.workout.video_url);
      if (this.workout.video_url === '' || this.workout.video_url === null) {
        this.workout.video_url = 'https://player.vimeo.com/video/';
        this.workoutVideoUrl.setValue('https://player.vimeo.com/video/');
      }
      this.changeWorkoutVideoUrl();
      this.updateDropzoneParams();
    }
  }

  validateForm() {
    let valid = true;
    if (this.workout.name === '') {
      this.workoutName.markAsTouched();
      valid = false;
    }
    if (this.workout.video_url === '') {
      this.workoutVideoUrl.markAsTouched();
      valid = false;
    }
    if (this.helpers.validateURL(this.workout.video_url)) {
      this.workoutVideoUrl.setErrors({'incorrect': true});
      valid = false;
    }
    if (!(/^\d+$/.test(this.workout.intervals))) {
      this.toastr.error(this.PAGE.campo_intervalo, '', this.helpers.toastrCfgs);
      this.workout.intervals = 0;
      valid = false;
    }

    return valid;
  }


  // funcoes para series de exercicios
  addSerie() {
    this.showSerieForm = true;
    this.serieForm.reset();
    this.serieForm.patchValue({ id: 0, color: '#707070', training_id: this.training_id });
    this.setSelectedWorkoutsToSerie();
  }

  editSerie(serie: any) {
    this.selected_workout = 0;
    this.total_selected = 0;
    this.selected_workouts = [];
    if (this.checkAll) {
      this.checkAll.indeterminate = false;
      this.checkAll.checked = false;
    }

    this.showSerieForm = true;
    this.serieForm.reset();
    this.serieForm.patchValue(serie);
  }

  setSelectedWorkoutsToSerie() {
    this.selected_workouts.map((checked: any, idx) => {
      if (checked) {
        this.workouts[idx].serie_check = true;
      }
    });
  }

  formSerieSave(serie: any = null) {
    // console.log('formSerieSave');

    if (this.serieForm.value.name === null) {
      this.toastr.warning(this.PAGE.campo_nome_obrigatorio, '', this.helpers.toastrCfgs);
      return false;
    }

    let selected: string = this.serieForm.value.selected;
    this.showSerieForm = false;
    this.loading = true;
    this.workouts.map((workout: any) => {
      if (workout.serie_check === true) {
        selected = selected + ',' + (workout.id).toString();
      }
    });
    if (serie) {
      serie.workouts.map((workout: any) => {
        if (workout.serie_check === true) {
          selected = selected + ',' + (workout.id).toString();
        }
      });
    }
    this.serieForm.patchValue({ selected: selected });

    if (parseInt(this.serieForm.value.id) === 0) {
      this.updateTranslationSerie(0);
      this.createSerie(this.serieForm.value);
    } else {
      this.updateTranslationSerie(this.serieForm.value.id);
      this.updateSerie(this.serieForm.value);
    }
  }

  formSerieCancel() {
    this.showSerieForm = false;
  }

  createSerie(data: any) {
    this.workoutProvider.postWorkoutSerie(data).then(
      (res: any) => {
        this.workouts = res.workouts;
        this.toastr.success(this.PAGE.serie_cadastro_sucesso, '', this.helpers.toastrCfgs);
        this.loading = false;

        this.selected_workout = 0;
        this.total_selected = 0;
        this.selected_workouts = [];
        if (this.checkAll) {
          this.checkAll.indeterminate = false;
          this.checkAll.checked = false;
        }
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        this.loading = false;
      });
  }

  updateSerie(data: any) {
    // console.log(data);
    // this.loading = false;
    this.workoutProvider.patchWorkoutSerie(data).then(
      (res: any) => {
        this.workout = res.workout;
        this.toastr.success(this.PAGE.serie_atualizado_sucesso, '', this.helpers.toastrCfgs);
        this.goBack();
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      });
  }

  deleteSerie(id = null) {
    this.deleteSerieConfirmation(id);
  }

  deleteSerieConfirmation(id: any): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.apagar_serie_title, message: this.PAGE.apagar_serie, confirmation: 'deletar'}
    });

    if (id === null) { id = this.workout.id; }

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'deletar') {
        this.loading = true;
        this.workoutProvider.deleteWorkoutSerie(id).then(
          (res: any) => {
            this.workouts = res.workouts;
            this.showSerieForm = false;
            this.serieForm.reset();
            this.serieForm.patchValue({ id: 0, color: '#707070', training_id: this.training_id });
            this.toastr.success(this.PAGE.apagar_serie_confirmacao_sucesso, '', this.helpers.toastrCfgs);
            this.loading = false;
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
            this.loading = false;
          }
        );
      }
    });
  }

  applyAll(field: string): void {
    console.log(field);
    console.log(this.workout);
    let data = Object.assign({}, this.workout);
    data._field = field;

    let campo;
    if (field === 'series_repetitions') campo = this.PAGE.series_repetitions;
    if (field === 'intervals') campo = this.PAGE.intervals;

    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.atualizar_exercicio_title, message: this.PAGE.atualizar_exercicio_a + '('+campo+')' + this.PAGE.atualizar_exercicio_b, confirmation: 'atualizar'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'atualizar') {
        this.loading = true;
        this.workoutProvider.updateMassWorkout(data).then(
          (res: any) => {
            this.workouts = res.workouts;
            this.toastr.success(this.PAGE.atualizar_exercicio_sucesso, '', this.helpers.toastrCfgs);
            this.loading = false;
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
            this.loading = false;
          }
        );
      }
    });    

  }

  helpIntervalo() {
    let msg = this.PAGE.ajuda_intervaloç
    const dialogRef = this.dialog.open(DialogLightComponent, { width: '360px', data: { message: msg } });
    dialogRef.afterClosed().subscribe(_result => {});
  }

  updateTranslation() {
    const translations = (document.getElementById('translationFieldWorkout') as HTMLInputElement).value;
    this.workout.translations = translations;
  }

  processTranslation(value: any) {
    // console.log(value);
    if (value) {
      (document.getElementById('translationFieldWorkout') as HTMLInputElement).value = JSON.stringify(value);
    }
  }

  updateTranslationSerie(serieId: number = 0) {
    const translations = (document.getElementById('translationFieldSeries' + serieId) as HTMLInputElement).value;
    this.serieForm.value.translations = translations;
  }
  processTranslationSerie(value: any) {
    // console.log(value);
    if (value) {
      (document.getElementById('translationFieldSeries' + value.id) as HTMLInputElement).value = JSON.stringify(value);
    }
  }

  helpVideo() {
    let msg = this.PAGE.ajuda_video;

    const dialogRef = this.dialog.open(DialogLightComponent, { width: '360px', data: { message: msg } });
    dialogRef.afterClosed().subscribe(_result => {});
  }

  deleteMassWorkout() {
    let arr: any = [];
    this.selected_workouts.map((checked: any, idx) => {
      if (checked) {
        arr.push(this.workouts[idx].id);
      }
    });
    this.deleteMassWorkoutConfirmation(arr);
  }

  deleteMassWorkoutConfirmation(arrIds: any): void {
    const msg = this.PAGE.apagar_exercicios;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.apagar_exercicios_title, message: msg, confirmation: 'deletar'}
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed: ' + result);
      if (result === 'deletar') {
        this.setWorkoutId.emit(0);
        this.loading = true;
        this.workoutProvider.deleteMassTraining({tids: arrIds}).then(
          (_res: any) => {
            this.goBack();
            this.setAll(false);
            this.toastr.success(this.PAGE.apagar_exercicios_sucesso, '', this.helpers.toastrCfgs);
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
      }
    });
  }

  workoutMassClone() {
    let arr: any = [];
    this.selected_workouts.map((checked: any, idx) => {
      if (checked) {
        arr.push(this.workouts[idx].id);
      }
    });
    this.cloneWorkoutConfirmation(arr);
  }

  cloneWorkoutConfirmation(arrIds: any = []): void {
    let msg = this.PAGE.clonar_exercicio;
    let confirmation = 'clonar';
    if ((this.total_workouts + arrIds.length) > this.limit) {
      msg = this.PAGE.o_limite_de + this.limit + this.PAGE.limite_exercicios;
      confirmation = 'ok'
    }

    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.clonar_exercicio_title, message: msg, confirmation: confirmation}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        return false;
      }
      if (result === 'clonar') {
        this.loading = true;
        this.workoutProvider.cloneTraining({ids: arrIds}).then(
          (_res: any) => {
            this.goBack();
            this.setAll(false);
            this.toastr.success(this.PAGE.clonar_exercicio_sucesso, '', this.helpers.toastrCfgs);
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
      }
    });
  }

  changeCheck(checked: boolean) {
    this.total_selected += (checked) ? + 1 : - 1;
  }

  updateAllComplete() {
    let selecteds: number = 0;
    this.selected_workouts.map((selected: boolean) => {
      if (selected) selecteds++;
    });
    if (selecteds === (this.total_workouts - this.total_workouts_without_series)) {
      this.checkAll.checked = true;
      this.checkAll.indeterminate = false;
    } else {
      if (this.selected_workouts.length > 0) {
        this.checkAll.indeterminate = true;
      }
    }
    if (selecteds === 0) {
      this.checkAll.indeterminate = false;
      this.checkAll.checked = false;
    }
  }

  setAll(checked: boolean) {
    if (this.selected_workouts == null) return;
    if (!checked) this.checkAllIndeterminate = false;
    let count: number = 0;
    for (let i = 0; i < this.workouts.length; i++) {
      if (!this.workouts[i].is_serie) {
        this.selected_workouts[i] = checked;
        count++;
      }
    }
    this.total_selected = (checked) ? count : 0;
  }

}
