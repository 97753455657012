import { Injectable } from '@angular/core';
import { ApiProvider } from '../api/api';

@Injectable()
export class GroupsMuscleProvider {

  constructor(
    public api: ApiProvider
  ) {
    //
  }

  index() {
    const url = 'v2/groupsmuscle';
    return this.api.get(url);
  }

  indexOpen() {
    const url = 'v2/groupsmuscle/index';
    return this.api.get(url);
  }

  get(id: any) {
    return this.api.get(`v2/groupsmuscle/${id}`);
  }

  patch(goal: any) {
    goal._method = 'PATCH';
    return this.api.post(`v2/groupsmuscle/${goal.id}`, goal);
  }

  post(goal: any) {
    return this.api.post(`v2/groupsmuscle/`, goal);
  }

  delete(id: any) {
    return this.api.delete(`v2/groupsmuscle/${id}`);
  }

}
