import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
// import { Router, ActivatedRoute, NavigationExtras, ParamMap } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { HelpersProvider } from '../../../providers/helpers/helpers';
import { UserProvider } from '../../../providers/user/user';
import { I18nProvider } from '../../../providers/i18n/i18n';

@Component({
  selector: 'dashboard-profile',
  templateUrl: './profile.html',
})
export class DashboardProfileComponent implements OnInit {

  STRINGS: any;
  PAGE: any;

  @Input() user: any;
  @Input() user_plan: any;
  show_expiration: boolean = true;
  subscription: boolean = false;
  loading = false;

  extras: NavigationExtras;

  constructor(
    // public auth: AuthService,
    public toastr: ToastrService,
    public userProv: UserProvider,
    private router: Router,
    private helpers: HelpersProvider,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('DashboardPage');
  }

  ngOnInit() {
    if (this.user) {
      if (this.user.subs) {
        if (this.user.subs.supertreinos_plan_id) {
          if (this.user.subs.supertreinos_plan_id === '0') {
            this.show_expiration = false;
          }
        }
        if (this.user.subs.id) {
          if (this.user.subs.id > 0) {
           if (this.user.subs.pagarme_subscriber_id === null && this.user.subs.pagarme_payment_link_id !== null) {
             this.subscription = false;
             this.show_expiration = true;
           } else {
             this.subscription = true;
             this.show_expiration = false;
           }
          }
        }

      }
    }
  }


  goToProfile() {
    this.userProv.goToProfile(this.router);
  }

  goToPlans() {
    this.userProv.goToSubsPage(this.router);
  }

  renewPlan() {
    this.userProv.renewPlan(this.router);
  }

}
