import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'dialog-logo-pro',
  templateUrl: 'dialog-logo-pro.html',
})

export class DialogLogoProComponent {
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogLogoProComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    //
  }

  date_selected = null;
  user: any = [];

  ngOnInit() {
    // console.log('data.date_selected: ' + this.data.date_selected);

    if (this.data.date_selected === null) {
      this.date_selected = new Date();
    } else {
      // const offsetMs = this.data.date_selected.getTimezoneOffset() * 3 * 60 * 1000;
      // this.date_selected = new Date(this.data.date_selected.getTime() - offsetMs);
      this.date_selected = new Date(this.data.date_selected);
    }

  }


}
