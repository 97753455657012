import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DeviceDetectorService } from 'ngx-device-detector';

import { LibraryProvider } from '../../providers/library/library';
import { AuthService } from '../../providers/auth/auth.service';
import { HelpersProvider } from 'src/providers/helpers/helpers';
import { I18nProvider } from '../../providers/i18n/i18n';

@Component({
  selector: 'app-programas',
  templateUrl: './programas.component.html',
  // styleUrls: ['./dashboard.component.css']
})
export class ProgramasComponent implements OnInit {
  STRINGS: any;
  PAGE: any;

  pid = 0;
  tid = 0;
  training: any = null;
  cloned_library: any = null;
  moveLeft: boolean = false;
  isClone: boolean = false;
  methodsList: any = [];
  musclesList: any = [];
  equipsList: any = [];
  typesList: any = [];
  user: any = {};
  ismobile: boolean = false;
  istablet: boolean = false;
  resetP: boolean = false;
  resetT: boolean = false;
  resetW: boolean = false;
  showTopper: boolean = true;
  segment: string = 'app-block-programs';

  constructor(
    public libraryProvider: LibraryProvider,
    public auth: AuthService,
    public router: Router,
    private device: DeviceDetectorService,
    public helpers: HelpersProvider,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('ProgramasPage');
  }

  ngOnInit() {
    // console.log('page programas - init');
    this.ismobile = this.device.isMobile();
    this.istablet = this.device.isTablet();
    this.istablet = (this.istablet) ? this.helpers.isPortrait() : this.istablet;
    if(!this.auth.isLoggednIn()) {
      this.router.navigate(['/login'], { skipLocationChange: true });
    }
    this.user = this.auth.retrieveLocalUser();

    this.getMethods();
    this.getMuscles();
    this.getEquips();
    this.getTypes();
  }


  getProgramId(id: any) {
    this.pid = id;
    if (id === 0) this.moveLeft = false;
    if (this.ismobile || this.istablet) this.showSegment('app-block-trainings');
  }

  getTrainingId(id: any) {
    this.tid = id;
    // console.log('page programs - tid:' + id);
    if (id === 0) this.moveLeft = false;
    if (this.ismobile || this.istablet) this.showSegment('app-block-workouts');
  }

  getTraining(training: any) {
    if (training === 0) {
      this.training = {};
    } else {
      this.training = training;
    }
    if (training.id) this.moveLeft = false;
  }

  // ativa a abertura da biblioteca do panel de exercicios
  toggleLibrary(_val: any) {
    // this.moveLeft = !this.moveLeft;
    this.moveLeft = _val;
    this.isClone = true;
    this.cloned_library = null;
  }

  // recebe o fechamento da biblioteca do panel de biblioteca
  getClonedLibrary(cloned_library: any = null) {
    if (cloned_library === null) {
      this.moveLeft = false;
    } else {
      this.cloned_library = cloned_library;
    }
  }

  getMethods() {
    this.libraryProvider.libraryMethods().then(
      (res: any) => {
        this.methodsList = res.methods;
      },
      (err: any) => {
        console.log(err);
      });
  }

  getMuscles() {
    this.libraryProvider.libraryMuscles().then(
      (res: any) => {
        this.musclesList = res.muscles;
      },
      (err: any) => {
        console.log(err);
      });
  }

  getEquips() {
    this.libraryProvider.libraryEquips().then(
      (res: any) => {
        this.equipsList = res.equips;
      },
      (err: any) => {
        console.log(err);
      });
  }

  getTypes() {
    this.libraryProvider.libraryTypes().then(
      (res: any) => {
        this.typesList = res.types;
      },
      (err: any) => {
        console.log(err);
      });
  }

  goBack() {
    this.showTopper = false;
    if (this.segment === 'app-block-trainings') this.resetP = true;
    if (this.segment === 'app-block-workouts') this.resetT = true;
    this.resetW = true;
    setTimeout(() => {
      if (this.segment === 'app-block-trainings') this.showSegment('app-block-programs');
      if (this.segment === 'app-block-workouts') this.showSegment('app-block-trainings');
    },250);
    setTimeout(() => {
      this.resetP = false;
      this.resetT = false;
      this.resetW = false;
    },150);
    setTimeout(() => {
      this.showTopper = true;
    },750);
  }

  showSegment(segment: string) {
    if (this.pid === 0 && segment === 'app-block-trainings') return false;
    if (this.tid === 0 && segment === 'app-block-workouts') return false;
    this.segment = segment;
    document.getElementById('app-block-programs').style.display = 'none';
    document.getElementById('app-block-trainings').style.display = 'none';
    document.getElementById('app-block-workouts').style.display = 'none';
    // document.getElementById('app-block-library').style.display = 'none';
    document.getElementById(segment).style.display = 'block';
    // if (segment === 'app-block-workouts') document.getElementById('app-block-library').style.display = 'block';
  }

}
