import { Component, OnInit, Input } from '@angular/core';

import { HelpersProvider } from '../../../providers/helpers/helpers';

@Component({
  selector: 'component-customer-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class CustomerPaymentsComponent implements OnInit {

  @Input() payment_list: any;

  constructor(
    private helpers: HelpersProvider,
  ) {
    //
  }

  ngOnInit() {
    // console.log(this.payment_list);
  }

  teste() {
    console.log(this.payment_list);
  }

  abrirLinkpagamento(payment: any) {
    if (payment.pagarme_payment_link_status === 'active') {
      window.open(payment.pagarme_payment_link_url);
    }
  }

}
