import { Injectable } from '@angular/core';
import { ApiProvider } from '../api/api';

@Injectable()
export class LibraryProvider {

  constructor(
    public api: ApiProvider
  ) {
    //
  }

  library() {
    const url = 'v2/library';
    return this.api.get(url);
  }

  libraryTeacher() {
    const url = 'v2/library/teacher';
    return this.api.get(url);
  }

  libraryItem(id: number) {
    return this.api.get(`v2/library/${id}`);
  }

  patchLibraryItem(libraryItem: any) {
    libraryItem._method = 'PATCH';
    return this.api.post(`v2/library/${libraryItem.id}`, libraryItem);
  }

  postLibraryItem(libraryItem: any) {
    return this.api.post(`v2/library/`, libraryItem);
  }

  deleteLibraryItem(id: number) {
    return this.api.delete(`v2/library/${id}`);
  }

  copyLibraryToTraining(data: any) {
    return this.api.post(`v2/library/copy`, data);
  }

  libraryMethods() {
    return this.api.get(`v2/library/methods`);
  }

  libraryMuscles() {
    return this.api.get(`v2/library/muscles`);
  }

  libraryEquips() {
    return this.api.get(`v2/library/equips`);
  }

  libraryTypes() {
    return this.api.get(`v2/library/types`);
  }

}
