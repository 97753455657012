import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';

import { HelpersProvider } from '../../providers/helpers/helpers';

import { DialogComponent } from '../../ui/dialog/dialog';
import { AuthService } from '../../providers/auth/auth.service';
import { CustomerProvider } from '../../providers/customer/customer';


@Component({
  selector: 'app-block-admin-account',
  templateUrl: './admin-account.html',
  styleUrls: ['./admin-account.scss']
})
export class AdminAccountComponent implements OnInit {

  loading = false;
  premium_expired = false;
  userPremiumExpires: any;
  userAddSubsDate: any;
  userCancelSubsDate: any;

  user = {
    name: null,
    status: 0,
    premium_expires_date: null,
    cancel_notes: null,
    created_at: null,
    teacher_simple: {
      id: 0,
      email: '',
    }
  };

  accountForm = new FormGroup({
    id: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    status_obs: new FormControl(''),
    pagarme_subscriber_id: new FormControl(''),
    premium_expires_date: new FormControl(''),
    add_subscription_date: new FormControl(''),
    cancel_subscription_date: new FormControl(''),
  });

  constructor(
    public auth: AuthService,
    public toastr: ToastrService,
    public dialog: MatDialog,
    private customerProv: CustomerProvider,
    private helpers: HelpersProvider,
  ) {
    //
  }

  ngOnInit() {
    // console.log(this.user);
  }

  save(block_status: number = null) {
    const dataString = JSON.stringify(this.accountForm.value);
    const data = JSON.parse(dataString);
    if (data.premium_expires_date) {
      data.premium_expires_date = data.premium_expires_date.substring(-10, 10);
    }
    if (data.add_subscription_date) {
      data.add_subscription_date = data.add_subscription_date.substring(-10, 10);
    }
    if (data.cancel_subscription_date) {
      data.cancel_subscription_date = data.cancel_subscription_date.substring(-10, 10);
    }

    if(block_status !== null) {
      data.status = block_status;
    }

    this.loading = true;

    const students: any = [];
    students.push(data);

    this.auth.admCustomerUpdate(students).then(
      (_res: any) => {
        // console.log(res.user);
        // this.user = res.user;
        // if (!this.user.status) {
        //   this.user.status = 0;
        // }
        this.loading = false;
        if (block_status == 9) {
          this.toastr.success('O Cliente foi bloqueado com sucesso!', '', this.helpers.toastrCfgs);
        } else if (block_status == 0) {
          this.toastr.success('O Cliente foi desbloqueado com sucesso!', '', this.helpers.toastrCfgs);
        } else {
          this.toastr.success('Seus dados foram atualizados com sucesso!', '', this.helpers.toastrCfgs);
        }

      },
      (err: any) => {
        this.loading = false;
        this.toastr.error(err || 'Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
      }
    );
  }

  updateForm(user: any) {
    this.user = user;
    console.log(this.user);
    this.accountForm.patchValue(user);

    if (user.premium_expires_date) {
      this.userPremiumExpires = new Date(user.premium_expires_date);
      // const offsetMs = this.userPremiumExpires.getTimezoneOffset() * 3 * 60 * 1000;
      // this.userPremiumExpires = new Date(this.userPremiumExpires.getTime() - offsetMs);
      this.userPremiumExpires = new Date(this.userPremiumExpires.getTime());
    }

    if (user.add_subscription_date) {
      this.userAddSubsDate = new Date(user.add_subscription_date);
      this.userAddSubsDate = new Date(this.userAddSubsDate.getTime());
    }

    if (user.cancel_subscription_date) {
      this.userCancelSubsDate = new Date(user.cancel_subscription_date);
      this.userCancelSubsDate = new Date(this.userCancelSubsDate.getTime());
    }
  }

  clear(action: string = '') {
    let title = '';
    if (action === 'favs') title = 'Favoritos';
    if (action === 'sched') title = 'Agenda';
    if (action === 'meal') title = 'Nutrição';
    if (action === 'note') title = 'Anotações';

    const msg = 'Deseja realmente apagar ' + title + ' deste Cliente?';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: 'Apagar ' + title, message: msg, confirmation: 'deletar'}
    });

    let data: any = { action: action, customer_id: this.accountForm.value.id }

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'deletar') {
        this.customerProv.admClearData(data).then(
          (_res: any) => {
            this.toastr.success('Os dados foram apagados com sucesso!', '', this.helpers.toastrCfgs);
          },
          (err: any) => {
            this.loading = false;
            this.toastr.error(err || 'Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
          }
        );
      }
    });

  }

}
