import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';

@Injectable()

export class TeacherGuardService implements CanActivate {

  constructor(
    private auth: AuthService,
    private myRoute: Router
  ) {
    //
  }

  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.auth.isTeacher()) {
      return true;
    } else {
      // this.auth.logout();
      this.myRoute.navigate(['login'], { skipLocationChange: true });
      return false;
    }
  }
}
