import { Component, OnChanges, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'dashboard-total-programs',
  templateUrl: './total-programs.html',
})
export class DashboardTotalProgramsComponent implements OnChanges, OnInit {

    @Input() user: any;
    @Input() programs: any;
    loading = true;

    pre_data: any = {
      iniciante: 0,
      intermediario: 0,
      avancado: 0,
      expert: 0,
    };
    data: any[];
    title: string;

    // options
    view: any[] = [560, 160];
    gradient: boolean = false;
    showLegend: boolean = true;
    legendTitle: string = '';
    showLabels: boolean = false;
    isDoughnut: boolean = false;
    legendPosition: string = 'right';

    colorScheme = {
      domain: ['#00DD00', '#FFDD00', '#FF4400', '#dd51ff']
    };

    constructor(
      public toastr: ToastrService,
      public router: Router,
    ) {
      //
      // Object.assign(this, { data: this.data });
    }

    ngOnInit() {
      this.data = [];
      // this.legendTitle = 'Total de Programas por Dificuldade - ' + this.user.subs.programs.total + '/' + this.user.subs.programs.max;
      this.legendTitle = 'Programas por Dificuldade';
      this.title = (this.user.subs.programs !== undefined) ? 'Programas Cadastrados - ' + this.user.subs.programs.total + '/' + this.user.subs.programs.max : 'Programas Cadastrados: ' + this.programs.length;
    }

    ngOnChanges() {
      // console.log('mudou');
      this.postProcessData()
    }



    onSelect(_data: any): void {
      // console.log('Item clicked', JSON.parse(JSON.stringify(_data)));
    }

    onActivate(_data: any): void {
      // console.log('Activate', JSON.parse(JSON.stringify(_data)));
    }

    onDeactivate(_data: any): void {
      // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
    }

    postProcessData() {
      if (this.programs.length !== undefined) {
        this.programs.map((program: any) => {
          // console.log(program);
          if (program.difficulty_level === 'Iniciante') this.pre_data.iniciante++;
          if (program.difficulty_level === 'Intermediário') this.pre_data.intermediario++;
          if (program.difficulty_level === 'Avançado') this.pre_data.avancado++;
          if (program.difficulty_level === 'Expert') this.pre_data.expert++;
        });
        // console.log(this.pre_data);

        this.data = [
          {
            "name": this.pre_data.iniciante + " - Iniciante",
            "value": this.pre_data.iniciante
          },
          {
            "name": this.pre_data.intermediario + " - Intermediário",
            "value": this.pre_data.intermediario
          },
          {
            "name": this.pre_data.avancado + " - Avançado",
            "value": this.pre_data.avancado
          },
          {
            "name": this.pre_data.expert + " - Expert",
            "value": this.pre_data.expert
          }
        ];
        this.title = (this.user.subs !== undefined) ? 'Programas Cadastrados - ' + this.user.subs.programs.total + '/' + this.user.subs.programs.max : 'Programas Cadastrados: ' + this.programs.length;
        this.loading = false;
      }
    }

    goToPrograms() {
      if (this.user.account_type === 'teacher') {
        this.router.navigate(['professor/programas'], { skipLocationChange: true });
      } else {
        this.router.navigate(['programas'], { skipLocationChange: true });
      }
    }

    goToTutorial() {
      window.open('https://www.stcoach.app/tutoriais-programas', '_system', 'location=yes');
    }

}
