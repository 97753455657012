import { Injectable } from '@angular/core';
import { ApiProvider } from '../api/api';

@Injectable()
export class ProgramProvider {

  constructor(
    public api: ApiProvider
  ) {
    //
  }

  programs(programId = '') {
    const url = 'v2/programs';
    return this.api.get(url, { params: { pid: programId } });
  }

  program(id: any) {
    return this.api.get(`v2/programs/${id}`);
  }

  patchProgram(program: any) {
    program._method = 'PATCH';
    return this.api.post(`v2/programs/${program.id}`, program);
  }

  postProgram(program: any) {
    return this.api.post(`v2/programs/`, program);
  }

  deleteProgram(id: any) {
    return this.api.delete(`v2/programs/${id}`);
  }

  cloneProgram(program: any) {
    return this.api.post(`v2/programs/clone/`, program);
  }

  reorderPrograms(programs: any) {
    return this.api.post(`v2/programs/reorder/`, programs);
  }

  sendProgram(data: any) {
    return this.api.post(`v2/programs/send/`, data);
  }

  // getCache(programId = '') {
  //   const url = 'v2/programs_cached';
  //   return this.api.get(url, { params: { pid: programId } });
  // }

  deleteCache() {
    const url = 'v2/programs_cached';
    return this.api.delete(url);
  }

  async admCustomerToggleAllProgramsStudents(pid: number, v: boolean = false) {
    let addremove = 'removeAll';
    if (v) addremove = 'addAll';
    return this.api.post('v2/programs/' + addremove + '/' + pid).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

}
