import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';

import { DialogComponent } from '../../../ui/dialog/dialog';
import { DialogLightComponent } from '../../../ui/dialog-light/dialog-light';

import { CustomerProvider } from '../../../providers/customer/customer';
import { AuthService } from '../../../providers/auth/auth.service';
import { UserProvider } from '../../../providers/user/user';
import { ApiProvider } from '../../../providers/api/api';
import { HelpersProvider } from '../../../providers/helpers/helpers';
import { I18nProvider } from '../../../providers/i18n/i18n';

@Component({
  selector: 'component-customer-waitline',
  templateUrl: './waitline.component.html',
  styleUrls: ['./waitline.component.scss']
})
export class CustomerWaitlineComponent implements OnInit {
  PAGE: any;
  STRINGS: any;

  protected headers: any;
  teacherCode: string = '';

  loading: boolean = false;
  user: any = [];
  route_id: string = '0';

  account: {
    purchase: {
      supertreinos_plan_id: 0,
    },
    students: {
      total: 0,
      max: 0,
      inactives: 0,
    },
    programs: {
      total: 0,
      max: 0,
    },
    teacher : {
      link: any,
    }
  }


  constructor(
    private route: ActivatedRoute,
    public auth: AuthService,
    public toastr: ToastrService,
    public dialog: MatDialog,
    public student: CustomerProvider,
    public userProv: UserProvider,
    public api: ApiProvider,
    public helpers: HelpersProvider,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('CustomerWaitlineComponent');

    this.user = this.auth.retrieveLocalUser();
    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};
    this.teacherCode = this.route.snapshot.params.code;
  }

  ngOnInit() {
    this.user = this.auth.retrieveLocalUser();
    this.getSubscriptions()
  }


  getSubscriptions() {
    this.loading = true;
    this.userProv.subscriptions().then(
      (p: any) => {
        this.account = p;
        this.helpers.clearState();
        this.account.teacher.link = window.location.href + '?p=' + this.account.teacher.link + '&l=' + this.STRINGS.lang;
        this.loading = false;
      },
      (err: any) => {
        this.loading = false;
        console.log(err);
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      }
    );
  }

  toggleClass(item: any) {
    item.active = !item.active;
  }

  copyTransfer(url: string = '') {
    console.log(url);
    if (this.helpers.copyTextToClipboard(url)) {
      this.toastr.success(this.PAGE.link_transferencia, '', this.helpers.toastrCfgs);
    } else {
      this.toastr.error(this.PAGE.copia_negada, '', this.helpers.toastrCfgs);
    }
  }

  saveGreeting() {
    const msg = this.PAGE.alterar_sauda;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.alterar_sauda_title, message: msg, confirmation: 'Sim'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Sim') {
        this.loading = true;
        this.userProv.updateUser(this.user).then(
          (_res: any) => {
            this.loading = false;
            this.toastr.success(this.PAGE.alterar_sauda_sucesso, '', this.helpers.toastrCfgs);
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
      }
    });
  }

  helpGreeting() {
    let msg = this.PAGE.saudacao_padrao

    const dialogRef = this.dialog.open(DialogLightComponent, { width: '360px', data: { message: msg } });
    dialogRef.afterClosed().subscribe(_result => {});
  }

  helpWaitline() {
    let msg = this.PAGE.saudacao_ajuda

    const dialogRef = this.dialog.open(DialogLightComponent, { width: '360px', data: { message: msg } });
    dialogRef.afterClosed().subscribe(_result => {});
  }

  clearText() {
     this.user.greeting = this.user.greeting.replace( /[^a-zA-Zà-úÀ-Ú0-9!?.,;* ]/gm, '');
  }

}
