import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exercicios',
  templateUrl: './exercicios.component.html',
  styleUrls: ['./exercicios.component.scss']
})
export class ExerciciosComponent implements OnInit {
  tid: any = 0;
  wid: any = 0;

  constructor(
    //
  ) {
    //
  }

  ngOnInit() {
    console.log('page exercicios - init');
  }

  getTrainingId(id: any) {
    this.tid = id;
  }

  getWorkoutId(id: any) {
    this.wid = id;
  }
}
