import { Component, EventEmitter, OnInit, OnChanges, Output, Input, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { ToastrService } from 'ngx-toastr';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { DialogComponent } from '../../../ui/dialog/dialog';

import { UserProvider } from '../../../providers/user/user';
import { ApiProvider } from '../../../providers/api/api';
import { HelpersProvider } from '../../../providers/helpers/helpers';
import { AuthService } from '../../../providers/auth/auth.service';
import { CustomerProvider } from '../../../providers/customer/customer';
import { I18nProvider } from '../../../providers/i18n/i18n';

@Component({
  selector: 'component-customer-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class CustomerFormComponent implements OnInit, OnChanges {
  PAGE: any;
  STRINGS: any;

  protected headers: any;
  loading: boolean = false;
  formSpinner: boolean = false;
  codes: any = [];

  customerLabel: string = 'cliente';

  @Input() user: any = null;
  @Input() admMode: boolean = null;
  @Input() account: any = [];
  @Input() goals: any = [];
  @Input() student: any = null;
  @Input() ismobile: any = null;
  @Input() istablet: any = null;

  @Output() setUserUpdated = new EventEmitter();

  studentAvatar: string = '';
  studentBirthdate: any = null;
  studentData: any = null;
  studentSelected: boolean = false;
  studentId: number = 0;
  studentAnamnesis: string = '';
  studentForm = new FormGroup({
    id: new FormControl('', [Validators.required]),
    status: new FormControl(''),
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    goal_id: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    password_confirmation: new FormControl('', [Validators.required]),
    details: new FormGroup({
      ddi: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      birthdate: new FormControl(),
      gender: new FormControl('', [Validators.required]),
      height: new FormControl(),
      weight: new FormControl(),
    }),
  });

  imgrnd: number;

  cropCheckInterval: any;
  @ViewChild(DropzoneComponent, {static: false}) dzCustomerAvatar?: DropzoneComponent;
  public avatar: DropzoneConfigInterface;

  constructor(
    public toastr: ToastrService,
    public auth: AuthService,
    public api: ApiProvider,
    public helpers: HelpersProvider,
    public dialog: MatDialog,
    public studentProvider: CustomerProvider,
    public userProvider: UserProvider,
    public router: Router,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('FormComponent');

    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};
    this.imgrnd = this.helpers.random(1000001, 9999999);
    if (this.user === null) {
      this.user = this.auth.retrieveLocalUser();
    }

    if (this.user.account_type === 'teacher') {
      this.customerLabel = this.STRINGS.aluno;
    }
    if (this.user.account_type === 'nutri') {
      this.customerLabel = this.STRINGS.paciente;
    }

    this.avatar = {
      paramName: 'file',
      thumbnailMethod: 'contain',
      thumbnailHeight: null,
      thumbnailWidth: null,
      addRemoveLinks: true,
      dictRemoveFile: this.STRINGS.dz_remover_imagem,
      dictCancelUpload: this.STRINGS.dz_cancelar_envio,
      dictCancelUploadConfirmation: this.STRINGS.dz_confirma_cancelar_envio,
      dictInvalidFileType: this.STRINGS.dz_arquivo_invalido,
      maxFiles: 1,
      transformFile: (file, done) => {
        this.cropCheckInterval = setInterval(() => { if (this.helpers.getLocallyStored('cropOngoing') === false) this.resetDropzoneUploads() }, 250);
        this.helpers.cropImage(file, done, 'dz-avatar', 500, 500);
      }
    };
  }

  ngOnInit() {
    //
  }

  ngOnChanges() {
    this.codes = this.helpers.sortByProperty(this.helpers.getLocallyStored('ddi_codes'), 'nome');
    this.loading = true;
    // fake load
    if (this.student.id === 0) {
      this.loading = false;
      this.new();
    } else {
      setTimeout(() => {
        this.loading = false;
        this.loadForm(this.student);
      }, 400);
    }
  }


  loadForm(student: any) {
    if (!student) return null;
    if (!student.password_confirmation) {
      student.password_confirmation = student.password;
    }
    this.studentAvatar = (student.details !== null) ? student.details.avatar : null;
    this.studentId = student.id;
    this.studentForm.patchValue(student);
    this.studentForm.value.goal_id = student.goal_id;
    this.studentForm.value.ddi = student.details.ddi;

    this.studentBirthdate = new Date(student.details.birthdate);
    const offsetMs = this.studentBirthdate.getTimezoneOffset() * 3 * 60 * 1000;
    this.studentBirthdate = new Date(this.studentBirthdate.getTime() + offsetMs);

    this.updateDropzoneParams(this.avatar);
    this.avatar.dictDefaultMessage = ((this.ismobile || this.istablet)
        ? this.PAGE.click_aqui
        : this.PAGE.arraste_aqui
    );
  }

  disableInputId(id: string = '') {
    document.getElementById(id).setAttribute('disabled', 'disabled');
  }

  new() {
    this.studentSelected = false;
    this.studentData = { id: 0, details : { ddi: '0055' } };
    this.studentId = 0;
    this.student = { id: 0, details : { ddi: '0055' } };;
    this.studentForm.reset();
    this.studentForm.patchValue({ status: 2, details : { ddi: '0055', gender: 'm'} });
  }

  removeAvatar() {
    const data = {
      'id': this.student.id,
      'name': this.student.name,
      'avatar': null,
    }
    const students: any = [];
    students.push(data);

    this.loading = true;
    this.studentProvider.admCustomerUpdate(students).then(
      (_user: any) => {
        // console.log(user);
        this.studentAvatar = _user.avatar;
        this.loading = false;
        this.toastr.success(this.PAGE.foto_removida_sucesso, '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        this.loading = false;
        console.log(err);
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      }
    );
  }  

  save() {
    if (this.account.students.total >= this.account.students.max) {
      this.goToSubscribe();
      return false;
    }

    this.validateAllFormFields(this.studentForm);

    if (this.studentForm.value.password !== this.studentForm.value.password_confirmation) {
      this.toastr.error(this.PAGE.senha_diferente, '', this.helpers.toastrCfgs);
      return false;
    }

    if (this.studentForm.value.details.phone === null || this.studentForm.value.details.phone === '') {
      this.toastr.error(this.PAGE.tel_branco, '', this.helpers.toastrCfgs);
      return false;
    }

    const dataString = JSON.stringify(this.studentForm.value);
    const data = JSON.parse(dataString);
    if (this.user.account_type === 'nutri') {
      if (data.goal_id === null) {
        data.goal_id = 1;
      }
      }
    if (data.details.birthdate) {
      data.details.birthdate = data.details.birthdate.substring(-10, 10);
    }
    if (data.status === null) {
      data.status = 2;
    }
    data.user_id = this.user.id;

    const students: any = [];
    students.push(data);

    this.loading = true;
    if (this.studentForm.value.id > 0) {
      this.studentProvider.admCustomerUpdate(students).then(
        (user: any) => {
          this.loading = false;
          this.toastr.success(this.PAGE.conta_atualizada_sucesso, '', this.helpers.toastrCfgs);
          this.returnUser(user);
        },
        (err: any) => {
          this.loading = false;
          console.log(err);
          this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        }
      );
    } else {

      this.studentProvider.admCustomerCreate(data).then(
        (user: any) => {
          this.loading = false;
          this.toastr.success(''+this.customerLabel.charAt(0).toUpperCase()+this.customerLabel.slice(1)+this.PAGE.criado_sucesso, '', this.helpers.toastrCfgs);
          this.returnUser(user);
        },
        (err: any) => {
          this.loading = false;
          console.log(err);
          if (err.message === 'Já existe uma conta com esse email!') {
            this.toastr.error(this.PAGE.email_cadastrado, '', this.helpers.toastrCfgs);
          } else {
            this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
          }
        }
      );
    }
  }

  returnUser(user: any = null) {
    this.setUserUpdated.emit(user);
  }

  delete(student: any) {
    this.deleteStudentConfirmation(student);
  }

  deleteStudentConfirmation(student: any): void {
    // console.log(student);
    const msg = this.PAGE.apagar_conta;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.apagar_conta_title, message: msg, confirmation: 'deletar'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'deletar') {
        this.loading = true;
        this.studentForm.reset();
        this.studentProvider.admCustomerDelete(student).then(
          (_res: any) => {
            this.loading = false;
            this.toastr.success(this.PAGE.apagar_conta_sucesso, '', this.helpers.toastrCfgs);
            if (this.ismobile || this.istablet) this.backToList();
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
            this.loading = false;
            if (this.ismobile || this.istablet) this.backToList();
          });
      }
    });
  }

  backToList() {
    // this.menuSelected = '';
  }

  getUserFormValidationMessage(el: any) {
    if (el.errors.required) {
      return this.STRINGS.campo_obrigatorio;
    }

    if (el.errors.incorrect || el.errors.pattern) {
      return this.STRINGS.campo_invalido;
    }
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }


  // dropzone funcs
  onAddedFile(_args: any) {
    //
  }

  onSendingFile(_$event: any) {
    //
  }

  onRemovedFile(_$event: any) {
    //
  }

  onUploadError(_$event: any) {
    //
  }

  onAvatarUploadSuccess($event: any) {
    console.log($event);
    console.log($event[1].request);
    this.loading = false;
    this.studentId = $event[1].customer_id;
    this.studentAvatar = $event[1].request + '?' + Date.now();
    (document.getElementById('profile-avatar-' + this.studentId) as HTMLFormElement).src = this.studentAvatar;
    (document.getElementById('selected-profile-avatar') as HTMLFormElement).src = this.studentAvatar;

    this.toastr.success(this.PAGE.foto_enviada_sucesso, '', this.helpers.toastrCfgs);

    this.resetDropzoneUploads();
    this.avatar.url = this.api.API_URL + '/v2/adm/customer/avatar/';
    this.avatar.params = {
      '_method': 'post',
      'id': this.studentId,
      'file': this.studentAvatar,
    };

  }

  updateDropzoneParams(dz: any): void {
    dz.headers = this.headers;
    dz.params = {
      'id': this.studentId,
      'file': (this.studentAvatar === '' ? 'fake_cover_path' : this.studentAvatar),
    };
    dz.url = this.api.API_URL + '/v2/adm/customer/avatar/';
  }

  resetDropzoneUploads(): void {
    this.dzCustomerAvatar.directiveRef.reset(true);
  }

  goToSubscribe() {
    let msg = '';
    let bt_ok = '';
    let bt_cancel_hide = false;
    if (this.account.purchase.supertreinos_plan_id === "4") {
      msg = this.STRINGS.limite_maximo;
      bt_ok = this.STRINGS.bt_ok;
      bt_cancel_hide = true;
    } else {
      msg = this.STRINGS.limite_registros;
      bt_ok = this.STRINGS.bt_upgrade;
      bt_cancel_hide = false;
    }

    const dialogRef = this.dialog.open(DialogComponent, {
      panelClass: 'subscribe-modal',
      width: '360px',
      data: {title: this.STRINGS.cadastro, message: msg, confirmation: 'Sim', bt_ok: bt_ok, bt_cancel_hide: bt_cancel_hide}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Sim') {
        this.userProvider.goToSubsPage(this.router);
      }
    });
  }     
}
