import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { DialogLightComponent } from '../../../ui/dialog-light/dialog-light';

import { ApiProvider } from '../../../providers/api/api';
import { HelpersProvider } from '../../../providers/helpers/helpers';
import { AuthService } from '../../../providers/auth/auth.service';
import { CustomerProvider } from '../../../providers/customer/customer';
import { UserProvider } from '../../../providers/user/user';
import { I18nProvider } from '../../../providers/i18n/i18n';

@Component({
  selector: 'component-customer-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss']
})
export class CustomerProgramsComponent implements OnInit {
  protected headers: any;
  @Input() studentData: any = null;
  @Input() studentSelected: any = null;
  @Input() user: any = null;
  @Input() programs: any = null;

  @Output() setUserListUpdated = new EventEmitter();

  loading: boolean = false;
  totalPrograms: number = 0;

  totalItens2 = { count: 0 };
  maxItens2: number = 30;
  page2: number = 0;
  totalPages: number = 0;
  searchProgram: any = {
    name: <string> '',
    premium: <any> '',
    published: <any> '',
    workout_muscle_ids: <any> [],
    workout_equip_ids: <any> [],
    workout_method_ids: <any> [],
  };
  showHistory: boolean = false;

  PAGE: any;
  STRINGS: any;

  constructor(
    public toastr: ToastrService,
    public auth: AuthService,
    public api: ApiProvider,
    public helpers: HelpersProvider,
    public dialog: MatDialog,
    public studentProv: CustomerProvider,
    public userProv: UserProvider,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('CustomerProgramsComponent');
    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};
  }
  ngOnInit() {
    // console.log('OnInit - componente.customer.programs');
    // console.log(this.programs);
    this.totalItens2.count = this.programs.length;
    this.totalPrograms = this.programs.length;
    this.totalPages = Math.ceil(this.totalItens2.count/this.maxItens2);
  }

  ngOnChanges() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 400);
  }


  toggleClass(item: any) {
    item.active = !item.active;
  }

  toggleActiveProgram(id: number) {
    if (this.studentSelected) {
      // console.log('Student ID:' + this.studentForm.value.id);
      // console.log('Program ID:' + id);
      const data = {
        cid: this.studentData.id,
        pid: id
      };
      this.studentProv.admCustomerToggleProgram(data).then(
        (res: any) => {
          // this.loading = false;
          // this.students = res.customers;
          this.setUserListUpdated.emit(res);
          this.toastr.success('(' + this.studentData.name.split(' ')[0] + ') ' + this.PAGE.lista_programas_atualizada, '', this.helpers.toastrCfgs);
        },
        (err: any) => {
          this.toastr.error(err, '', this.helpers.toastrCfgs);
          this.loading = false;
        }
      );

    }
  }

  helpSendPrograms() {
    let msg = this.PAGE.ajuda_enviar_programas;

    const dialogRef = this.dialog.open(DialogLightComponent, { width: '360px', data: { message: msg } });
    dialogRef.afterClosed().subscribe(_result => {});
  }

  searchProgramChange() {
    this.totalPages = Math.ceil(this.totalItens2.count/this.maxItens2);
  }

  removePagination(page: number = 0) {
    const pageTarget = this.page2 - page;
    this.changePagination(pageTarget);
  }

  addPagination(page: number = 0) {
    const pageTarget = this.page2 + page;
    this.changePagination(pageTarget);
  }

  changePagination(page: number = 0) {
    this.loading = true;

    this.page2 = page;
    if (page <= 0) {
      // console.log('page <= 0');
      this.page2 = 0;
    }
    if (page >= this.totalPages) {
      // console.log('page >= this.totalPages');
      this.page2 = this.totalPages - 1;
    }

    setTimeout(() => {
      this.loading = false;
    }, 250);
  }

}
