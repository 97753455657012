import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { ProgramCategoryProvider } from '../../../providers/program/category';
import { HelpersProvider } from '../../../providers/helpers/helpers'
import { WorkoutProvider } from '../../../providers/workout/workout'


@Component({
  selector: 'adm-config-workout-videos',
  templateUrl: './workout-videos.component.html',
  styleUrls: ['./workout-videos.component.scss']
})
export class WorkoutVideosComponent implements OnInit {

  loading: boolean = false;
  
  categoryForm = new FormGroup({
    id: new FormControl(''),
    name: new FormControl(''),
    translations: new FormControl(''),
  });
  categories: any = [];

  workoutTotal: number = 0;
  videoForm = new FormGroup({
    old: new FormControl(''),
    new: new FormControl(''),
    qtd: new FormControl(''),
    master: new FormControl(''),
  });


  constructor(
    public categoryProv: ProgramCategoryProvider,
    public toastr: ToastrService,
    public helpers: HelpersProvider,
    public workoutProv: WorkoutProvider,
  ) {
    //
  }

  ngOnInit() {
    //
    // this.loading = true;
    // this.categoryProv.index().then(
    //   (res: any) => {
    //     this.categories = res.categories;
    //     this.loading = false;
    //   },
    //   (err: any) => {
    //     console.log(err);
    //     this.loading = false;
    //   }
    // );
  }

  resetForm() {
    this.categoryForm.reset();
  }


  listarTotal() {
    this.loading = true;
    let data = Object.assign({}, this.videoForm.value);
    console.log(data);

    if (data.old === '') {
      this.toastr.warning('É preciso pelo menos preencher o campo do vídeo original');
      return false
    }

    if (data.master === true) data.master = 1;

    this.workoutProv.getVideoMigrate(data).then(
      (res: any) => {
        if (typeof res.total === 'number') {
          this.workoutTotal = res.total;
          this.videoForm.patchValue({qtd: this.workoutTotal});
        }
        this.loading = false;
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(err.error.err, '', this.helpers.toastrCfgs);
        this.loading = false;
    });
  }

  migrate() {
    // https://player.vimeo.com/video/271563912
    // https://player.vimeo.com/video/946532130
        
    this.loading = true;
    let data = Object.assign({}, this.videoForm.value);
    console.log(data);

    if (data.old === '') {
      this.toastr.warning('É preciso preencher o campo do vídeo original');
      return false
    }
    if (data.new === '') {
      this.toastr.warning('É preciso preencher o campo do vídeo novo');
      return false
    }

    if (data.master === true) data.master = 1;

    this.workoutProv.postVideoMigrate(data).then(
      (res: any) => {
        if (typeof res.total === 'number') {
          //
          this.toastr.success('Exercícios atualizados!');
        }
        this.loading = false;
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(err.error.err, '', this.helpers.toastrCfgs);
        this.loading = false;
    });
  }

}
