import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, finalize } from "rxjs/operators";


import { ToastrService } from 'ngx-toastr';

import { ApiProvider } from '../api/api';
import { AuthService } from '../auth/auth.service';
import { User } from './user.model';

import { HelpersProvider } from '../../providers/helpers/helpers';

import { environment } from '../../environments/environment';

@Injectable()

export class UserProvider {

  user: any;

  API_URL = environment.API_ENV_URLS;

  constructor(
    public api: ApiProvider,
    public auth: AuthService,
    public toastr: ToastrService,
    public helpers: HelpersProvider,
    private http: HttpClient,
  ) {
    //
  }

  findUsers(courseId: number, filter = '', accountType = '', sort = 'created_at', sortOrder = 'asc', pageNumber = 0, pageSize = 3):  Observable<User[]> {
    const token = localStorage.getItem('LoggedInUser');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    return this.http.get(`${this.API_URL}/master/v2/user/index`, {
      headers: headers,
      params: new HttpParams()
          .set('courseId', courseId.toString())
          .set('filter', filter)
          .set('accountType', accountType)
          .set('sort', sort)
          .set('sortOrder', sortOrder)
          .set('page', pageNumber.toString())
          .set('pageSize', pageSize.toString())
    }).pipe(
      // map((res: any) => res["users"])
      map((res: any) => res)
    );
  }

  findUser(id: number = 0) {
    return this.api.get('master/v2/user/read/' + id).then(
      (res: any) => {
        return res.user;
      },
      (err: any) => {
        throw new Error(err);
      }
    );
  }


  async registerUser(data: any) {
    return this.api.post('v2/user/create', data).then(
      (res: any) => {
        this.auth.storeLocallyUser(res.user);
        return res;
      },
      (err: any) => {
        console.log(err);
        let message: any;
        if (err.status === 422 || err.status === 404) {
          message = err.error.err;
        } else {
          message = 'Algo não funcionou bem. Tente novamente.';
        }
        throw new Error(message);
      }
    );
  }


  async getTeacherList() {
    return this.api.get('v2/user/teacher').then(
      (res: any) => {
        return res.users;
      },
      (err: any) => {
        throw new Error(err);
      }
    );
  }

  async getMasterList() {
    return this.api.get('master/v2/user/index').then(
      (res: any) => {
        return res.users;
      },
      (err: any) => {
        throw new Error(err);
      }
    );
  }

  async createUser(data: any) {
    const logged = this.auth.retrieveLocalUser();
    return this.api.post('master/v2/user/create', data).then(
      (res: any) => {
        if (logged.account_type !== 'master' && logged.account_type !== 'admin') {
          return this.auth.storeLocallyUser(res.user);
        }
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async updateUser(data: any) {
    const logged = this.auth.retrieveLocalUser();
    return this.api.post('v2/user/update', data).then(
      (res: any) => {
        if (logged.account_type !== 'master' && logged.account_type !== 'admin') {
          return this.auth.storeLocallyUser(res.user);
        }
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async deleteUser(data: any) {
    // so o vinicius pode apagar contas de prof por enquanto
    const logged = this.auth.retrieveLocalUser();
    if (logged.account_type !== 'master') return false;
    return this.api.post('v2/user/delete', data).then(
      (_res: any) => {
        return _res;
        // if (logged.account_type !== 'master') {
        //   return this.auth.storeLocallyUser(res.user);
        // }
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async activateUser() {
    const data = null;
    return this.api.post('v2/user/activate', data).then(
      (res: any) => {       
        //return this.auth.storeLocallyUser(res.user);
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }  


  async subscription(id: number) {
    return this.api.get(`v2/user/adm-get-subs/${id}`).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async subscriptions() {
    return this.api.get('v2/user/subscription').then(
      (res: any) => {
        const logged = this.auth.retrieveLocalUser();
        res.purchase.changed_plan = false;
        if (logged.subs.supertreinos_plan_id !== res.purchase.supertreinos_plan_id) res.purchase.changed_plan = true;
        logged.subs = res.purchase;
        logged.premium_expires_date = res.teacher.premium_expires_date;
        logged.premium_id = res.teacher.premium_id;
        this.auth.storeLocallyUser(logged);

        if (res.teacher.status === 9) {
          this.toastr.error('Sua conta está bloqueada. Contate o administrador.', '', this.helpers.toastrCfgs);
          this.auth.logout();
        }
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async subscribe(data: any) {
    return this.api.post('v2/user/subscription', data).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async cancelSubscription() {
    return this.api.delete('v2/user/subscription').then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async getTeacherGreeting(code: string = '') {
    return this.api.get('v2/user/greeting/' + code).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async getWorkoustNotes(cid: number = 0) {
    return this.api.get('v2/workouts/notes/' + cid).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async getPayments(qtd: number = 1) {
    return this.api.get('v2/user/payments/' + qtd).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async postPayments(data: any) {
    return this.api.post('v2/user/payments', data).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async admUserMassDelete(data: any) {
    return this.api.post('master/v2/user/massdelete', data).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  goToSubsPage(router: any) {
    const user = this.auth.retrieveLocalUser();
    if (user.account_type === 'teacher') {
      router.navigate(['professor/perfil'], { skipLocationChange: true, queryParams: { tab: 1 } });
    }
    if (user.account_type === 'nutri') {
      router.navigate(['nutri/perfil'], { skipLocationChange: true, queryParams: { tab: 1 } });
    }
  }

  goToProfile(router: any) {
    const user = this.auth.retrieveLocalUser();
    if (user.account_type === 'teacher') {
      router.navigate(['professor/perfil'], { skipLocationChange: true});
    }
    if (user.account_type === 'nutri') {
      router.navigate(['nutri/perfil'], { skipLocationChange: true});
    }
  }

  renewPlan(router: any) {
    const user = this.auth.retrieveLocalUser();
    if (user.account_type === 'teacher') {
      router.navigate(['professor/perfil'], { skipLocationChange: true, queryParams: { tab: 1, renew: 1 } });
    }
    if (user.account_type === 'nutri') {
      router.navigate(['nutri/perfil'], { skipLocationChange: true, queryParams: { tab: 1, renew: 1 } });
    }
  }

  getPlan(user: any) {
    let plan = 'Gratuito';

    if (user) {
      if (user.active_sub) {
        if (user.pagarme_payment_link_status === 'paid') {
          if (user.active_sub.supertreinos_plan_title) {
            return user.active_sub.supertreinos_plan_title;
          }
        }
        if (user.active_sub.pagarme_subscriber_id !== null && user.active_sub.cancel_subscription_date === null) {
          if (user.active_sub.supertreinos_plan_title) {
            return user.active_sub.supertreinos_plan_title;
          }
        }
      }
      if (user.premium_id) {
        switch (user.premium_id) {
          case 0:
            plan = 'Grátis';
            break;
          case 1:
            plan = 'Basic';
            break;
          case 2:
            plan = 'Standard';
            break;
          case 3:
            plan = 'Pro';
            break;
          case 4:
            plan = 'Black';
            break;
          case 11:
            plan = 'Lite';
            break;
          case 12:
            plan = 'Gold';
            break;
          default:
            plan = 'Grátis';
            break;
        }
      }
      if (this.helpers.expired(user)) {
        plan += ' (expirado)';
      } else {
        // plan += ' (' + user.premium_expires_date.substring(-10, 10) + ')';
      }
    }
    return plan;
  }

}

// interface User {
//   headers?: HttpHeaders;
//   params?: object;
// }
