import { environment } from '../../environments/environment';

let API = (environment.API_ENV_URLS).replace('/api', '/');

export const banners = {
  std: [
    {url: API + 'img/treinos/standard/1.jpg'},
    {url: API + 'img/treinos/standard/2.jpg'},
    {url: API + 'img/treinos/standard/3.jpg'},
    {url: API + 'img/treinos/standard/4.jpg'},
    {url: API + 'img/treinos/standard/5.jpg'},
    {url: API + 'img/treinos/standard/6.jpg'},
    {url: API + 'img/treinos/standard/7.jpg'},
    {url: API + 'img/treinos/standard/8.jpg'},
    {url: API + 'img/treinos/standard/9.jpg'},
    {url: API + 'img/treinos/standard/10.jpg'},
    {url: API + 'img/treinos/standard/11.jpg'},
    {url: API + 'img/treinos/standard/12.jpg'},
    {url: API + 'img/treinos/standard/13.jpg'},
    {url: API + 'img/treinos/standard/14.jpg'},
    {url: API + 'img/treinos/standard/15.jpg'},
    {url: API + 'img/treinos/standard/16.jpg'},
    {url: API + 'img/treinos/standard/17.jpg'},
    {url: API + 'img/treinos/standard/18.jpg'},
    {url: API + 'img/treinos/standard/19.jpg'},
    {url: API + 'img/treinos/standard/20.jpg'},
    {url: API + 'img/treinos/standard/21.jpg'},
    {url: API + 'img/treinos/standard/22.jpg'},
    {url: API + 'img/treinos/standard/23.jpg'},
    {url: API + 'img/treinos/standard/24.jpg'},
    {url: API + 'img/treinos/standard/25.jpg'},
    {url: API + 'img/treinos/standard/26.jpg'},
    {url: API + 'img/treinos/standard/27.jpg'},
    {url: API + 'img/treinos/standard/28.jpg'},
    {url: API + 'img/treinos/standard/29.jpg'},
    {url: API + 'img/treinos/standard/30.jpg'},
    {url: API + 'img/treinos/standard/31.jpg'},
    {url: API + 'img/treinos/standard/32.jpg'},
    {url: API + 'img/treinos/standard/33.jpg'},
    {url: API + 'img/treinos/standard/34.jpg'},
    {url: API + 'img/treinos/standard/35.jpg'},
    {url: API + 'img/treinos/standard/36.jpg'},
    {url: API + 'img/treinos/standard/37.jpg'},
    {url: API + 'img/treinos/standard/38.jpg'},
    {url: API + 'img/treinos/standard/39.jpg'},
    {url: API + 'img/treinos/standard/40.jpg'},
    {url: API + 'img/treinos/standard/41.jpg'},
    {url: API + 'img/treinos/standard/42.jpg'},
    {url: API + 'img/treinos/standard/43.jpg'},
    {url: API + 'img/treinos/standard/44.jpg'},
    {url: API + 'img/treinos/standard/45.jpg'},
    {url: API + 'img/treinos/standard/46.jpg'},
    {url: API + 'img/treinos/standard/47.jpg'},
    {url: API + 'img/treinos/standard/48.jpg'},
    {url: API + 'img/treinos/standard/49.jpg'},
    {url: API + 'img/treinos/standard/50.jpg'},
  ],

  free: [
    {url: API + 'img/treinos/free/1.jpg'},
    {url: API + 'img/treinos/free/2.jpg'},
    {url: API + 'img/treinos/free/3.jpg'},
    {url: API + 'img/treinos/free/4.jpg'},
    {url: API + 'img/treinos/free/5.jpg'},
    {url: API + 'img/treinos/free/6.jpg'},
    {url: API + 'img/treinos/free/7.jpg'},
    {url: API + 'img/treinos/free/8.jpg'},
    {url: API + 'img/treinos/free/9.jpg'},
    {url: API + 'img/treinos/free/10.jpg'},
    {url: API + 'img/treinos/free/11.jpg'},
    {url: API + 'img/treinos/free/12.jpg'},
    {url: API + 'img/treinos/free/13.jpg'},
    {url: API + 'img/treinos/free/14.jpg'},
    {url: API + 'img/treinos/free/15.jpg'},
    {url: API + 'img/treinos/free/16.jpg'},
    {url: API + 'img/treinos/free/17.jpg'},
    {url: API + 'img/treinos/free/18.jpg'},
    {url: API + 'img/treinos/free/19.jpg'},
    {url: API + 'img/treinos/free/20.jpg'},
    {url: API + 'img/treinos/free/21.jpg'},
    {url: API + 'img/treinos/free/22.jpg'},
    {url: API + 'img/treinos/free/23.jpg'},
    {url: API + 'img/treinos/free/24.jpg'},
    {url: API + 'img/treinos/free/25.jpg'},
    {url: API + 'img/treinos/free/26.jpg'},
    {url: API + 'img/treinos/free/27.jpg'},
    {url: API + 'img/treinos/free/28.jpg'},
    {url: API + 'img/treinos/free/29.jpg'},
    {url: API + 'img/treinos/free/30.jpg'},
    {url: API + 'img/treinos/free/31.jpg'},
    {url: API + 'img/treinos/free/32.jpg'},
    {url: API + 'img/treinos/free/33.jpg'},
    {url: API + 'img/treinos/free/34.jpg'},
    {url: API + 'img/treinos/free/35.jpg'},
    {url: API + 'img/treinos/free/36.jpg'},
    {url: API + 'img/treinos/free/37.jpg'},
    {url: API + 'img/treinos/free/38.jpg'},
    {url: API + 'img/treinos/free/39.jpg'},
    {url: API + 'img/treinos/free/40.jpg'},
    {url: API + 'img/treinos/free/41.jpg'},
    {url: API + 'img/treinos/free/42.jpg'},
    {url: API + 'img/treinos/free/43.jpg'},
    {url: API + 'img/treinos/free/44.jpg'},
    {url: API + 'img/treinos/free/45.jpg'},
    {url: API + 'img/treinos/free/46.jpg'},
  ]
}
