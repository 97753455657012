import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { HelpersProvider } from '../../providers/helpers/helpers';
import { I18nProvider } from '../../providers/i18n/i18n';


@Component({
  selector: 'dialog-select-date',
  templateUrl: 'dialog-select-date.html',
})

export class DialogSelectDateComponent {
  constructor(
    private helpers: HelpersProvider,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogSelectDateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
  }

  STRINGS: any;
  PAGE: any;

  date_selected = null;
  user: any = [];

  ngOnInit() {
    if (this.data.date_selected !== null) {
      if (this.data.date_selected.length < 11) this.data.date_selected = this.data.date_selected + ' 23:59:59';
    }
    // console.log('data.date_selected: ' + this.data.date_selected);
    // if (this.data.date_selected === null) {
    //   this.date_selected = new Date();
    // } else {
    //   this.date_selected = new Date(this.data.date_selected);
    // }
  }


}
