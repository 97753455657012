import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

import { DialogComponent } from '../../../ui/dialog/dialog';

import { ApiProvider } from '../../../providers/api/api';
import { HelpersProvider } from '../../../providers/helpers/helpers';
import { AuthService } from '../../../providers/auth/auth.service';
import { CustomerProvider } from '../../../providers/customer/customer';
import { UserProvider } from '../../../providers/user/user';
import { I18nProvider } from '../../../providers/i18n/i18n';

@Component({
  selector: 'component-customer-anamnesis',
  templateUrl: './anamnesis.component.html',
  styleUrls: ['./anamnesis.component.scss']
})
export class CustomerAnamnesisComponent implements OnInit {
  PAGE: any;
  STRINGS: any;

  protected headers: any;
  @Input() anamnesis: any = null;
  @Input() studentData: any = null;
  @Input() admMode: boolean = false;

  loading: boolean = false;
  callLogin: boolean = true;
  done: boolean = false;
  showForm: boolean = false;

  teacherCode: string = '';
  studentEmail: string = '';
  anamnesisLink: string = '';
  language: string = '';
  teacher = { id: 0, name: 'Professor', avatar: null, link: '' };
  user: any = null;
  anamnesisList: any = null;
  anamnesisCreatedAt: any = null;
  anamnesisId: number = 0;

  studentSelected: boolean = false;
  studentId: number = 0;

  queixas = [];
  doencas = [];
  frequencia = [];
  favoritos = [];

  anamneseForm = new FormGroup({
    id: new FormControl(''),
    complain_ids: new FormControl(''),
    complain_obs: new FormControl(''),
    disease_ids: new FormControl(''),
    disease_obs: new FormControl(''),
    pessoal_cirurgy: new FormControl(''),
    pessoal_trauma: new FormControl(''),
    pessoal_obs: new FormControl(''),
    family_colesterol: new FormControl(''),
    family_diabetes: new FormControl(''),
    family_obs: new FormControl(''),
    habits_alchool: new FormControl(''),
    habits_smoke: new FormControl(''),
    habits_acpill: new FormControl(''),
    habits_obs: new FormControl(''),
    treino_tempo: new FormControl(''),
    treino_freq: new FormControl(''),
    treino_periodo: new FormControl(''),
    treino_tempo_extra: new FormControl(''),
    treino_favs: new FormControl(''),
    treino_inicio: new FormControl(''),
    treino_obs_extra: new FormControl(''),
    observacoes: new FormControl(''),
  });

  passwordForm = new FormGroup({
    password: new FormControl(''),
  });

  studentDateStart: any;

  constructor(
    public toastr: ToastrService,
    public auth: AuthService,
    public api: ApiProvider,
    public helpers: HelpersProvider,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public studentProv: CustomerProvider,
    public userProv: UserProvider,
    public i18n: I18nProvider,
  ) {
    const qs = this.helpers.getUrlParam();
    if (qs.l !== null) this.helpers.changeLang(qs.l);


    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('CustomerAnamnesisComponent');

    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};
    // this.teacherCode = this.route.snapshot.params.code;

    this.route.queryParams.subscribe(
      (params: any) => {
        // Defaults to 0 if no query param provided.
        this.teacherCode = params['professor'] || '';
        this.studentEmail = params['aluno'] || '';
      }
    );

    this.queixas = [
      { id: 1, nome: this.PAGE.queixa_dor_cabeca, selecionada: false },
      { id: 2, nome: this.PAGE.queixa_dor_pescoco, selecionada: false },
      { id: 3, nome: this.PAGE.queixa_dor_peito, selecionada: false },
      { id: 4, nome: this.PAGE.queixa_dor_ombros, selecionada: false },
      { id: 5, nome: this.PAGE.queixa_dor_lombar, selecionada: false },
      { id: 6, nome: this.PAGE.queixa_falta_ar_esforco, selecionada: false },
      { id: 7, nome: this.PAGE.queixa_falta_ar_repouso, selecionada: false },
      { id: 8, nome: this.PAGE.queixa_palpitacoes, selecionada: false },
      { id: 9, nome: this.PAGE.queixa_dor_joelhos, selecionada: false },
      { id: 10, nome: this.PAGE.queixa_tontura, selecionada: false },
      { id: 11, nome: this.PAGE.queixa_sem_dores, selecionada: false },
    ];
  
    this.doencas = [
      { id: 1, nome: this.PAGE.doenca_diabetes, selecionada: false },
      { id: 2, nome: this.PAGE.doenca_colesterol, selecionada: false },
      { id: 3, nome: this.PAGE.doenca_pressao_alta, selecionada: false },
      { id: 4, nome: this.PAGE.doenca_nao_tem, selecionada: false },
    ];
  
    this.frequencia = [
      { id: 1, nome: '2x', selecionada: false },
      { id: 2, nome: '3x', selecionada: false },
      { id: 3, nome: '4x', selecionada: false },
      { id: 4, nome: '5x', selecionada: false },
      { id: 5, nome: '6x', selecionada: false },
      { id: 6, nome: '7x', selecionada: false },
    ];
  
    this.favoritos = [
      { id: 1, nome: this.PAGE.fav_serie_trad, selecionada: false },
      { id: 2, nome: this.PAGE.fav_serie_circ, selecionada: false },
      { id: 3, nome: this.PAGE.fav_peso_corpo, selecionada: false },
      { id: 4, nome: this.PAGE.fav_peso_cardio, selecionada: false },
    ];    
  }
  ngOnInit() {
    console.log('anamnesis ok');

    this.anamneseForm.reset();
    this.studentSelected = false;
    this.studentId = 0;

    if (this.admMode === false) {
      // se admMode for falso, quer dizer que e o aluno preenchendo a anamnese
      this.showForm = true;
      this.getTeacherGreeting();

    } else {
      // caso contrario e o professor acessando a lista de anamneses
      // if (this.anamnesis !== '' && this.anamnesis !== null) {
      //   this.patchAnamnesis(this.anamnesis);
      // }
      this.done = false;
      this.callLogin = false;
      this.teacher = this.auth.retrieveLocalUser();
      this.anamnesisLink = window.location.host + '?p=' + this.teacher.link + '&s=' + this.studentData.email + '&l=' + this.STRINGS.lang;
      this.getStudentAnamnesis();
    }
  }

  ngOnChanges() {
    this.getStudentAnamnesis();
    // this.loading = true;
    // setTimeout(() => {
    //   this.loading = false;
    // }, 400);
  }


  getTeacherGreeting() {
    this.loading = true;
    // busca o avatar e saudacao do professor
    this.userProv.getTeacherGreeting(this.teacherCode).then(
      (res: any) => {
        this.loading = false;
        this.teacher = res.teacher;
        if (this.user === null) this.callLogin = true;
      },
      (err: any) => {
        console.log(err);
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        this.teacher = null;
        this.loading = false;
      }
    );
  }

  getStudentAnamnesis() {
    this.loading = true;
    this.studentProv.getAnamnesis(this.studentData.id).then(
      (res: any) => {
        this.anamnesisList = (res.anamns.length > 0) ? res.anamns : null;
        this.loading = false;
      },
      (err: any) => {
        console.log(err);
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        this.loading = false;
      }
    );
  }

  selectAnamnesis(data: any = null) {
    this.showForm = true;
    this.patchAnamnesis(data.anamnesis);
    this.anamnesisId = data.id;
    this.anamnesisCreatedAt = data.created_at;
  }

  backAnamnesis() {
    this.anamneseForm.reset();
    this.showForm = false;
  }

  patchAnamnesis(data: string = '') {
    const anamnesis = JSON.parse(data);
    // console.log(anamnesis);
    this.anamneseForm.patchValue(anamnesis);
    this.queixas = (anamnesis.complain_ids) ? anamnesis.complain_ids : null;
    this.doencas = (anamnesis.disease_ids) ? anamnesis.disease_ids : null;
    this.frequencia = (anamnesis.treino_freq) ? anamnesis.treino_freq : null;
    this.favoritos = (anamnesis.treino_favs) ? anamnesis.treino_favs : null;
    this.favoritos = (anamnesis.treino_favs) ? anamnesis.treino_favs : null;

    this.studentDateStart = new Date(anamnesis.treino_inicio);
    const offsetMs = this.studentDateStart.getTimezoneOffset() * 3 * 60 * 1000;
    this.studentDateStart = new Date(this.studentDateStart.getTime() + offsetMs);
  }


  login() {
    if (this.user === null) {
      this.loading = true;
      let email = this.studentEmail;
      let pass = this.passwordForm.value.password;

      // valida o email
      this.studentProv.getCustomerLogin(email, pass).then(
        (res: any) => {
          // verify if user already fill the anamnesis
          this.loading = false;
          this.user = res.user;
          this.auth.storeToken(res.token);
          this.callLogin = false;
          if (this.user.anamnesis) {
            this.done = true;
          }
          // console.log(this.user);
        },
        (_err: any) => {
          this.toastr.error(this.PAGE.senha_invalida, '', this.helpers.toastrCfgs);
          // this.toastr.error(err);
          this.loading = false;
        }
      );
    }
  }

  new() {
    this.anamneseForm.reset();
    this.studentSelected = false;
    this.studentId = 0;
    // this.studentForm.patchValue({ status: 2 });
  }


  save() {
    this.validateAllFormFields(this.anamneseForm);

    // if (this.studentForm.value.password !== this.studentForm.value.password_confirmation) {
    //   this.toastr.error('As senhas não conferem!');
    //   return false;
    // }

    const dataString = JSON.stringify(this.anamneseForm.value);
    const data = JSON.parse(dataString);
    if (data.treino_inicio) {
      data.treino_inicio = data.treino_inicio.substring(-10, 10);
    }
    data.customer_id = this.user.id;
    data.user_id = this.teacher.id;
    data.complain_ids = this.queixas;
    data.disease_ids = this.doencas;
    data.treino_freq = this.frequencia;
    data.treino_favs = this.favoritos;

    const anamnesis = {
      id: this.user.id,
      details: {
        anamnesis: JSON.stringify(data)
      }
    }

    // console.log(anamnesis);

    this.loading = true;
    this.studentProv.sendAnamnesis(anamnesis).then(
      (_user: any) => {
        this.loading = false;
        this.done = true;
        localStorage.removeItem('LoggedInUser');
        this.toastr.success(this.PAGE.anamnese_add_sucesso, '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        this.loading = false;
        console.log(err);
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      }
    );

  }

  delete(id: number = 0) {
    const msg = this.PAGE.anamnese_apagar;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.anamnese_apagar_title, message: msg, confirmation: 'Sim'}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Sim') {
        this.loading = true;
        this.studentProv.admDeleteAnamnesis(id).then(
          (res: any) => {
            this.loading = false;
            this.anamnesisList = (res.anamns.length > 0) ? res.anamns : null;
            this.toastr.success(this.PAGE.anamnese_apagar_sucesso, '', this.helpers.toastrCfgs);
            this.backAnamnesis();
          },
          (err: any) => {
            this.loading = false;
            console.log(err);
            this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);    
          }
        );
      }
    });
  }

  updateAllComplete() {
    //
  }

  getUserFormValidationMessage(el: any) {
    if (el.errors.required) {
      return this.STRINGS.campo_obrigatorio;
    }

    if (el.errors.incorrect || el.errors.pattern) {
      return this.STRINGS.campo_invalido;
    }
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  toggleClass(item: any) {
    item.active = !item.active;
  }

  copyTransfer(url: string = '') {
    const navShare: any = window.navigator;
    if (navShare.share !== undefined) {
      // console.log('share ok');
      navShare.share({
        title: 'ST Coach',
        text: this.PAGE.questionario,
        url: url,
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
    } else {
      // console.log('share not ok');
      if (this.helpers.copyTextToClipboard(url)) {
        this.toastr.success(this.STRINGS.link_copiado, '', this.helpers.toastrCfgs);
      } else {
        this.toastr.error(this.STRINGS.link_copiado_erro, '', this.helpers.toastrCfgs);
      }        
    }
  }

}
