import { Component, EventEmitter, OnInit, OnChanges, Output, Input, ViewChild } from '@angular/core';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DialogComponent } from '../../ui/dialog/dialog';

import { HelpersProvider } from '../../providers/helpers/helpers';
import { ApiProvider } from '../../providers/api/api';
import { BannerProvider } from '../../providers/banner/banner';

import { environment } from '../../environments/environment';


@Component({
  selector: 'app-block-banners',
  templateUrl: './banners.html'
})
export class BannersComponent implements OnInit, OnChanges {
  @ViewChild(DropzoneComponent, {static: false}) componentRef?: DropzoneComponent;

  @Output() setBannerItem = new EventEmitter();
  @Output() setReloadBanners = new EventEmitter();

  @Input() item: any = null;
  @Input() form: boolean = false;
  @Input() reloadBanners: number = 0;

  loading: boolean = false;

  @Input() banner: any = {
    id: <number> 0,
    premium: <number> 0,
    published: <number> 1,
    banner_url: <string> '',
    payment_text: <string> '',
  };

  banners: any = [];

  bannerName = new FormControl('', [Validators.required]);
  bannerImgUrl = new FormControl('', [Validators.required]);

  public config: DropzoneConfigInterface = {
    paramName: 'cover_img',
    thumbnailMethod: 'contain',
    thumbnailHeight: null,
    thumbnailWidth: null,
    addRemoveLinks: true,
    dictRemoveFile: 'REMOVER IMAGEM',
    dictCancelUpload: 'CANCELAR ENVIO',
    dictCancelUploadConfirmation: 'CONFIRMA CANCELAR ENVIO?',
    dictInvalidFileType: 'ARQUIVO INVÁLIDO',
    maxFiles: 1,
    // autoProcessQueue: false,
  };

  protected headers: any;
  protected API = (environment.API_ENV_URLS).replace('/api', '/');

  constructor(
    public bannerProvider: BannerProvider,
    public helpers: HelpersProvider,
    public api: ApiProvider,
    public toastr: ToastrService,
    public dialog: MatDialog,
  ) {
    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};
  }

  ngOnInit() {
    this.loading = false;
    if (this.form === true) {
      this.updateDropzoneParams();
    } else {
      this.getBanners();
    }
  }

  ngOnChanges() {
    console.log('fired ngOnChanges');
    if (this.form === true) {
      this.prepareForm();
    } else {
      if (this.reloadBanners > 0) {
        this.getBanners();
      }
    }
    if (this.item !== null) {
      this.prepareForm();
      this.banner = Object.assign({}, this.item);
      if ((this.banner.published === 0) || (this.banner.published === '0')) {
        this.banner.published = false;
      } else {
        this.banner.published = true;
      }
      if ((this.banner.premium === 0) || (this.banner.premium === '0')) {
        this.banner.premium = false;
      } else {
        this.banner.premium = true;
      }
      this.updateDropzoneParams();
    }
  }

  goBack() {
    this.reloadBanners++;
    this.setReloadBanners.emit(this.reloadBanners++);
  }

  selectBanner(banner: any) {
    this.setBannerItem.emit(banner);
  }


  getBanners() {
    if (this.form) {
      return;
    }

    this.loading = true;
    this.bannerProvider.get().then(
      (res: any) => {
        this.banners = res.banners;
        this.loading = false;
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
      });
  }

  prepareForm() {
    this.setBannerItem.emit(null);
    this.banner = this.helpers.resetObject(this.banner);
    this.banner.id = 0;
    this.banner.published = 1;
    this.banner.premium = 0;
    this.banner.banner_url = '';
    this.banner.payment_text = '';
    this.updateDropzoneParams();
  }

  newBanner() {
    this.prepareForm();
    this.item = this.banner;
  }


  saveBanner() {
    this.loading = true;

    if (this.banner.id > 0) {
      this.updateBanner();
    }
    if (this.banner.id === 0) {
      this.createBanner();
    }
  }

  updateBanner() {
    const itemUploaded = Object.assign({}, this.banner);

    this.bannerProvider.patchBannerItem(itemUploaded).then(
      (res: any) => {
        this.banner = res.banner;
        this.toastr.success('Banner atualizado com sucesso!', '', this.helpers.toastrCfgs);
        this.loading = false;
        this.goBack();
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(err.error.err, '', this.helpers.toastrCfgs);
        this.loading = false;
      });
  }

  createBanner() {
    this.bannerProvider.postBannerItem(this.banner).then(
      (res: any) => {
        this.banner = res.banner;
        this.toastr.success('O Banner foi cadastrado com sucesso!', '', this.helpers.toastrCfgs);
        this.loading = false;
        this.reloadBanners++;
        this.prepareForm();
        this.goBack();
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(err.error.err, '', this.helpers.toastrCfgs);
        this.loading = false;
      });
  }

  deleteBanner(id = null) {
    this.deleteBannerConfirmation(id);
  }

  deleteBannerConfirmation(id: any): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: 'Apagar Exercício', message: 'Deseja realmente apagar este Exercício?', confirmation: 'deletar'}
    });

    if (id === null) { id = this.banner.id; }

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed: ' + result);
      if (result === 'deletar') {
        this.prepareForm();
        this.loading = true;
        this.bannerProvider.deleteBannerItem(id).then(
          (_res: any) => {
            this.toastr.success('O Banner foi apagado com sucesso!', '', this.helpers.toastrCfgs);
            this.getBanners();
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(err.error.err, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
      }
    });
  }

  //dropzone
  onAddedFile(_args: any) {
    // console.log('adicionando arquivo...');
  }

  onSendingFile(_$event: any) {
    //
  }

  onRemovedFile(_$event: any) {
    //
  }

  onUploadError(_$event: any) {
    console.log('upload error');
    console.log(_$event);
  }

  onUploadSuccess($event: any) {
    this.loading = false;
    console.log($event);
    // this.workout = $event[1].workout;
    this.banner.id = $event[1].banner.id;
    this.banner.banner_url = $event[1].banner.banner_url;
    this.toastr.success('Banner enviado com sucesso!', '', this.helpers.toastrCfgs);

    this.resetDropzoneUploads();
    this.config.url = this.api.API_URL + '/v2/payment/banners/' + this.banner.id;
    this.config.params = {
      '_method': 'patch',
      'payment_text': this.banner.payment_text,
      'banner_url': this.banner.banner_url,
    };
    this.goBack();
  }

  resetDropzoneUploads(): void {
    this.componentRef.directiveRef.reset(true);
  }

  updateDropzoneParams(): void {
    this.config.headers = this.headers;

    this.config.params = {
      'payment_text': this.banner.payment_text,
      'banner_url': this.banner.banner_url,
    };

    if (this.banner.id > 0) {
      this.config.url = this.api.API_URL + '/v2/payment/banners/' + this.banner.id;
      this.config.params._method = 'PATCH';
    } else {
      this.config.url = this.api.API_URL + '/v2/payment/banners/';
    }
  }
  //dropzone

}
