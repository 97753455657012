import { Component, OnChanges, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { MatPaginatorIntl } from '@angular/material/paginator';

import { ToastrService } from 'ngx-toastr';
import { ProgramProvider } from '../../../providers/program/program';
import { CustomerProvider } from '../../../providers/customer/customer';
import { MenuProvider } from '../../../providers/menu/menu';
import { HelpersProvider } from '../../../providers/helpers/helpers';

import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'dashboard-ultimas-prescricoes',
  templateUrl: './ultimas-prescricoes.html',
})
export class DashboardUltimasPrescricoesComponent implements OnChanges, OnInit {

    @Input() user: any;
    loading = false;
    currentItemsToShow = [];
    menusPerpage: number = 7;

    data: any[];
    menus: Array<any> = null;
    qtdMenus: string = '';

    constructor(
      public toastr: ToastrService,
      public programProv: ProgramProvider,
      public student: CustomerProvider,
      public menusProv: MenuProvider,
      public router: Router,
      public helpers: HelpersProvider,
      private paginator: MatPaginatorIntl,
      private device: DeviceDetectorService,
    ) {
      //
      // Object.assign(this, { data: this.data });
      this.paginator.itemsPerPageLabel = 'Itens por página';
      this.paginator.previousPageLabel = 'Anterior';
      this.paginator.nextPageLabel = 'Próxima';
      this.paginator.getRangeLabel = (page: number, pageSize: number, length: number) => {
        length = Math.max(length, 0);
        if (length == 0 || pageSize == 0) {
          return '0 de $length';
        }
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return (startIndex + 1) + ' - ' + endIndex + ' de ' + length;
      };
    }

    ngOnInit() {
      // console.log(' - DashboardUltimasPrescricoesComponent OnInit');
      this.getMenus();
      this.data = [];

      this.menusPerpage = (this.device.isMobile()) ? 3 : 7;
    }

    ngOnChanges() {
      this.loading = true;
      this.processData();
    }

    processData() {
      setTimeout(() => {
        if (this.user.subs.menus !== undefined ) {
          this.qtdMenus = ' - ' + this.user.subs.menus.total + '/' + this.user.subs.menus.max;
        }
          this.loading = false;
      }, 500);      
    }

    onSelect(_data: any): void {
      // console.log('Item clicked', JSON.parse(JSON.stringify(_data)));
    }

    onActivate(_data: any): void {
      // console.log('Activate', JSON.parse(JSON.stringify(_data)));
    }

    onDeactivate(_data: any): void {
      // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
    }

    templatePhoneEmail(student: any) {
      // return `<div class="color-red">Top Template<button style="background: white; color: black">Press me</button></div>`;
      if (student.details == null) {
          return '';
      }
      return  '<div class="inner-tooltip">' +
              '<div class="email">' + (student.email)+ '</div>' +
              '<div class="phone">' + (student.details.phone || 'Sem telefone')+ '</div>' +
              '<div class="arrow-down"></div>' +
              '</div>';
    }

    onPageChange($event: any) {
      this.currentItemsToShow = this.menus.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
    }

    goToMenus() {
      this.router.navigate(['nutri/menus'], { skipLocationChange: true });
    }

    goToStudent(student: any, _blank: boolean = false) {
      // console.log(student.details.phone);
      this.helpers.clearState();
      let newWindow = window.location.href + '?ct=cst&ci=' + student.email;
      let basicUrl = 'clientes/' + student.email;

      if (this.user.account_type === 'teacher') {
        basicUrl = 'professor/alunos/' + student.id;
        newWindow = window.location.href + '?ct=std&ci=' + + student.id;
      }

      // this.router.navigate([], { skipLocationChange: true }).then(_result => {  window.open(basicUrl, '_blank'); });
      if (_blank) {
        this.router.navigate([], { skipLocationChange: true }).then(_result => {  window.open(newWindow, '_blank'); });
        // this.router.navigate([newWindow], { skipLocationChange: false });
      } else {
        this.router.navigate([basicUrl], { skipLocationChange: true });
      }
    }

    goToWhats(student: any) {
      // console.log(student);
      if (student.details.phone === null) {
        this.toastr.error('O telefone do cliente não foi cadastrado!', '', this.helpers.toastrCfgs)
      } else {
        // window.open('https://api.whatsapp.com/send?phone=+' + student.details.phone, '_system', 'location=yes');
        window.open('https://wa.me/' + student.details.ddi.replace(/^0+(?!\.|$)/, '') + student.details.phone, '_system', 'location=yes');
      }
    }

    goToTutorial() {
      window.open('https://www.stcoach.app/tutoriais-aluno', '_system', 'location=yes');
    }

    returnBarPct(premium_expires_date: any) {
      if (premium_expires_date) {
        const pct = this.helpers.checkPremiumExpire(premium_expires_date, '%');
        // console.log(pct);
        return pct;
      }
    }

    returnBarClass(premium_expires_date: any) {
      let expire_percent = this.returnBarPct(premium_expires_date);

      let expire_class = 'success';

      if (expire_percent < 99) {
        expire_class = 'start';
      }
      if (expire_percent < 65) {
        expire_class = 'alert';
      }
      if (expire_percent < 35) {
        expire_class = 'danger';
      }
      if (expire_percent === 'expirado') {
        expire_class = 'danger';
      }

      // console.log(expire_class);
      return expire_class;
    }
 

    getMenus(force: boolean = false) {
      this.loading = true;
      // busca os alunos
      this.menus = this.helpers.getLocallyStored('menus');
      if (this.menus === null || force === true) {
        this.menusProv.index().then(
          (res: any) => {
            this.menus = res.menus;
            this.helpers.storeLocally('menus', res.menus);
            this.currentItemsToShow = this.menus.slice(0, this.menusPerpage);
            this.processData();
            this.loading = false;
          },
          (err: any) => {
            this.toastr.error(err, '', this.helpers.toastrCfgs);
            this.currentItemsToShow = [];
            this.loading = false;
          }
        );
      } else {
        // loading fake pra dar sensacao de carregamento rapido
        this.menus = null;
        setTimeout(() => {
          this.menus = this.helpers.getLocallyStored('menus');
          this.currentItemsToShow = this.menus.slice(0, this.menusPerpage);
          this.processData();
          this.loading = false;
        }, 500);
      }
    }
}
