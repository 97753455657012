import { Component, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from '../../ui/dialog/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { CustomerProvider } from '../../providers/customer/customer';
import { HelpersProvider } from '../../providers/helpers/helpers';
import { ValuationProvider } from '../../providers/valuation/valuation';
import { I18nProvider } from '../../providers/i18n/i18n';

import { environment } from '../../environments/environment';

@Component({
  selector: 'dialog-feedbacks',
  templateUrl: 'dialog-feedbacks.html',
})

export class DialogFeedbacksComponent {
  constructor(
    public toastr: ToastrService,
    private router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogFeedbacksComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = {feedbacks: null},
    public helpers: HelpersProvider,
    public valuationProv: ValuationProvider,
    public i18n: I18nProvider,
    public studentProv: CustomerProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('DashboardFeedbacksComponent');
    this.dataSource = new MatTableDataSource();
  }

  ENV: any;
  PAGE: any;
  STRINGS: any;

  protected API = (environment.API_ENV_URLS).replace('/api', '/');

  loading: boolean = false;
  user: any = { gender: 'masc' };

  dataSource: MatTableDataSource<any>;
  displayedColumns = ['aluno', 'programa', 'treino', 'data', 'feedback'];
  feedbacks: any = [];
  
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  ngOnInit() {
    console.log(this.data);
    this.loading = true;
    this.ENV = environment;
    this.user = JSON.parse(localStorage.getItem('user'));
    this.getFeedbacks();
  }


  handlePageEvent(evt: any = null) {
    this.helpers.storeLocally('lastPageSizeValuation', evt.pageSize);
  }

  openFeedback(f: any = null) {
    const title = this.PAGE.feedback_title;
    const inner_msg = '' +
      '<p><i>'+this.PAGE.aluno+':</i> '+f.customer_name+'</p>' +
      '<p><i>'+this.PAGE.titulo_programa+':</i> '+f.program_name+'</p>' +
      '<p><i>'+this.PAGE.titulo_treino+':</i> '+f.training_name+'</p>' +
      '<pre><i>'+this.PAGE.feedback+':</i> '+f.feedback+'</pre>' +
      '';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '90%',
      panelClass: 'mat-dialog-feedback-details',
      data: {title: title, inner_msg: inner_msg, confirmation: 'deletar', bt_cancel_hide: true}
    });
  }

  getFeedbacks() {
    this.loading = true;
    this.studentProv.getAllFeedbacks().then(
      (res: any) => {
        this.feedbacks = res.feedbacks;
        this.dataSource = new MatTableDataSource(this.feedbacks);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;       
        this.loading = false;    
      },
      (err: any) => {
        this.loading = false;
        console.log(err);
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      }
    );
  }
}

// export interface DialogData {
//   title: string;
//   message: string;
//   confirmation: string;
//   bt_ok?: string;
// }

