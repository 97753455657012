import { Injectable } from '@angular/core';
import { ApiProvider } from '../api/api';

@Injectable()
export class FileProvider {

  constructor(
    public api: ApiProvider
  ) {
    //
  }

  index() {
    const url = 'v2/files';
    return this.api.get(url);
  }

  get(id: any) {
    return this.api.get(`v2/files/${id}`);
  }

  patch(food: any) {
    food._method = 'PATCH';
    return this.api.post(`v2/files/${food.id}`, food);
  }

  post(food: any) {
    return this.api.post(`v2/files/`, food);
  }

  clone(food: any) {
    return this.api.post(`v2/files/clone`, food);
  }

  delete(id: any) {
    return this.api.delete(`v2/files/${id}`);
  }


  async admCustomerToggleAllFileStudents(fid: number, v: boolean = false) {
    let addremove = 'removeAll';
    if (v) addremove = 'addAll';
    return this.api.post('v2/files/' + addremove + '/' + fid).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }


  indexDBFiles() {
    return this.api.get(`v2/adm/dbfiles/list`);
  }

}
