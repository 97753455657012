import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { LOCALE_ID } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeviceDetectorModule, DeviceDetectorService } from 'ngx-device-detector';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';

import { MatNativeDateModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDatepickerModule, } from '@angular/material/datepicker';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { DragDropModule } from '@angular/cdk/drag-drop';

import { AppRoutingModule } from './app-routing.module';

import { AuthGuardService } from '../providers/guards/auth-guard.service';
import { AdmGuardService } from '../providers/guards/adm-guard.service';
import { TeacherGuardService } from '../providers/guards/teacher-guard.service';
import { NutriGuardService } from '../providers/guards/nutri-guard.service';
import { PartnerGuardService } from '../providers/guards/partner-guard.service';
import { AuthService } from '../providers/auth/auth.service';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
 // Change this to your upload POST address:
  url: 'http://142.93.235.119/api',
  maxFilesize: 50,
  acceptedFiles: 'image/*'
};
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { ToastrModule } from 'ngx-toastr';
import { ImageCropperModule } from 'ngx-image-cropper';

// FILTERS
import { LibraryFilterPipe } from '../components/library/library.filter.pipe';
import { StudentFilterPipe } from '../pages/alunos/alunos.filter.pipe';
import { ProgramFilterPipe } from '../pages/programas/programas.filter.pipe';
import { FoodFilterPipe } from '../pages/nutricao/nutricao.filter.pipe';
import { TeacherFilterPipe } from '../pages/professores/adm-lista/professores.filter.pipe';
import { ArquivosFilterPipe } from '../pages/professores/arquivos/arquivos.filter.pipe';
import { MenusFilterPipe } from '../pages/professores/menus/menus.filter.pipe';

// PROVIDERS
import { ApiProvider } from '../providers/api/api';
import { HelpersProvider } from '../providers/helpers/helpers';
import { PurchaseService } from '../providers/purchase/purchase.service';
import { ProgramProvider } from '../providers/program/program';
import { CustomerProvider } from '../providers/customer/customer';
import { UserProvider } from '../providers/user/user';
import { UserPurchaseService } from '../providers/user/purchase';
import { TrainingProvider } from '../providers/training/training';
import { WorkoutProvider } from '../providers/workout/workout';
import { GoalProvider } from '../providers/goal/goal';
import { GroupsMuscleProvider } from '../providers/groups-muscle/groups-muscle';
import { LibraryProvider } from '../providers/library/library';
import { BannerProvider } from '../providers/banner/banner';
import { ConfigProvider } from '../providers/config/config';
import { ProgramCategoryProvider } from '../providers/program/category';
import { FoodProvider } from '../providers/food/food';
import { CacheProvider } from '../providers/cache/cache';
import { FileProvider } from '../providers/file/file';
import { MenuProvider } from '../providers/menu/menu';
import { MealProvider } from '../providers/meal/meal';
import { ValuationProvider } from '../providers/valuation/valuation';
import { I18nProvider } from '../providers/i18n/i18n';

import { DownloadService } from '../providers/file/download.service';
import { SAVER, getSaver } from '../providers/file/saver.provider';

// NAVIGATION, MENUS, BARS
import { MaterialSidenavComponent } from '../ui/material-sidenav/material-sidenav.component';
import { MaterialNavComponent } from '../ui/material-nav/material-nav.component';
import { BasicNavComponent } from '../ui/basic-nav/basic-nav.component';
import { NavTeacherComponent } from '../ui/nav-teacher/nav-teacher.component';
import { NavMasterComponent } from '../ui/nav-master/nav-master.component';
import { NavCustomerComponent } from '../ui/nav-customer/nav-customer.component';
import { NavAdminComponent } from '../ui/nav-admin/nav-admin.component';
import { NavSimpleComponent } from '../ui/nav-simple/nav-simple.component';
import { NavPartnerComponent } from '../ui/nav-partner/nav-partner.component';
import { NavNutriComponent } from '../ui/nav-nutri/nav-nutri.component';

// USER INTERFACE
import { DialogComponent } from '../ui/dialog/dialog';
import { DialogLightComponent } from '../ui/dialog-light/dialog-light';
import { DialogLoginComponent } from '../ui/dialog-login/dialog-login';
import { DialogSelectBannerComponent } from '../ui/dialog-select-banner/dialog-select-banner';
import { DialogValuationComponent } from '../ui/dialog-valuation/dialog-valuation';
import { DialogSelectFoodComponent } from '../ui/dialog-select-food/dialog-select-food';
import { DialogSelectTrainingBannerComponent } from '../ui/dialog-select-training-banner/dialog-select-training-banner';
import { DialogSelectDateComponent } from '../ui/dialog-select-date/dialog-select-date';
import { DialogLogoProComponent } from '../ui/dialog-logo-pro/dialog-logo-pro';
import { DialogSendToTeacherComponent } from '../ui/dialog-send-to-teacher/dialog-send-to-teacher';
import { DialogI18nComponent } from '../ui/dialog-i18n/dialog-i18n';
import { DialogTutorialComponent } from '../ui/dialog-tutorial/dialog-tutorial';
import { DialogInputComponent } from '../ui/dialog-input/dialog-input';
import { DialogInputCustomComponent } from '../ui/dialog-input-custom/dialog-input-custom';
import { DialogLinkComponent } from '../ui/dialog-link/dialog-link';
import { DialogCancelPlanComponent } from '../ui/dialog-cancel-plan/dialog-cancel-plan';
import { DialogExportPDFComponent } from '../ui/dialog-export-pdf/dialog-export-pdf';
import { DialogFeedbacksComponent } from '../ui/dialog-feedbacks/dialog-feedbacks';

// PAGES
import { ConfigComponent } from '../pages/config/config.component';
import { ProgramasComponent } from '../pages/programas/programas.component';
import { TreinosComponent } from '../pages/treinos/treinos.component';
import { ExerciciosComponent } from '../pages/exercicios/exercicios.component';
import { PerfilComponent } from '../pages/st-clientes/perfil/perfil.component';
import { LoginComponent } from '../pages/login/login.component';
import { ClientesComponent } from '../pages/st-clientes/clientes.component';
import { UsuariosComponent } from '../pages/usuarios/usuarios.component';
import { NotFoundComponent } from '../pages/not-found/not-found.component';
import { BibliotecaComponent } from '../pages/biblioteca/biblioteca.component';
import { AvisosComponent } from '../pages/avisos/avisos.component';
import { CadastroComponent } from '../pages/cadastro/cadastro.component';
import { ProfessoresComponent } from '../pages/professores/professores.component';
import { ListaAdmProfessoresComponent } from '../pages/professores/adm-lista/professores.component';
import { ArquivosComponent } from '../pages/professores/arquivos/arquivos.component';
import { MenusComponent } from '../pages/professores/menus/menus.component';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { NutricaoComponent } from '../pages/nutricao/nutricao.component';
import { StoreComponent } from '../pages/store/store.component';
import { AlunosComponent } from '../pages/alunos/alunos.component';
import { AlunosWaitlineComponent } from '../pages/alunos/waitline/waitline.component';
import { AlunosPrecadComponent } from '../pages/alunos/precad/precad.component';
import { AlunosAnamneseComponent } from '../pages/alunos/anamnese/anamnese.component';
import { AlunosCadastroComponent } from '../pages/alunos/cadastro/cadastro.component';

// MODULES
import { ProgramsComponent } from '../components/programs/teacher/programs';
import { ProgramsMasterComponent } from '../components/programs/master/programs-master';
import { TrainingsComponent } from '../components/trainings/trainings';
import { WorkoutsComponent } from '../components/workouts/workouts';
import { AdminAccountComponent } from '../components/admin-account/admin-account';
import { LibraryComponent } from '../components/library/library';
import { BannersComponent } from '../components/banners/banners';
import { ProgramCategoriesComponent } from '../components/config/program-categories/program-categories.component';
import { CustomerGoalsComponent } from '../components/config/customer-goals/customer-goals.component';
import { GroupsMuscleComponent } from '../components/config/groups-muscle/groups-muscle.component';
import { WorkoutVideosComponent } from '../components/config/workout-videos/workout-videos.component';
import { DashboardProfileComponent } from '../components/dashboard/profile/profile';
import { DashboardTotalAlunosComponent } from '../components/dashboard/total-alunos/total-alunos';
import { DashboardTotalProgramsComponent } from '../components/dashboard/total-programs/total-programs';
import { DashboardTempoConsultoriaComponent } from '../components/dashboard/tempo-consultoria/tempo-consultoria';
import { DashboardUltimasPrescricoesComponent } from '../components/dashboard/ultimas-prescricoes/ultimas-prescricoes';
import { DashboardProgramsGoalsComponent } from '../components/dashboard/programs-goals/programs-goals';
import { DashboardTrainingsDoneComponent } from '../components/dashboard/trainings-done/trainings-done';
import { DashboardTotalSubsAndCancelsComponent } from '../components/dashboard/total-subs-and-cancels/total-subs-and-cancels';
import { DashboardTotalSubsAndCancelsSCComponent } from '../components/dashboard/total-subs-and-cancels-sc/total-subs-and-cancels-sc';
import { DashboardTotalSubsAndCancelsSCPPComponent } from '../components/dashboard/total-subs-and-cancels-sc-pp/total-subs-and-cancels-sc-pp';
import { DashboardTotalSubsAndCancelsDPComponent } from '../components/dashboard/total-subs-and-cancels-dp/total-subs-and-cancels-dp';
import { DashboardAlunosListaesperaComponent } from '../components/dashboard/alunos-listaespera/alunos-listaespera';
import { DashboardFeedbacksComponent } from '../components/dashboard/feedbacks/feedbacks';
import { CustomerAnamnesisComponent } from '../components/customer/anamnesis/anamnesis.component';
import { CustomerNotesComponent } from '../components/customer/notes/notes.component';
import { CustomerFilesComponent } from '../components/customer/files/files.component';
import { CustomerFormComponent } from '../components/customer/form/form.component';
import { CustomerHistoryComponent } from '../components/customer/history/history.component';
import { CustomerProgramsComponent } from '../components/customer/programs/programs.component';
import { CustomerMenusComponent } from '../components/customer/menus/menus.component';
import { CustomerWaitlineComponent } from '../components/customer/waitline/waitline.component';
import { CustomerAccountComponent } from '../components/customer/account/account.component';
import { CustomerPaymentsComponent } from '../components/customer/payments/payments.component';
import { CustomerProfileComponent } from '../components/customer/profile/profile.component';
import { CustomerFeedbackComponent } from '../components/customer/feedback/feedback.component';
import { UserFormComponent } from '../components/user/form/form.component';

@NgModule({
  declarations: [
    AppComponent,
    MaterialNavComponent,
    BasicNavComponent,
    NavTeacherComponent,
    NavMasterComponent,
    NavCustomerComponent,
    NavAdminComponent,
    NavSimpleComponent,
    NavPartnerComponent,
    NavNutriComponent,
    DialogComponent,
    DialogLightComponent,
    DialogLoginComponent,
    DialogSelectBannerComponent,
    DialogValuationComponent,
    DialogSelectFoodComponent,
    DialogSelectTrainingBannerComponent,
    DialogSelectDateComponent,
    DialogLogoProComponent,
    DialogSendToTeacherComponent,
    DialogI18nComponent,
    DialogTutorialComponent,
    DialogInputComponent,
    DialogInputCustomComponent,
    DialogLinkComponent,
    DialogCancelPlanComponent,
    DialogExportPDFComponent,
    DialogFeedbacksComponent,
    ProgramasComponent,
    TreinosComponent,
    LoginComponent,
    PerfilComponent,
    ProgramsComponent,
    ProgramsMasterComponent,
    TrainingsComponent,
    WorkoutsComponent,
    ClientesComponent,
    UsuariosComponent,
    AdminAccountComponent,
    NotFoundComponent,
    ExerciciosComponent,
    LibraryComponent,
    LibraryFilterPipe,
    StudentFilterPipe,
    ProgramFilterPipe,
    FoodFilterPipe,
    TeacherFilterPipe,
    ArquivosFilterPipe,
    MenusFilterPipe,
    BibliotecaComponent,
    AvisosComponent,
    BannersComponent,
    ConfigComponent,
    ProgramCategoriesComponent,
    DashboardProfileComponent,
    DashboardTotalAlunosComponent,
    DashboardTotalProgramsComponent,
    DashboardTempoConsultoriaComponent,
    DashboardUltimasPrescricoesComponent,
    DashboardProgramsGoalsComponent,
    DashboardTrainingsDoneComponent,
    DashboardTotalSubsAndCancelsComponent,
    DashboardTotalSubsAndCancelsSCComponent,
    DashboardTotalSubsAndCancelsSCPPComponent,
    DashboardTotalSubsAndCancelsDPComponent,
    DashboardAlunosListaesperaComponent,
    DashboardFeedbacksComponent,
    CadastroComponent,
    AlunosComponent,
    AlunosWaitlineComponent,
    AlunosPrecadComponent,
    AlunosAnamneseComponent,
    AlunosCadastroComponent,
    ProfessoresComponent,
    ListaAdmProfessoresComponent,
    ArquivosComponent,
    MenusComponent,
    MaterialSidenavComponent,
    DashboardComponent,
    NutricaoComponent,
    StoreComponent,
    CustomerAnamnesisComponent,
    CustomerNotesComponent,
    CustomerFilesComponent,
    CustomerFormComponent,
    CustomerHistoryComponent,
    CustomerProgramsComponent,
    CustomerMenusComponent,
    CustomerWaitlineComponent,
    CustomerGoalsComponent,
    GroupsMuscleComponent,
    WorkoutVideosComponent,
    CustomerAccountComponent,
    CustomerPaymentsComponent,
    CustomerProfileComponent,
    CustomerFeedbackComponent,
    UserFormComponent,
  ],
  entryComponents: [
    DialogComponent,
    DialogLightComponent,
    DialogLoginComponent,
    DialogSelectBannerComponent,
    DialogValuationComponent,
    DialogSelectFoodComponent,
    DialogSelectTrainingBannerComponent,
    DialogSelectDateComponent,
    DialogLogoProComponent,
    DialogSendToTeacherComponent,
    DialogI18nComponent,
    DialogTutorialComponent,
    DialogInputComponent,
    DialogInputCustomComponent,
    DialogLinkComponent,
    DialogCancelPlanComponent,
    DialogExportPDFComponent,
    DialogFeedbacksComponent,
  ],
  imports: [
    DeviceDetectorModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    FlexLayoutModule,
    MatNativeDateModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatCheckboxModule,
    MatStepperModule,
    MatDatepickerModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatTooltipModule,
    MatTabsModule,
    MatDialogModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    MatSortModule,
    MatTableModule,
    MatProgressBarModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DropzoneModule,
    DragDropModule,
    NgxChartsModule,
    InfiniteScrollModule,
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
    ImageCropperModule,
  ],
  exports: [
    ProgramsComponent,
    ProgramsMasterComponent,
    TrainingsComponent,
    WorkoutsComponent,
  ],
  providers: [
    Title,
    { provide: SAVER, useFactory: getSaver },
    // { provide: MAT_DATE_LOCALE, useValue: 'pt' },
    { provide: MAT_DATE_LOCALE, 
      useFactory: (translate: I18nProvider) => {
        let lang = translate.getLanguage();
        switch (lang) {
            case 'br':
                registerLocaleData(localePt);
                lang = 'pt';
                break;
            case 'en':
                registerLocaleData(localeEn);
                break;
            case 'es':
                registerLocaleData(localeEs);
                break;
            default:
                registerLocaleData(localePt);
                break;
        }
        return lang;
      },
      deps: [I18nProvider]
    },
    // { provide: LOCALE_ID, useValue: 'pt' },
    { provide: LOCALE_ID, 
      useFactory: (translate: I18nProvider) => {
        let lang = translate.getLanguage();
        switch (lang) {
            case 'br':
                registerLocaleData(localePt);
                lang = 'pt';
                break;
            case 'en':
                registerLocaleData(localeEn);
                break;
            case 'es':
                registerLocaleData(localeEs);
                break;
            default:
                registerLocaleData(localePt);
                break;
        }
        return lang;
      },
      deps: [I18nProvider]
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    DeviceDetectorService,
    AuthGuardService,
    AdmGuardService,
    TeacherGuardService,
    NutriGuardService,
    PartnerGuardService,
    AuthService,
    ApiProvider,
    HelpersProvider,
    PurchaseService,
    ProgramProvider,
    TrainingProvider,
    WorkoutProvider,
    GoalProvider,
    GroupsMuscleProvider,
    LibraryProvider,
    BannerProvider,
    ConfigProvider,
    ProgramCategoryProvider,
    CustomerProvider,
    UserProvider,
    UserPurchaseService,
    FoodProvider,
    CacheProvider,
    FileProvider,
    MenuProvider,
    MealProvider,
    ValuationProvider,
    I18nProvider,
    DownloadService,
    LibraryFilterPipe,
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
