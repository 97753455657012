import { Injectable } from '@angular/core';
import { ApiProvider } from '../api/api';

@Injectable()
export class WorkoutProvider {

  constructor(
    public api: ApiProvider
  ) {
    //
  }

  workouts(trainingId = '') {
    const url = 'v2/workouts';
    return this.api.get(url, { params: { tid: trainingId } });
  }

  workout(id: any) {
    return this.api.get(`v2/workouts/${id}`);
  }

  patchWorkout(workout: any) {
    workout._method = 'PATCH';
    return this.api.post(`v2/workouts/${workout.id}`, workout);
  }

  postWorkout(workout: any) {
    return this.api.post(`v2/workouts/`, workout);
  }

  deleteWorkout(id: any) {
    return this.api.delete(`v2/workouts/${id}`);
  }

  reorderWorkout(workouts: any) {
    return this.api.post(`v2/workouts/reorder/`, workouts);
  }

  // series
  patchWorkoutSerie(serie: any) {
    serie._method = 'PATCH';
    return this.api.post(`v2/workouts/series/${serie.id}`, serie);
  }

  postWorkoutSerie(serie: any) {
    return this.api.post(`v2/workouts/series`, serie);
  }

  deleteWorkoutSerie(id: any) {
    return this.api.delete(`v2/workouts/series/${id}`);
  }


  deleteMassTraining(tids: any) {
    return this.api.delete(`v2/workouts/mass`, { params: tids });
  }

  updateMassWorkout(workout: any) {
    return this.api.post(`v2/workouts/mass/`, workout);
  }

  cloneTraining(ids: any) {
    return this.api.post(`v2/workouts/clone`, ids);
  }

  getVideoMigrate(data: any) {
    const url = `data/adm/migrateVimeoID?o=${data.old}&n=${data.new}&l=${data.qtd}&m=${data.master}`;
    return this.api.get(url);
  }
  
  postVideoMigrate(data: any) {
    const url = `data/adm/migrateVimeoID?o=${data.old}&n=${data.new}&l=${data.qtd}&m=${data.master}`;
    return this.api.post(url);
  }


}
